import { gql } from "@apollo/client";

export default gql`
  query findBaseDetails($baseSlug: String, $baseId: String) {
    findBaseDetails(baseSlug: $baseSlug, baseId: $baseId) {
      _id
      name
      logo
      bannerImage
      city
      country
      sport
      createdBy
      description
      sliderImages
      address
      slug
      isSuperbase
      slotPurchased {
        parentId
        slotAvailable
      }

      slotDetails {
        price
        discount
        subTtile
        description
      }
      membershipDetails {
        price
        discount
        subTtile
        description
      }

      limitedStreamingRecords {
        _id
        userDetails {
          _id
          name
          avatar
        }
        caption
        videoThumbnailUrl
        url
        createdAt
      }
    }
  }
`;
