import { gql } from "@apollo/client";

export default gql`
  query findAllKidMembersOfTheBase($baseId: String!) {
    findAllKidMembersOfTheBase(baseId: $baseId) {
        _id
        name
        parentId
    }
  }
`;