import React, { useEffect } from "react";
import {
  LOCAL_STORAGE_KEYS,
  EVENTS_TYPE,
  EVENT_APPROVAL_STATUSES,
} from "../../utils/constant";
import { format, formatISO } from "date-fns";
import { Link, useNavigate } from "react-router-dom";

import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import FIND_MY_INVENTED_EVENT from "../../gql/query/base/findMyInvitedEvent.query";
import FIND_MY_BOOKED_EVENT from "../../gql/query/base/findMyBookedEvents.query";
import CommonBackdrop from "../../component/common/CommonBackdrop";

function MyEvents() {
  const navigate = useNavigate();

  const {
    data: invitedEventData,
    loading: invitedEventLoading,
    error: invitedEventError,
    refetch: InvitedEventRefetch,
  } = useQuery(FIND_MY_INVENTED_EVENT, {
    variables: {
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });

  const {
    data: bookedEventData,
    loading: bookedEventLoading,
    error: bookedEventError,
    refetch: bookedEventRefetch,
  } = useQuery(FIND_MY_BOOKED_EVENT, {
    variables: {
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });

  // Set data of events
  useEffect(() => {
    function fetchData() {
      InvitedEventRefetch();
      bookedEventRefetch();
    }
    fetchData();
  }, []);

  if (invitedEventLoading || bookedEventLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );
  return (
    <CommonBackdrop rootName='Schedule' pageName='My Events'>
      <div>
        <div className="w-full flex flex-row mt-7 ">
          <div className="w-1/2 ">
            <h1 className="text-center text-2xl" style={{ color: "#a1bddf" }}>
              My booked events
            </h1>
            {bookedEventData?.findMyBookedEvents?.map((event, index) => {
              return (
                <div>
                  {/* DATE + EVENT info + BOOK session button */}
                  <div
                    className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:ml-5 py-2"
                    key={index}
                    style={{
                      borderLeft: "5px solid yellow",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    {/* DATE & Event INFO */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="py-2"
                    >
                      {/* DATE section */}
                      <div
                        style={{
                          color: "white",
                          width: "80px",
                          borderRight: "1px dotted #ffffff45",
                          paddingLeft: "15px",
                        }}
                        className="text-2xl"
                      >
                        {format(new Date(event.dateTime), "EEE MMM dd")}
                      </div>

                      {/* Schdule INFO section */}
                      <div className="font-semibold ml-5">
                        {/* TIME */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                            <div className="w-1 h-1 rounded-full bg-black"></div>
                          </div>
                          {/* Time value */}
                          <div
                            style={{ color: "#8F9BB3" }}
                            className="text-sm ml-2"
                          >
                            {event.eventType}
                          </div>
                        </div>

                        {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
                          <>
                            {event.caption ? (
                              <div className="text-xl mt-1 mb-2">
                                {event.caption}
                              </div>
                            ) : (
                              <div className="text-xl mt-1 mb-2">
                                Special Event
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {/* CAPTION */}
                            <div className="text-sm">
                              Event Type:{" "}
                              <b style={{ color: "#ccd0e0" }}>
                                {event.eventType}
                              </b>
                            </div>

                            {/* CAPTION */}
                            {event.caption && (
                              <div className="text-sm">
                                Title:{" "}
                                <b style={{ color: "#ccd0e0" }}>
                                  {event.caption}
                                </b>
                              </div>
                            )}
                          </>
                        )}

                        {/* CAPTION */}
                        {event.unseenMessageCounts > 0 && (
                          <div
                            className="text-lg bg-slate-600 px-5 py-3 rounded-lg flex items-center"
                            style={{ color: "gold" }}
                          >
                            Messages from Admin{" "}
                            <div
                              className="ml-3 text-lg w-[30px] h-[30px] rounded-2xl flex items-center"
                              style={{
                                color: "white",
                                backgroundColor: "red",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              {event.unseenMessageCounts}
                            </div>
                          </div>
                        )}

                        {/* DURATIOON */}
                        {event.duration && (
                          <div className="text-sm">
                            Duration:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.duration}</b>
                          </div>
                        )}

                        {/* SPORTS */}
                        {event.sports && event.sports.length > 0 && (
                          <div className="text-sm">
                            Sports:{" "}
                            <b style={{ color: "#ccd0e0" }}>
                              {event.sports.join(", ")}
                            </b>
                          </div>
                        )}

                        {/* GAME TYPE */}
                        {event.gameType && (
                          <div className="text-sm">
                            Game Type:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.gameType}</b>
                          </div>
                        )}

                        {/* SLOT */}
                        <div>
                          <div className="text-xs flex flex-wrap items-center">
                            {event?.timeSlots?.map((slot) => (
                              <div
                                className={`bg-green-500 px-2 py-2 rounded-lg mt-2 mr-2 font-bold`}
                                style={{ color: "white" }}
                              >
                                {slot}
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* PRICE */}
                        {event.eventPrice && (
                          <div
                            className="text-md mt-2"
                            style={{ color: "#00fff5" }}
                          >
                            Price: ${event.eventPrice}
                          </div>
                        )}
                      </div>
                    </div>

                    <button
                      className="text-sm mr-1  right-[68px]   font-bold py-1 px-5 rounded-full max-h-7 mt-5"
                      style={{
                        backgroundColor: "#0000008a",
                        color: "#FFFFFF",
                      }}
                      onClick={() => {
                        navigate(`${`/event-details/${event._id}`}`);
                      }}
                    >
                      DETAILS
                    </button>
                  </div>
                </div>
              );
            })}

            {(!bookedEventData?.findMyBookedEvents ||
              bookedEventData.findMyBookedEvents.length == 0) && (
              <div className="text-center mt-10">
                You have no booked events!
              </div>
            )}
          </div>

          <div className="w-1/2">
            <h1 className="text-center text-2xl" style={{ color: "#a1bddf" }}>
              Invited events
            </h1>
            {invitedEventData?.findMyInvitedEvent?.map((event, index) => {
              return (
                <div>
                  {/* DATE + EVENT info + BOOK session button */}
                  <div
                    className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:ml-5 py-2"
                    key={index}
                    style={{
                      borderLeft: "5px solid yellow",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* DATE & Event INFO */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="py-2"
                    >
                      {/* DATE section */}
                      <div
                        style={{
                          color: "white",
                          width: "80px",
                          borderRight: "1px dotted #ffffff45",
                          paddingLeft: "15px",
                        }}
                        className="text-2xl"
                      >
                        {format(new Date(event.dateTime), "EEE MMM dd")}
                      </div>

                      {/* Schdule INFO section */}
                      <div className="font-semibold ml-5">
                        {/* TIME */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                            <div className="w-1 h-1 rounded-full bg-black"></div>
                          </div>
                          {/* Time value */}
                          <div
                            style={{ color: "#8F9BB3" }}
                            className="text-sm ml-2"
                          >
                            {format(new Date(event.dateTime), "h:mm aa")}
                          </div>
                        </div>

                        {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
                          <>
                            {event.caption ? (
                              <div className="text-xl mt-1 mb-2">
                                {event.caption}
                              </div>
                            ) : (
                              <div className="text-xl mt-1 mb-2">
                                Special Event
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {/* CAPTION */}
                            <div className="text-sm">
                              Event Type:{" "}
                              <b style={{ color: "#ccd0e0" }}>
                                {event.eventType}
                              </b>
                            </div>

                            {/* CAPTION */}
                            {event.caption && (
                              <div className="text-sm">
                                Title:{" "}
                                <b style={{ color: "#ccd0e0" }}>
                                  {event.caption}
                                </b>
                              </div>
                            )}
                          </>
                        )}

                        {/* DURATIOON */}
                        {event.duration && (
                          <div className="text-sm">
                            Duration:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.duration}</b>
                          </div>
                        )}

                        {/* SPORTS */}
                        {event.sports && event.sports.length > 0 && (
                          <div className="text-sm">
                            Sports:{" "}
                            <b style={{ color: "#ccd0e0" }}>
                              {event.sports.join(", ")}
                            </b>
                          </div>
                        )}

                        {/* GAME TYPE */}
                        {event.gameType && (
                          <div className="text-sm">
                            Game Type:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.gameType}</b>
                          </div>
                        )}

                        {/* SLOT */}
                        {event.slot && (
                          <div className="text-sm">
                            #SLOT:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.slot}</b>
                          </div>
                        )}

                        {/* PRICE */}
                        {event.eventPrice && (
                          <div
                            className="text-md mt-2"
                            style={{ color: "#00fff5" }}
                          >
                            Price: ${event.eventPrice}
                          </div>
                        )}
                      </div>
                    </div>

                    <button
                      className="text-sm  mr-1   font-bold py-1 px-5 rounded-full max-h-7 mt-5"
                      style={{
                        backgroundColor: "#0000008a",
                        color: "#FFFFFF",
                      }}
                      onClick={() => {
                        navigate(`/event-details/${event._id}?isInvited=true`);
                      }}
                    >
                      DETAILS
                    </button>
                  </div>
                </div>
              );
            })}

            {(!invitedEventData?.findMyInvitedEvent ||
              invitedEventData?.findMyInvitedEvent.length == 0) && (
              <div className="text-center mt-10">
                You have no new invited events!
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonBackdrop>
  );
}

export default MyEvents;
