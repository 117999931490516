import gql from "graphql-tag";

export default gql`
  mutation createEventByClient(
    $createdBy: String
    $baseSlug: String
    $eventType: String
    $caption: String
    $dateTime: String
    $sports: [String]
    $gameType: String
    $address: String
    $paymentMethod: String
    $eventStartHour: Int
    $eventEndHour: Int
    $venmoOrZelleTrnxId: String
  ) {
    createEventByClient(
      createdBy: $createdBy
      baseSlug: $baseSlug
      eventType: $eventType
      caption: $caption
      dateTime: $dateTime
      sports: $sports
      gameType: $gameType
      address: $address
      paymentMethod: $paymentMethod
      eventStartHour: $eventStartHour
      eventEndHour: $eventEndHour
      venmoOrZelleTrnxId: $venmoOrZelleTrnxId
    ) {
      _id
      additionalData
      successMessage
    }
  }
`;
