import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  format,
  addYears,
  formatISO,
  addMonths,
  addDays,
  differenceInDays,
  addHours,
  startOfDay,
  isAfter,
  endOfDay,
  differenceInHours,
} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { utcToZonedTime, format as tzFormat } from "date-fns-tz";
import { Image } from "cloudinary-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  BASEMENTSPORTS_BANNER,
  BASEMENT_SPORTS_OFFICAL_LOCATION,
  CLIENT_CREATED_EVENT_PAYMENT_STATUSES,
  CLOUDINARY_INFO,
  EVENT_DURATION,
  EVENT_DURATION_HOUR,
  EVENT_TIME_FOR_A_DAY,
  LOCAL_STORAGE_KEYS,
  PAYMENT_METHOD,
  TIME_SLOTS_DATA,
  WEEK_DAYAS,
} from "../../utils/constant";
import DataLoading from "../../component/loader/DataLoading";
import ClipLoader from "react-spinners/ClipLoader";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import Confetti from "react-confetti";

// Rich text editor import
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Query
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";
import FIND_USED_HOURS from "../../gql/query/base/findUsedHours.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";

// Mutations
import CREATE_EVENT_BY_CLIENT from "../../gql/mutation/base/createEventByClient.mutations";
import { calculateEventEndTime } from "../../utils/eventTime";

function AddEventByClient() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  // Query parameters
  const search = useLocation().search;
  let preSelectedDate = new URLSearchParams(search).get("preSelectedDate");
  if (preSelectedDate) {
    preSelectedDate = preSelectedDate.replace(" ", "+");
  }

  const preSelectedSlot = new URLSearchParams(search).get("preSelectedSlot");

  const [dropdownShow, setDropdownShow] = useState(false);
  const [eventType, setEventType] = useState("Special Events");
  const [caption, setCaption] = useState("");
  const [address, setAddress] = useState("");
  const [eventPrice, setEventPrice] = useState(0);
  const [amountToPay, setAmountToPay] = useState(0);

  // Coupon State
  const [promoCode, setPromoCode] = useState("");
  const [coupons, setCoupons] = useState([]);

  // Checkout PAGE STATE
  const [isCheckoutPage, setIsCheckoutPage] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [venmoOrZelleTrnxId, setVenmoOrZelleTrnxId] = useState(""); // Client will enter venmo or zelle transaction ID

  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedSportsForComponent, setSelectedSportsForComponent] = useState(
    []
  );
  const [selectedGametype, setSelectedGametype] = useState("");
  const [selectedGametypeForComponent, setSelectedGametypeForComponent] =
    useState("");
  const [startDate, setStartDate] = useState(
    preSelectedDate ? new Date(preSelectedDate) : new Date()
  );
  const [timeSlots, setTimeSlots] = useState(
    preSelectedSlot ? [preSelectedSlot] : []
  );

  // Whole day price
  const [realPriceOfDay, setRealPriceOfDay] = useState(0);

  // Base opening & ending time
  const [baseOpeningTime, setBaseOpeningTime] = useState(new Date());
  const [baseEndingTime, setBaseEndingTime] = useState(new Date());

  // Event start time
  const [eventStartTime, setEventStartTime] = useState(new Date());
  const [eventStartHour, setEventStartHour] = useState(0);

  // Event end time
  const [eventEndTime, setEventEndTime] = useState(new Date());
  const [eventEndHour, setEventEndHour] = useState(0);

  // All used dates
  const [usedHoursOfSelectedDate, setUsedHoursOfSelectedDate] = useState([]);

  // All available hours
  const [availableHours, setAvailableHours] = useState([]);

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // Thank you page after event
  const [thankYouPage, setThankYouPage] = useState(false);
  const [newlyCreatedEventId, setNewlyCreatedEventId] = useState(null);

  // set window size
  const [windowSize, setWindowSize] = useState(getWindowSize());

  // Query
  const {
    data: allUsedHours,
    loading: allUsedHoursLoading,
    error: allUsedHoursError,
    refetch: allUsedHoursRefetch,
  } = useQuery(FIND_USED_HOURS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      selectedDate: startDate,
    },
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [
    createEventMutation,
    { error: createEventError, loading: createEventMutationLoading },
  ] = useMutation(CREATE_EVENT_BY_CLIENT);

  // DATE variables
  let oneYearFuture = addYears(new Date(), 1);

  // Initialize event data
  useEffect(() => {
    function initializeEventData() {
      // Set location
      // Set Location
      setAddress(
        baseData?.findBaseDetails?.address
          ? baseData.findBaseDetails.address
          : BASEMENT_SPORTS_OFFICAL_LOCATION
      );

      if (baseData?.findBaseDetails) {
        setRealPriceOfDay(
          baseData?.findBaseDetails?.capacity *
            baseData?.findBaseDetails?.slotDetails?.price
        );
      }
    }
    initializeEventData();
  }, [baseData]);

  // Update amount To PAY
  useEffect(() => {
    function updateAmountToPay() {
      if (baseData?.findBaseDetails) {
        setAmountToPay(
          (differenceInHours(eventEndTime, eventStartTime) < 0
            ? 0
            : differenceInHours(eventEndTime, eventStartTime)) *
            baseData?.findBaseDetails?.perHourPrice
        );
      }
    }
    updateAmountToPay();
  }, [eventStartTime, eventEndTime]);

  // Set used HOURS
  useEffect(() => {
    function setUsedHours() {
      if (allUsedHours?.findUsedHours && baseData?.findBaseDetails) {
        // The used hours
        let usedHours = JSON.parse(allUsedHours?.findUsedHours?.additionalData);
        let finalDateObjects = [];

        // Convert all used hours to DATE Object
        for (let index = 0; index < usedHours.length; index++) {
          let currentTime = new Date();
          currentTime.setHours(usedHours[index]);
          currentTime.setMinutes(0);
          currentTime.setSeconds(0);
          finalDateObjects.push(currentTime);
        }

        // Set used DATE objects into a STATE
        setUsedHoursOfSelectedDate(finalDateObjects);

        // Find all the available hours of the BASE
        let baseHours = [];
        let baseOpeningHour = baseData?.findBaseDetails?.baseOpeningHour;
        let baseClosingHour = baseData?.findBaseDetails?.baseClosingHour;
        for (let index = baseOpeningHour; index <= baseClosingHour; index++) {
          baseHours.push(index);
        }

        // Find all the available HOURS to use
        let allAvailableHours = baseHours.filter(
          (baseHour) => !usedHours.includes(baseHour)
        );
        setAvailableHours(allAvailableHours); // Set the available hours

        // If there is allAvailableHours then set that allAvailableHours
        // Else, set the base opening hour as event start hour & end as end hour
        let tempEventStartHour = null;
        let tempEventEndHour = null;
        if (allAvailableHours.length > 0) {
          tempEventStartHour = allAvailableHours[0];
          tempEventEndHour = allAvailableHours[allAvailableHours.length - 1];
        } else {
          tempEventStartHour = baseData.findBaseDetails.baseOpeningHour;
          tempEventEndHour = baseData.findBaseDetails.baseClosingHour;
        }

        // Set the EVENT start time
        // Set the EVENT start time
        let currentDate = new Date();
        // set hour, muntes & seconds
        currentDate.setHours(tempEventStartHour);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        setBaseOpeningTime(currentDate);
        // set event start time & hour
        setEventStartTime(currentDate);
        setEventStartHour(tempEventStartHour);

        // Set the EVENT end time
        // Set the EVENT end time
        let newCurrentDate = new Date();
        // set hour, muntes & seconds
        newCurrentDate.setHours(tempEventEndHour);
        newCurrentDate.setMinutes(0);
        newCurrentDate.setSeconds(0);
        setBaseEndingTime(newCurrentDate);
        // set event end time & hour
        setEventEndTime(currentDate); // Setting the end time as event start time
        setEventEndHour(tempEventStartHour); // Setting the end hour as event start hour
      }
    }
    setUsedHours();
  }, [allUsedHours, baseData]);

  // Set window size
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    return {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    };
  }

  // Handle Event Type Dropdown
  const handleDropdown = (e) => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-toggle")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };

  // Handle Event Type selection & clean other STATEs
  function handleEventTypeSelection(value) {
    setEventType(value);

    // Clean STATEs
    setSelectedSports([]);
    setSelectedSportsForComponent([]);
    setSelectedGametype("");
    setSelectedGametypeForComponent("");
  }

  // Dropdown Options of Event Type
  const eventTypeList = [{ type: "Special Events" }];

  // Handle Sports dropdown
  const handleSelectSportsChange = (selectedOptions) => {
    setSelectedSportsForComponent(selectedOptions);
    const values = selectedOptions.map((option) => option.value);
    setSelectedSports(values);
  };

  // Dropdown Options of Sports
  const sportsOption = [
    { value: "soccer", label: "Soccer" },
    { value: "hockey", label: "Hockey" },
    { value: "baseBall", label: "Baseball" },
    { value: "basketBall", label: "Basketball" },
    { value: "pickelBall", label: "Pickelball" },
    { value: "tennis", label: "Tennis" },
    { value: "racket", label: "Racket" },
    { value: "volleyBall", label: "Volleyball" },
  ];

  // Handle Gametype dropdown
  const handleSelectGameTypeChange = (selectedOptions) => {
    setSelectedGametypeForComponent(selectedOptions);
    setSelectedGametype(selectedOptions.value);
  };

  // Dropdown Options of Game Type
  const gameTypesOption = [
    { value: "1v", label: "1V" },
    { value: "2v", label: "2V" },
    { value: "3v", label: "3V" },
  ];

  // Set custom style in react-select
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black", // Set the color for all options
    }),
  };

  // Coupon related method
  const addCoupon = () => {
    if (coupons.length > 0) {
      return;
    }
    // a function to add a new coupon object to the array
    setCoupons([
      ...coupons,
      {
        code: "",
        // startDate: new Date(),
        // endDate: null,
      },
    ]);
  };

  // a function to remove a coupon object from the array by index
  const removeCoupon = (index) => {
    setCoupons(coupons.filter((_, i) => i !== index));
  };

  // a function to update a coupon object by index, key and value
  const handleChange = (index, key, value) => {
    setCoupons(
      coupons.map((coupon, i) =>
        i === index ? { ...coupon, [key]: value } : coupon
      )
    );
  };

  // Check if coupon is valid or not
  function isValidCoupon(promoCode) {
    return String(promoCode).match(/^[a-zA-Z][a-zA-Z0-9]*[0-9]$/);
  }

  // Submit to create Event
  async function goToPaymentConfirmationPage() {
    // Check EventType was given or not
    if (
      baseData?.findBaseDetails?.eventMinimumHour &&
      differenceInHours(eventEndTime, eventStartTime) <
        baseData?.findBaseDetails?.eventMinimumHour
    ) {
      toast.error(
        `You can't submit a request that is less than ${baseData?.findBaseDetails?.eventMinimumHour} hour`
      );
      return;
    }

    if (differenceInHours(eventEndTime, eventStartTime) <= 0) {
      toast.error("You can't submit a request that is less than 1 hour");
      return;
    }

    // Check if the selected start time & end time sequential or not
    let selectedHours = [];
    for (let hour = eventStartHour; hour <= eventEndHour; hour++) {
      selectedHours.push(hour);
    }
    let unavailableButSelectedHours = selectedHours.filter(
      (hour) => !availableHours.includes(hour)
    );
    if (unavailableButSelectedHours.length > 0) {
      toast.error(
        "Start and End time should be sequential within available hours!"
      );
      return;
    }

    // Check Coupons are valid or not
    if (coupons && coupons.length > 0) {
      // Check if invalid coupon code exist or not
      for (let index = 0; index < coupons.length; index++) {
        const coupon = coupons[index];
        if (!coupon.code || coupon.code == "") {
          toast.error("Coupon code can't be empty!");
          return;
        }
        if (!String(coupon.code).match(/^[a-zA-Z][a-zA-Z0-9]*[0-9]$/)) {
          toast.error("Invalid coupon codes found!");
          return;
        }
      }
      // Check if all given coupon codes are unique
      let codes = coupons.map((obj) => obj.code); // Create an array of codes
      let set = new Set(codes); // Remove all repeated codes from the array
      if (set.size != coupons.length) {
        toast.error("All coupon codes must be unique!");
        return;
      }
    }
    // Check startedate
    if (startDate == "" || startDate == null) {
      toast.error("Please select a date & time!");
      return;
    }
    // Check if EventType is Tournament or League or Contest
    if (
      (eventType === "Tournament" ||
        eventType === "League" ||
        eventType === "Contest") &&
      (!selectedSports || selectedSports == null || selectedSports.length == 0)
    ) {
      toast.error("Please select at least one Sport!");
      return;
    }
    // Check if EventType Tournament or League
    if (
      (eventType === "Tournament" || eventType === "League") &&
      (selectedGametype == "" || selectedGametype == null)
    ) {
      toast.error("Please select Game Type!");
      return;
    }

    // Move to checkout PAGE
    setIsCheckoutPage(true);
  }

  // Confirm payment & create event
  async function payAndCreateEvent() {
    if (!selectedPaymentMethod || selectedPaymentMethod == "") {
      toast.error("Please select a payment method to create this event!");
      return;
    }

    // Set time format
    let dateToSubmit = formatISO(startDate, { representation: "complete" });

    const { data } = await createEventMutation({
      variables: {
        createdBy: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        eventType,
        caption: caption == "" ? null : caption,
        dateTime: dateToSubmit,
        sports: selectedSports.length == 0 ? null : selectedSports,
        gameType: selectedGametype == "" ? null : selectedGametype,
        address,
        paymentMethod: selectedPaymentMethod,
        eventStartHour: Number(eventStartHour),
        eventEndHour: Number(eventEndHour),
        venmoOrZelleTrnxId:
          selectedPaymentMethod == PAYMENT_METHOD.VENMO ||
          selectedPaymentMethod == PAYMENT_METHOD.ZELLE
            ? venmoOrZelleTrnxId
            : null,
      },
    });

    if (data.createEventByClient.errorMessage == null) {
      if (
        selectedPaymentMethod == PAYMENT_METHOD.PAY_BY_CASH ||
        selectedPaymentMethod == PAYMENT_METHOD.VENMO ||
        selectedPaymentMethod == PAYMENT_METHOD.ZELLE
      ) {
        toast.success("Successfully added event");
        setThankYouPage(true);
        setNewlyCreatedEventId(data.createEventByClient._id);
      } else if (
        selectedPaymentMethod == PAYMENT_METHOD.DEPOSIT_BY_STRIPE ||
        selectedPaymentMethod == PAYMENT_METHOD.STRIPE
      ) {
        window.location.href = data.createEventByClient.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      }
    }
  }

  if (thankYouPage) {
    return (
      <CommonBackdrop rootName='Events' pageName='Add New Event'>
        <div className="max-w-5xl mx-auto py-3 px-4 ">
          <div className="text-5xl mt-7 mb-10" style={{ textAlign: "center" }}>
            Event has been created successfully 🎉
          </div>

          <div className="text-3xl">
            <img
              src={BASEMENTSPORTS_BANNER}
              alt="BasementSports"
              style={{
                borderRadius: "10px",
                maxHeight: "480px",
                objectFit: "cover",
                width: "100%",
              }}
              className="mt-5"
            />
          </div>

          <button
            className="mt-7 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
            onClick={() => {
              navigate(`/event-details/${newlyCreatedEventId}`);
            }}
          >
            Event Details
          </button>
        </div>
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={600}
          recycle={false}
        />
      </CommonBackdrop>
    );
  }

  return (
    <CommonBackdrop rootName='Events' pageName='Add New Event'>
      {isCheckoutPage ? (
        <div className="max-w-5xl mx-auto py-3 px-4 mb-14">
          <div
            className="flex items-center mt-6 mb-8"
            style={{ justifyContent: "space-between" }}
          >
            <h3
              className="text-center font-bold text-[36px] my-2"
              style={{ position: "relative" }}
            >
              Confirm Payment
            </h3>

            <div
              className="bg-slate-500 text-white px-2 py-2 rounded-lg text-sm flex items-center cursor-pointer"
              onClick={() => {
                setIsCheckoutPage(false);
              }}
            >
              <FontAwesomeIcon className="mr-2" icon={faClose} />
              Go Back
            </div>
          </div>

          {/* Checkout page Event TYPE */}
          {/* Checkout page Event TYPE */}
          <div className="my-3 flex items-center">
            <label className="mr-12 pr-16 font-bold" style={{ color: "gold" }}>
              Event Type
            </label>
            <div className="pl-2 w-64 bg-transparent rounded font-bold font-display text-xl font-bold">
              {eventType}
            </div>
          </div>

          {/* Checkout page Selected Date */}
          {/* Checkout page Selected Date */}
          <div className="my-3 flex items-center">
            <label className="mr-16 pr-8 font-bold" style={{ color: "gold" }}>
              Selected Date
            </label>
            <div className="bg-transparent rounded font-bold font-display text-xl font-bold">
              {format(startDate, "do MMMM yyyy")}
            </div>
          </div>

          {/* Event Start Time */}
          {/* Event Start Time */}
          <div className="my-3 flex items-center">
            <label
              className="min-w-[204px] font-bold"
              style={{ color: "gold" }}
            >
              Time
            </label>

            <div className="flex items-center text-xl font-bold">
              <div>{format(eventStartTime, "h:mm aa")}</div>
              <div className="text-md mx-3 text-yellow-200">to</div>
              <div>{format(eventEndTime, "h:mm aa")}</div>
            </div>
          </div>

          {/* Event Price */}
          {/* Event Price */}
          <div className="mt-10 flex items-center">
            <label
              className="w-[190px] font-bold text-xl"
              style={{ color: "cyan" }}
            >
              Total Amount
            </label>
            <div className="px-3 py-2 bg-transparent rounded text-2xl text-yellow-200 font-bold">
              $
              {baseData?.findBaseDetails?.perHourPrice *
                differenceInHours(eventEndTime, eventStartTime)}
            </div>
            <div className="text-2xl">
              ( ${baseData?.findBaseDetails?.perHourPrice} x{" "}
              {differenceInHours(eventEndTime, eventStartTime)} hours ){" "}
            </div>
          </div>

          {baseData?.findBaseDetails?.isEnabledDeposit &&
            differenceInDays(startDate, new Date()) >= 2 && (
              <div className="mt-1 flex items-center">
                <label
                  className="w-[195px] font-bold text-xl"
                  style={{ color: "cyan" }}
                >
                  Deposit
                </label>
                <div className="py-2 bg-transparent rounded text-2xl text-yellow-200 font-bold">
                  <label className="ml-2 font-bold cursor-pointer">
                    <b style={{ color: "gold" }}>
                      $
                      {(baseData?.findBaseDetails?.depositInPercent *
                        amountToPay) /
                        100}
                    </b>
                    <b className="font-normal ml-3" style={{ color: "white" }}>
                      ( Pay {baseData?.findBaseDetails?.depositInPercent}% now )
                    </b>
                  </label>
                </div>
              </div>
            )}

          <div className="mt-3 text-xl" style={{ display: "flex" }}>
            <label className="mr-11 font-bold" style={{ color: "cyan" }}>
              Payment Method
            </label>

            {!baseLoading && (
              <div>
                {/* Pay by Cash */}
                {baseData?.findBaseDetails?.payByCash && (
                  <div
                    className="flex items-center mb-2"
                    onClick={() => {
                      setSelectedPaymentMethod(
                        selectedPaymentMethod == PAYMENT_METHOD.PAY_BY_CASH
                          ? ""
                          : PAYMENT_METHOD.PAY_BY_CASH
                      );
                    }}
                  >
                    <input
                      className="border-none rounded-lg px-3 py-3 cursor-pointer"
                      type="checkbox"
                      checked={
                        selectedPaymentMethod == PAYMENT_METHOD.PAY_BY_CASH
                          ? true
                          : false
                      }
                    />
                    <label
                      className="ml-2 px-4 py-2 font-bold cursor-pointer rounded-lg"
                      style={{
                        backgroundColor: "green",
                        border:
                          selectedPaymentMethod == PAYMENT_METHOD.PAY_BY_CASH
                            ? "1px solid white"
                            : "",
                        opacity:
                          selectedPaymentMethod == PAYMENT_METHOD.PAY_BY_CASH
                            ? 1
                            : 0.5,
                      }}
                    >
                      Pay by Cash
                    </label>
                  </div>
                )}

                {/* ZELLE */}
                {/* ZELLE */}
                <div className="flex items-center mb-2">
                  <input
                    className="border-none rounded-lg px-3 py-3 cursor-pointer"
                    type="checkbox"
                    checked={selectedPaymentMethod == PAYMENT_METHOD.ZELLE}
                    onClick={() => {
                      setSelectedPaymentMethod(
                        selectedPaymentMethod == PAYMENT_METHOD.ZELLE
                          ? ""
                          : PAYMENT_METHOD.ZELLE
                      );
                    }}
                  />
                  <label
                    className="ml-2 px-4 py-2 font-bold cursor-pointer rounded-lg"
                    style={{
                      backgroundColor: "green",
                      border:
                        selectedPaymentMethod == PAYMENT_METHOD.ZELLE
                          ? "1px solid white"
                          : "",
                      opacity:
                        selectedPaymentMethod == PAYMENT_METHOD.ZELLE ? 1 : 0.5,
                    }}
                    onClick={() => {
                      setSelectedPaymentMethod(
                        selectedPaymentMethod == PAYMENT_METHOD.ZELLE
                          ? ""
                          : PAYMENT_METHOD.ZELLE
                      );
                    }}
                  >
                    Zelle
                  </label>
                  {selectedPaymentMethod == PAYMENT_METHOD.ZELLE && (
                    <div className="flex items-center">
                      <div className="ml-2 text-yellow-200">
                        {baseData?.findBaseDetails?.zelleNumber}
                      </div>
                      <input
                        className="ml-5 border-2 w-64 border-slate-500 px-2 py-1 bg-transparent rounded font-bold font-display"
                        onChange={(e) => {
                          setVenmoOrZelleTrnxId(e.target.value);
                        }}
                        value={venmoOrZelleTrnxId}
                        placeholder="Enter Zelle transaction id"
                      />
                    </div>
                  )}
                </div>

                {/* VENMO */}
                {/* VENMO */}
                <div className="flex items-center mb-2">
                  <input
                    className="border-none rounded-lg px-3 py-3 cursor-pointer"
                    type="checkbox"
                    checked={selectedPaymentMethod == PAYMENT_METHOD.VENMO}
                    onClick={() => {
                      setSelectedPaymentMethod(
                        selectedPaymentMethod == PAYMENT_METHOD.VENMO
                          ? ""
                          : PAYMENT_METHOD.VENMO
                      );
                    }}
                  />
                  <label
                    className="ml-2 px-4 py-2 font-bold cursor-pointer rounded-lg"
                    style={{
                      backgroundColor: "green",
                      border:
                        selectedPaymentMethod == PAYMENT_METHOD.VENMO
                          ? "1px solid white"
                          : "",
                      opacity:
                        selectedPaymentMethod == PAYMENT_METHOD.VENMO ? 1 : 0.5,
                    }}
                    onClick={() => {
                      setSelectedPaymentMethod(
                        selectedPaymentMethod == PAYMENT_METHOD.VENMO
                          ? ""
                          : PAYMENT_METHOD.VENMO
                      );
                    }}
                  >
                    Venmo
                  </label>
                  {selectedPaymentMethod == PAYMENT_METHOD.VENMO && (
                    <div className="flex items-center">
                      <div className="ml-2 text-yellow-200">
                        {baseData?.findBaseDetails?.venmoNumber}
                      </div>
                      <input
                        className="ml-5 border-2 w-64 border-slate-500 px-2 py-1 bg-transparent rounded font-bold font-display"
                        onChange={(e) => {
                          setVenmoOrZelleTrnxId(e.target.value);
                        }}
                        value={venmoOrZelleTrnxId}
                        placeholder="Enter Venmo transaction id"
                      />
                    </div>
                  )}
                </div>

                {/* PAY DEPOSIT */}
                {/* PAY DEPOSIT */}
                {baseData?.findBaseDetails?.isEnabledDeposit && (
                  <div>
                    {differenceInDays(startDate, new Date()) < 2 ? (
                      <div
                        className="flex items-center mb-2"
                        onClick={() => {
                          setSelectedPaymentMethod(
                            selectedPaymentMethod == PAYMENT_METHOD.STRIPE
                              ? ""
                              : PAYMENT_METHOD.STRIPE
                          );
                        }}
                      >
                        <input
                          className="border-none rounded-lg px-3 py-3 cursor-pointer"
                          type="checkbox"
                          checked={
                            selectedPaymentMethod == PAYMENT_METHOD.STRIPE // STRIPE to pay full payment amount
                              ? true
                              : false
                          }
                        />
                        <label
                          className="ml-2 px-4 py-2 font-bold cursor-pointer rounded-lg"
                          style={{
                            backgroundColor: "green",
                            border:
                              selectedPaymentMethod == PAYMENT_METHOD.STRIPE
                                ? "1px solid white"
                                : "",
                            opacity:
                              selectedPaymentMethod == PAYMENT_METHOD.STRIPE
                                ? 1
                                : 0.5,
                          }}
                        >
                          STRIPE
                        </label>
                      </div>
                    ) : (
                      <div
                        className="flex items-center mb-2"
                        onClick={() => {
                          setSelectedPaymentMethod(
                            selectedPaymentMethod ==
                              PAYMENT_METHOD.DEPOSIT_BY_STRIPE
                              ? ""
                              : PAYMENT_METHOD.DEPOSIT_BY_STRIPE
                          );
                        }}
                      >
                        {/* STRIPE */}
                        {/* STRIPE */}
                        <input
                          className="border-none rounded-lg px-3 py-3 cursor-pointer"
                          type="checkbox"
                          checked={
                            selectedPaymentMethod ==
                            PAYMENT_METHOD.DEPOSIT_BY_STRIPE // PAY only deposit amount
                              ? true
                              : false
                          }
                        />
                        <label
                          className="ml-2 px-4 py-2 font-bold cursor-pointer rounded-lg"
                          style={{
                            backgroundColor: "green",
                            border:
                              selectedPaymentMethod ==
                              PAYMENT_METHOD.DEPOSIT_BY_STRIPE
                                ? "1px solid white"
                                : "",
                            opacity:
                              selectedPaymentMethod ==
                              PAYMENT_METHOD.DEPOSIT_BY_STRIPE
                                ? 1
                                : 0.5,
                          }}
                        >
                          STRIPE
                        </label>
                      </div>
                    )}
                  </div>
                )}

                {/* STRIPE if evenrything off */}
                {/* STRIPE if evenrything off */}
                {!baseData?.findBaseDetails?.payByCash &&
                  !baseData?.findBaseDetails?.isEnabledDeposit && (
                    <div
                      className="flex items-center mb-2"
                      onClick={() => {
                        setSelectedPaymentMethod(
                          selectedPaymentMethod == PAYMENT_METHOD.STRIPE
                            ? ""
                            : PAYMENT_METHOD.STRIPE
                        );
                      }}
                    >
                      <input
                        className="border-none rounded-lg px-3 py-3 cursor-pointer"
                        type="checkbox"
                        checked={
                          selectedPaymentMethod == PAYMENT_METHOD.STRIPE
                            ? true
                            : false
                        }
                      />
                      <label
                        className="ml-2 px-4 py-2 font-bold cursor-pointer rounded-lg"
                        style={{
                          backgroundColor: "green",
                          border:
                            selectedPaymentMethod == PAYMENT_METHOD.STRIPE
                              ? "1px solid white"
                              : "",
                          opacity:
                            selectedPaymentMethod == PAYMENT_METHOD.STRIPE
                              ? 1
                              : 0.5,
                        }}
                      >
                        Pay <b style={{ color: "gold" }}>${amountToPay}</b> by
                        STRIPE{" "}
                      </label>
                    </div>
                  )}
              </div>
            )}
          </div>

          {/* SUBMIT button */}
          <div className="mt-10">
            {createEventMutationLoading ? (
              <button
                className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold pt-3 pb-2 px-8 rounded mx-auto block"
                disabled={true}
              >
                <ClipLoader
                  color={"#ffffff"}
                  loading={true}
                  css={override}
                  size={20}
                />
              </button>
            ) : (
              <button
                className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
                onClick={() => {
                  payAndCreateEvent();
                }}
              >
                Book this Place
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="max-w-5xl mx-auto py-3 px-4 mb-14">
          <h3 className="text-center font-bold text-[36px] mt-2 mb-6">
            Book a Place
          </h3>

          {!baseLoading && (
            <>
              {/* BASE name */}
              {/* BASE name */}
              <div className="mt-3 flex items-center">
                <label className="mr-12 font-bold">Base Name</label>
                <div
                  className="w-[500px] py-2 bg-transparent rounded font-bold font-display"
                  style={{ color: "gold" }}
                >
                  {baseData?.findBaseDetails?.name} (@
                  {baseData?.findBaseDetails?.slug})
                </div>
              </div>

              {/* Event Location */}
              {/* Event Location */}
              <div className="mb-3 flex items-center">
                <label className="mr-16 pr-1 font-bold">Location</label>
                <div
                  className="w-[500px] py-2 bg-transparent rounded font-bold font-display"
                  style={{ color: "gold" }}
                >
                  {address}
                </div>
              </div>
            </>
          )}

          {/* Event TYPE */}
          {/* Event TYPE */}
          <div className="dropdown cursor-pointer relative my-2 ">
            <div>
              <label className="mr-9 font-bold">
                Event Type <b style={{ color: "#ff0000a1" }}> * </b>
              </label>
              <button
                className="border-2 border-slate-500 px-2 py-5 rounded dropdown-toggle  inline-flex items-center  h-6"
                type="button"
                onClick={(e) => handleDropdown(e)}
              >
                <h1 className="text-white font-display font-bold">
                  {eventType ? eventType : "Event type"}
                </h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-white h-6 w-6 "
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                </svg>
              </button>
            </div>

            <div
              className={
                dropdownShow
                  ? "dropdown-menu bg-black/90 z-10  min-w-[200px] whitespace-nowrap rounded-xl py-4 px-2 text-left shadow-xl show h-40  text-yellow-300 absolute  top-full mx-auto"
                  : "dropdown-menu bg-black/90 z-10  min-w-[200px] whitespace-nowrap rounded-xl py-4 px-2 text-left shadow-xl hidden text-yellow-300 absolute top-full"
              }
            >
              {eventTypeList.map((event) => (
                <button
                  key={event.type}
                  onClick={() => handleEventTypeSelection(event.type)}
                  className="block dropdown-text mx-auto"
                >
                  {event.type}
                </button>
              ))}
            </div>
          </div>

          {/* Caption */}
          {/* Caption */}
          <div className="my-3">
            <label className="mr-16 pr-3 font-bold">Caption</label>
            <input
              className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
              onChange={(e) => setCaption(e.target.value)}
              value={caption}
            />
          </div>

          {/* Date Picker for DATE */}
          {/* Date Picker for DATE */}
          <div className="my-2 flex items-center">
            <label className="mr-20 pr-2 font-bold">
              Date <b style={{ color: "#ff0000a1" }}> * </b>
            </label>

            <DatePicker
              className="rounded w-64 text-xl font-bold text-black"
              style={{ width: "100px" }}
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                // Unset Time Slot
                setTimeSlots([]);
                // Refetch available Time Slots
                allUsedHoursRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  selectedDate: new Date(date),
                });
              }}
              maxDate={oneYearFuture}
              minDate={new Date()}
              dateFormat="MMMM d, yyyy"
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="Time"
              placeholderText="Select Date and Time"
            />

            {timeSlots.length > 1 && (
              <h1 className="ml-2 font-bold text-yellow-200">
                {timeSlots.length == 4 ? "Full day" : "Half day"}
              </h1>
            )}
          </div>

          {/* Event Start Time */}
          {/* Event Start Time */}
          <div className="my-3 flex items-center">
            <label className="min-w-[135px] font-bold ">
              Start Time <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <div>
              {allUsedHoursLoading ? (
                <ClipLoader
                  color={"#ffffff"}
                  loading={true}
                  css={override}
                  size={20}
                />
              ) : (
                <DatePicker
                  className="rounded w-32 text-xl font-bold text-black"
                  style={{ width: "100px" }}
                  selected={eventStartTime}
                  onChange={(date) => {
                    // Set event start time
                    setEventStartTime(date);
                    setEventStartHour(date.getHours());
                    // Set event end time & hour
                    setEventEndTime(date);
                    setEventEndHour(date.getHours());
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                  timeFormat="h:mm aa"
                  timeIntervals={60}
                  timeCaption="Time"
                  placeholderText="Start Time"
                  minTime={baseOpeningTime}
                  maxTime={baseEndingTime}
                  excludeTimes={usedHoursOfSelectedDate}
                />
              )}
            </div>
          </div>

          {/* Event End Time */}
          {/* Event End Time */}
          <div className="my-2 flex items-center">
            <label className="min-w-[135px] font-bold ">
              End Time <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <div>
              {allUsedHoursLoading ? (
                <ClipLoader
                  color={"#ffffff"}
                  loading={true}
                  css={override}
                  size={20}
                />
              ) : (
                <DatePicker
                  className="rounded w-32 text-xl font-bold text-black"
                  style={{ width: "200px" }}
                  selected={eventEndTime}
                  onChange={(date) => {
                    // Set event start time
                    setEventEndTime(date);
                    setEventEndHour(date.getHours());
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                  timeFormat="h:mm aa"
                  timeIntervals={60}
                  timeCaption="Time"
                  placeholderText="Start Time"
                  minTime={eventStartTime}
                  maxTime={baseEndingTime}
                  excludeTimes={usedHoursOfSelectedDate}
                />
              )}
            </div>
            {baseData?.findBaseDetails?.eventMinimumHour && (
              <div className="ml-5 text-sm">
                <i className="font-bold text-yellow-300">NOTE:</i> Select{" "}
                {baseData?.findBaseDetails.eventMinimumHour} hr. minimum
              </div>
            )}
          </div>

          {/* Event Price */}
          {/* Event Price */}
          <div className="mt-0 mb-3 flex items-center">
            <label className="mr-2 font-bold">Amount to Pay</label>
            {baseLoading ? (
              <div className="ml-3 my-2 pt-1">
                <ClipLoader
                  color={"#ffffff"}
                  loading={true}
                  css={override}
                  size={20}
                />
              </div>
            ) : (
              <div className="flex items-center">
                <div className="px-3 py-2 bg-transparent rounded text-2xl text-yellow-200 font-bold">
                  ${amountToPay}
                </div>
                <div>
                  {" "}
                  ( ${baseData?.findBaseDetails?.perHourPrice} x{" "}
                  {differenceInHours(eventEndTime, eventStartTime) < 0
                    ? 0
                    : differenceInHours(eventEndTime, eventStartTime)}{" "}
                  hours ){" "}
                </div>
              </div>
            )}
          </div>

          {/* Sports */}
          {/* Sports */}
          {(eventType === "Tournament" ||
            eventType === "League" ||
            eventType === "Contest") && (
            <div className="my-2 flex items-center">
              {" "}
              <label className="mr-16 pr-2 font-bold">
                Sports <b style={{ color: "#ff0000a1" }}> * </b>
              </label>
              <Select
                className="w-64"
                value={selectedSportsForComponent}
                onChange={handleSelectSportsChange}
                options={sportsOption}
                isMulti
                styles={customStyles}
              />
            </div>
          )}

          {/* Game Type */}
          {/* Game Type */}
          {(eventType === "Tournament" || eventType === "League") && (
            <div className="my-2 flex items-center ">
              <label className="mr-10 font-bold">
                Game type <b style={{ color: "#ff0000a1" }}> * </b>
              </label>
              <Select
                className="w-64"
                value={selectedGametypeForComponent}
                onChange={handleSelectGameTypeChange}
                options={gameTypesOption}
                isMulti={false}
                styles={customStyles}
              />
            </div>
          )}

          {/* Capacity */}
          {/* Capacity */}
          <div className="flex items-center">
            <label className="mr-11 pr-5 font-bold">Capacity</label>
            <div className="bg-transparent rounded w-64 text-white font-bold font-display">
              {baseData?.findBaseDetails?.capacity
                ? baseData?.findBaseDetails?.capacity
                : 0}{" "}
              Person
            </div>
          </div>

          {/* SUBMIT button */}
          <button
            className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
            onClick={() => {
              goToPaymentConfirmationPage();
            }}
          >
            Submit
          </button>
        </div>
      )}
    </CommonBackdrop>
  );
}
export default AddEventByClient;
