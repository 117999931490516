import React, { useState, useEffect } from "react";
import {
  CLIENT_CREATED_EVENT_PAYMENT_STATUSES,
  EVENTS_TYPE,
  EVENT_APPROVAL_STATUSES,
  EVENT_DURATION,
  LOCAL_STORAGE_KEYS,
  PAYMENT_METHOD,
  RANDOM_AVATAR_URLS,
  TIME_SLOTS_DATA,
} from "../../utils/constant";
import { format, formatISO } from "date-fns";

function ClientSIdeEventCard({
  event,
  goToEventDetails,
  goToEventEditPage,
  goToKidDetails,
  bookSessionFromEventCard,
  findMyKids,
}) {
  // Event start time
  const [eventStartTime, setEventStartTime] = useState(new Date());

  // Event end time
  const [eventEndTime, setEventEndTime] = useState(new Date());

  // Initialize event data
  useEffect(() => {
    function initializeEventData() {
      // Update base baseOpeningHour
      if (event?.eventStartHour) {
        let currentDate = new Date();
        // set hour, minutes & seconds
        currentDate.setHours(event.eventStartHour);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        // set event start time
        setEventStartTime(currentDate);
      }

      // Update base baseClosingHour
      if (event?.eventEndHour) {
        let currentDate = new Date();
        // set hour, minutes & seconds
        currentDate.setHours(event?.eventEndHour);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        // set event end time
        setEventEndTime(currentDate);
      }
    }
    initializeEventData();
  }, []);

  function SeasonDetails({
    eventPrice,
    recurringStartDate,
    recurringEndDate,
    recurringDays,
    recurringEventIds,
    seasonPrice,
    discount,
  }) {
    return (
      <div className="mx-4" style={{ borderTop: "1px dotted #ffffff45" }}>
        {/* season title & coupon code for discount */}
        <div
          className="text-2xl mt-3 mb-1 flex items-center"
          style={{ color: "#00ff1b" }}
        >
          <b className="whitespace-nowrap">Season Details</b>
          {discount > 0 && (
            <div className="text-sm ml-2" style={{ color: "white" }}>
              ({" "}
              <b className="font-bold text-yellow-300">
                {((discount / (seasonPrice + discount)) * 100).toFixed(2)}%{" "}
              </b>{" "}
              off on a full-season purchase )
            </div>
          )}
        </div>
        {/* dcurring from & to dates */}
        <div className="my-2" style={{ color: "white" }}>
          From{" "}
          <b className="text-cyan-300">
            {format(Number(recurringStartDate), "do MMM',' yyyy")}
          </b>{" "}
          To{" "}
          <b className="text-cyan-300">
            {format(Number(recurringEndDate), "do MMM',' yyyy")}
          </b>
        </div>
        {/* recurring days */}
        <div className="flex items-center">
          <div>On Every</div>
          <div className="flex items-center">
            {recurringDays.map((day) => (
              <div className="ml-2 px-2 py-1 bg-green-600 rounded-md font-bold">
                {day}
              </div>
            ))}
          </div>
        </div>
        {/* total events */}
        <div
          className="flex items-center justify-between"
          style={{ color: "white" }}
        >
          <div>
            Total Events:
            <b className="ml-2 text-yellow-300">
              {recurringEventIds?.length ? recurringEventIds.length : 0}
            </b>
          </div>

          <div className="text-sm">
            {event.seasonBookedBy ==
            localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) ? (
              <div className="px-3 py-2 bg-slate-800 rounded-lg text-green-400">
                SEASON BOOKED BY YOU
              </div>
            ) : (
              <button
                className="px-3 py-2 bg-yellow-500 hover:bg-yellow-700 rounded-lg"
                onClick={async (e) =>
                  bookSessionFromEventCard({ e, event, isBookFullSeason: true })
                }
              >
                BOOK FULL SEASON
              </button>
            )}
          </div>
        </div>
        {/* total price */}
        {discount > 0 ? (
          <div className="" style={{ color: "white" }}>
            Season Price:
            <b className="ml-2 text-slate-500 line-through">
              ${seasonPrice + discount}
            </b>
            <b className="ml-2 text-yellow-300">${seasonPrice}</b>
          </div>
        ) : (
          <div className="" style={{ color: "white" }}>
            Season Price:
            <b className="ml-2 text-yellow-300">${seasonPrice}</b>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {/* DATE + EVENT info + BOOK session button */}
      <div
        className="bg-black/20 mt-4 mb-8 font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:ml-5 py-2 cursor-pointer"
        style={{
          borderLeft: "5px solid yellow",
        }}
        onClick={(e) => goToEventDetails(e, event._id)}
      >
        {/* DATE & Event INFO + BUTTONS at Right */}
        <div
          className="flex items-center"
          style={{ justifyContent: "space-between" }}
        >
          {/* DATE & EVENT INFO */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="py-2"
          >
            {/* DATE section */}
            <div
              style={{
                color: "white",
                width: "80px",
                borderRight: "1px dotted #ffffff45",
                paddingLeft: "15px",
              }}
              className="text-2xl"
            >
              {format(new Date(event.dateTime), "EEE MMM dd")}
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                ( {format(new Date(event.dateTime), "yyyy")} )
              </div>
            </div>

            {/* Schdule INFO section */}
            <div className="font-semibold ml-5">
              {/* TIME */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                  <div className="w-1 h-1 rounded-full bg-black"></div>
                </div>
                {/* Time value */}
                <div style={{ color: "#8F9BB3" }} className="text-sm ml-2">
                  {event.eventType}
                </div>
              </div>

              {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
                <>
                  {event.caption ? (
                    <div className="text-xl mt-1 mb-2">{event.caption}</div>
                  ) : (
                    <div className="text-xl mt-1 mb-2">Special Event</div>
                  )}
                </>
              ) : (
                <>
                  {/* CAPTION */}
                  <div className="text-sm">
                    Event Type:{" "}
                    <b style={{ color: "#ccd0e0" }}>{event.eventType}</b>
                  </div>

                  {/* CAPTION */}
                  {event.caption && (
                    <div className="text-sm">
                      Title: <b style={{ color: "#ccd0e0" }}>{event.caption}</b>
                    </div>
                  )}
                </>
              )}

              {/* SPORTS */}
              {event.sports && event.sports.length > 0 && (
                <div className="text-sm">
                  Sports:{" "}
                  <b style={{ color: "#ccd0e0" }}>{event.sports.join(", ")}</b>
                </div>
              )}

              {/* GAME TYPE */}
              {event.gameType && (
                <div className="text-sm">
                  Game Type:{" "}
                  <b style={{ color: "#ccd0e0" }}>{event.gameType}</b>
                </div>
              )}

              {/* START & END Time */}
              <div
                className="flex items-center text-xl font-bold"
                style={{ color: "white" }}
              >
                <div>{format(eventStartTime, "h:mm aa")}</div>
                <div className="text-md mx-3 text-yellow-200">to</div>
                <div>{format(eventEndTime, "h:mm aa")}</div>
              </div>

              <div className="flex items-center flex-wrap my-2">
                {findMyKids?.findMyAllKidsToSubscribeBase
                  ?.filter((kid) => event.participantKidIds.includes(kid._id))
                  .map((kid) => {
                    return (
                      <div
                        className="mr-2 my-1 cursor-pointer"
                        onClick={(e) => {
                          goToKidDetails(e, kid.player._id);
                        }}
                      >
                        <img
                          src={
                            RANDOM_AVATAR_URLS[kid.avatar]
                              ? RANDOM_AVATAR_URLS[kid.avatar]
                              : RANDOM_AVATAR_URLS["user4_asset4"]
                          }
                          alt="Basement Sports"
                          className="max-h-8 object-contain"
                        />
                      </div>
                    );
                  })}
              </div>

              {/* PRICE */}

              {event.eventPrice && (
                <div className="text-md mt-2 flex items-center">
                  <div className="mr-2">Price:</div>
                  {!event.hasRecurring &&
                    event.discount > 0 &&
                    event.eventPrice && (
                      <div className="mr-3">
                        <b className="line-through text-slate-500">
                          ${event.eventPrice + event.discount}
                        </b>
                      </div>
                    )}
                  <div>${event.eventPrice}</div>
                </div>
              )}

              {/* PAYMENT INFO - THE CLIENT WHO CREATED THIS EVENT */}
              <div className="text-sm mt-1">
                {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
                  event.createdBy ==
                    localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) && (
                    <div>
                      {/* Payment Method */}
                      <div className="mr-5">
                        <b style={{ color: "cyan" }}>Payment Method:</b>{" "}
                        {event.paymentMethod == PAYMENT_METHOD.DEPOSIT_BY_STRIPE
                          ? "Stripe"
                          : event.paymentMethod}
                      </div>
                      {/* Payment STATUS */}
                      <div
                        className="mr-5 font-normal"
                        style={{ color: "#ff9393" }}
                      >
                        <b style={{ color: "cyan" }}>Payment Status: </b>{" "}
                        {event.paymentStatus}
                      </div>
                      {/* Paid Amount */}
                      <div
                        className="mr-5 font-normal"
                        style={{ color: "gold" }}
                      >
                        <b style={{ color: "cyan" }}>Paid amount: </b> $
                        {event.paidAmount / 100}
                      </div>
                      {/* DISCOUNTED Amount */}
                      {event?.discount > 0 && (
                        <div className="mr-5 font-normal text-slate-200">
                          <b style={{ color: "#03ca03" }}>
                            Discount From Admin:{" "}
                          </b>{" "}
                          ${event.discount}
                        </div>
                      )}
                      {/* DUE Amount */}
                      {event?.paidAmount < event.amountToPay && (
                        <div
                          className="mr-5 font-normal"
                          style={{ color: "gold" }}
                        >
                          <b style={{ color: "cyan" }}>Due: </b> $
                          {(event.amountToPay - event.paidAmount) / 100}
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>

          {/* BUTTONS AT RIGHT*/}
          <div className="mr-5">
            {/* BOOKED KIDS */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* SHOW EDIT BUTTON (Must have to logged in & EVENT should be created by this USER ) */}
                <div className="my-1">
                  {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
                    event.createdBy ==
                      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) && (
                      <button
                        className="bg-yellow-500 hover:bg-yellow-700  text-white text-sm font-bold py-2 px-5 rounded w-full"
                        style={{
                          whiteSpace: "nowrap",
                          zIndex: 100,
                        }}
                        onClick={async (e) => goToEventEditPage(e, event._id)}
                      >
                        EDIT
                      </button>
                    )}
                </div>

                {/* Show BOOKED. If not BOOKED then show EDIT or BOOK */}
                <div className="my-1">
                  {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
                  (findMyKids?.findMyAllKidsToSubscribeBase?.filter((kid) =>
                    event.participantKidIds.includes(kid._id)
                  ).length > 0 ||
                    event.bookedBy ==
                      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) ? (
                    <div>
                      <button
                        className="bg-slate-800  text-cyan-600 text-sm font-bold py-2 px-5 rounded"
                        style={{
                          whiteSpace: "nowrap",
                          zIndex: 100,
                        }}
                        onClick={async (e) =>
                          bookSessionFromEventCard({ e, event })
                        }
                      >
                        BOOKED BY YOU
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="bg-blue-500 hover:bg-blue-700  text-white text-sm font-bold py-2 px-5 rounded"
                        style={{
                          whiteSpace: "nowrap",
                          zIndex: 100,
                        }}
                        onClick={async (e) =>
                          bookSessionFromEventCard({ e, event })
                        }
                      >
                        BOOK THIS EVENT
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* RECURRING EVENTS DETAILS at BOTTOM */}
        {event.hasRecurring == true && (
          <SeasonDetails
            eventPrice={event?.eventPrice}
            recurringStartDate={event?.recurringStartDate}
            recurringEndDate={event?.recurringEndDate}
            recurringDays={event?.recurringDays}
            recurringEventIds={event?.recurringEventIds}
            seasonPrice={event?.seasonPrice}
            discount={event?.discount}
          />
        )}

        {/* RECURRING FROM EVENT DETAILS at BOTTOM */}
        {event?.recurringFromEventId && event.recurringFromEventId != "" && (
          <SeasonDetails
            eventPrice={event?.recurringFromEventDetails?.eventPrice}
            recurringStartDate={
              event?.recurringFromEventDetails?.recurringStartDate
            }
            recurringEndDate={
              event?.recurringFromEventDetails?.recurringEndDate
            }
            recurringDays={event?.recurringFromEventDetails?.recurringDays}
            recurringEventIds={
              event?.recurringFromEventDetails?.recurringEventIds
            }
            seasonPrice={event?.recurringFromEventDetails?.seasonPrice}
            discount={event?.recurringFromEventDetails?.discount}
          />
        )}
      </div>
    </div>
  );
}

export default ClientSIdeEventCard;
