import { useNavigate } from "react-router-dom";
import TransparentCard from "../common/TransparentCard";
import { LOCAL_STORAGE_KEYS } from "../../utils/constant";

export default function SingleEventPromotion() {
  const navigate = useNavigate();
  return (
    <TransparentCard>
      <div
        className="flex items-center font-display text-yellow text-lg cursor-pointer"
        onClick={() =>
          navigate(`/${LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG}/eventscalender`)
        }
      >
        <div className="font-semibold">
          {/* CAPTION */}
          <div>Drop-In Classes</div>
          <div className="text-md font-bold text-white">$35/Session</div>

          <div className="text-sm mt-5 text-white">
            Sessions run for 90-120 minutes. During weekdays, we offer 2
            sessions per day, with special events on weekends.{" "}
          </div>
        </div>
      </div>
    </TransparentCard>
  );
}
