import { gql } from "@apollo/client";

export default gql`
  query findAvailableSlots(
    $baseSlug: String!
    $selectedDate: String
    $selectedEventId: String
  ) {
    findAvailableSlots(
      baseSlug: $baseSlug
      selectedDate: $selectedDate
      selectedEventId: $selectedEventId
    ) {
      allTimeSlots
      availableTimeSlots
    }
  }
`;
