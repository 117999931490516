import { gql } from "@apollo/client";

export default gql`
  query findBaseEventsByFilter(
    $baseSlug: String
    $baseId: String
    $year: Int
    $month: Int
    $particularDate: String
    $filterBy: String
    $searchName: String
    $queryFromClient: Boolean
    $userId: String
    $onlyNewEvents: Boolean
  ) {
    findBaseEventsByFilter(
      baseSlug: $baseSlug
      baseId: $baseId
      year: $year
      month: $month
      particularDate: $particularDate
      filterBy: $filterBy
      searchName: $searchName
      queryFromClient: $queryFromClient
      userId: $userId
      onlyNewEvents: $onlyNewEvents
    ) {
      _id
      eventType
      caption
      dateTime
      eventPrice
      seasonPrice
      duration
      sports
      gameType
      bookedBy
      slot
      participantKidIds
      timeSlots
      paymentStatus
      createdBy
      paymentMethod
      isCreatedByAdmin
      amountToPay
      paidAmount
      approvalStatus
      eventDuration
      dateTimeAsDate
      eventStartHour
      eventEndHour
      venmoOrZelleTrnxId
      hasRecurring
      recurringStartDate
      recurringEndDate
      recurringDays
      recurringEventIds
      promoCodes {
        code
      }
      discount
      recurringFromEventId
      seasonBookedBy
      recurringFromEventDetails {
        recurringStartDate
        recurringEndDate
        recurringDays
        recurringEventIds
        promoCodes {
          code
        }
        seasonPrice
        discount
        seasonBookedBy
      }
    }
  }
`;
