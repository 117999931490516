import { gql } from "@apollo/client";

export default gql`
  query findMyBookedEvents($userId: String, $userPlayerId: String) {
    findMyBookedEvents(userId: $userId, userPlayerId: $userPlayerId) {
      _id
      eventType
      caption
      dateTime
      normalDate
      eventPrice
      duration
      sports
      gameType
      slot
      participantKidIds
      timeSlots
      createdBy
      approvalStatus
      unseenMessageCounts
      eventStartHour
      eventEndHour
    }
  }
`;
