import { gql } from "@apollo/client";

export default gql`
  query findRecurringEvents($eventId: String) {
    findRecurringEvents(eventId: $eventId) {
      _id
      eventType
      caption
      dateTime
      eventPrice
      duration
      sports
      gameType
      slot
      participantKidIds
    }
  }
`;
