const   AvatarIcon = ({ avatar, size = "normal" }) => (
  <div
    className={`${
      size === "normal" ? "w-[50px] h-[50px]" : "w-[30px] h-[30px]"
    } rounded-full overflow-hidden`}
  >
    <img
      src={avatar}
      className="w-[50px] h-[50px] object-fit"
      alt="Base Logo"
    />
  </div>
);

const AvatarIcons = ({ avatars, size = "normal" }) => (
  <div className="flex flex-row items-center gap-1">
    {avatars.map((avatar) => (
      <AvatarIcon avatar={avatar} size={size} />
    ))}
  </div>
);

export { AvatarIcon, AvatarIcons };
