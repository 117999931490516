import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";

import { ShoppingCardContext } from "../context/cardContext";
import { el } from "date-fns/locale";
import SideMenu from "./shared/SideMenu";
import { ADMIN_USER_IDS, LOCAL_STORAGE_KEYS } from "../utils/constant";

function Header() {
  const [isMenu, setIsMenu] = useState(false);
  const [isCollapse, setCollapse] = useState(null);

  const { card, setCard } = useContext(ShoppingCardContext);
  const [headColor, setHeadColor] = useState(false);

  const [headerComponentReloader, setHeaderComponentReloader] = useState(1);

  function hideMenu() {
    setIsMenu(false);
  }
  function handleMenu() {
    setIsMenu(!isMenu);
  }

  const changeHeader = () => {
    if (window.scrollY >= 90) {
      setHeadColor(true);
    } else {
      setHeadColor(false);
    }
  };

  const mobileCollapse = (id) => {
    console.log(id, "This is id ok?");
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };
  window.addEventListener("scroll", changeHeader);

  // window resize
  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     if (window.innerWidth >= 1024) {
  //       setIsMenu(false);
  //     }
  //   });
  // });

  return (
    <nav
      className={`w-full flex justify-center fixed backdrop-blur z-20 duration-500 ${
        headColor && "bg-black/50"
      }`}
    >
      <div className="w-11/12 xl:w-10/12 flex flex-wrap items-center justify-between md:px-4 h-24">
        <a
          href="https://www.basementsports.com/"
          className="flex items-center lg:w-80 text-label-primary"
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 5H1m0 0 4 4M1 5l4-4"
            />
          </svg>
          <span className="self-center text-lg font-display font-bold ml-1.5 whitespace-nowrap">
            HOME
          </span>
        </a>

        {/* <Link to={localStorage.getItem("userid") ? "/mint-nfts" : "/login"}>
          <img
            src="images/NFT-logo.png"
            alt="Basement Sports"
            className="max-h-20 object-contain"
          />
        </Link> */}

        <div id="mega-menu-icons" className="hidden lg:flex w-80">
          <ul className="lg:flex items-center justify-between text-label-primary font-display mx-10 ">
            <li
              id="dropdownHoverButtonBase"
              data-dropdown-toggle="dropdownHoverBase"
              data-dropdown-trigger="hover"
              type="button"
            >
              <Link>Base</Link>
            </li>

            <div
              id="dropdownHoverBase"
              className="z-10 hidden bg-black/50 px-5 rounded"
            >
              <ul
                className="py-7 text-sm text-label-primary"
                aria-labelledby="dropdownHoverButtonBase"
              >
                {/* <li className="mt-3 py-2 px-5 flex justify-center items-center rounded hover:shadow-md hover:shadow-yellow-300/20">
                    <Link to="/base/registration">Registration</Link>
                  </li> */}

                <li className="mt-3 py-2 px-5 flex justify-center items-center rounded hover:shadow-md hover:shadow-yellow-300/20">
                  <Link
                    to={`/${LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG}/registration`}
                  >
                    Registration
                  </Link>
                </li>
              </ul>
            </div>
          </ul>

          {localStorage.getItem("userid") ? (
            <ul className="lg:flex items-center justify-between text-label-primary font-display w-full">
              {/* NFTs Section */}

              <li
                id="dropdownHoverButtonNft"
                data-dropdown-toggle="dropdownHoverNft"
                data-dropdown-trigger="hover"
                type="button"
              >
                <Link>NFT</Link>
              </li>

              <div
                id="dropdownHoverNft"
                className="z-10 hidden bg-black/50 px-5 rounded"
              >
                <ul
                  className="py-7 text-sm text-label-primary"
                  aria-labelledby="dropdownHoverButtonNft"
                >
                  <li className=" py-2 px-4 flex justify-center items-center rounded hover:shadow-md hover:shadow-yellow-300/20">
                    <Link
                      to={
                        localStorage.getItem("userid") ? "/my-nfts" : "/login"
                      }
                    >
                      MY NFTs
                    </Link>
                  </li>

                  <li className="mt-3 py-2 px-5 flex justify-center items-center rounded hover:shadow-md hover:shadow-yellow-300/20">
                    <Link
                      to={
                        localStorage.getItem("userid") ? "/mint-nfts" : "/login"
                      }
                    >
                      MINT NFTs
                    </Link>
                  </li>
                </ul>
              </div>

              <li>
                <Link to={"/cart"}>
                  <div className="relative">
                    <div className="absolute  right-[-50%] top-[-60%]">
                      {card.totalNft}
                    </div>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9V4a3 3 0 0 0-6 0v5m9.92 10H2.08a1 1 0 0 1-1-1.077L2 6h14l.917 11.923A1 1 0 0 1 15.92 19Z"
                      />
                    </svg>
                  </div>
                </Link>
              </li>
              {/* <li className="bg-yellow-300/20 p-2 rounded-full"> */}
              <button
                id="dropdownHoverButton"
                data-dropdown-toggle="dropdownHover"
                data-dropdown-trigger="hover"
                className="bg-yellow-300/20 p-2 rounded-full"
                type="button"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 14 18"
                >
                  <path d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                </svg>
              </button>

              <div
                id="dropdownHover"
                className="z-10 hidden bg-black/50 px-5 rounded"
              >
                <ul
                  className="py-7 text-sm text-label-primary"
                  aria-labelledby="dropdownHoverButton"
                >
                  <li className=" py-2 px-4 flex justify-center items-center rounded hover:shadow-md hover:shadow-yellow-300/20">
                    <Link
                      to={
                        localStorage.getItem("userid") ? "/profile" : "/login"
                      }
                    >
                      MY ACCOUNT
                    </Link>
                  </li>
                  <li className="mt-3 py-2 px-4 flex justify-center items-center rounded hover:shadow-md hover:shadow-yellow-300/20">
                    <Link
                      to={"/login"}
                      onClick={() => {
                        localStorage.removeItem("userid");
                        localStorage.removeItem("bsports_user_name");
                        localStorage.removeItem("user_email");
                        localStorage.removeItem("kidcointoken");
                        localStorage.removeItem("affiliateCouponCode");
                        localStorage.removeItem(
                          LOCAL_STORAGE_KEYS.SUPER_BASE_ID
                        );
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TYPE);
                        setHeaderComponentReloader(headerComponentReloader + 1);
                      }}
                    >
                      SIGN OUT
                    </Link>
                    <svg
                      className="w-4 h-4 ml-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
              {/* </li> */}
            </ul>
          ) : (
            <ul className="items-center justify-end text-label-primary font-display w-full">
              <li>
                <Link to="/login">LOGIN</Link>
              </li>
            </ul>
          )}
        </div>
        <div className="lg:hidden flex items-center mr-2">
          <button
            className="ease-in-out duration-500 text-xl text-orange-800 rounded-full border-yellow-300 border-b-2 border-r px-5 py-2 shadow-lg shadow-yellow-300/30"
            onClick={handleMenu}
          >
            {!isMenu ? (
              <svg
                className="w-4 h-4 text-label-primary"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="12"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h14M1 6h14M1 11h7"
                />
              </svg>
            ) : (
              <svg
                className="w-4 h-4 text-label-primary "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      <div
        className={`bg-black/90 w-8/12 md:w-4/12 top-24 absolute duration-500 sm:duration-700 ${
          isMenu
            ? "translate-x-[-25%] md:translate-x-[-100%]"
            : "-translate-x-[200%]"
        }`}
      >
        <SideMenu hideMenu={hideMenu}></SideMenu>
      </div>
    </nav>
  );
}

export default Header;
