import { gql } from "@apollo/client";

export const SAVE_ADMIN_PASSCODE = gql`
  mutation saveAdminPasscodeForFrontDeskMode(
    $baseId: String!
    $passcode: String!
  ) {
    saveAdminPasscodeForFrontDeskMode(baseId: $baseId, passcode: $passcode) {
      isSet
      passcode
    }
  }
`;
