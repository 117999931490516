import React, { useState, useEffect } from "react";
import AdminPortal from "./AdminPortal";
import Calendar from "react-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useQuery, useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { format, formatISO } from "date-fns";
import { faRotateBack, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce, groupBy } from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";

// Query
import FIND_BASE_EVENTS_BY_FILTER from "../../gql/query/base/findBaseEventsByFilter.query";
import FIND_AVAILABLE_SLOTS from "../../gql/query/base/findAvailableSlots.query";
import FIND_UN_SEEN_EVENTS_COUNT from "../../gql/query/base/findUnSeenEventsCount.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";

// Mutations
import VERIFY_CASH_PAYMENT from "../../gql/mutation/base/verifyCashOfClientEvent.mutations";

import {
  CASH_RECEIVED_BY,
  CLIENT_CREATED_EVENT_PAYMENT_STATUSES,
  CLIENT_EVENTS_FILTER,
  DAY_ORDER,
  EVENTS_FILTER_BY,
  EVENTS_TYPE,
  EVENT_APPROVAL_STATUSES,
  LOCAL_STORAGE_KEYS,
  MONTHS_BY_NUMBER,
  PAYMENT_METHOD,
} from "../../utils/constant";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";

function Event() {
  const navigate = useNavigate();
  let baseId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);

  // filtered Events data
  const [filteredEventsData, setFilteredEventsData] = useState([]);
  const [filteredEventsDataWithDayView, setFilteredEventsDataWithDayView] =
    useState(null);

  // Calender State
  const [dateValue, setDateValue] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedFilterType, setFilterType] = useState(EVENTS_FILTER_BY.DATE);

  // Search filter STATE
  const [searchEventName, setSearchEventName] = useState("");

  // Day view STATE
  const [isDayView, setIsDayView] = useState(false);

  // CLIENT events filter
  const [clientEventFilter, setClientEventFilter] = useState(null);

  // Loading state
  const [eventLoadingId, setEventLoadingId] = useState(null);

  // Verify modal STATE
  const [isOpenVerifyCashModal, setIsOpenVerifyCashModal] = useState(false);
  const [selectedEventToVerifyCash, setSelectedEventToVerifyCash] =
    useState(null);
  const [paidAmountByClient, setPaidAmountByClient] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(
    PAYMENT_METHOD.PAY_BY_CASH
  );
  const [cashTnxNumber, setCashTnxNumber] = useState(null);

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_BASE_EVENTS_BY_FILTER, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      particularDate: dateValue,
      filterBy: EVENTS_FILTER_BY.DATE,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: availableSlots,
    loading: availableSlotsLoading,
    error: availableSlotsError,
    refetch: availableSlotsRefetch,
  } = useQuery(FIND_AVAILABLE_SLOTS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      selectedDate: dateValue,
    },
  });

  const {
    data: unseenEventsCount,
    loading: unseenEventsCountLoading,
    error: unseenEventsCountError,
    refetch: unseenEventsCountRefetch,
  } = useQuery(FIND_UN_SEEN_EVENTS_COUNT, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    pollInterval: 2000,
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [
    verifyCashOfClientEventMutation,
    {
      error: verifyCashOfClientEventError,
      loading: verifyCashOfClientEventLoading,
    },
  ] = useMutation(VERIFY_CASH_PAYMENT);

  // Refetch event data
  useEffect(() => {
    function refetchEventData() {
      eventsRefetch();
      availableSlotsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        selectedDate: dateValue,
      });
      unseenEventsCountRefetch();
    }
    refetchEventData();
  }, []);

  // Refetch event data
  useEffect(() => {
    function manipulateEventsData() {
      if (eventsData?.findBaseEventsByFilter) {
        // Declare final events variable
        let filteredEvents = [];

        // First set eventStartTime & eventEndTime
        // First set eventStartTime & eventEndTime
        for (let i = 0; i < eventsData?.findBaseEventsByFilter.length; i++) {
          let event = JSON.parse(
            JSON.stringify(eventsData?.findBaseEventsByFilter[i])
          );

          // First add eventStartTime
          if (event?.eventStartHour) {
            let currentDate = new Date();
            // set hour, minutes & seconds
            currentDate.setHours(event?.eventStartHour);
            currentDate.setMinutes(0);
            currentDate.setSeconds(0);
            // set event start time
            event.eventStartTime = currentDate;
          }

          // First add eventEndTime
          if (event?.eventEndHour) {
            let currentDate = new Date();
            // set hour, minutes & seconds
            currentDate.setHours(event?.eventEndHour);
            currentDate.setMinutes(0);
            currentDate.setSeconds(0);
            // set event start time
            event.eventEndTime = currentDate;
          }

          filteredEvents.push(event);
        }

        // Set this events into STATE (This date is for without DAY vide)
        setFilteredEventsData(filteredEvents);

        // First add a new day property in each objects
        const dataWithDayName = filteredEvents.map((item) => {
          return {
            ...item,
            dayName: format(new Date(item.dateTime), "EEEE"),
          };
        });

        // Then group by that dayName
        const groupedEvents = groupBy(dataWithDayName, (obj) => obj.dayName);

        // convert the object of arrays to an array of entries
        const entries = Object.entries(groupedEvents);

        // sort the array of entries by using the dayOrder object
        const sortedEntries = entries.sort((a, b) => {
          // get the order of the day names for each entry
          const orderA = DAY_ORDER[a[0]];
          const orderB = DAY_ORDER[b[0]];
          // compare the order and return the result
          return orderA - orderB;
        });

        // convert the sorted array of entries back to an object of arrays
        const sortedData = Object.fromEntries(sortedEntries);

        // Now set the final data
        setFilteredEventsDataWithDayView(sortedData);
      }
    }
    manipulateEventsData();
  }, [eventsData]);

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // Handle Calender Actions
  function handleClickDay(date) {
    // Refetch Available SLots
    availableSlotsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      selectedDate: String(date),
    });

    // Today date
    const currentDate = new Date();

    // First convert the date from STRING to DATE format
    const selectedDate = new Date(date);

    // Set current Hour, Minute, Second (As DatePicker can't handle it)
    selectedDate.setHours(currentDate.getHours());
    selectedDate.setMinutes(currentDate.getMinutes());
    selectedDate.setSeconds(currentDate.getSeconds());

    let formattedDate = formatISO(selectedDate, { representation: "complete" });

    setDateValue(formattedDate);

    // Update filter STATE
    setFilterType(EVENTS_FILTER_BY.DATE);
    setSearchEventName("");
  }

  function handleOnActiveStartDateChange(view) {
    // Refetch data by MONTH
    eventsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      year: view.activeStartDate.getFullYear(),
      month: view.activeStartDate.getMonth(),
      filterBy:
        selectedFilterType == EVENTS_FILTER_BY.RECURRING_EVENTS
          ? EVENTS_FILTER_BY.RECURRING_EVENTS
          : selectedFilterType == EVENTS_FILTER_BY.CLIENT_EVENTS
          ? EVENTS_FILTER_BY.CLIENT_EVENTS
          : EVENTS_FILTER_BY.MONTH,
      onlyNewEvents: false,
    });

    if (selectedFilterType == EVENTS_FILTER_BY.CLIENT_EVENTS) {
      setClientEventFilter(CLIENT_EVENTS_FILTER.ALL_EVENTS);
    }

    // Update filter STATE
    setFilterType(
      selectedFilterType == EVENTS_FILTER_BY.RECURRING_EVENTS
        ? EVENTS_FILTER_BY.RECURRING_EVENTS
        : selectedFilterType == EVENTS_FILTER_BY.CLIENT_EVENTS
        ? EVENTS_FILTER_BY.CLIENT_EVENTS
        : EVENTS_FILTER_BY.MONTH
    );

    // Update Year & Month
    setSelectedYear(view.activeStartDate.getFullYear());
    setSelectedMonth(view.activeStartDate.getMonth());
  }

  // search event by name
  const searchEventByName = debounce(async (searchName) => {
    // If "searchName" has a valid string. ELSE search by today date
    if (searchName && searchName != "") {
      // Update STATE
      setFilterType(EVENTS_FILTER_BY.SEARCH_NAME);

      // Call to backend for checking this coupon code
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        searchName,
        filterBy: EVENTS_FILTER_BY.SEARCH_NAME,
      });
    } else {
      // Update STATE
      setFilterType(EVENTS_FILTER_BY.DATE);

      // Call to backend for checking this coupon code
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        particularDate:
          !dateValue || dateValue == "" ? new Date() : new Date(dateValue), // Check if "dateValue" has value. If not then set today date as "particularDate" & refetch. ELSE IF "dateValue" has value, then refetch with that selected date
        filterBy: EVENTS_FILTER_BY.DATE,
      });
    }
  }, 400);

  // Events CARD component
  function EventsCard({ event }) {
    return (
      <div>
        {/* DATE + EVENT info + BOOK session button */}
        <div
          className="mb-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:ml-5 py-2"
          style={{
            backgroundColor: "#86939140",
            borderLeft: "5px solid yellow",
            justifyContent: "space-between",
          }}
        >
          {/* DATE & Event INFO */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="py-2"
          >
            {/* DATE section */}
            <div
              style={{
                color: "white",
                width: "80px",
                borderRight: "1px dotted #ffffff45",
                paddingLeft: "15px",
              }}
              className="text-2xl"
            >
              {format(new Date(event.dateTime), "EEE MMM dd")}
              <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                ( {format(new Date(event.dateTime), "yyyy")} )
              </div>
            </div>

            {/* Schdule INFO section */}
            <div className="font-semibold ml-5">
              {/* TIME */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                  <div className="w-1 h-1 rounded-full bg-black"></div>
                </div>
                {/* Time value */}
                <div style={{ color: "#8F9BB3" }} className="text-sm ml-2">
                  {event.eventType}
                </div>
              </div>

              {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
                <>
                  {event.caption ? (
                    <div className="text-xl mt-1 mb-2">{event.caption}</div>
                  ) : (
                    <div className="text-xl mt-1 mb-2">Special Event</div>
                  )}
                </>
              ) : (
                <>
                  {/* CAPTION */}
                  <div className="text-sm">
                    Event Type:{" "}
                    <b style={{ color: "#ccd0e0" }}>{event.eventType}</b>
                  </div>

                  {/* CAPTION */}
                  {event.caption && (
                    <div className="text-sm">
                      Title: <b style={{ color: "#ccd0e0" }}>{event.caption}</b>
                    </div>
                  )}
                </>
              )}

              {/* SPORTS */}
              {event.sports && event.sports?.length > 0 && (
                <div className="text-sm">
                  Sports:{" "}
                  <b style={{ color: "#ccd0e0" }}>{event.sports.join(", ")}</b>
                </div>
              )}

              {/* GAME TYPE */}
              {event.gameType && (
                <div className="text-sm">
                  Game Type:{" "}
                  <b style={{ color: "#ccd0e0" }}>{event.gameType}</b>
                </div>
              )}

              {/* TIME SLOT */}
              {event?.timeSlots && (
                <div>
                  <div className="text-xs flex flex-wrap items-center">
                    {event?.timeSlots?.map((slot) => (
                      <div
                        className={`bg-green-500 px-2 py-2 rounded-lg mt-2 mr-2 font-bold`}
                        style={{ color: "white" }}
                      >
                        {slot}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* START & END Time */}
              {event?.eventStartTime && event?.eventEndTime && (
                <div
                  className="flex items-center text-xl font-bold"
                  style={{ color: "white" }}
                >
                  <div>{format(event.eventStartTime, "h:mm aa")}</div>
                  <div className="text-md mx-3 text-yellow-200">to</div>
                  <div>{format(event.eventEndTime, "h:mm aa")}</div>
                </div>
              )}

              {/* PRICE */}
              {event.eventPrice && (
                <div className="text-md mt-2" style={{ color: "#00fff5" }}>
                  Price: ${event.eventPrice}
                </div>
              )}

              {!event.isCreatedByAdmin && (
                <div className="text-sm mt-2">
                  <div>
                    <b style={{ color: "cyan" }}>Created by:</b> Client
                  </div>
                  {/* Payment Method */}
                  <div className="mt-1">
                    <b style={{ color: "cyan" }}>Payment Method:</b>{" "}
                    {event.paymentMethod == PAYMENT_METHOD.DEPOSIT_BY_STRIPE
                      ? "Stripe"
                      : event.paymentMethod}
                  </div>
                  {/* Payment STATUS */}
                  <div
                    className="mt-1 font-normal"
                    style={{ color: "#ff9393" }}
                  >
                    <b style={{ color: "cyan" }}>Payment Status: </b>{" "}
                    {event.paymentStatus}
                  </div>
                  {/* Paid Amount */}
                  <div className="mt-1 font-normal" style={{ color: "gold" }}>
                    <b style={{ color: "cyan" }}>Paid amount: </b> $
                    {event.paidAmount / 100}
                  </div>
                  {/* DUE amount */}
                  {(event.paymentStatus ==
                    CLIENT_CREATED_EVENT_PAYMENT_STATUSES.DEPOSITED ||
                    event.paymentStatus ==
                      CLIENT_CREATED_EVENT_PAYMENT_STATUSES.INCOMPLETE) && (
                    <div
                      className="mr-5 font-normal"
                      style={{ color: "#ff9393" }}
                    >
                      <b style={{ color: "cyan" }}>Due: </b> $
                      {(event.amountToPay - event.paidAmount) / 100}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div
            className="mr-5"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* Event update button */}
            <button
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded my-1 mr-2"
              onClick={() => {
                navigate(`${`/admin/update-event/${event._id}`}`);
              }}
            >
              Details
            </button>
            {/* APPROVE button */}
            {!event.isCreatedByAdmin && (
              <div>
                {event.paidAmount == 0 && !baseLoading && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <button
                      className={`bg-blue-500 text-white font-bold py-2 px-4 rounded my-1 mr-2`}
                      onClick={async () => {
                        setSelectedEventToVerifyCash(event);
                        setIsOpenVerifyCashModal(true);
                        setPaidAmountByClient(
                          (baseData?.findBaseDetails?.depositInPercent *
                            event.amountToPay) /
                            100 /
                            100 // In cent
                        );
                      }}
                    >
                      Confirm Payment
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  // Accept Invitation Modal functions
  function closeVerifyCashModal() {
    setIsOpenVerifyCashModal(false);
  }

  async function verifyCash(event) {
    const { data } = await verifyCashOfClientEventMutation({
      variables: {
        adminUserId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        eventId: event._id,
        paidAmountByClient: Number(paidAmountByClient),
      },
    });
    if (data?.verifyCashOfClientEvent?.errorMessage == null) {
      setIsOpenVerifyCashModal(false);
      eventsRefetch();
      toast.success("Successfully confirmed payment!");
    }
  }

  if (eventsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="bg-slate-700 flex justify-between mb-3 px-5 py-3 mt-8">
        <h3 className="font-display font-bold text-2xl">Calendar Management</h3>
        <Link
          to={"/admin/add-event"}
          className="bg-slate-800 py-1 px-2 rounded"
        >
          + Add Event
        </Link>
      </div>

      {/* FILTER & SEARCH bar */}
      <div
        className="flex justify-between mb-3 px-5 mt-5 py-1 flex-wrap"
        style={{
          alignItems: "center",
          backgroundColor: "#1f2e49c9",
          borderRadius: "5px",
        }}
      >
        {/* FILTER */}
        <div className="mt-2">
          {/* FILTER BY DATE */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              onClick={() => {
                // Update STATE
                setFilterType(EVENTS_FILTER_BY.DATE);
                setSearchEventName("");

                // Check if "dateValue" has value. If not then set today date as "particularDate" & refetch
                // ELSE IF "dateValue" has value, then refetch with that selected date
                if (!dateValue || dateValue == "") {
                  let todayDate = new Date();
                  // Now refetch data
                  eventsRefetch({
                    baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                    particularDate: todayDate,
                    filterBy: EVENTS_FILTER_BY.DATE,
                  });
                } else if (dateValue && dateValue != "") {
                  let selectedDateValue = new Date(dateValue);
                  // Now refetch data
                  eventsRefetch({
                    baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                    particularDate: selectedDateValue,
                    filterBy: EVENTS_FILTER_BY.DATE,
                  });
                }
              }}
              style={{
                opacity: selectedFilterType == EVENTS_FILTER_BY.DATE ? 1 : 0.4,
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter events by date."
            >
              Filter by Date
            </button>

            {/* FILTER BY MONTH */}
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              onClick={() => {
                // Update state
                setFilterType(EVENTS_FILTER_BY.MONTH);
                setSearchEventName("");

                // Set current year & Month
                let actionDate;
                let year;
                let month;

                // Check if "dateValue" has value. If not then set today date (today year & today month) & refetch
                // ELSE IF "dateValue" has value, then refetch with that selected date (Year & month from that selected date)
                if (!dateValue || dateValue == "") {
                  actionDate = new Date();
                  year = actionDate.getFullYear();
                  month = actionDate.getMonth();
                } else if (dateValue && dateValue != "") {
                  actionDate = new Date(dateValue);
                  year = actionDate.getFullYear();
                  month = actionDate.getMonth();
                }

                // Now refetch data
                eventsRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  year,
                  month,
                  filterBy: EVENTS_FILTER_BY.MONTH,
                });

                // Update Year & Month
                setSelectedYear(year);
                setSelectedMonth(month);
              }}
              style={{
                opacity: selectedFilterType == EVENTS_FILTER_BY.MONTH ? 1 : 0.4,
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter events by month!"
            >
              Filter by Month
            </button>

            {/* UP_COMING EVENTS */}
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2 mb-2"
              style={{
                opacity:
                  selectedFilterType == EVENTS_FILTER_BY.UPCOMING ? 1 : 0.4,
              }}
              onClick={() => {
                // Update STATE
                setFilterType(EVENTS_FILTER_BY.UPCOMING);
                setSearchEventName("");
                // Re-fetch events
                eventsRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  filterBy: EVENTS_FILTER_BY.UPCOMING,
                });
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter all upcoming events!"
            >
              Upcoming
            </button>

            {/* PAST EVENTS */}
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              style={{
                opacity: selectedFilterType == EVENTS_FILTER_BY.PAST ? 1 : 0.4,
              }}
              onClick={() => {
                // Update STATE
                setFilterType(EVENTS_FILTER_BY.PAST);
                setSearchEventName("");
                // Re-fetch events
                eventsRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  filterBy: EVENTS_FILTER_BY.PAST,
                });
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter all past events!"
            >
              Past
            </button>

            {/* FETCH RECURRING EVENTS */}
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              onClick={() => {
                // Set current year & Month
                let actionDate;
                let year;
                let month;

                if (!dateValue || dateValue == "") {
                  actionDate = new Date();
                  year = actionDate.getFullYear();
                  month = actionDate.getMonth();
                } else if (dateValue && dateValue != "") {
                  actionDate = new Date(dateValue);
                  year = actionDate.getFullYear();
                  month = actionDate.getMonth();
                }

                // Re-fetch recurring events
                eventsRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  year,
                  month,
                  filterBy: EVENTS_FILTER_BY.RECURRING_EVENTS,
                });

                // Update Year & Month
                setSelectedYear(year);
                setSelectedMonth(month);

                // Update STATE
                setFilterType(EVENTS_FILTER_BY.RECURRING_EVENTS);
                setSearchEventName("");
              }}
              style={{
                opacity:
                  selectedFilterType == EVENTS_FILTER_BY.RECURRING_EVENTS
                    ? 1
                    : 0.4,
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter events that have a recurring schedule"
            >
              Recurring
            </button>

            {/* ALL EVENTS */}
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              style={{
                opacity: selectedFilterType == EVENTS_FILTER_BY.ALL ? 1 : 0.4,
              }}
              onClick={() => {
                // Update state
                setFilterType(EVENTS_FILTER_BY.ALL);
                setSearchEventName("");
                // Re-fetch events
                eventsRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  filterBy: EVENTS_FILTER_BY.ALL,
                });
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter all events!"
            >
              All
            </button>

            {/* CLIENT EVENTS */}
            <button
              className="hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              onClick={() => {
                // Set current year & Month
                let actionDate;
                let year;
                let month;

                if (!dateValue || dateValue == "") {
                  actionDate = new Date();
                  year = actionDate.getFullYear();
                  month = actionDate.getMonth();
                } else if (dateValue && dateValue != "") {
                  actionDate = new Date(dateValue);
                  year = actionDate.getFullYear();
                  month = actionDate.getMonth();
                }

                // Fetch only new clients event or not
                const onlyNewEvents =
                  unseenEventsCount?.findUnSeenEventsCount?.additionalData &&
                  Number(
                    unseenEventsCount?.findUnSeenEventsCount?.additionalData
                  ) > 0
                    ? true
                    : false;

                // Re-fetch recurring events
                eventsRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  year,
                  month,
                  filterBy: EVENTS_FILTER_BY.CLIENT_EVENTS,
                  onlyNewEvents,
                });

                // Update Year & Month
                setSelectedYear(year);
                setSelectedMonth(month);

                // Update STATE
                setFilterType(EVENTS_FILTER_BY.CLIENT_EVENTS);
                setSearchEventName("");
                setClientEventFilter(
                  onlyNewEvents
                    ? CLIENT_EVENTS_FILTER.NEW_EVENTS
                    : CLIENT_EVENTS_FILTER.ALL_EVENTS
                );
              }}
              style={{
                color:
                  selectedFilterType == EVENTS_FILTER_BY.CLIENT_EVENTS
                    ? "white"
                    : "#c2bbbb82",
                position: "relative",
                backgroundColor:
                  selectedFilterType == EVENTS_FILTER_BY.CLIENT_EVENTS
                    ? "#0380ff"
                    : "#177df569",
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter events created by CLIENT"
            >
              Client Events
              {!unseenEventsCountLoading &&
                unseenEventsCount?.findUnSeenEventsCount?.additionalData &&
                Number(
                  unseenEventsCount?.findUnSeenEventsCount?.additionalData
                ) > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: -20,
                      right: -10,
                      backgroundColor: "red",
                      borderRadius: "50px",
                      color: "white",
                    }}
                    className="px-3 py-1"
                  >
                    {unseenEventsCount?.findUnSeenEventsCount.additionalData}
                  </div>
                )}
            </button>

            <div
              className=" mb-2"
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Reset filter"
            >
              <FontAwesomeIcon
                className="mt-1 cursor-pointer"
                icon={faRotateBack}
                onClick={() => {
                  handleClickDay(new Date());
                }}
              />
            </div>
          </div>
        </div>

        {/* SEARCH bar */}
        <div>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            placeholder="Search events by name"
            onChange={(e) => {
              // Update STATE
              setSearchEventName(e.target.value);
              // Call to re-fetch with debounce
              searchEventByName(e.target.value);
            }}
            value={searchEventName}
          />
        </div>
      </div>

      {/* FILTER & CALENDER & SCHDULEs */}
      <div className="flex justify-between flex-wrap">
        {/* FILTER & CALENDER */}
        <div className="w-full md:w-1/2">
          {/* CALENDER */}
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="mt-3 mb-4"
          >
            <Calendar
              value={
                selectedFilterType == EVENTS_FILTER_BY.DATE
                  ? dateValue
                    ? dateValue
                    : new Date()
                  : null
              }
              onClickDay={handleClickDay}
              className="bg-black/20"
              onActiveStartDateChange={handleOnActiveStartDateChange}
            />
          </div>
        </div>
        {/* SCHDULES */}
        {eventsLoading ? (
          <div className="mt-20 w-full md:w-1/2">
            <DataLoading loadingType="success" />
          </div>
        ) : (
          <div className="w-full md:w-1/2 ">
            {/* If FILTER BY CLIENT EVENTS */}
            {selectedFilterType == EVENTS_FILTER_BY.CLIENT_EVENTS && (
              <div className="md:ml-5 mt-2 flex items-center">
                <div
                  className={`bg-blue-500 hover:bg-blue-700 px-2 py-2 rounded-lg mt-2 mr-2 font-bold cursor-pointer`}
                  style={{
                    color: "white",
                    opacity:
                      clientEventFilter == CLIENT_EVENTS_FILTER.ALL_EVENTS
                        ? 1
                        : 0.3,
                  }}
                  onClick={() => {
                    // Set current year & Month
                    let actionDate;
                    let year;
                    let month;

                    if (!dateValue || dateValue == "") {
                      actionDate = new Date();
                      year = actionDate.getFullYear();
                      month = actionDate.getMonth();
                    } else if (dateValue && dateValue != "") {
                      actionDate = new Date(dateValue);
                      year = actionDate.getFullYear();
                      month = actionDate.getMonth();
                    }

                    // Re-fetch events
                    eventsRefetch({
                      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                      year,
                      month,
                      filterBy: EVENTS_FILTER_BY.CLIENT_EVENTS,
                      onlyNewEvents: false,
                    });

                    // Update STATE
                    setClientEventFilter(CLIENT_EVENTS_FILTER.ALL_EVENTS);
                  }}
                >
                  All Client Events
                </div>

                <div
                  className={`bg-blue-500 hover:bg-blue-700 px-2 py-2 rounded-lg mt-2 mr-2 font-bold cursor-pointer`}
                  style={{
                    color:
                      clientEventFilter == CLIENT_EVENTS_FILTER.NEW_EVENTS
                        ? "white"
                        : "#c2bbbb82",
                    position: "relative",
                    backgroundColor:
                      clientEventFilter == CLIENT_EVENTS_FILTER.NEW_EVENTS
                        ? "#0380ff"
                        : "#177df569",
                  }}
                  onClick={() => {
                    // Re-fetch events
                    eventsRefetch({
                      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                      filterBy: EVENTS_FILTER_BY.CLIENT_EVENTS,
                      onlyNewEvents: true,
                    });

                    // Update STATE
                    setClientEventFilter(CLIENT_EVENTS_FILTER.NEW_EVENTS);
                  }}
                >
                  New Client Events
                  {!unseenEventsCountLoading &&
                    unseenEventsCount?.findUnSeenEventsCount?.additionalData &&
                    Number(
                      unseenEventsCount?.findUnSeenEventsCount?.additionalData
                    ) > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: -20,
                          right: -10,
                          backgroundColor: "red",
                          borderRadius: "50px",
                          color: "white",
                        }}
                        className="px-3 py-1"
                      >
                        {
                          unseenEventsCount?.findUnSeenEventsCount
                            .additionalData
                        }
                      </div>
                    )}
                </div>
              </div>
            )}
            {/* EVENTS COUNT */}
            {filteredEventsData.length > 0 && (
              <div
                className="flex items-center mt-2 mb-3"
                style={{ justifyContent: "space-between" }}
              >
                <div
                  className="md:ml-5"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  {selectedFilterType == EVENTS_FILTER_BY.DATE ? (
                    <div>
                      <div>
                        {filteredEventsData.length} events were found on{" "}
                        <b style={{ color: "gold" }}>
                          {dateValue && dateValue != ""
                            ? format(new Date(dateValue), "do MMMM, yyyy")
                            : format(new Date(), "do MMMM, yyyy")}
                        </b>
                        !
                      </div>
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.MONTH ||
                    selectedFilterType == EVENTS_FILTER_BY.RECURRING_EVENTS ? (
                    <div>
                      {filteredEventsData.length}{" "}
                      {selectedFilterType ==
                        EVENTS_FILTER_BY.RECURRING_EVENTS && (
                        <b style={{ color: "gold" }}>Recurring </b>
                      )}
                      events were found in{" "}
                      <b style={{ color: "#00ff1b" }} className="text-2xl">
                        {MONTHS_BY_NUMBER[selectedMonth]} {selectedYear}
                      </b>
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.UPCOMING ? (
                    <div>
                      {filteredEventsData.length}{" "}
                      <b style={{ color: "gold" }}>upcoming</b> events were
                      found!
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.PAST ? (
                    <div>
                      {filteredEventsData.length} events have
                      <b style={{ color: "gold" }}> passed</b>!
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.ALL ? (
                    <div>
                      A <b style={{ color: "gold" }}>total</b> of{" "}
                      {filteredEventsData.length} events were found!
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.CLIENT_EVENTS &&
                    clientEventFilter == CLIENT_EVENTS_FILTER.ALL_EVENTS ? (
                    <div>
                      {filteredEventsData.length}{" "}
                      {selectedFilterType ==
                        EVENTS_FILTER_BY.RECURRING_EVENTS && (
                        <b style={{ color: "gold" }}>Client </b>
                      )}
                      events were found in{" "}
                      <b style={{ color: "gold" }}>
                        {MONTHS_BY_NUMBER[selectedMonth]} {selectedYear}
                      </b>
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.CLIENT_EVENTS &&
                    clientEventFilter == CLIENT_EVENTS_FILTER.NEW_EVENTS ? (
                    <div>
                      A <b style={{ color: "gold" }}>total</b> of{" "}
                      {filteredEventsData.length}{" "}
                      <b style={{ color: "gold" }}>New Client</b> events were
                      created!
                    </div>
                  ) : (
                    <div>
                      <b style={{ color: "gold" }}>
                        {eventsData?.findBaseEventsByFilter?.length
                          ? eventsData?.findBaseEventsByFilter?.length
                          : ""}
                      </b>{" "}
                      Events found!
                    </div>
                  )}
                </div>
                {/* DAY VIEW */}
                <div className="flex items-center">
                  <input
                    className="border-none rounded-lg"
                    onClick={() => {
                      setIsDayView(!isDayView);
                    }}
                    type="checkbox"
                    checked={isDayView}
                  />
                  <label className="ml-2 font-bold">Day View</label>
                </div>
              </div>
            )}

            {/* EVENTS LIST */}
            <div>
              {/* If DAY VIEW IS ACTIVE THEN SHOW DAY VIEW, Otherwise normal view */}
              {isDayView ? (
                <div>
                  {Object.keys(filteredEventsDataWithDayView).map((dayName) => (
                    <div>
                      <div className="bg-blue-500 text-white text-2xl font-bold md:ml-5 mt-5 mb-4 rounded-sm px-2 py-2">
                        {dayName}
                      </div>
                      <div>
                        {filteredEventsDataWithDayView[dayName].map((event) => (
                          <EventsCard event={event} />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {filteredEventsData.map((event, index) => {
                    return <EventsCard event={event} />;
                  })}
                </div>
              )}

              {(!eventsData.findBaseEventsByFilter ||
                filteredEventsData.length == 0) && (
                <div
                  className="text-center mt-10"
                  style={{ fontWeight: "bold", fontSize: "30px" }}
                >
                  {selectedFilterType == EVENTS_FILTER_BY.DATE ? (
                    <div>
                      <div>
                        No events have been scheduled for{" "}
                        <b style={{ color: "gold" }}>
                          {dateValue && dateValue != ""
                            ? format(new Date(dateValue), "do MMMM, yyyy")
                            : format(new Date(), "do MMMM, yyyy")}
                        </b>{" "}
                        yet.
                      </div>
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.MONTH ||
                    selectedFilterType == EVENTS_FILTER_BY.RECURRING_EVENTS ? (
                    <div>
                      No{" "}
                      {selectedFilterType ==
                        EVENTS_FILTER_BY.RECURRING_EVENTS && (
                        <b style={{ color: "gold" }}>Recurring </b>
                      )}{" "}
                      events have been scheduled for{" "}
                      <b style={{ color: "gold" }}>
                        {MONTHS_BY_NUMBER[selectedMonth]} {selectedYear}
                      </b>{" "}
                      yet.
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.UPCOMING ? (
                    <div>
                      No
                      <b style={{ color: "gold" }}> upcoming</b> events were
                      found!
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.PAST ? (
                    <div>
                      No events have
                      <b style={{ color: "gold" }}> passed</b>!
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.ALL ? (
                    <div>No events found!</div>
                  ) : (
                    "No events found!"
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* ACCEPT INVITATION MODAL */}
      {/* ACCEPT INVITATION MODAL */}
      <Modal
        isOpen={isOpenVerifyCashModal}
        onRequestClose={closeVerifyCashModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000059",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeVerifyCashModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Confirm payment you received
        </h1>

        {/* Received by */}
        {/* Received by */}
        <div className="flex items-center py-2">
          <label className="min-w-[170px] font-bold">Received By</label>
          <div className="text-2xl text-yellow-200 font-bold">
            {selectedEventToVerifyCash?.paymentMethod}
          </div>
        </div>

        {/* Transaction ID */}
        {/* Transaction ID */}
        {selectedEventToVerifyCash?.venmoOrZelleTrnxId && (
          <div className="flex items-center py-2">
            <label className="min-w-[170px] font-bold">
              {selectedEventToVerifyCash?.paymentMethod == PAYMENT_METHOD.VENMO
                ? "Venmo "
                : selectedEventToVerifyCash?.paymentMethod ==
                  PAYMENT_METHOD.ZELLE
                ? "Zelle "
                : ""}
              Transaction Id
            </label>
            <div className="text-2xl text-yellow-200 font-bold">
              {selectedEventToVerifyCash?.venmoOrZelleTrnxId}
            </div>
          </div>
        )}

        {/* Received amount */}
        {/* Received amount */}
        <div className="flex items-center mt-2">
          <label className="min-w-[170px] font-bold">Received Amount</label>
          <input
            className="border-2 border-slate-500 px-2 bg-transparent rounded w-32 text-white font-bold font-display"
            type="number"
            onChange={(e) => setPaidAmountByClient(e.target.value)}
            min={0}
            value={paidAmountByClient}
          />
          <div className="ml-2">$</div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="mt-10 mb-5"
        >
          {verifyCashOfClientEventLoading ? (
            <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded my-1 mr-2">
              Loading
            </button>
          ) : (
            <button
              onClick={() => {
                verifyCash(selectedEventToVerifyCash);
              }}
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded my-1 mr-2"
            >
              Confirm
            </button>
          )}
        </div>
      </Modal>
    </AdminPortal>
  );
}
export default Event;
