import gql from "graphql-tag";

export default gql`
  mutation verifyUserAccountByAdmin(
    $userIdToVerify: String
    $userEmail: String
  ) {
    verifyUserAccountByAdmin(
      userIdToVerify: $userIdToVerify
      userEmail: $userEmail
    ) {
      errorMessage
      successMessage
    }
  }
`;
