import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import { useQuery, useMutation } from "@apollo/client";
import { format, addYears } from "date-fns";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import differenceInYears from "date-fns/differenceInYears";
import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  RANDOM_AVATAR_URLS,
  SUPER_BASE_COUPON_CODE_INFO,
  EVENTS_TYPE,
  PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
  OTHER_VIDEO_TYPES_TO_FETCH,
  BASEMENT_SPORTS_OFFICAL_LOCATION,
  CLIENT_CREATED_EVENT_PAYMENT_STATUSES,
  TIME_SLOTS_DATA,
  EVENT_APPROVAL_STATUSES,
} from "../../utils/constant";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faUsers,
  faCirclePlay,
  faUser,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import __ from "lodash";
import iconFinder from "../../utils/iconFinder";

// Share
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";

// Query
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";
import DataLoading from "../../component/loader/DataLoading";
import FIND_MY_KIDS from "../../gql/query/base/findMyAllKidsToSubscribeBase.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetailsForBaseDashboard.query";
import FIND_EVENT_PARTICIPANTS from "../../gql/query/base/findEventParticipantUsers.query";
import FIND_UNSEEN_EVENTS_MESSAGES from "../../gql/query/base/findMyEventsUnseenMessagesCount.query";

// MUTATION
import PARTICIPATE_EVENT from "../../gql/mutation/base/participateEvent.mutations";
import INITIATE_STRIPE_SESSION from "../../gql/mutation/base/initiateStripeSession.mutations";
import INITIATE_STRIPE_BILLING_PORTAL_SESSION from "../../gql/mutation/base/initiateStripeBillingPortalSession.mutations";
import CHECK_EVENT_COUPON_CODE_VALIDITY from "../../gql/mutation/base/checkEventCouponValidity.mutations";
import ENROLL_KIDS_USING_SLOT from "../../gql/mutation/base/enrollKidsUsingSlot.mutations";
import INVITE_PEOPLE_TO_EVENT from "../../gql/mutation/base/invitePeoplesToJoinEvent.mutations";
import ACCEPT_INVITATION_WITHOUT_LOGIN from "../../gql/mutation/base/acceptInvitationWithoutLogin.mutations";
import ACCEPT_INVITATION_WITH_LOGIN from "../../gql/mutation/base/acceptInvitationWithLogin.mutations";

export default function EventDetails() {
  const { eventId } = useParams();

  const search = useLocation().search;
  const isInvited = new URLSearchParams(search).get("isInvited");
  // const isInvited = false;

  const navigate = useNavigate();

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParent, setKidsWithParents] = useState([]);
  const [kidsWithoutParent, setKidsWithoutParent] = useState([]);

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParentToShow, setKidsWithParentsToShow] = useState([]);
  const [kidsWithoutParentToShow, setKidsWithoutParentToShow] = useState([]);

  // Total kids coming count
  const [totalKidsComing, setTotalKidsComing] = useState(0);

  // Modal STATE
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventDetails, setSelectedScheDetail] = useState(null);
  const [selectedKidIds, setSelectedKidIds] = useState([]);

  // Special Event Purchase Modal (For Coupon Code entry)
  const [specialEventModalIsOpen, setIsOpenSpecialEventModal] = useState(false);

  // Accept Invitation Modal
  const [acceptInvitationModal, setAcceptInvitationModal] = useState(false);
  const [parentEmail, setParentEmail] = useState(null);
  const [kids, setKids] = useState([]);

  // Invite Modal
  const [inviteModal, setInviteModal] = useState(false);
  const [emailsToInvite, setEmailsToInvite] = useState([]);

  // Coupon Code State
  const [showCouponBox, setShowCouponBox] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeCheckResponse, setCouponCodeCheckResponse] = useState("");
  const [totalDiscountInDollar, setTotalDiscountInDollar] = useState(0);

  // Event price state
  const [selectedEventPrice, setSelectedEventPrice] = useState(0);

  // Event start time
  const [eventStartTime, setEventStartTime] = useState(new Date());

  // Event end time
  const [eventEndTime, setEventEndTime] = useState(new Date());

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_A_EVENT, {
    variables: {
      eventId: eventId,
    },
  });

  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_MY_KIDS, {
    variables: {
      parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  const {
    data: eventParticipants,
    loading: eventParticipantsLoading,
    error: eventParticipantsLoadingError,
    refetch: eventParticipantsRefetch,
  } = useQuery(FIND_EVENT_PARTICIPANTS, {
    variables: {
      eventId,
    },
  });

  const {
    data: findMyEventsUnseenMessages,
    loading: findMyEventsUnseenMessagesLoading,
    error: findMyEventsUnseenMessagesError,
    refetch: findMyEventsUnseenMessagesRefetch,
  } = useQuery(FIND_UNSEEN_EVENTS_MESSAGES, {
    variables: {
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      eventId,
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    pollInterval: 1000,
  });

  // MUTATION
  const [
    participateEvent,
    { loading: participateEventLoading, error: participateEventError },
  ] = useMutation(PARTICIPATE_EVENT);

  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SESSION);

  const [
    checkEventCouponValidity,
    { loading: loadingCheckCouponValidity, error: errorCheckCouponValidity },
  ] = useMutation(CHECK_EVENT_COUPON_CODE_VALIDITY);

  const [
    enrollKidsUsingSlot,
    { loading: enrollKidsUsingSlotLoading, error: enrollKidsUsingSlotError },
  ] = useMutation(ENROLL_KIDS_USING_SLOT);

  const [
    invitePeopleToJoin,
    { loading: invitePeopleToJoinLoading, error: invitePeopleToJoinError },
  ] = useMutation(INVITE_PEOPLE_TO_EVENT);

  const [
    acceptInvitationWithoutLoginMutation,
    {
      loading: acceptInvitationWithoutLoginMutationLoading,
      error: acceptInvitationWithourLoginMutationError,
    },
  ] = useMutation(ACCEPT_INVITATION_WITHOUT_LOGIN);

  const [
    acceptInvitationWithLoginMutation,
    {
      loading: acceptInvitationWithLoginMutationLoading,
      error: acceptInvitationWithLoginMutationError,
    },
  ] = useMutation(ACCEPT_INVITATION_WITH_LOGIN);

  const copyToClipboard = (url) => {
    copy(url);
    toast.success("Successfully coppied share link!");
  };

  // Refetch event data
  useEffect(() => {
    function refetchEventData() {
      eventsRefetch();
      eventParticipantsRefetch();
      findMyKidsRefetch();
      baseRefetch();
    }
    refetchEventData();
  }, []);

  // Set data of events
  useEffect(() => {
    function setEventData() {
      if (eventsData && eventsData.findEvent) {
        setEmailsToInvite(eventsData.findEvent.invitedTo);

        // Update base baseOpeningHour
        if (eventsData.findEvent?.eventStartHour) {
          let currentDate = new Date();
          // set hour, minutes & seconds
          currentDate.setHours(eventsData.findEvent.eventStartHour);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
          // set event start time
          setEventStartTime(currentDate);
        }

        // Update base baseClosingHour
        if (eventsData.findEvent?.eventEndHour) {
          let currentDate = new Date();
          // set hour, minutes & seconds
          currentDate.setHours(eventsData.findEvent?.eventEndHour);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
          // set event end time
          setEventEndTime(currentDate);
        }
      }
    }
    setEventData();
  }, [eventsData]);

  useEffect(() => {
    function manipulateBasePlayers() {
      if (eventParticipants?.findEventParticipantUsers) {
        const allUsers = eventParticipants?.findEventParticipantUsers;

        // ✅ First array (If the "parentId" exists)
        // ✅ First array (If the "parentId" exists)
        const filteredArray = __.filter(
          allUsers,
          (user) =>
            user?.parentId && user.parentId != "" && user.parentId != undefined
        );
        // Group by similar "parentId"
        const groupedArray = __.groupBy(filteredArray, (user) => user.parentId);
        // Now create an object like this format { "parentId1": { name: "parentId1_name", email: "parentId1_email", kids: all_kids } }
        const resultArray = __.map(groupedArray, (value, key) => {
          const parentDetails = value[0].parentDetails;
          const kids = value;
          return {
            _id: parentDetails?._id,
            playerId: parentDetails?.player?._id,
            name: parentDetails?.name,
            email: parentDetails?.email,
            avatar: parentDetails?.avatar,
            role: parentDetails?.role,
            kids: kids,
          };
        });

        // Find the total parents & kids coming
        let totalKids = 0;
        resultArray.map((parent) => {
          totalKids = totalKids + parent.kids.length;
        });
        setTotalKidsComing(totalKids);

        // ✅ Second array (If the "parentId" not exists)
        // ✅ Second array (If the "parentId" not exists)
        const secondArray = __.filter(
          allUsers,
          (user) =>
            !user.parentId || user.parentId == "" || user.parentId == undefined
        );
        // all parent User IDS those have KIDS
        let parentIdsThoseHaveKids = Object.keys(groupedArray);
        // Now remove those already added parents from this second array
        const finalSecondFilteredArray = secondArray.filter(
          (user) => !parentIdsThoseHaveKids.includes(user._id)
        );

        // Update STATE
        setKidsWithParents(resultArray);
        setKidsWithoutParent(finalSecondFilteredArray);
        // Update STATE (To Show)
        setKidsWithParentsToShow(resultArray);
        setKidsWithoutParentToShow(finalSecondFilteredArray);
      }
    }
    manipulateBasePlayers();
  }, [eventParticipants]);

  function createMarkup(html) {
    const content = JSON.parse(html);
    const contentState = convertFromRaw(content);
    const editorState = EditorState.createWithContent(contentState);

    const htmlFinalData = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    return {
      __html: htmlFinalData,
    };
  }

  // Modal functions
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);

    // Make coupon code info as empty for safety
    setShowCouponBox(false);
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
  }

  // Special Event Modal functions
  function closeSpecialEventModal() {
    setIsOpenSpecialEventModal(false);

    // Make coupon code info as empty for safety
    setShowCouponBox(false);
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
  }

  // Accept Invitation Modal functions
  function closeAcceptInvitationModal() {
    setAcceptInvitationModal(false);
    setSelectedKidIds([]);
  }

  // Invite Modal functions
  function closeInviteModal() {
    setInviteModal(false);
  }

  // Only for subscribed KIDS to enrol into events by free
  // "kidIds" will always be a single item array as parent can "FREE TO JOIN" only 1 subscribed KID at a time.
  async function joinEventAsASubscriptionUser(eventId, kidIds) {
    console.log(kidIds, "okk");
    const { data } = await participateEvent({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        eventId,
        kidIds,
      },
    });
    if (data.participateEvent.errorMessage == null) {
      toast.success("Successfully enrolled into this event!");
      eventsRefetch();
      setSelectedKidIds([]);
      setIsOpen(false);
      return;
    } else {
      toast.error(data.participateEvent.errorMessage);
      return;
    }
  }

  // Check CouponCode Validity
  const checkCouponValidity = debounce(
    async (couponCode, eventId, paymentFor) => {
      console.log("ASCEEEE DEBOUNCE");
      // Set coupon code
      setCouponCode(couponCode);
      // Call to backend for checking this coupon code
      const { data } = await checkEventCouponValidity({
        variables: {
          couponCode,
          eventId,
          paymentFor,
        },
      });
      // If coupon code is correct then set discount price
      if (data.checkEventCouponValidity.errorMessage == null) {
        setCouponCodeCheckResponse(
          data.checkEventCouponValidity.successMessage
        );
        setTotalDiscountInDollar(
          data.checkEventCouponValidity?.additionalData
            ? data.checkEventCouponValidity.additionalData
            : 0
        );
      } else if (data.checkEventCouponValidity?.errorMessage) {
        setTotalDiscountInDollar(0);
        setCouponCodeCheckResponse(data.checkEventCouponValidity.errorMessage);
      }
    },
    600
  );

  // Single Event Purchase (only special events)
  // Call the Mutation to get STRIPE session URL to redirect into STRIPE payment gateway
  async function initiateStripeCheckoutUiForSpecialEvent() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Get my all kid IDs
      let kidIds = findMyKids?.findMyAllKidsToSubscribeBase?.map(
        (kid) => kid._id
      );
      // Call mutation to get STRIPE session url
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          eventId: selectedEventId,
          kidIds, // Make all of my kids enrolled into this event
          couponCode,
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        // Close Modal
        closeSpecialEventModal();
        // redirect to STRIPE gateway
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to login page
      navigate(`/login`);
      return;
    }
  }

  // Single Event Purchase (except special events)
  async function initiateStripeCheckoutUi(eventId, parentUserAvailableSlot) {
    if (!selectedKidIds || selectedKidIds.length == 0) {
      toast.error("Please select a KID to join the event!");
      return;
    }
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // If slots available then enroll kids via slots
      if (parentUserAvailableSlot > 0) {
        // Else Call the Mutation to get STRIPE session URL
        const { data } = await enrollKidsUsingSlot({
          variables: {
            parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
          },
        });
        if (data.enrollKidsUsingSlot.errorMessage == null) {
          baseRefetch();
          findMyKidsRefetch();
          eventsRefetch();
          // Off modal
          setSelectedKidIds([]);
          setIsOpen(false);
          toast.success(data.enrollKidsUsingSlot.successMessage);
        } else {
          toast.error(data.enrollKidsUsingSlot.errorMessage);
        }
      } else {
        // Call the Mutation to get STRIPE session URL
        const { data } = await initiateStripeSessionMutation({
          variables: {
            project: PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
            userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
            couponCode,
          },
        });
        if (data.initiateStripeSession.errorMessage == null) {
          setSelectedKidIds([]);
          setIsOpen(false);
          window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
        } else {
          toast.error(data.initiateStripeSession.errorMessage);
        }
      }
    } else {
      // As user is not logged in, so navigate the user to login page
      navigate(`/login`);
      return;
    }
  }

  // ACCEPT INVITATIONS RELATED METHODS
  const addKid = () => {
    // a function to add a new coupon object to the array
    setKids([
      ...kids,
      {
        name: "",
        // startDate: new Date(),
        // endDate: null,
      },
    ]);
  };

  // a function to remove a coupon object from the array by index
  const removeKid = (index) => {
    setKids(kids.filter((_, i) => i !== index));
  };

  // a function to update a coupon object by index, key and value
  const handleChangeKid = (index, key, value) => {
    setKids(
      kids.map((coupon, i) =>
        i === index ? { ...coupon, [key]: value } : coupon
      )
    );
  };

  // INVITE PEOPLE RELATED METHODS
  const addInvitedEmail = () => {
    // a function to add a new coupon object to the array
    setEmailsToInvite([
      ...emailsToInvite,
      {
        email: "",
        acceptStatus: false,
      },
    ]);
  };

  // a function to remove a coupon object from the array by index
  const removeInvitedEmail = (index) => {
    setEmailsToInvite(emailsToInvite.filter((_, i) => i !== index));
  };

  // a function to update a coupon object by index, key and value
  const handleChangeInvitedEmail = (index, key, value) => {
    setEmailsToInvite(
      emailsToInvite.map((email, i) =>
        i === index ? { ...email, [key]: value } : email
      )
    );
  };

  // accept invitation method
  async function acceptInvitation() {
    // IF LOGGED IN USER
    // IF LOGGED IN USER
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      if (!selectedKidIds || selectedKidIds.length == 0) {
        toast.error("Please select a KID to join the event for free!");
        return;
      }
      const { data } = await acceptInvitationWithLoginMutation({
        variables: {
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          eventId: selectedEventId,
          kidIds: selectedKidIds,
        },
      });
      if (data.acceptInvitationWithLogin.errorMessage == null) {
        toast.success("You have accepted the invitation!");
        // close Modal
        closeAcceptInvitationModal();
        // Reftch event data
        eventsRefetch();
        eventParticipantsRefetch();
      } else {
        toast.error(data.acceptInvitationWithLogin.errorMessage);
      }
    } else {
      // If not logged In USER
      // If not logged In USER
      if (!parentEmail || parentEmail == "") {
        toast.error("Please enter your email!");
        return;
      }

      if (!kids || kids.length == 0) {
        toast.error("Please insert at least one kid's info!");
        return;
      }

      // Kid's name cann't be empty
      let problemKidsInfo = kids.filter(
        (kidObject) => !kidObject.name || kidObject.name == ""
      );
      if (problemKidsInfo && problemKidsInfo.length > 0) {
        toast.error("The kid's name cannot be empty!");
        return;
      }

      const { data } = await acceptInvitationWithoutLoginMutation({
        variables: {
          email: parentEmail,
          eventId: selectedEventId,
          kids,
        },
      });
      if (data.acceptInvitationWithoutLogin.errorMessage == null) {
        toast.success("You have accepted the invitation!");
        // close Modal
        closeAcceptInvitationModal();
        // Reftch event data
        eventsRefetch();
        // ✅ Apply login state
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER_ID,
          data.acceptInvitationWithoutLogin.user._id
        );
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER_NAME,
          data.acceptInvitationWithoutLogin.user.name
        );
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER_EMAIL,
          data.acceptInvitationWithoutLogin.user.email
        );
        if (data.acceptInvitationWithoutLogin.user.kidCoinToken) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_KIDCOIN_TOKEN,
            data.acceptInvitationWithoutLogin.user.kidCoinToken
          );
        }
        if (data.acceptInvitationWithoutLogin.user.affiliateCouponCode) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_AFFILIATE_COUPON_CODE,
            data.acceptInvitationWithoutLogin.user.affiliateCouponCode
          );
        }
        // Add iuser type in Local Storage
        if (data.acceptInvitationWithoutLogin.user.userType) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_TYPE,
            data.acceptInvitationWithoutLogin.user.userType
          );
        }
        // Set Base ID in Local Storage
        if (
          data.acceptInvitationWithoutLogin.user.baseInfo &&
          data.acceptInvitationWithoutLogin.user.baseInfo._id
        ) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
            data.acceptInvitationWithoutLogin.user.baseInfo._id
          );
        }
      } else {
        toast.error(data.acceptInvitationWithoutLogin.errorMessage);
      }
    }
  }

  // Book EVENT action
  async function bookEvent(eventDetails) {
    if (
      !localStorage.getItem(
        LOCAL_STORAGE_KEYS.USER_ID // ✅ If special events
      )
    ) {
      navigate(`/login`);
    } else if (
      eventDetails.eventType == EVENTS_TYPE.SPECIAL_EVENTS // ✅ If special events
    ) {
      if (
        findMyKids?.findMyAllKidsToSubscribeBase?.filter((kid) =>
          eventDetails.participantKidIds.includes(kid._id)
        ).length > 0 ||
        eventDetails.bookedBy ==
          localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) // ✅ If special events & if already booked special event
      ) {
        toast.error("You have allready booked this session");
        return;
      } else {
        // ✅ If special events & if not yet booked special event then open Special Event Modal to input Coupon Code
        setSelectedEventId(eventDetails._id);
        // Set event price
        setSelectedEventPrice(eventDetails.eventPrice);
        // Open Modal
        setIsOpenSpecialEventModal(true);
      }
    } else if (
      // ✅ If it is not a special event but no kid found (As Kids need to be selected for Other events from Modal)
      findMyKids?.findMyAllKidsToSubscribeBase?.length < 1
    ) {
      navigate("/add-kid");
    } else {
      // ✅ If it is not a special event & kid found
      // Set IDs for getting data
      setSelectedEventId(eventDetails._id);
      setSelectedScheDetail(eventDetails);

      // Make coupon code info as empty for safety
      setCouponCode("");
      setCouponCodeCheckResponse("");
      setTotalDiscountInDollar(0);

      // Set event price
      setSelectedEventPrice(eventDetails.eventPrice);

      // Open Modal
      openModal();
    }
  }

  async function sendInvitations() {
    if (!emailsToInvite || emailsToInvite.length == 0) {
      toast.error("Please insert at least one email/ phone to invite!");
      return;
    }

    // Email cann't be empty
    let problemEmail = emailsToInvite.filter(
      (emailObject) => !emailObject.email || emailObject.email == ""
    );
    if (problemEmail && problemEmail.length > 0) {
      toast.error("The email/phone cannot be empty!");
      return;
    }

    const { data } = await invitePeopleToJoin({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        eventId: selectedEventId,
        invitedTo: emailsToInvite,
      },
    });
    if (data.invitePeoplesToJoinEvent.errorMessage == null) {
      toast.success("Successfully invited people into this event!");
      eventsRefetch();
      closeInviteModal();
    } else {
      toast.error(data.invitePeoplesToJoinEvent.errorMessage);
    }
  }

  if (eventsLoading || findMyKidsLoading || baseLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (eventsError || findMyKidsError || baseError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  let eventDetails = eventsData.findEvent;

  // Set available slot to use
  let allSlotPurchased = baseData?.findBaseDetails?.slotPurchased;
  let parentUserAvailableSlot = 0;

  if (allSlotPurchased) {
    let parentUserSlotInfoIndex = allSlotPurchased.findIndex(
      (item) =>
        item.parentId === localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
    );

    if (parentUserSlotInfoIndex !== -1) {
      parentUserAvailableSlot =
        allSlotPurchased[parentUserSlotInfoIndex].slotAvailable;
    }
  }

  // set isShowInviteButton button. Logic is bellow. Show "INVITE" button if 👇
  // the user is logged In &&
  // the user booked this event &&
  // the event is a special event
  let isShowInviteButton =
    localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) && // the user is logged In &&
    eventDetails.bookedBy == localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) && // the user booked this event &&
    eventDetails.eventType == EVENTS_TYPE.SPECIAL_EVENTS; // the event is a special event

  // set isShowAcceptButton button. Logic is bellow. Show "ACCEPT" button If 👇
  // (isInvited == true in URL 'OR' (the user is logged in && already invited in the event) ) &&
  // the event was booked really
  // not owner of this event
  // the event is a special event
  let isShowAcceptButton =
    (isInvited || // isInvited == true in URL
      (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
        eventDetails.invitedTo.filter(
          (invitedEmail) =>
            invitedEmail.email ==
            localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL)
        ).length > 0)) && // the user is logged in && already invited in the event
    eventDetails.bookedBy && // the event was booked really
    eventDetails.bookedBy != "" && // the event was booked really
    eventDetails.bookedBy != localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) && // not owner of this event
    eventDetails.eventType == EVENTS_TYPE.SPECIAL_EVENTS; // the event is a special event

  return (
    <CommonBackdrop rootName="Event" pageName="Details">
      <div>
        <div className="md:w-full sm:w-full sx:w-full px-2 w-full ">
          {!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) && (
            <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row justify-between mt-5">
              <Link to="/login" className="">
                <h1 className="ml-1 font-medium">Log In</h1>
              </Link>
            </div>
          )}

          <div className="mt-4 font-medium text-3xl text-yellow-300">
            {baseData?.findBaseDetails?.name} {" - "}
            {baseData?.findBaseDetails?.city}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {eventsData.findEvent?.bannerImage &&
            eventsData.findEvent?.bannerImage != "" && (
              <img
                src={eventsData.findEvent.bannerImage}
                alt="Uploaded"
                style={{
                  borderRadius: "10px",
                  maxHeight: "460px",
                  objectFit: "cover",
                  width: "100%",
                }}
                className="mt-5"
              />
            )}
        </div>

        {/* SINGLE EVENT PROMOTION */}
        <div className="my-5">
          <div className="bg-black/20 flex justify-between items-center font-display text-yellow-300 text-lg rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30">
            <div className="font-semibold w-full">
              {/* CAPTION */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>Drop-In Event</div>

                {/* BUTTON to BOOK SESSION */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  {/* BOOKED KIDS */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    {findMyKids?.findMyAllKidsToSubscribeBase
                      ?.filter((kid) =>
                        eventDetails.participantKidIds.includes(kid._id)
                      )
                      .map((kid) => {
                        return (
                          <div
                            className="mr-2 cursor-pointer"
                            onClick={() => {
                              navigate(`/kid-details/${kid.player._id}`);
                            }}
                          >
                            <img
                              src={iconFinder(kid.avatar)}
                              alt="Basement Sports"
                              className="max-h-8 object-contain"
                            />
                          </div>
                        );
                      })}

                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {/* CHAT WITH ADMIN */}
                      <div className="mx-2">
                        {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
                          eventDetails.createdBy ==
                            localStorage.getItem(
                              LOCAL_STORAGE_KEYS.USER_ID
                            ) && (
                            <button
                              className="bg-blue-500 hover:bg-blue-700  text-white text-sm font-bold py-2 px-5 rounded w-full"
                              style={{
                                whiteSpace: "nowrap",
                                position: "relative",
                              }}
                              onClick={async (e) =>
                                navigate(`${`/chat/${eventId}`}`)
                              }
                            >
                              CHAT WITH ADMIN
                              {!findMyEventsUnseenMessagesLoading &&
                                findMyEventsUnseenMessages
                                  ?.findMyEventsUnseenMessagesCount
                                  ?.additionalData &&
                                Number(
                                  findMyEventsUnseenMessages
                                    ?.findMyEventsUnseenMessagesCount
                                    ?.additionalData
                                ) > 0 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: -24,
                                      right: -12,
                                      backgroundColor: "red",
                                      borderRadius: "50px",
                                      color: "white",
                                      textAlign: "center",
                                      justifyContent: "center",
                                    }}
                                    className="text-lg w-[32px] h-[32px] rounded-lg flex items-center"
                                  >
                                    {
                                      findMyEventsUnseenMessages
                                        ?.findMyEventsUnseenMessagesCount
                                        ?.additionalData
                                    }
                                  </div>
                                )}
                            </button>
                          )}
                      </div>

                      {/* SHOW EDIT BUTTON (Must have to logged in & EVENT should be created by this USER ) */}
                      <div className="mx-1">
                        {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
                          eventDetails.createdBy ==
                            localStorage.getItem(
                              LOCAL_STORAGE_KEYS.USER_ID
                            ) && (
                            <button
                              className="bg-yellow-500 hover:bg-yellow-700  text-white text-sm font-bold py-2 px-5 rounded w-full"
                              style={{
                                whiteSpace: "nowrap",
                                zIndex: 100,
                              }}
                              onClick={async (e) =>
                                navigate(`${`/update-event/${eventId}`}`)
                              }
                            >
                              EDIT
                            </button>
                          )}
                      </div>

                      {/* Show BOOKED. If not BOOKED then show EDIT or BOOK */}
                      <div className="mx-1">
                        {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
                        (findMyKids?.findMyAllKidsToSubscribeBase?.filter(
                          (kid) =>
                            eventDetails.participantKidIds.includes(kid._id)
                        ).length > 0 ||
                          eventDetails.bookedBy ==
                            localStorage.getItem(
                              LOCAL_STORAGE_KEYS.USER_ID
                            )) ? (
                          <div>
                            <button
                              className="bg-blue-500 hover:bg-blue-700  text-white text-sm font-bold py-2 px-5 rounded"
                              style={{
                                whiteSpace: "nowrap",
                                zIndex: 100,
                              }}
                              onClick={async (e) => bookEvent(eventDetails)}
                            >
                              BOOKED BY YOU
                            </button>
                          </div>
                        ) : eventDetails.bookedBy &&
                          eventDetails.bookedBy !=
                            localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) ? (
                          <div
                            className="text-xl font-bold py-1 px-5 rounded-full"
                            style={{
                              backgroundColor: "#00000061",
                              color: "#ffff0042",
                            }}
                          >
                            BOOKED BY OTHER
                          </div>
                        ) : (
                          <div>
                            <button
                              className="bg-blue-500 hover:bg-blue-700  text-white text-sm font-bold py-2 px-5 rounded"
                              style={{
                                whiteSpace: "nowrap",
                                zIndex: 100,
                              }}
                              onClick={async (e) => bookEvent(eventDetails)}
                            >
                              BOOK
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* IF, already BOOKED by others then show that, it already BOOKED by others */}
                    {}
                  </div>
                </div>
              </div>

              {/* PAYMENT INFO - THE CLIENT WHO CREATED THIS EVENT */}
              {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
                eventDetails.createdBy ==
                  localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) && (
                  <div>
                    {/* Payment Method */}
                    <div className="mr-5">
                      <b style={{ color: "cyan" }}>Payment Method:</b>{" "}
                      {eventDetails.paymentMethod ==
                      PAYMENT_METHOD.DEPOSIT_BY_STRIPE
                        ? "Stripe"
                        : eventDetails.paymentMethod}
                    </div>
                    {/* Payment STATUS */}
                    <div
                      className="mr-5 font-normal"
                      style={{ color: "#ff9393" }}
                    >
                      <b style={{ color: "cyan" }}>Payment Status: </b>{" "}
                      {eventDetails.paymentStatus}
                    </div>
                    {/* Paid Amount */}
                    <div className="mr-5 font-normal" style={{ color: "gold" }}>
                      <b style={{ color: "cyan" }}>Paid amount: </b> $
                      {eventDetails.paidAmount / 100}
                    </div>
                    {/* Due Amount */}
                    {eventDetails.paidAmount < eventDetails.amountToPay && (
                      <div
                        className="mr-5 font-normal"
                        style={{ color: "gold" }}
                      >
                        <b style={{ color: "cyan" }}>Due: </b> $
                        {(eventDetails.amountToPay - eventDetails.paidAmount) /
                          100}
                      </div>
                    )}
                    {/* Discount Amount */}
                    {eventDetails?.discount > 0 && (
                      <div
                        className="mr-5 font-normal"
                        style={{ color: "gold" }}
                      >
                        <b style={{ color: "#03ca03" }}>
                          Discount From Admin:{" "}
                        </b>{" "}
                        ${eventDetails.discount}
                      </div>
                    )}
                  </div>
                )}

              <div className="text-sm mt-5" style={{ color: "#ccd0e0" }}>
                Session will run for{" "}
                {eventDetails.duration ? eventDetails.duration : "90-120"}{" "}
                minutes. During weekdays, we offer 2 sessions per day, with
                special events on weekends.{" "}
              </div>
            </div>
          </div>
        </div>

        {/* SHARE & ACCEPT */}
        <div style={{ display: "flex" }} className="mb-5">
          {/* If (isInvited == true or the user is already invited in the event) && not owner of this event, then show this ACCEPT BUTTON */}
          {isShowAcceptButton && (
            <div className="w-full md:w-1/2 flex">
              <div
                className=" w-full px-4 mr-5 font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-5"
                style={{
                  backgroundColor: "#454f6680",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* COUNT */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <div className="text-sm" style={{ color: "#ccd0e0" }}>
                    Invite other people to your event!
                  </div> */}
                  <div className="text-2xl">
                    Total Coming:{" "}
                    {totalKidsComing +
                      kidsWithParentToShow.length +
                      kidsWithoutParentToShow.length}{" "}
                    Person
                  </div>
                  <div className="text-sm" style={{ color: "cyan" }}>
                    (
                    <b className="text-yellow-200">
                      {kidsWithParentToShow.length}
                    </b>{" "}
                    Parent -{" "}
                    <b className="text-yellow-200">{totalKidsComing}</b> Kids -{" "}
                    <b className="text-yellow-200">
                      {kidsWithoutParentToShow.length}
                    </b>{" "}
                    General Users)
                  </div>
                </div>

                {/* ACCEPT BUTTON */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={() => {
                      setAcceptInvitationModal(true);
                      setSelectedEventId(eventDetails._id);
                      setSelectedScheDetail(eventDetails);
                    }}
                    className="text-xl font-bold py-1 px-5 rounded-full"
                    style={{
                      backgroundColor: "#0000008a",
                      color: "yellow",
                      width: "233px",
                    }}
                  >
                    {eventDetails.invitedTo.filter(
                      (invitedEmail) =>
                        invitedEmail.email ==
                          localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL) &&
                        invitedEmail.acceptStatus
                    ).length > 0
                      ? "ACCEPTED"
                      : "ACCEPT INVITATION"}
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* IF the user is the OWNER of the EVENT, then show this section for INVITATION */}
          {isShowInviteButton && (
            <div className="w-full md:w-1/2 flex">
              <div
                className=" w-full px-4 mr-5 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#454f6680",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="py-2 pr-3"
                >
                  {/* COUNT */}
                  <div className="text-2xl">
                    Total Coming:{" "}
                    {totalKidsComing +
                      kidsWithParentToShow.length +
                      kidsWithoutParentToShow.length}{" "}
                    Person
                  </div>
                  <div className="text-sm" style={{ color: "cyan" }}>
                    (
                    <b className="text-yellow-200">
                      {kidsWithParentToShow.length}
                    </b>{" "}
                    Parent -{" "}
                    <b className="text-yellow-200">{totalKidsComing}</b> Kids -{" "}
                    <b className="text-yellow-200">
                      {kidsWithoutParentToShow.length}
                    </b>{" "}
                    General Users)
                  </div>
                </div>

                {/* INVITE BUTTON */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() => {
                      // If this event payment status is UNPAID then return & ask for payment
                      console.log(
                        eventDetails.createdBy,
                        eventDetails.paymentStatus
                      );
                      if (eventDetails.paidAmount == 0) {
                        toast.error(
                          "Please be informed that payment was not confirmed yet by Admin!"
                        );
                        return;
                      }
                      setInviteModal(true);
                      setSelectedEventId(eventDetails._id);
                    }}
                    className="text-xl font-bold py-1 px-5 rounded-full"
                    style={{
                      backgroundColor: "#0000008a",
                      color: "yellow",
                      width: "133px",
                    }}
                  >
                    INVITE
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* SHARE */}
          <div
            className={` w-full ${
              isShowAcceptButton || isShowInviteButton ? "md:w-1/2" : null
            } px-4 font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2`}
            style={{
              backgroundColor: "#454f6680",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
              className="py-2"
            >
              <div>
                <h1>{eventsData.findEvent?.caption}</h1>
                <h1>
                  Date :{" "}
                  {format(
                    new Date(eventsData.findEvent?.dateTime),
                    "do MMM yyyy"
                  )}
                </h1>

                {/* START & END Time */}
                <div
                  className="flex items-center text-xl font-bold"
                  style={{ color: "white" }}
                >
                  <div>{format(eventStartTime, "h:mm aa")}</div>
                  <div className="text-md mx-3 text-yellow-200">to</div>
                  <div>{format(eventEndTime, "h:mm aa")}</div>
                </div>

                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="text-sm"
                >
                  {eventsData?.findEvent?.timeSlots?.map((slot) => (
                    <div
                      className={`bg-green-500 hover:bg-green-700 px-2 py-2 rounded-lg my-1 mr-2 font-bold`}
                      style={{ color: "white" }}
                    >
                      {slot}
                    </div>
                  ))}
                </div>

                {/* Location */}
                <h1>
                  Location :{" "}
                  {eventsData.findEvent?.address
                    ? eventsData.findEvent?.address
                    : BASEMENT_SPORTS_OFFICAL_LOCATION}
                </h1>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="my-3"
            >
              <EmailShareButton
                url={`${window.location.href}${
                  isShowInviteButton ? "?isInvited=true" : ""
                }`}
                // url={window.location.href}
                subject={"Special Event Invitation!"}
                separator={"\n"}
                className="mr-2"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <WhatsappShareButton
                url={`${window.location.href}${
                  isShowInviteButton ? "?isInvited=true" : ""
                }`}
                // url={window.location.href}
                title={"Special Event Invitation!"}
                separator=":: "
                className="mr-2"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <TelegramShareButton
                url={`${window.location.href}${
                  isShowInviteButton ? "?isInvited=true" : ""
                }`}
                // url={window.location.href}
                className="mr-2"
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <FacebookShareButton
                url={`${window.location.href}${
                  isShowInviteButton ? "?isInvited=true" : ""
                }`}
                // url={window.location.href}
                className="mr-2"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={`${window.location.href}${
                  isShowInviteButton ? "?isInvited=true" : ""
                }`}
                // url={window.location.href}
                hashtags={["Base", "BasementSports"]}
                className="mr-2"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <button
                onClick={() =>
                  copyToClipboard(
                    `${window.location.href}${
                      isShowInviteButton ? "?isInvited=true" : ""
                    }`
                    // window.location.href
                  )
                }
                className="text-sm font-bold py-1 px-5 rounded-full max-h-7"
                style={{
                  backgroundColor: "#0000008a",
                  color: "#FFFFFF",
                }}
              >
                COPY
              </button>
            </div>
          </div>
        </div>

        {eventsData.findEvent?.description &&
          eventsData.findEvent?.description != null && (
            <div
              className="mt-4 px-5 rounded-lg shadow-md shadow-black/30 pt-5 pb-8"
              style={{
                justifyContent: "space-between",
                height: "100%",
                backgroundColor: "#454f6680",
              }}
            >
              {/* <div className="mt-2 mb-3 text-2xl">Event Details</div> */}
              <div
                className="preview descriptionDiv"
                dangerouslySetInnerHTML={createMarkup(
                  eventsData.findEvent?.description
                )}
              ></div>
            </div>
          )}

        {isShowInviteButton &&
          emailsToInvite.filter(
            (invitedUser) => invitedUser.avatar && invitedUser.avatar != ""
          ).length > 0 && (
            <div className="mt-2">
              <div
                className="w-6xl mx-auto mt-8 pb-7 px-8 mb-8 rounded-md"
                style={{ backgroundColor: "#454f6680" }}
              >
                <h1 className="text-center font-bold text-2xl pt-8">
                  Invited People
                </h1>

                <div className="mt-3 flex flex-wrap">
                  {emailsToInvite
                    .filter(
                      (invitedUser) =>
                        invitedUser.avatar && invitedUser.avatar != ""
                    )
                    .map((invitedUser) => (
                      <div
                        style={{
                          height: "100%",
                          position: "relative",
                        }}
                        className="w-full md:w-1/2"
                      >
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                            backgroundColor: "#454f6680",
                            borderRadius: "5px",
                            borderLeft: `5px solid "#0095ff`,
                          }}
                          className="mx-2 my-2 px-3 py-4 shadow-md shadow-black/30"
                        >
                          {/* Parent AVATAR */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={iconFinder(invitedUser.avatar)}
                              alt="Basement Sports"
                              style={{
                                color: "white",
                                minWidth: "100px",
                                maxWidth: "100px",
                                paddingLeft: "15px",
                                paddingRight: "15px",
                              }}
                              className="cursor-pointer"
                              onClick={() => {
                                if (
                                  invitedUser.playerId &&
                                  invitedUser.playerId != ""
                                ) {
                                  navigate(
                                    `/kid-details/${invitedUser.playerId}`
                                  );
                                }
                              }}
                            />
                          </div>

                          {/* PARENT INFO SECTION */}
                          <div
                            className="ml-5"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              overflow: "hidden",
                            }}
                          >
                            {/* Parent Name */}
                            <div>{invitedUser.name}</div>
                            {/* Parent Email */}
                            <div className="text-sm">{invitedUser.email}</div>
                            <div className="text-sm">
                              {invitedUser.acceptStatus == true ? (
                                <div style={{ color: "#06ff06" }}>Accepted</div>
                              ) : (
                                <div style={{ color: "yellow" }}>
                                  Not yet accepted
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}

        {(kidsWithParentToShow?.length > 0 ||
          kidsWithoutParentToShow?.length > 0) && (
          <div className="mt-2">
            <div
              className="w-6xl mx-auto mt-8 pb-7 px-8 mb-8 rounded-md"
              style={{ backgroundColor: "#454f6680" }}
            >
              {/* ✅✅✅✅✅ (Users who have a PARENT/KID relation) */}
              {!eventParticipantsLoading && kidsWithParentToShow.length > 0 && (
                <h1 className="text-center font-bold text-2xl pt-8">
                  Event Participants (Users who have a Parent/Kid relation)
                </h1>
              )}

              {!eventParticipantsLoading && kidsWithParentToShow.length > 0 && (
                <h1 className="font-bold text-xl mt-3">
                  <b style={{ color: "yellow" }}>
                    {kidsWithParentToShow.length}
                  </b>{" "}
                  Parents found
                </h1>
              )}

              <div className="">
                {!eventParticipantsLoading &&
                  kidsWithParentToShow?.map((player, index) => {
                    return (
                      <div
                        className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                        style={{
                          borderLeft: "5px solid green",
                          justifyContent: "space-between",
                          height: "100%",
                          backgroundColor: "#454f6680",
                          position: "relative",
                        }}
                      >
                        {/* Family logo */}
                        <div
                          style={{ position: "absolute", bottom: 8, left: 8 }}
                          data-tooltip-id="tooltip-content"
                          data-tooltip-content={"Parent"}
                          className="cursor-pointer"
                        >
                          <FontAwesomeIcon
                            className="mr-3"
                            icon={faUsers}
                            size="2x"
                            opacity={0.1}
                          />
                        </div>
                        {/* Parent AVATAR + Name */}
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                          }}
                          className="py-2"
                        >
                          {/* Parent AVATAR */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={iconFinder(player.avatar)}
                              alt="Basement Sports"
                              className="max-h-17 cursor-pointer"
                              style={{
                                color: "white",
                                minWidth: "100px",
                                maxWidth: "100px",
                                paddingLeft: "15px",
                                paddingRight: "15px",
                              }}
                              onClick={() => {
                                navigate(`/kid-details/${player.playerId}`);
                              }}
                            />
                          </div>

                          {/* PARENT INFO SECTION */}
                          <div
                            className="ml-5"
                            style={{
                              borderRight: "1px dotted #ffffff45",
                              paddingRight: "60px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              minWidth: "250px",
                              maxWidth: "250px",
                              overflow: "hidden",
                            }}
                          >
                            {/* Parent Name */}
                            <div>{player.name}</div>
                            {/* Parent Email */}
                            {player.name != player?.email && (
                              <div>{player?.email}</div>
                            )}
                            <div>Role: {player.role}</div>
                          </div>

                          {/* KID DETAILS + KID GAME INFOS */}
                          <div
                            style={{
                              color: "#ccd0e0",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                            className="pl-6 text-sm  pt-1 pb-3"
                          >
                            <div className="text-xl font-bold">
                              Kid list -{" "}
                              <b style={{ color: "yellow" }}>
                                {player.kids?.length ? player.kids?.length : 0}
                              </b>{" "}
                              Kids found
                            </div>
                            {/* ALL KIDS */}
                            {/* ALL KIDS */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexWrap: "wrap",
                              }}
                              className="mt-5"
                            >
                              {player.kids.map((kid) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    minWidth: "100px",
                                  }}
                                  className="mx-1 mb-2"
                                >
                                  {/* KID AVATAR */}
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={iconFinder(kid.avatar)}
                                      alt="Basement Sports"
                                      className="max-h-17 cursor-pointer"
                                      style={{
                                        color: "white",
                                        minHeight: "50px",
                                        maxHeight: "50px",
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          `/kid-details/${kid.player._id}`
                                        );
                                      }}
                                    />
                                  </div>

                                  {/* KID INFO SECTION */}
                                  <div
                                    style={{ textAlign: "center" }}
                                    className="mt-1"
                                  >
                                    {/* KID Name */}
                                    <div>{kid.name}</div>
                                    {/* CHECKED-In or not */}
                                    {eventsData?.findEvent?.checkedInUserIds?.includes(
                                      kid._id
                                    ) && (
                                      <div
                                        className="text-white font-bold py-1 px-3 mt-1 rounded"
                                        style={{ backgroundColor: "#189b32" }}
                                      >
                                        Checked In
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* ✅✅✅✅✅ (Users who have no PARENT/KID relation) */}
              {!eventParticipantsLoading &&
                kidsWithoutParentToShow.length > 0 && (
                  <h1 className="pt-8 text-center font-bold text-2xl">
                    Event Participants (Users who have no Parent/Kid relation)
                  </h1>
                )}

              {!eventParticipantsLoading &&
                kidsWithoutParentToShow.length > 0 && (
                  <h1 className="font-bold text-xl  mt-3">
                    <b style={{ color: "yellow" }}>
                      {kidsWithoutParentToShow.length}
                    </b>{" "}
                    General users found
                  </h1>
                )}

              <div
                className="flex flex-wrap items-center font-display text-yellow-300 text-lg rounded-lg py-2"
                style={{
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                {!eventParticipantsLoading &&
                  kidsWithoutParentToShow?.map((player, index) => {
                    return (
                      <div
                        style={{
                          height: "100%",
                          position: "relative",
                        }}
                        className="w-full md:w-1/2"
                      >
                        {/* Family logo */}
                        <div
                          style={{ position: "absolute", bottom: 15, left: 20 }}
                          data-tooltip-id="tooltip-content"
                          data-tooltip-content={
                            player.user?.verified
                              ? "Verified User"
                              : "Not verified User"
                          }
                          className="cursor-pointer"
                        >
                          <FontAwesomeIcon
                            className="mr-3"
                            icon={
                              player.user?.verified ? faUser : faMobileScreen
                            }
                            size="xl"
                            opacity={0.3}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                            backgroundColor: "#454f6680",
                            borderRadius: "5px",
                            borderLeft: `5px solid ${
                              player.user?.verified ? "#0095ff" : "red"
                            }`,
                          }}
                          className="mx-2 my-2 px-3 py-4 shadow-md shadow-black/30"
                        >
                          {/* Parent AVATAR */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={iconFinder(player.avatar)}
                              alt="Basement Sports"
                              style={{
                                color: "white",
                                minWidth: "100px",
                                maxWidth: "100px",
                                paddingLeft: "15px",
                                paddingRight: "15px",
                              }}
                              className="cursor-pointer"
                              onClick={() => {
                                navigate(`/kid-details/${player.player._id}`);
                              }}
                            />
                          </div>

                          {/* PARENT INFO SECTION */}
                          <div
                            className="ml-5"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              overflow: "hidden",
                            }}
                          >
                            {/* Parent Name */}
                            <div>{player.name}</div>
                            {/* Parent Email */}
                            <div className="text-sm">{player.email}</div>
                            {/* CHECKED-In or not */}
                            {eventsData?.findEvent?.checkedInUserIds?.includes(
                              player._id
                            ) && (
                              <div
                                className="text-white font-bold py-1 px-3 mt-1 rounded w-24 text-center text-sm"
                                style={{ backgroundColor: "#189b32" }}
                              >
                                Checked In
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {eventParticipantsLoading && (
                <div
                  className="mt-10 mb-14"
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                  }}
                >
                  <DataLoading loadingType="success" />
                </div>
              )}
            </div>
          </div>
        )}

        {/* ✅ VIDEOS */}
        {/* ✅ VIDEOS */}
        <div className="font-semibold my-auto mt-5">
          <h1 className="text-xl" style={{ color: "white" }}>
            Videos
          </h1>
          {/* videos */}
          <div
            className="flex flex-wrap"
            style={{
              alignItems: "center",
            }}
          >
            {eventsData.findEvent.streamingRecords.map((video, index) => {
              return (
                <div
                  className="w-full md:w-1/3"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="mr-3 my-3 cursor-pointer"
                    style={{
                      position: "relative",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                    onClick={() =>
                      navigate(
                        `/video/${video._id}?otherVideoTypes=${OTHER_VIDEO_TYPES_TO_FETCH.fetchSameEventVideos}`
                      )
                    }
                  >
                    {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                    <img
                      src={
                        video?.videoThumbnailUrl
                          ? video.videoThumbnailUrl
                          : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                      }
                      alt="Basement Sports"
                      className={`md:block w-[90px] object-cover `}
                      style={{ width: "100%", height: "200px" }}
                    />

                    {/* PLAY ICON */}
                    <div
                      style={{
                        color: "yellow",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon icon={faCirclePlay} size="3x" />
                    </div>

                    {/* video details */}
                    <div
                      style={{
                        backgroundColor: "#00000033",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="px-4 py-4"
                    >
                      {/* left panel */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* avatar */}
                        <img
                          src={
                            RANDOM_AVATAR_URLS[video?.userDetails?.avatar]
                              ? RANDOM_AVATAR_URLS[video.userDetails.avatar]
                              : RANDOM_AVATAR_URLS["user4_asset4"]
                          }
                          alt="Basement Sports"
                          className={`md:block w-[90px] mr-2`}
                          style={{ width: "40px", height: "40px" }}
                        />
                        {/* video caption & base name */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {/* video caption */}
                          <div
                            className="text-sm"
                            style={{
                              fontWeight: "700",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              minWidth: "50px",
                              maxWidth: "140px",
                            }}
                          >
                            {video.caption}
                          </div>
                          {/* base name */}
                          <div className="text-sm" style={{ opacity: "0.5" }}>
                            @{"base"}
                          </div>
                        </div>
                      </div>

                      {/* right panel */}
                      <div style={{ display: "flex" }}>
                        <div className="text-xs" style={{ opacity: "0.5" }}>
                          {formatDistanceToNow(Number(video.createdAt))} ago
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {eventsData.findEvent.streamingRecords.length == 0 && (
              <div>No videos yet</div>
            )}
          </div>
        </div>
      </div>

      {/* MODAL MODAL */}
      {/* MODAL MODAL */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Assign to Members{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* SHOW KIDS */}
        {findMyKids &&
        findMyKids.findMyAllKidsToSubscribeBase &&
        findMyKids.findMyAllKidsToSubscribeBase.length > 0 ? (
          <div>
            {findMyKids.findMyAllKidsToSubscribeBase.map((kid, index) => {
              return (
                <div>
                  {/* KIDS */}
                  <div
                    className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                    key={index}
                    style={{
                      borderLeft: "5px solid yellow",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* KID AVATAR + Name + Check button */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="py-2"
                    >
                      {/* KID AVATAR */}
                      <img
                        src={
                          RANDOM_AVATAR_URLS[kid.avatar]
                            ? RANDOM_AVATAR_URLS[kid.avatar]
                            : RANDOM_AVATAR_URLS["user4_asset4"]
                        }
                        alt="Basement Sports"
                        className="max-h-17"
                        style={{
                          color: "white",
                          width: "100px",
                          borderRight: "1px dotted #ffffff45",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      />

                      {/* KID INFO section */}
                      <div className="font-semibold ml-5">
                        {/* CAPTION */}
                        <div>
                          Name: <b style={{ color: "#ccd0e0" }}>{kid.name}</b>
                        </div>
                        <div>
                          <b style={{ color: "#ccd0e0" }}>
                            {kid.gender == "male" ? "Boy" : "Girl"}
                          </b>
                        </div>
                        {kid.age && (
                          <div>
                            Age: <b style={{ color: "#ccd0e0" }}>{kid.age}</b>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* Check if KID not yet joined into the event */}
                      {!selectedEventDetails?.participantKidIds.includes(
                        kid._id
                      ) ? (
                        <>
                          {/* Check if the KID already a SUBSCRIBER for MONTH by parent */}
                          {kid.paymentDetails?.paymentFor ===
                          PAYMENT_FOR.PURCHASE_FULL_MONTH_EVENT_OF_BASE ? (
                            <button
                              onClick={() => {
                                joinEventAsASubscriptionUser(selectedEventId, [
                                  kid._id,
                                ]);
                              }}
                              className="text-xs font-bold py-1 px-5 rounded-full mr-3"
                              style={{
                                backgroundColor: "#0000008a",
                                color: "yellow",
                              }}
                            >
                              FREE TO JOIN
                            </button>
                          ) : (
                            <>
                              {/* If the KID not yet enrolled into the event && even not yet SUBSCRIBED for month */}
                              <div
                                onClick={() => {
                                  if (selectedKidIds.includes(kid._id)) {
                                    // Remove Array reference & create a new one
                                    let prevSelectedKidIds = [
                                      ...selectedKidIds,
                                    ];
                                    // Get KID ID index
                                    let index = prevSelectedKidIds.indexOf(
                                      kid._id
                                    );
                                    // SPLICE out the KID ID
                                    prevSelectedKidIds.splice(index, 1);
                                    // SET new ARRAY of IDs
                                    setSelectedKidIds(prevSelectedKidIds);
                                  } else {
                                    setSelectedKidIds([
                                      ...selectedKidIds,
                                      kid._id,
                                    ]);
                                  }
                                }}
                                className={`w-5 h-5 border-2 rounded-full border-yellow-300 mr-5 ${
                                  selectedKidIds.includes(kid._id)
                                    ? "bg-yellow-300"
                                    : null
                                }`}
                              ></div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* If the KID already joined into this event */}
                          <div
                            className="text-xs font-bold py-1 px-5 rounded-full mr-3"
                            style={{
                              backgroundColor: "#00000042",
                              color: "#ffff00bf",
                            }}
                          >
                            ENROLLED
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>No Kids added yet! Please add kid first!</div>
        )}

        {/* FINAL SUBMIT BUTTON for payment/ Free for Join */}
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="mt-5"
        >
          {/* If selected STRIPE payment then show coupon code & Amount to pay Info. Othersie show available SLOTs */}
          {Number(parentUserAvailableSlot) > 0 ? (
            <div className="text-center">
              <div style={{ color: "yellow" }} className="text-xl">
                Total selected kids: {selectedKidIds.length}
              </div>
              <div style={{ color: "#add8ff" }} className="text-xl my-3">
                Slot available to use: {parentUserAvailableSlot}
              </div>
            </div>
          ) : (
            <div>
              {/* Coupon Code */}
              <div>
                <div className="mb-4">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        setShowCouponBox(!showCouponBox);
                        setCouponCode("");
                        setCouponCodeCheckResponse("");
                        setTotalDiscountInDollar(0);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      className="cursor-pointer"
                    >
                      <input
                        className="relative bg-yellow-300 rounded"
                        type="checkbox"
                        id="exampleCheck2"
                        checked={showCouponBox}
                      />
                      <div className="ml-2">Do you have any coupon code?</div>
                    </div>

                    {showCouponBox && (
                      <div>
                        <input
                          onChange={(e) => {
                            checkCouponValidity(
                              e.target.value,
                              selectedEventDetails._id,
                              PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE
                            );
                          }}
                          className="bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-full h-8"
                          type="text"
                          placeholder="Coupon Code"
                        />
                        {couponCode && (
                          <p
                            className={`text-xs font-bold text-center ${
                              couponCodeCheckResponse === "Coupon found"
                                ? "text-green-400"
                                : "text-red-400"
                            }`}
                          >
                            {couponCodeCheckResponse}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Amount to pay */}
              <div
                className="text-center text-yellow-300 text-xl mb-4 mt-1"
                style={{ fontWeight: "700" }}
              >
                Total amount to pay: $
                {selectedEventPrice * selectedKidIds.length -
                  totalDiscountInDollar * selectedKidIds.length}{" "}
                {/* Discount */}
                {totalDiscountInDollar > 0 && (
                  <b
                    style={{ color: "white" }}
                    className="text-center text-sm mb-4"
                  >
                    (${totalDiscountInDollar * selectedKidIds.length} discount)
                  </b>
                )}
              </div>
            </div>
          )}

          {initiateStripeSessionMutationLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-3 px-6 rounded-lg  mt-2"
                style={{
                  backgroundColor: "#0078ffc7",
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
                onClick={() => {
                  initiateStripeCheckoutUi(
                    selectedEventId,
                    parentUserAvailableSlot
                  );
                }}
              >
                ENROLL KIDS
              </button>
            </div>
          )}
        </div>
      </Modal>

      {/* SPECIAL EVENT MODAL */}
      {/* SPECIAL EVENT MODAL */}
      <Modal
        isOpen={specialEventModalIsOpen}
        onRequestClose={closeSpecialEventModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeSpecialEventModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Special Event{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* COUPON CODE ENTRY */}
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="mt-5"
        >
          <div>
            {/* Coupon Code */}
            <div>
              <div className="mb-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <div
                    onClick={() => {
                      setShowCouponBox(!showCouponBox);
                      setCouponCode("");
                      setCouponCodeCheckResponse("");
                      setTotalDiscountInDollar(0);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                    className="cursor-pointer"
                  >
                    <input
                      className="relative bg-yellow-300 rounded"
                      type="checkbox"
                      id="exampleCheck2"
                      checked={showCouponBox}
                    />
                    <div className="ml-2">Do you have any coupon code?</div>
                  </div>

                  {showCouponBox && (
                    <div>
                      <input
                        onChange={(e) => {
                          // setCouponCode(e.target.value);
                          checkCouponValidity(
                            e.target.value,
                            selectedEventId,
                            PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE
                          );
                        }}
                        className="bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-full h-8"
                        type="text"
                        placeholder="Coupon Code"
                      />
                      {couponCode && (
                        <p
                          className={`text-xs font-bold text-center ${
                            couponCodeCheckResponse === "Coupon found"
                              ? "text-green-400"
                              : "text-red-400"
                          }`}
                        >
                          {couponCodeCheckResponse}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Amount to pay */}
            <div
              className="text-center text-yellow-300 text-xl mb-4 mt-1"
              style={{ fontWeight: "700" }}
            >
              Total amount to pay: ${selectedEventPrice - totalDiscountInDollar}{" "}
              {/* Discount */}
              {totalDiscountInDollar > 0 && (
                <b
                  style={{ color: "white" }}
                  className="text-center text-sm mb-4"
                >
                  (${totalDiscountInDollar} discount)
                </b>
              )}
            </div>
          </div>

          {initiateStripeSessionMutationLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-3 px-6 rounded-lg  mt-2"
                style={{
                  backgroundColor: "#0078ffc7",
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
                onClick={() => {
                  initiateStripeCheckoutUiForSpecialEvent();
                }}
              >
                PURCHASE EVENT
              </button>
            </div>
          )}
        </div>
      </Modal>

      {/* INVITE MODAL */}
      {/* INVITE MODAL */}
      <Modal
        isOpen={inviteModal}
        onRequestClose={closeInviteModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeInviteModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          INVITE OTHERS{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* ADD EMAIL TO INVITE */}
        {/* ADD EMAIL TO INVITE */}
        <div>
          <div className="my-3">
            <label className="mr-4 py-2 font-bold">
              Add Emails/ Phones to invite
            </label>
            <button
              type="button"
              onClick={addInvitedEmail}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Add New
            </button>
            {emailsToInvite && emailsToInvite.length > 0 && (
              <div
                style={{
                  padding: "10px 10px",
                  border: "1px solid #7a787869",
                }}
                className="rounded-lg mt-3 mb-6"
              >
                {emailsToInvite.map((emailToInvite, index) => (
                  <div>
                    <div
                      key={index}
                      className="coupon my-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <label>Email/ Phone </label>
                      <input
                        className="border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display w-96"
                        onChange={(e) =>
                          handleChangeInvitedEmail(
                            index,
                            "email",
                            e.target.value.toLowerCase()
                          )
                        }
                        value={emailToInvite.email}
                        type="text"
                        // style={{ width: "150px" }}
                        placeholder="Email or Phone With Country Code (Ex: +1xxxxx)"
                      />

                      <div
                        style={{ textAlign: "center", width: "100px" }}
                        className="text-xs"
                      >
                        {emailToInvite.acceptStatus == true ? (
                          <div style={{ color: "#06ff06" }}>Accepted</div>
                        ) : (
                          <div style={{ color: "yellow" }}>Not Accepted</div>
                        )}
                      </div>

                      <button onClick={() => removeInvitedEmail(index)}>
                        <FontAwesomeIcon className="" icon={faClose} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* FINAL SUBMIT BUTTON for payment/ Free for Join */}
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="mt-5"
        >
          {initiateStripeSessionMutationLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
                style={{
                  backgroundColor: "#0000008a",
                  color: "#FFFFFF",
                }}
                onClick={() => sendInvitations()}
              >
                {invitePeopleToJoinLoading ? "Loading" : "INVITE"}
              </button>
            </div>
          )}
        </div>
      </Modal>

      {/* ACCEPT INVITATION MODAL */}
      {/* ACCEPT INVITATION MODAL */}
      <Modal
        isOpen={acceptInvitationModal}
        onRequestClose={closeAcceptInvitationModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeAcceptInvitationModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Accept Invitation{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* IF LOGGED IN, then show KIDs. If no KID exist while logged in then show a text like "Add Kid First" */}
        {/* Else If, not logged in then show "Email & Kid name input" system */}
        {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) ? (
          <>
            {/* SHOW KIDS */}
            {findMyKids &&
            findMyKids.findMyAllKidsToSubscribeBase &&
            findMyKids.findMyAllKidsToSubscribeBase.length > 0 ? (
              <div>
                {findMyKids.findMyAllKidsToSubscribeBase.map((kid, index) => {
                  return (
                    <div>
                      {/* KIDS */}
                      <div
                        className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                        key={index}
                        style={{
                          borderLeft: "5px solid yellow",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* KID AVATAR + Name + Check button */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="py-2"
                        >
                          {/* KID AVATAR */}
                          <img
                            src={iconFinder(kid.avatar)}
                            alt="Basement Sports"
                            className="max-h-17"
                            style={{
                              color: "white",
                              width: "100px",
                              borderRight: "1px dotted #ffffff45",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                            }}
                          />

                          {/* KID INFO section */}
                          <div className="font-semibold ml-5">
                            {/* CAPTION */}
                            <div>
                              Name:{" "}
                              <b style={{ color: "#ccd0e0" }}>{kid.name}</b>
                            </div>
                            <div>
                              <b style={{ color: "#ccd0e0" }}>
                                {kid.gender == "male" ? "Boy" : "Girl"}
                              </b>
                            </div>
                            {kid.age && (
                              <div>
                                Age:{" "}
                                <b style={{ color: "#ccd0e0" }}>{kid.age}</b>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Action Part (Check box / Button) for each KID */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* Check if KID not yet joined into the event */}
                          {!selectedEventDetails?.participantKidIds.includes(
                            kid._id
                          ) ? (
                            <>
                              {/* If the KID not yet enrolled into the event && even not yet SUBSCRIBED for month */}
                              <div
                                onClick={() => {
                                  if (selectedKidIds.includes(kid._id)) {
                                    // Remove Array reference & create a new one
                                    let prevSelectedKidIds = [
                                      ...selectedKidIds,
                                    ];
                                    // Get KID ID index
                                    let index = prevSelectedKidIds.indexOf(
                                      kid._id
                                    );
                                    // SPLICE out the KID ID
                                    prevSelectedKidIds.splice(index, 1);
                                    // SET new ARRAY of IDs
                                    setSelectedKidIds(prevSelectedKidIds);
                                  } else {
                                    setSelectedKidIds([
                                      ...selectedKidIds,
                                      kid._id,
                                    ]);
                                  }
                                }}
                                className={`w-5 h-5 border-2 rounded-full border-yellow-300 mr-5 ${
                                  selectedKidIds.includes(kid._id)
                                    ? "bg-yellow-300"
                                    : null
                                }`}
                              ></div>
                            </>
                          ) : (
                            <>
                              {/* If the KID already joined into this event */}
                              <div
                                className="text-xs font-bold py-1 px-5 rounded-full mr-3"
                                style={{
                                  backgroundColor: "#00000042",
                                  color: "#ffff00bf",
                                }}
                              >
                                ENROLLED
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>Add Kid first please!</div>
            )}
          </>
        ) : (
          // As not Logged in, so show a system to input "Email & KIDs name"
          <>
            <div>
              {/* PARENT EMAIL */}
              <div className="my-3 flex">
                <label className="font-bold text-nowrap w-[140px]">
                  Email/ Phone
                </label>
                <div>
                  <input
                    className="w-96 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
                    onChange={(e) => setParentEmail(e.target.value)}
                    placeholder="Email or Phone With Country Code (Ex: +1xxxxx)"
                  />
                  <div className="text-xs mt-1">
                    <b className="text-yellow-300">NOTE: </b>Enter the email or
                    phone number from which you received the invitation message
                    and link.
                  </div>
                </div>
              </div>

              {/* Event PROMO CODE */}
              {/* Event PROMO CODE */}
              <div className="my-3 flex">
                <label className="pt-2 font-bold w-[140px]">Kids</label>
                <div>
                  <button
                    type="button"
                    onClick={addKid}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Add Kid
                  </button>
                  {kids && kids.length > 0 && (
                    <div
                      style={{
                        padding: "10px 10px",
                        border: "1px solid #7a787869",
                      }}
                      className="rounded-lg mt-3 mb-6"
                    >
                      {kids.map((coupon, index) => (
                        <div>
                          <div
                            key={index}
                            className="coupon my-2"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <label>Kid Name </label>
                            <input
                              className="border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display w-56 mx-4"
                              onChange={(e) =>
                                handleChangeKid(index, "name", e.target.value)
                              }
                              value={coupon.name}
                              type="text"
                              // style={{ width: "150px" }}
                              placeholder="Enter kid name"
                            />

                            <button onClick={() => removeKid(index)}>
                              <FontAwesomeIcon className="" icon={faClose} />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {/* FINAL SUBMIT BUTTON for payment/ Free for Join */}
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="mt-5"
        >
          {initiateStripeSessionMutationLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-3 px-6 rounded-lg  mt-2"
                style={{
                  backgroundColor: "#0078ffc7",
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
                onClick={async () => {
                  acceptInvitation();
                }}
              >
                {acceptInvitationWithoutLoginMutationLoading ||
                acceptInvitationWithLoginMutationLoading
                  ? "Loading"
                  : "ENROLL KIDS FOR FREE"}
              </button>
            </div>
          )}
        </div>
      </Modal>
    </CommonBackdrop>
  );
}
