import { gql } from "@apollo/client";

export default gql`
  query findMessages($eventId: String, $userId: String) {
    findMessages(eventId: $eventId, userId: $userId) {
      createdBy
      userType
      message
      createdAt

      createdByDetails {
        name
        avatar

        player {
          _id
        }
      }
    }
  }
`;
