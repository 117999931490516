import React, { useEffect, useState } from "react";
import CommonBackdrop from "../common/CommonBackdrop";
import { EVENT_PAY } from "../../utils/constant";

export default function CommonAuth(props) {
  const currentUrl = window.location.pathname;
  const bgImage =
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1694403039/bSportsKidBallImage_h5sjty.png";

  const bgImage2 =
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1694423998/bSportsKidBallImage2_xjnxuf.png";

  return (
    <CommonBackdrop rootName='Members' pageName='Add Member'>
      <div className="sm:w-full w-full sx:px-auto flex md:flex-row sx:flex-col justify-items-center">
        {/* <!-- Left --> */}
        {/* <!-- Left --> */}
        <div
          style={{
            backgroundImage: `url(${bgImage})`,
            // backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          className="sx:hidden h-screen  md:block bg-contain  md:bg-cover bg-center sm:w-1/2  sx:w-full  mt-4"
        >
          <div className="flex flex-row my-[70px] ">
            <img
              src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694406557/bSportsVLogo_pel9sf.png"
              alt="Basement Sports"
              className="sx:hidden w-1/3 sm:block h-1/2 object-contain ml-10"
            />

            {currentUrl === "/base/registration" && (
              <div className="w-2/5 mx-auto ">
                <div className="bg-gray-600 bg-opacity-30 rounded p-3 mb-2">
                  <h1 className="font-medium text-yellow-300 text-xl ">
                    Membership
                  </h1>
                  <h1 className="font-medium">
                    ${EVENT_PAY.PER_MONTH_PAY} / Month
                  </h1>

                  <h3 className="font-display">
                    Our first Basement Sports Super Base is opening in Jersey
                    City on September 13, 2023. Register your kids NOW! Space is
                    limited (to 200 kid members) 10% Sibling Discount Available.
                  </h3>
                </div>

                <div className="bg-gray-600 bg-opacity-30 rounded p-3">
                  <h1 className="font-medium text-yellow-300 text-xl ">
                    Drop-In Classes
                  </h1>
                  <h1 className="font-medium">
                    ${EVENT_PAY.PER_MONTH_PAY} / Month
                  </h1>

                  <h3 className="font-display">
                    Sessions run for 90-120 minutes. During weekdays, we offer 2
                    sessions per day, with special events on weekends.
                  </h3>
                </div>
              </div>
            )}
          </div>

          <div className="w-10/12 mx-auto">
            {currentUrl != "/base/registration" && currentUrl != "add-kid" && (
              // <img
              //   src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694423648/welcomCard_oprzq0.png"
              //   alt="Basement Sports"
              //   className="sx:hidden sm:block h-1/2   sx:w-full sm:w-6/7 lg:pr-10 object-contain "
              // />

              <>
                <div className=" w-full h-full rounded  bg-gray-300 bg-opacity-10 p-2 ">
                  <h1 className="flex flex-row font-display">
                    Welcom to{" "}
                    <h1 className="text-yellow-300 ml-2"> Basement Sports</h1>
                  </h1>

                  <h3 className="text-[10px]">
                    Create a User Account and explore super bases, register for
                    specific events, manage subscriptions, view your digital
                    collectibles and more. Download the App and login with your
                    User Account to instantly play single games, series, and
                    tournaments for multiple sports.
                  </h3>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url(${bgImage2})`,
            // backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          className=" sx:block   md:hidden bg-cover bg-center w-full h-[500px] pt-[40px]"
        >
          {/* <img
            src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694423998/bSportsKidBallImage2_xjnxuf.png"
            alt="Basement Sports"
            className="sx:hidden sm:block h-1/2 object-contain ml-10 mt-[40px]"
          /> */}

          <img
            src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694423337/bSportsHLogo_hehbig.png"
            alt="Basement Sports"
            className="md:hidden sm:block w-1/2  object-contain ml-[20px] mb-3 mt-[280px]"
          />
        </div>

        <img
          src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694423648/welcomCard_oprzq0.png"
          alt="Basement Sports"
          className="md:hidden sx:block h-1/2 object-contain "
        />

        <div className="md:hidden sx:block">
          {currentUrl === "/base/registration" && (
            <div className="w-full mt-2 mx-auto ">
              <div className="bg-gray-500 bg-opacity-30 rounded p-3 mb-2">
                <h1 className="font-medium text-yellow-300 text-xl ">
                  Membership
                </h1>
                <h1 className="font-medium">
                  ${EVENT_PAY.PER_MONTH_PAY} / Month
                </h1>

                <h3 className="font-display">
                  Our first Basement Sports Super Base is opening in Jersey City
                  on September 13, 2023. Register your kids NOW! Space is
                  limited (to 200 kid members) 10% Sibling Discount Available.
                </h3>
              </div>

              <div className="bg-gray-600 bg-opacity-30 rounded p-3">
                <h1 className="font-medium text-yellow-300 text-xl ">
                  Drop-In Classes
                </h1>
                <h1 className="font-medium">
                  ${EVENT_PAY.PER_MONTH_PAY} / Month
                </h1>

                <h3 className="font-display">
                  Sessions run for 90-120 minutes. During weekdays, we offer 2
                  sessions per day, with special events on weekends.
                </h3>
              </div>
            </div>
          )}
        </div>
        {/* Middle Slot End */}

        {/* <!-- Right Login and SignUP --> */}
        {/* <!-- Right Login and SignUP--> */}
        <div className="md:w-1/2 sx:pb-10 px-2  sx:w-full flex justify-center ">
          {props.children}
        </div>
        {/* <!-- Right END --> */}
      </div>
    </CommonBackdrop>
  );
}
