import React, { useState, useEffect } from "react";
import AdminPortal from "./AdminPortal";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  format,
  addYears,
  formatISO,
  addMonths,
  addDays,
  differenceInHours,
  differenceInDays,
  isWithinInterval,
} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { utcToZonedTime, format as tzFormat } from "date-fns-tz";
import { Image } from "cloudinary-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  CLOUDINARY_INFO,
  EVENT_DURATION,
  EVENT_DURATION_HOUR,
  LOCAL_STORAGE_KEYS,
  WEEK_DAYAS,
} from "../../utils/constant";
import DataLoading from "../../component/loader/DataLoading";
import ClipLoader from "react-spinners/ClipLoader";

// Rich text editor import
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Query
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";
import FIND_USED_HOURS from "../../gql/query/base/findUsedHours.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";

// Mutations
import CREATE_EVENT from "../../gql/mutation/base/createEvent.mutations";
import { calculateEventEndTime } from "../../utils/eventTime";

function AddEvent() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  // Query parameters
  const search = useLocation().search;
  let preSelectedDate = new URLSearchParams(search).get("preSelectedDate");
  if (preSelectedDate) {
    preSelectedDate = preSelectedDate.replace(" ", "+");
  }

  const preSelectedSlot = new URLSearchParams(search).get("preSelectedSlot");

  const [dropdownShow, setDropdownShow] = useState(false);
  const [eventType, setEventType] = useState("Special Events");
  const [caption, setCaption] = useState("");
  const [address, setAddress] = useState("");
  const [eventPrice, setEventPrice] = useState(0);
  const [amountToPay, setAmountToPay] = useState(0); // In 'dollar'

  // Coupon State
  const [promoCode, setPromoCode] = useState("");
  const [coupons, setCoupons] = useState([]);

  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedSportsForComponent, setSelectedSportsForComponent] = useState(
    []
  );
  const [selectedGametype, setSelectedGametype] = useState("");
  const [selectedGametypeForComponent, setSelectedGametypeForComponent] =
    useState("");
  const [startDate, setStartDate] = useState(
    preSelectedDate ? new Date(preSelectedDate) : new Date()
  );

  const eventHour = [1, 2, 3, 4, 6, 12];
  // const [selectHour, setselectHour] = useState(1)

  // Set a zero time hour date
  let zeroTimeDate = new Date();
  zeroTimeDate.setHours(0);
  zeroTimeDate.setMinutes(0);
  zeroTimeDate.setMilliseconds(0);

  // Recurring event
  const [hasRecurring, setHasRecurring] = useState(false);
  const [recurringStartDate, setRecurringStartDate] = useState(zeroTimeDate);
  const [recurringEndDate, setRecurringEndDate] = useState(
    addMonths(new Date(), 1)
  );
  const [recurringDays, setRecurringDays] = useState([]);
  const [maxDateOfEndRecurring, setMaxDateOfEndRecurring] = useState(
    addMonths(new Date(), 1)
  );
  // We set initial value of "totalRecurringEvents" = 1 so that "totalRecurringEvents" multiply with "TOTAL PRICE" won't give 0
  const [totalRecurringEvents, setTotalRecurringEvents] = useState(1); // Total recurring days within selected recurring duration

  // Rich text editor state
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Image state
  const [image, setImage] = useState(null);

  // Event duration & time
  const [eventDuration, seteEventDuration] = useState(EVENT_DURATION.HALF_DAY);

  // Base opening & ending time
  const [baseOpeningTime, setBaseOpeningTime] = useState(new Date());
  const [baseEndingTime, setBaseEndingTime] = useState(new Date());

  // Event start time
  const [eventStartTime, setEventStartTime] = useState(new Date());
  const [eventStartHour, setEventStartHour] = useState(0);

  // Event end time
  const [eventEndTime, setEventEndTime] = useState(new Date());
  const [eventEndHour, setEventEndHour] = useState(0);

  // All used dates
  const [usedHoursOfSelectedDate, setUsedHoursOfSelectedDate] = useState([]);

  // All available hours
  const [availableHours, setAvailableHours] = useState([]);

  // Thank you page after event
  const [thankYouPage, setThankYouPage] = useState(false);

  const [discount, setDiscount] = useState(0); // Admin will set this discount as a single discount for an event

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_A_EVENT, {
    variables: {
      eventId,
    },
  });

  const {
    data: allUsedHours,
    loading: allUsedHoursLoading,
    error: allUsedHoursError,
    refetch: allUsedHoursRefetch,
  } = useQuery(FIND_USED_HOURS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      selectedDate: startDate,
    },
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [
    createEventMutation,
    { error: createEventError, loading: createEventMutationLoading },
  ] = useMutation(CREATE_EVENT);

  // DATE variables
  let oneYearFuture = addYears(new Date(), 1);

  // Initialize event data
  useEffect(() => {
    function initializeEventData() {
      // Refetch event data
      eventsRefetch();

      // If data successfully came
      if (eventsData && eventsData.findEvent) {
        let eventDetails = eventsData.findEvent;
        // Set event type
        setEventType(eventDetails.eventType);
        // set caption
        setCaption(eventDetails.caption);

        // set event price
        setAmountToPay(eventDetails.eventPrice);

        // set sports
        if (eventDetails.sports && eventDetails.sports.length > 0) {
          setSelectedSports(eventDetails.sports);
          let sportsForComponent = sportsOption.filter((sport) =>
            eventDetails.sports.includes(sport.value)
          );
          setSelectedSportsForComponent(sportsForComponent);
        }
        // Set coupon
        if (eventDetails.promoCodes && eventDetails.promoCodes.length > 0) {
          let newPromoCodesArray = eventDetails.promoCodes.map((promoCode) => {
            return {
              code: promoCode.code,
            };
          });
          setCoupons(newPromoCodesArray);
        }
        // set game type
        if (eventDetails.gameType && eventDetails.gameType != null) {
          setSelectedGametype(eventDetails.gameType);
          let sportsForComponent = gameTypesOption.filter((gameType) =>
            eventDetails.gameType.includes(gameType.value)
          );
          setSelectedGametypeForComponent(sportsForComponent);
        }

        // set date
        setStartDate(new Date(eventDetails.dateTime));

        // Set banner image
        if (eventDetails?.bannerImage) {
          setImage(eventDetails?.bannerImage);
        }

        // set description in Editor state
        if (eventDetails?.description) {
          const content = JSON.parse(eventDetails.description);
          const contentState = convertFromRaw(content);
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }

        // Set Location
        setAddress(eventDetails.address);

        // Refetch available Time SLOTS
        allUsedHoursRefetch({
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          selectedDate: new Date(eventDetails.dateTime),
        });
      }
    }
    initializeEventData();
  }, [eventsData]);

  // Set used HOURS
  useEffect(() => {
    function setUsedHours() {
      if (allUsedHours?.findUsedHours && baseData?.findBaseDetails) {
        // The used hours
        let usedHours = JSON.parse(allUsedHours?.findUsedHours?.additionalData);
        let finalDateObjects = [];

        // Convert all used hours to DATE Object
        for (let index = 0; index < usedHours.length; index++) {
          let currentTime = new Date();
          currentTime.setHours(usedHours[index]);
          currentTime.setMinutes(0);
          currentTime.setSeconds(0);
          finalDateObjects.push(currentTime);
        }

        // Set used DATE objects into a STATE
        setUsedHoursOfSelectedDate(finalDateObjects);

        // Find all the available hours of the BASE
        let baseHours = [];
        let baseOpeningHour = baseData?.findBaseDetails?.baseOpeningHour;
        let baseClosingHour = baseData?.findBaseDetails?.baseClosingHour;
        for (let index = baseOpeningHour; index <= baseClosingHour; index++) {
          baseHours.push(index);
        }

        // Find all the available HOURS to use
        let allAvailableHours = baseHours.filter(
          (baseHour) => !usedHours.includes(baseHour)
        );
        setAvailableHours(allAvailableHours); // Set the available hours

        // If there is allAvailableHours then set that allAvailableHours
        // Else, set the base opening hour as event start hour & end as end hour
        let tempEventStartHour = null;
        let tempEventEndHour = null;
        if (allAvailableHours.length > 0) {
          tempEventStartHour = allAvailableHours[0];
          tempEventEndHour = allAvailableHours[allAvailableHours.length - 1];
        } else {
          tempEventStartHour = baseData.findBaseDetails.baseOpeningHour;
          tempEventEndHour = baseData.findBaseDetails.baseClosingHour;
        }

        // Set the EVENT start time
        // Set the EVENT start time
        let currentDate = new Date();
        // set hour, muntes & seconds
        currentDate.setHours(tempEventStartHour);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        setBaseOpeningTime(currentDate);
        // set event start time & hour
        setEventStartTime(currentDate);
        setEventStartHour(tempEventStartHour);

        // Set the EVENT end time
        // Set the EVENT end time
        let newCurrentDate = new Date();
        // set hour, muntes & seconds
        newCurrentDate.setHours(tempEventEndHour);
        newCurrentDate.setMinutes(0);
        newCurrentDate.setSeconds(0);
        setBaseEndingTime(newCurrentDate);
        // set event end time & hour
        setEventEndTime(currentDate); // Setting the end time as event start time
        setEventEndHour(tempEventStartHour); // Setting the end hour as event start hour
      }
    }
    setUsedHours();
  }, [allUsedHours, baseData]);

  // Update amount To PAY
  useEffect(() => {
    function updateAmountToPay() {
      if (baseData?.findBaseDetails) {
        setAmountToPay(
          (differenceInHours(eventEndTime, eventStartTime) < 0
            ? 0
            : differenceInHours(eventEndTime, eventStartTime)) *
            baseData?.findBaseDetails?.perHourPrice
        );
      }
    }
    updateAmountToPay();
  }, [eventStartTime, eventEndTime]);

  // Update total recurring events count
  useEffect(() => {
    function updateRecrruingEventsCount() {
      let eventsCount = 0;

      // Calculate the number of days between the start and end dates
      const daysCount =
        differenceInDays(recurringEndDate, recurringStartDate) + 1;

      // Loop over the daysCount
      for (let i = 0; i <= daysCount; i++) {
        // Add i daysCount to the start date
        let currentDate = addDays(recurringStartDate, i);

        // Format the day name as Monday, Tuesday, etc.
        let dayName = format(currentDate, "EEE");
        dayName = dayName.toUpperCase();

        if (recurringDays.includes(dayName)) {
          ++eventsCount; // Increase the recurring events count
        }
      }

      // If total recurring events count is 0 then set it to 1 so that total price multiplication won't give 0
      if (eventsCount <= 0) {
        eventsCount = 1;
      }

      setTotalRecurringEvents(eventsCount);
    }
    updateRecrruingEventsCount();
  }, [
    hasRecurring,
    recurringStartDate,
    recurringEndDate,
    recurringDays,
    startDate,
  ]);

  // Handle Event Type Dropdown
  const handleDropdown = (e) => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-toggle")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };

  // Handle Event Type selection & clean other STATEs
  function handleEventTypeSelection(value) {
    setEventType(value);

    // Clean STATEs
    setSelectedSports([]);
    setSelectedSportsForComponent([]);
    setSelectedGametype("");
    setSelectedGametypeForComponent("");
  }

  // Dropdown Options of Event Type
  const eventTypeList = [
    { type: "Tournament" },
    { type: "Special Events" },
    { type: "Contest" },
  ];

  // Handle Sports dropdown
  const handleSelectSportsChange = (selectedOptions) => {
    setSelectedSportsForComponent(selectedOptions);
    const values = selectedOptions.map((option) => option.value);
    setSelectedSports(values);
  };

  // Dropdown Options of Sports
  const sportsOption = [
    { value: "soccer", label: "Soccer" },
    { value: "hockey", label: "Hockey" },
    { value: "baseBall", label: "Baseball" },
    { value: "basketBall", label: "Basketball" },
    { value: "pickelBall", label: "Pickelball" },
    { value: "tennis", label: "Tennis" },
    { value: "racket", label: "Racket" },
    { value: "volleyBall", label: "Volleyball" },
  ];

  // Handle Gametype dropdown
  const handleSelectGameTypeChange = (selectedOptions) => {
    setSelectedGametypeForComponent(selectedOptions);
    setSelectedGametype(selectedOptions.value);
  };

  // Dropdown Options of Game Type
  const gameTypesOption = [
    { value: "1v", label: "1V" },
    { value: "2v", label: "2V" },
    { value: "3v", label: "3V" },
  ];

  // Set custom style in react-select
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black", // Set the color for all options
    }),
  };

  // Coupon related method
  const addCoupon = () => {
    // a function to add a new coupon object to the array
    setCoupons([
      ...coupons,
      {
        code: "",
        // startDate: new Date(),
        // endDate: null,
      },
    ]);
  };

  // a function to remove a coupon object from the array by index
  const removeCoupon = (index) => {
    setCoupons(coupons.filter((_, i) => i !== index));
  };

  // a function to update a coupon object by index, key and value
  const handleChange = (index, key, value) => {
    setCoupons(
      coupons.map((coupon, i) =>
        i === index ? { ...coupon, [key]: value } : coupon
      )
    );
  };

  // Check if coupon is valid or not
  function isValidCoupon(promoCode) {
    return String(promoCode).match(/^[a-zA-Z][a-zA-Z0-9]*[0-9]$/);
  }

  // Submit to create Event
  async function createEvent() {
    // Check EventType was given or not
    if (eventType == "" || eventType == null) {
      toast.error("Please select Event Type!");
      return;
    }

    // Check the time
    if (
      baseData?.findBaseDetails?.eventMinimumHour &&
      differenceInHours(eventEndTime, eventStartTime) <
        baseData?.findBaseDetails?.eventMinimumHour
    ) {
      toast.error(
        `You can't submit a request that is less than ${baseData?.findBaseDetails?.eventMinimumHour} hour`
      );
      return;
    }

    if (differenceInHours(eventEndTime, eventStartTime) <= 0) {
      toast.error("You can't submit a request that is less than 1 hour");
      return;
    }

    // Check if the selected start time & end time sequential or not
    let selectedHours = [];
    for (let hour = eventStartHour; hour <= eventEndHour; hour++) {
      selectedHours.push(hour);
    }
    let unavailableButSelectedHours = selectedHours.filter(
      (hour) => !availableHours.includes(hour)
    );
    if (unavailableButSelectedHours.length > 0) {
      toast.error(
        "Start and End time should be sequential within available hours!"
      );
      return;
    }

    // Check Coupons are valid or not
    if (coupons && coupons.length > 0) {
      // Check if invalid coupon code exist or not
      for (let index = 0; index < coupons.length; index++) {
        const coupon = coupons[index];
        if (!coupon.code || coupon.code == "") {
          toast.error("Coupon code can't be empty!");
          return;
        }
        if (!String(coupon.code).match(/^[a-zA-Z][a-zA-Z0-9]*[0-9]$/)) {
          toast.error("Invalid coupon codes found!");
          return;
        }
      }
      // Check if all given coupon codes are unique
      let codes = coupons.map((obj) => obj.code); // Create an array of codes
      let set = new Set(codes); // Remove all repeated codes from the array
      if (set.size != coupons.length) {
        toast.error("All coupon codes must be unique!");
        return;
      }
    }
    // Check startedate
    if (startDate == "" || startDate == null) {
      toast.error("Please select a date & time!");
      return;
    }
    // Check recurring data
    if (hasRecurring) {
      if (recurringDays.length == 0) {
        toast.error("Please choose at least one recurring day!");
        return;
      }
    }
    // Check if EventType is Tournament or League or Contest
    if (
      (eventType === "Tournament" ||
        eventType === "League" ||
        eventType === "Contest") &&
      (!selectedSports || selectedSports == null || selectedSports.length == 0)
    ) {
      toast.error("Please select at least one Sport!");
      return;
    }
    // Check if EventType Tournament or League
    if (
      (eventType === "Tournament" || eventType === "League") &&
      (selectedGametype == "" || selectedGametype == null)
    ) {
      toast.error("Please select Game Type!");
      return;
    }
    // Check Image
    if (!image || image == null || image == "") {
      toast.error("Please insert a banner image!");
      return;
    }
    // Now check is Rich Editor state empty or not
    const checkContentState = editorState.getCurrentContent();
    const plainText = checkContentState.getPlainText().trim();
    const isEmpty = plainText.length === 0;
    if (isEmpty) {
      toast.error("Please insert some description!");
      return;
    }

    let dateToSubmit = formatISO(startDate, { representation: "complete" });

    const { data } = await createEventMutation({
      variables: {
        createdBy: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        eventType: eventType,
        caption: caption == "" ? null : caption,
        dateTime: dateToSubmit,
        eventPrice:
          recurringDays.length > 0
            ? Number(amountToPay) * totalRecurringEvents
            : Number(amountToPay),
        sports: selectedSports.length == 0 ? null : selectedSports,
        gameType: selectedGametype == "" ? null : selectedGametype,
        description: JSON.stringify(
          convertToRaw(editorState.getCurrentContent())
        ),
        easyDescription: plainText,
        bannerImage: image,
        promoCodes: coupons,
        address,
        hasRecurring,
        recurringStartDate,
        recurringEndDate,
        recurringDays,
        eventStartHour: Number(eventStartHour),
        eventEndHour: Number(eventEndHour),
        discount: Number(discount),
      },
    });

    if (data.createEvent.errorMessage == null) {
      toast.success("Successfully added event");
      setThankYouPage(true);
      // navigate(`/admin/update-event/${data.createEvent._id}`);
    }
  }

  // Upload cloudinary image
  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    let formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "blueLinerfile");
    formData.append("cloud_name", "dva2poi3c");

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dvqldxdfv/image/upload`,
        {
          method: "post",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        setImage(data.secure_url);
      }
    } catch (error) {}
  };

  if (eventsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (thankYouPage) {
    return (
      <AdminPortal>
        <div
          className="md:w-12/12 my-auto bg-slate-700 flex items-center px-4 py-3 mt-8 mb-3 rounded-lg justify-center text-5xl"
          style={{ minHeight: "700px", flexDirection: "column" }}
        >
          <div className="mb-3" style={{ textAlign: "center" }}>
            Event has been created successfully 🎉
          </div>

          <img
            src={image}
            alt="Uploaded"
            style={{
              borderRadius: "10px",
              maxHeight: "460px",
              objectFit: "cover",
              width: "100%",
            }}
            className="mt-5"
          />
        </div>
      </AdminPortal>
    );
  }

  return (
    <AdminPortal>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        // className="ml-10 mr-10 mt-6"
        className="md:w-12/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mt-8 mb-3 rounded-lg"
      >
        <div className="">
          <Link
            to={
              eventId
                ? `/admin/update-event/${eventId}`
                : "/admin/calendar-management"
            }
            className="font-bold text-lg px-4 m-1 py-1 rounded"
          >
            <FontAwesomeIcon className="mr-3" icon={faCircleArrowLeft} />
            Back
          </Link>
        </div>
      </div>

      <div className="max-w-6xl mx-auto bg-slate-700 mt-5 py-3 px-4 mb-14 rounded-lg">
        <h3 className="text-center font-bold text-[30px] mt-2 mb-6">
          Create Event
        </h3>

        {/* Event TYPE */}
        {/* Event TYPE */}
        <div className="dropdown cursor-pointer relative my-2 ">
          <div>
            <label className="mr-9 font-bold">
              Event Type <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <button
              className="border-2 border-slate-500 px-2 py-5 rounded dropdown-toggle  inline-flex items-center  h-6"
              type="button"
              onClick={(e) => handleDropdown(e)}
            >
              <h1 className="text-white font-display font-bold">
                {eventType ? eventType : "Event type"}
              </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-white h-6 w-6 "
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
              </svg>
            </button>
          </div>

          <div
            className={
              dropdownShow
                ? "dropdown-menu bg-black/90 z-10  min-w-[200px] whitespace-nowrap rounded-xl py-4 px-2 text-left shadow-xl show h-40  text-yellow-300 absolute  top-full mx-auto"
                : "dropdown-menu bg-black/90 z-10  min-w-[200px] whitespace-nowrap rounded-xl py-4 px-2 text-left shadow-xl hidden text-yellow-300 absolute top-full"
            }
          >
            {eventTypeList.map((event) => (
              <button
                key={event.type}
                onClick={() => handleEventTypeSelection(event.type)}
                className="block dropdown-text mx-auto"
              >
                {event.type}
              </button>
            ))}
          </div>
        </div>

        {/* Caption */}
        {/* Caption */}
        <div className="my-3">
          <label className="mr-16 pr-3 font-bold">Caption</label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setCaption(e.target.value)}
            value={caption}
          />
        </div>

        {/* Date Picker for DATE */}
        {/* Date Picker for DATE */}
        <div className="my-2">
          <label className="mr-20 pr-1 font-bold">
            Date <b style={{ color: "#ff0000a1" }}> * </b>
          </label>

          <DatePicker
            className="rounded w-64 text-xl font-bold text-blue-900"
            style={{ width: "100px" }}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              // Refetch available Time Slots
              allUsedHoursRefetch({
                baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                selectedDate: new Date(date),
              });

              // Find the selected event date DAY
              if (hasRecurring) {
                let dayName = format(date, "EEE");
                dayName = dayName.toUpperCase();
                setRecurringDays([dayName]); // Set the selected event date DAY as initial Recurring day
              }
            }}
            maxDate={oneYearFuture}
            minDate={new Date()}
            dateFormat="MMMM d, yyyy, EEE"
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Time"
            placeholderText="Select Date and Time"
          />
        </div>

        {/* Event Start Time */}
        {/* Event Start Time */}
        <div className="my-3 flex items-center">
          <label className="min-w-[135px] font-bold ">
            Start Time <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <div>
            {allUsedHoursLoading ? (
              <ClipLoader
                color={"#ffffff"}
                loading={true}
                css={override}
                size={20}
              />
            ) : (
              <DatePicker
                className="rounded w-32 text-xl font-bold text-blue-900"
                style={{ width: "100px" }}
                selected={eventStartTime}
                onChange={(date) => {
                  // Set event start time
                  setEventStartTime(date);
                  setEventStartHour(date.getHours());
                  // Set event end time & hour
                  setEventEndTime(date);
                  setEventEndHour(date.getHours());
                }}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                timeIntervals={60}
                timeCaption="Time"
                placeholderText="Start Time"
                minTime={baseOpeningTime}
                maxTime={baseEndingTime}
                excludeTimes={usedHoursOfSelectedDate}
              />
            )}
          </div>
        </div>

        {/* Event End Time */}
        {/* Event End Time */}
        <div className="my-2 flex items-center">
          <label className="min-w-[135px] font-bold ">
            End Time <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <div>
            {allUsedHoursLoading ? (
              <ClipLoader
                color={"#ffffff"}
                loading={true}
                css={override}
                size={20}
              />
            ) : (
              <DatePicker
                className="rounded w-32 text-xl font-bold text-blue-900"
                style={{ width: "200px" }}
                selected={eventEndTime}
                onChange={(date) => {
                  // Set event start time
                  setEventEndTime(date);
                  setEventEndHour(date.getHours());
                }}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                timeIntervals={60}
                timeCaption="Time"
                placeholderText="Start Time"
                minTime={eventStartTime}
                maxTime={baseEndingTime}
                excludeTimes={usedHoursOfSelectedDate}
              />
            )}
          </div>
          {baseData?.findBaseDetails?.eventMinimumHour && (
            <div className="ml-5 text-sm">
              <i className="font-bold text-yellow-300">NOTE:</i> Select{" "}
              {baseData?.findBaseDetails.eventMinimumHour} hr. minimum (You can
              change it from Event Management section)
            </div>
          )}
        </div>

        {/* DURATION */}
        {/* DURATION */}
        <div className="mt-0 mb-3 flex items-center">
          <label className="mr-16 pr-1 font-bold">Duration</label>
          <div className="flex items-center text-xl font-bold">
            {differenceInHours(eventEndTime, eventStartTime) < 0
              ? 0
              : differenceInHours(eventEndTime, eventStartTime)}{" "}
            hours
          </div>
        </div>

        {/* Event Price */}
        {/* Event Price */}
        <div className="my-3 flex items-center">
          <label className="mr-10 pr-14 font-bold">Price</label>
          <div
            className={`text-2xl font-bold   ${
              discount > 0 ? "line-through text-slate-400" : "text-yellow-300"
            }`}
          >
            ${amountToPay * totalRecurringEvents}
          </div>
          {discount > 0 && (
            <div className="text-2xl font-bold text-yellow-300 ml-3">
              {amountToPay * totalRecurringEvents - discount < 0 ? (
                <div>$0</div>
              ) : (
                <div>${amountToPay * totalRecurringEvents - discount}</div>
              )}
            </div>
          )}
          {recurringDays.length == 0 ? (
            <div className="ml-3">
              ( ${baseData?.findBaseDetails?.perHourPrice}/hour x{" "}
              {differenceInHours(eventEndTime, eventStartTime) < 0
                ? 0
                : differenceInHours(eventEndTime, eventStartTime)}{" "}
              hours ){" "}
            </div>
          ) : (
            <div className="ml-3">
              ( ${baseData?.findBaseDetails?.perHourPrice}/hour x{" "}
              {differenceInHours(eventEndTime, eventStartTime) < 0
                ? 0
                : differenceInHours(eventEndTime, eventStartTime)}{" "}
              hours x {recurringDays.length > 0 ? totalRecurringEvents : 0}{" "}
              Recurring events ){" "}
            </div>
          )}
        </div>

        {/* Event DISCOUNT */}
        {/* Event DISCOUNT */}
        <div className="my-3 flex items-center">
          <label className="mr-10 pr-7 font-bold">Discount</label>
          <input
            className="border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display w-32"
            onChange={(e) => {
              setDiscount(e.target.value);
            }}
            value={discount}
            type="text"
            placeholder="Ex:  asdf10"
          />
          <div className="ml-2">$</div>

          {/* Discount info */}
          {amountToPay * totalRecurringEvents > 0 && discount > 0 && (
            <div className="flex items-center ml-6">
              <div className="text-lg">
                <b className="text-yellow-100 font-bold">
                  {(
                    (discount / (amountToPay * totalRecurringEvents)) *
                    100
                  ).toFixed(2)}
                  %
                </b>{" "}
                Discount
              </div>

              <div className="flex items-center ml-4 font-bold text-2xl">
                ({" "}
                <div className="text-2xl font-bold line-through text-slate-400">
                  ${amountToPay * totalRecurringEvents}
                </div>
                <div className="text-2xl font-bold text-yellow-300 ml-3">
                  {amountToPay * totalRecurringEvents - discount < 0 ? (
                    <div>$0</div>
                  ) : (
                    <div>${amountToPay * totalRecurringEvents - discount}</div>
                  )}
                </div>{" "}
                )
              </div>
            </div>
          )}
        </div>

        {/* RECURRING EVENT */}
        {/* RECURRING EVENT */}
        {eventType == "Special Events" && (
          <div className="my-5" style={{ display: "flex" }}>
            <label className="min-w-[135px] font-bold">Recurring</label>

            <div>
              {/* STATUS of recurring */}
              <div className="flex items-center">
                <input
                  className="border-none rounded-lg"
                  onClick={() => {
                    let zeroTimeDate = new Date();
                    zeroTimeDate.setHours(0);
                    zeroTimeDate.setMinutes(0);
                    zeroTimeDate.setMilliseconds(0);

                    setHasRecurring(!hasRecurring);
                    setRecurringStartDate(zeroTimeDate);
                    setRecurringEndDate(addMonths(new Date(), 1));

                    // If now hasRecurring is true then set "recurringDays" as the selected date DAY
                    // Else, set "recurringDays" as empty array
                    if (!hasRecurring) {
                      // Find the selected event date DAY
                      let dayName = format(startDate, "EEE");
                      dayName = dayName.toUpperCase();
                      setRecurringDays([dayName]); // Set the selected event date DAY as initial Recurring day
                    } else {
                      setRecurringDays([]);
                    }
                  }}
                  type="checkbox"
                  checked={hasRecurring}
                />
                <label className="ml-2 font-bold">
                  Enable recurring this event
                </label>
              </div>

              {hasRecurring && (
                <div>
                  {/* FROM & TO */}
                  <div className="flex items-center mt-2">
                    {/* FROM */}
                    <div className="flex items-center mr-5">
                      <div className="font-bold mr-2">From</div>
                      <DatePicker
                        className="rounded w-64 text-black"
                        style={{ width: "100px" }}
                        selected={recurringStartDate}
                        onChange={(date) => {
                          date.setHours(0);
                          date.setMinutes(0);
                          date.setMilliseconds(0);

                          setRecurringStartDate(date);
                          // Set recurring end date
                          setRecurringEndDate(addMonths(new Date(date), 1));
                          // Set 1 month as MAX for Recurring End Date
                          setMaxDateOfEndRecurring(
                            addMonths(new Date(date), 1)
                          );
                        }}
                        maxDate={oneYearFuture}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select recurring start date"
                      />
                    </div>

                    {/* TO */}
                    <div className="flex items-center mr-5">
                      <div className="font-bold mr-2">To</div>
                      <DatePicker
                        className="rounded w-64 text-black"
                        style={{ width: "100px" }}
                        selected={recurringEndDate}
                        onChange={(date) => {
                          setRecurringEndDate(date);
                        }}
                        maxDate={oneYearFuture}
                        minDate={recurringStartDate}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select recurring end date"
                      />
                    </div>
                  </div>
                  {/* SET RECURRING FOR DAY */}
                  <div className="flex items-center font-bold">
                    <div className="mr-2 my-2">Days</div>
                    <div className="flex flex-wrap items-center">
                      {/* ALL DAYS */}
                      <div
                        className="px-2 py-2 mr-2 my-2 rounded-lg cursor-pointer"
                        style={{
                          backgroundColor:
                            recurringDays.length == WEEK_DAYAS.length
                              ? "#0054ffc9"
                              : "#44546f",
                        }}
                        onClick={() => {
                          if (recurringDays.length == WEEK_DAYAS.length) {
                            setRecurringDays([]);
                          } else {
                            setRecurringDays(WEEK_DAYAS);
                          }
                        }}
                      >
                        ALL DAYS
                      </div>
                      {/* WEEK DAYS */}
                      {WEEK_DAYAS.map((day) => (
                        <div
                          className="px-2 py-2 mr-2 my-2 rounded-lg cursor-pointer"
                          style={{
                            backgroundColor: recurringDays.includes(day)
                              ? "#0054ffc9"
                              : "#44546f",
                          }}
                          onClick={() => {
                            if (recurringDays.includes(day)) {
                              setRecurringDays(
                                recurringDays.filter(
                                  (weekDay) => weekDay != day
                                )
                              );
                            } else {
                              setRecurringDays([...recurringDays, day]);
                            }
                          }}
                        >
                          {day}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Event PROMO CODE */}
        {/* Event PROMO CODE */}
        <div className="my-3">
          <div style={{ display: "flex" }}>
            <label className="mr-4 pr-6 pt-2 font-bold">Promo Code</label>
            <div>
              <button
                type="button"
                onClick={addCoupon}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                Add Coupon
              </button>
              <div
                className="text-xs mt-3 w-[600px]"
                style={{ color: "#c1e9ef99", alignSelf: "center" }}
              >
                <div>
                  <i>NOTE:</i> Example coupon{" "}
                  <b style={{ color: "yellow" }}>Morning200</b> (where 200
                  represents a $200 discount).
                </div>

                {coupons && coupons.length > 0 && (
                  <div
                    style={{
                      padding: "10px 10px",
                      border: "1px solid #7a787869",
                    }}
                    className="rounded-lg mt-3 mb-6"
                  >
                    {coupons.map((coupon, index) => (
                      <div>
                        <div
                          key={index}
                          className="coupon my-2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <label className="text-lg">Code </label>
                          <input
                            className="border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display w-32 mx-5"
                            onChange={(e) =>
                              handleChange(index, "code", e.target.value)
                            }
                            value={coupon.code}
                            type="text"
                            // style={{ width: "150px" }}
                            placeholder="Ex:  asdf10"
                          />

                          {/* Discount */}
                          {String(coupon.code).match(
                            /^[a-zA-Z][a-zA-Z0-9]*[0-9]$/
                          ) && (
                            <div>
                              {recurringDays.length > 0 ? (
                                <div className="text-lg">
                                  <b className="text-yellow-100 font-bold">
                                    {(
                                      (Number(coupon.code.match(/[0-9]+/)) /
                                        (amountToPay * totalRecurringEvents)) *
                                      100
                                    ).toFixed(2)}{" "}
                                    %
                                  </b>{" "}
                                  Discount
                                </div>
                              ) : (
                                <div className="text-lg">
                                  <b className="text-yellow-100 font-bold">
                                    {(
                                      (Number(coupon.code.match(/[0-9]+/)) /
                                        amountToPay) *
                                      100
                                    ).toFixed(2)}{" "}
                                    %
                                  </b>{" "}
                                  discount
                                </div>
                              )}
                            </div>
                          )}

                          {/* New price */}
                          {String(coupon.code).match(
                            /^[a-zA-Z][a-zA-Z0-9]*[0-9]$/
                          ) && (
                            <div>
                              {recurringDays.length > 0 ? (
                                <div className="flex items-center text-2xl font-bold">
                                  {amountToPay * totalRecurringEvents >
                                    Number(coupon.code.match(/[0-9]+/)) && (
                                    <div className="flex items-center">
                                      (
                                      <div className="line-through">
                                        ${amountToPay * totalRecurringEvents}
                                      </div>
                                      <div
                                        className="ml-3"
                                        style={{ color: "gold" }}
                                      >
                                        $
                                        {amountToPay * totalRecurringEvents -
                                          Number(coupon.code.match(/[0-9]+/))}
                                      </div>
                                      )
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="flex items-center text-2xl font-bold">
                                  {amountToPay >
                                    Number(coupon.code.match(/[0-9]+/)) && (
                                    <div className="flex items-center">
                                      (
                                      <div className="line-through">
                                        ${amountToPay}
                                      </div>
                                      <div
                                        className="ml-3"
                                        style={{ color: "gold" }}
                                      >
                                        $
                                        {amountToPay -
                                          Number(coupon.code.match(/[0-9]+/))}
                                      </div>
                                      )
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          <button onClick={() => removeCoupon(index)}>
                            <FontAwesomeIcon className="" icon={faClose} />
                          </button>
                        </div>
                        {coupon.code != "" && (
                          <div className="mb-4">
                            {String(coupon.code).match(
                              /^[a-zA-Z][a-zA-Z0-9]*[0-9]$/
                            ) ? (
                              <div style={{ color: "#06ff06" }}>
                                Valid coupon code!
                              </div>
                            ) : (
                              <div style={{ color: "red" }}>
                                Invalid coupon code!
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Sports */}
        {/* Sports */}
        {(eventType === "Tournament" ||
          eventType === "League" ||
          eventType === "Contest") && (
          <div className="my-2 flex items-center">
            {" "}
            <label className="mr-16 pr-2 font-bold">
              Sports <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <Select
              className="w-64"
              value={selectedSportsForComponent}
              onChange={handleSelectSportsChange}
              options={sportsOption}
              isMulti
              styles={customStyles}
            />
          </div>
        )}

        {/* Game Type */}
        {/* Game Type */}
        {(eventType === "Tournament" || eventType === "League") && (
          <div className="my-2 flex items-center ">
            <label className="mr-10 font-bold">
              Game type <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <Select
              className="w-64"
              value={selectedGametypeForComponent}
              onChange={handleSelectGameTypeChange}
              options={gameTypesOption}
              isMulti={false}
              styles={customStyles}
            />
          </div>
        )}

        {/* Capacity */}
        {/* Capacity */}
        {eventType === "Special Events" && (
          <div className="flex items-center">
            <label className="mr-11 pr-5 font-bold">Capacity</label>
            <div>
              {baseData?.findBaseDetails?.capacity
                ? baseData?.findBaseDetails?.capacity
                : 0}
            </div>
          </div>
        )}

        {/* BANNER IMAGE */}
        <div style={{ display: "flex" }} className="mt-5">
          <label className="mr-2 pr-2 font-bold">
            Banner Image <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <div>
            <input type="file" onChange={handleFileChange} />
            {image && (
              <img
                src={image}
                alt="Uploaded"
                style={{ borderRadius: "10px", maxHeight: "300px" }}
                className="mt-5"
              />
            )}
          </div>
        </div>

        {/* Event Location */}
        {/* Event Location */}
        <div className="my-3">
          <label className="mr-16 pr-1 font-bold">Location</label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
        </div>

        {/* Description */}
        <div className="mt-5">
          <label className="mr-10 pr-2 font-bold">
            Description <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
        </div>

        {/* DEWCRIPTION body */}
        <div className="mt-2 text-black">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            placeholder="Enter event description."
          />
        </div>

        {/* SUBMIT button */}
        {createEventMutationLoading ? (
          <button
            className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold pt-3 pb-2 px-8 rounded mx-auto block"
            disabled={true}
          >
            <ClipLoader
              color={"#ffffff"}
              loading={true}
              css={override}
              size={20}
            />
          </button>
        ) : (
          <button
            className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
            onClick={() => {
              createEvent();
            }}
          >
            Submit
          </button>
        )}
      </div>
    </AdminPortal>
  );
}
export default AddEvent;
