import React, { useState, useEffect, useRef } from "react";
import AdminPortal from "./AdminPortal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useQuery, useMutation } from "@apollo/client";
import DataLoading from "../../component/loader/DataLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faUsers,
  faUser,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import __ from "lodash";
import iconFinder from "../../utils/iconFinder";
import ClipLoader from "react-spinners/ClipLoader";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

// Query
import FIND_EVENT_PARTICIPANTS from "../../gql/query/base/findEventParticipantUsers.query";
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";
import FIND_MESSAGES from "../../gql/query/base/findMessages.query";
import {
  BASEMENT_SPORTS_OFFICAL_LOCATION,
  LOCAL_STORAGE_KEYS,
  USER_TYPES,
} from "../../utils/constant";

// Mutations
import CREATE_MESSAGE from "../../gql/mutation/base/createMessage.mutations";

function ChatWithClient() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParent, setKidsWithParents] = useState([]);
  const [kidsWithoutParent, setKidsWithoutParent] = useState([]);

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParentToShow, setKidsWithParentsToShow] = useState([]);
  const [kidsWithoutParentToShow, setKidsWithoutParentToShow] = useState([]);

  // Event start time
  const [eventStartTime, setEventStartTime] = useState(new Date());

  // Event end time
  const [eventEndTime, setEventEndTime] = useState(new Date());

  // Current message
  const [currentMessage, setCurrentMessage] = useState(null);

  // Message DIV
  const messageDivRef = useRef(null);

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_A_EVENT, {
    variables: {
      eventId,
    },
  });

  const {
    data: messages,
    loading: messagesLoading,
    error: messagesError,
    refetch: refetchMessages,
  } = useQuery(FIND_MESSAGES, {
    variables: {
      eventId,
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    pollInterval: 1000,
  });

  // Mutations
  const [
    createMessageMutation,
    {
      error: createMessageMutationError,
      loading: createMessageMutationLoading,
    },
  ] = useMutation(CREATE_MESSAGE);

  // Do actions after changed the messages data
  useEffect(() => {
    function actionsAfterMessageFetch() {
      if (messages?.findMessages) {
        setTimeout(() => {
          messageDivRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }, 200);
      }
    }
    actionsAfterMessageFetch();
  }, [messages]);

  // Set data & times of events
  useEffect(() => {
    function setEventData() {
      if (eventsData && eventsData?.findEvent) {
        // Update base baseOpeningHour
        if (eventsData.findEvent?.eventStartHour) {
          let currentDate = new Date();
          // set hour, minutes & seconds
          currentDate.setHours(eventsData.findEvent.eventStartHour);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
          // set event start time
          setEventStartTime(currentDate);
        }

        // Update base baseClosingHour
        if (eventsData.findEvent?.eventEndHour) {
          let currentDate = new Date();
          // set hour, minutes & seconds
          currentDate.setHours(eventsData.findEvent?.eventEndHour);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
          // set event end time
          setEventEndTime(currentDate);
        }
      }
    }
    setEventData();
  }, [eventsData]);

  // Refetch data on focus
  useEffect(() => {
    function refetchData() {
      eventsRefetch();
      refetchMessages();
    }
    refetchData();
  }, []);

  async function sendAMessage() {
    const { data } = await createMessageMutation({
      variables: {
        createdBy: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        userType: USER_TYPES.ADMIN,
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        message: currentMessage,
        eventId,
      },
    });

    if (data.createMessage.errorMessage == null) {
      setCurrentMessage("");
      refetchMessages();
    }
  }

  return (
    <AdminPortal>
      <div
        className="md:w-11/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mt-8 mb-3 "
        style={{ justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="cursor-pointer"
          >
            <FontAwesomeIcon className="mr-3" icon={faCircleArrowLeft} />
          </div>
          <h1 className="text-center font-bold text-2xl ml-7">
            Chat With Client
          </h1>
        </div>
        <Link
          to={`/admin/update-event/${eventId}`}
          className="text-xl font-bold bg-blue-500 py-1 px-2 rounded"
          style={{ color: "white" }}
        >
          Event Details
        </Link>
      </div>

      {/* EVENT DETAILS */}
      {eventsLoading ? (
        <div className="flex items-center justify-center">
          <ClipLoader
            color={"#ffffff"}
            loading={true}
            css={override}
            size={40}
          />
        </div>
      ) : (
        <div
          className={`overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto px-4 flex items-center font-display text-yellow-300 text-lg rounded-lg py-2 mt-5`}
          style={{
            justifyContent: "space-between",
            backgroundColor: "#454f6680",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
            }}
            className="py-2"
          >
            {/* Event creator AVATAR & NAME */}
            <div className="flex items-center">
              {/* AVATAR */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={iconFinder(
                    eventsData?.findEvent?.createdByDetails?.avatar
                  )}
                  alt="Basement Sports"
                  className="max-h-17 cursor-pointer"
                  style={{
                    color: "white",
                    minWidth: "100px",
                    maxWidth: "100px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                  onClick={() => {
                    navigate(
                      `/admin/kid-details/${eventsData?.findEvent?.createdByDetails?.player?._id}`
                    );
                  }}
                />
              </div>
              {/* PARENT INFO SECTION */}
              <div
                className="ml-5"
                style={{
                  borderRight: "1px dotted #ffffff45",
                  paddingRight: "60px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  minWidth: "250px",
                  maxWidth: "250px",
                  overflow: "hidden",
                }}
              >
                {/* Parent Name */}
                <div>{eventsData?.findEvent?.createdByDetails?.name}</div>
                {/* Parent Email */}
                <div>{eventsData?.findEvent?.createdByDetails?.email}</div>
                <div>Role: {eventsData?.findEvent?.createdByDetails?.role}</div>
              </div>
            </div>
            {/* EVENT INFO */}
            <div className="ml-5">
              <h1>
                <b className="" style={{ color: "white" }}>
                  Event Name:{" "}
                </b>
                {eventsData?.findEvent?.caption}
              </h1>
              <h1>
                <b className="" style={{ color: "white" }}>
                  Date:{" "}
                </b>
                {format(
                  new Date(eventsData?.findEvent?.dateTime),
                  "do MMM yyyy"
                )}
              </h1>

              {/* START & END Time */}
              <div
                className="flex items-center font-bold"
                style={{ color: "white" }}
              >
                <div className="mr-2" style={{ color: "white" }}>
                  From:{" "}
                </div>
                <div className="text-cyan-400">
                  {format(eventStartTime, "h:mm aa")}
                </div>
                <div className="mx-3">to</div>
                <div className="text-cyan-400">
                  {format(eventEndTime, "h:mm aa")}
                </div>
              </div>

              <div>
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="text-sm"
                >
                  {eventsData?.findEvent?.timeSlots?.map((slot) => (
                    <div
                      className={`bg-green-500 hover:bg-green-700 px-2 py-2 rounded-lg mt-2 mr-2 font-bold cursor-pointer`}
                      style={{ color: "white" }}
                    >
                      {slot}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="my-5 rounded-lg sm:w-10/12 md:w-11/12 mx-auto"
        style={{ backgroundColor: "#454f6680" }}
      >
        <div className="flex flex-col relative">
          <div className="overflow-y-scroll h-[530px]">
            <div className="mt-5 mb-[100px]">
              {/* all messages */}
              {messages?.findMessages?.map((message) => (
                <div className=" mx-3 mt-3 mb-5 flex items-center">
                  {/* Avatar*/}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={iconFinder(message?.createdByDetails?.avatar)}
                        alt="Basement Sports"
                        className="cursor-pointer"
                        style={{
                          color: "white",
                          minWidth: "70px",
                          maxWidth: "70px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                        onClick={() => {
                          navigate(
                            `/admin/kid-details/${message?.createdByDetails?.player?._id}`
                          );
                        }}
                      />
                    </div>
                  </div>
                  {/* Name & Message */}
                  <div>
                    {console.log("DDDDDDD: ", message)}
                    {/* name */}
                    <div className="font-bold text-cyan-300 text-xs">
                      {message?.createdByDetails?.name}{" "}
                      {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) ==
                        message?.createdBy && (
                        <b className="text-yellow-100">(ME)</b>
                      )}
                    </div>
                    {/* message */}
                    <div style={{ color: "white" }}>{message?.message}</div>
                    <div className="text-xs" style={{ opacity: "0.5" }}>
                      {formatDistanceToNow(Number(message.createdAt))} ago
                    </div>
                  </div>
                </div>
              ))}

              {/* This empty div serves as the reference point for scrolling */}
              <div className="mt-16 pt-5" ref={messageDivRef} />
            </div>
          </div>

          {/* <div className="p-4 bg-slate-700 rounded-lg border-gray-300 fixed bottom-5    sm:w-6/12 md:w-6/12 mx-auto   left-0 right-0 flex justify-center items-center"> */}
          <div
            className="absolute bottom-0 left-0 right-0 flex justify-center items-center p-4 rounded-lg"
            style={{ backgroundColor: "#425c83" }}
          >
            <div className="flex items-center sm:w-8/12 md:w-8/12 mx-auto">
              <input
                type="text"
                className="flex-grow mr-4 p-2 border rounded"
                placeholder="Type your message..."
                value={currentMessage}
                onChange={(e) => {
                  setCurrentMessage(e.target.value);
                }}
                style={{ color: "black" }}
              />
              <button
                className="bg-blue-500 text-white rounded p-2"
                onClick={() => {
                  sendAMessage();
                }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminPortal>
  );
}
export default ChatWithClient;
