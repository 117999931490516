import { gql } from "@apollo/client";

export default gql`
  query me($_id: String!) {
    me(_id: $_id) {
      _id
      name
      age
      verified
      avatar
      commentsMade
      gamesPlayed
      teamsCreated
      createdAt
      kidCoinToken
      publicWalletAddresses
      parentId
      userStatus

      affiliateInfoDetails {
        _id
        userId
        affiliateCouponCode
        totalNftSell
        totalNftPayout
      }
      userType
      baseInfo {
        _id
        name
        slug
      }
    }
  }
`;
