import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import AdminPortal from "./AdminPortal";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  CONTACT_FORM_URL,
  LOCAL_STORAGE_KEYS,
  USER_ROLES,
} from "../../utils/constant";
import { toast } from "react-toastify";
import Select from "react-select";
import { debounce } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faArrowRightToFile,
  faCreditCardAlt,
  faClose,
  faUser,
  faUsers,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import __ from "lodash";
import Modal from "react-modal";

// Query
import FIND_BASE_PLAYERS from "../../gql/query/base/findBasePlayers.query";

// MUTATION
import UPDATE_KIDS_PARENT_RELATION from "../../gql/mutation/base/updateKidsParentRelation.mutations";
import INITIATE_STRIPE_SETUP_SESSION from "../../gql/mutation/base/initiateStripeSetupSession.mutations";
import ADD_BASE_MEMBER_BY_ADMIN from "../../gql/mutation/base/addBaseMemberByAdmin.mutations";
import VERIFY_USER_ACCOUNT from "../../gql/mutation/base/verifyUserAccountByAdmin.mutations";

// Utils
import iconFinder from "../../utils/iconFinder";

function KidManagement() {
  const navigate = useNavigate();

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParent, setKidsWithParents] = useState([]);
  const [kidsWithoutParent, setKidsWithoutParent] = useState([]);

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParentToShow, setKidsWithParentsToShow] = useState([]);
  const [kidsWithoutParentToShow, setKidsWithoutParentToShow] = useState([]);

  // Image state
  const [searchName, setSearchName] = useState(null);
  const [playersData, setPlayersData] = useState([]);

  // Add Member Form State
  const [isShowAddMemberForm, setIsShowAddMemberForm] = useState(false);
  const [parentEmail, setParentEmail] = useState(null);
  const [kids, setKids] = useState([]);

  // STATE for --> Add a Parent for a General user
  const [selectedGeneralPlayer, setSelectedGeneralPlayer] = useState(null);
  const [parentNameForGeneralUser, setParentNameForGeneralUser] =
    useState(null);
  const [parentEmailForGeneralUser, setParentEmailForGeneralUser] =
    useState(null);
  const [parentRelationForGeneralUser, setParentRelationForGeneralUser] =
    useState(null);

  // STATE for --> Add a Parent for a General user
  const [emailToVerifyUser, setEmailToVerifyUser] = useState(null);

  // Action Button states
  const [isLoadingAction, setIsLoadingAction] = useState({
    _id: null,
    updateAction: false,
    creditCardAction: false,
  }); // It will keep the player Id who is loading

  // Modal STATE
  const [isOpenParentRelationModal, setIsOpenParentRelationModal] =
    useState(false);
  const [isOpenVerifyModal, setIsOpenVerifyModal] = useState(false);

  const relationOptions = [
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Uncle", label: "Uncle" },
    { value: "Grandparent", label: "Grandparent" },
    { value: "Coach", label: "Coach" },
    { value: "Teacher", label: "Teacher" },
    { value: "Aunt", label: "Aunt" },
    { value: "Sibling", label: "Sibling" },
    { value: "Friend", label: "Friend" },
    { value: "Cousin", label: "Cousin" },
  ];

  // Query
  const {
    data: basePlayers,
    loading: basePlayersLoading,
    error: basePlayersError,
    refetch: basePlayersRefetch,
  } = useQuery(FIND_BASE_PLAYERS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [
    updateKidsParent,
    { error: updateKidsParentError, loading: updateKidsParentLoading },
  ] = useMutation(UPDATE_KIDS_PARENT_RELATION);

  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SETUP_SESSION);

  const [
    addBaseMemberByAdmin,
    { loading: addBaseMemberByAdminLoading, error: addBaseMemberByAdminError },
  ] = useMutation(ADD_BASE_MEMBER_BY_ADMIN);

  const [
    verifyUserAccountByAdmin,
    {
      loading: verifyUserAccountByAdminLoading,
      error: verifyUserAccountByAdminError,
    },
  ] = useMutation(VERIFY_USER_ACCOUNT);

  // Update Image State from Database data
  useEffect(() => {
    function fetchData() {
      basePlayersRefetch();
    }
    fetchData();
  }, [basePlayers]);

  // Attach kids with Parents
  useEffect(() => {
    function manipulateBasePlayers() {
      if (basePlayers?.findBasePlayers) {
        const allPlayers = basePlayers?.findBasePlayers;

        // ✅ First array (If the "parentId" exists)
        // ✅ First array (If the "parentId" exists)
        const filteredArray = __.filter(
          allPlayers,
          (obj) =>
            obj.user?.parentId &&
            obj.user.parentId != "" &&
            obj.user.parentId != undefined
        );
        // Group by similar "parentId"
        const groupedArray = __.groupBy(
          filteredArray,
          (obj) => obj.user.parentId
        );
        // Now create an object like this format { "parentId1": { name: "parentId1_name", email: "parentId1_email", kids: all_kids } }
        const resultArray = __.map(groupedArray, (value, key) => {
          const parentDetails = value[0].user.parentDetails;
          const kids = value;
          return {
            _id: parentDetails?._id,
            playerId: parentDetails?.player?._id,
            name: parentDetails?.name,
            email: parentDetails?.email,
            avatar: parentDetails?.avatar,
            role: parentDetails?.role,
            kids: kids,
          };
        });

        // ✅ Second array (If the "parentId" not exists)
        // ✅ Second array (If the "parentId" not exists)
        const secondArray = __.filter(
          allPlayers,
          (player) =>
            !player.user.parentId ||
            player.user.parentId == "" ||
            player.user.parentId == undefined
        );
        // all parent User IDS those have KIDS
        let parentIdsThoseHaveKids = Object.keys(groupedArray);
        // Now remove those already added parents from this second array
        const finalSecondFilteredArray = secondArray.filter(
          (player) => !parentIdsThoseHaveKids.includes(player.user._id)
        );

        // Update STATE
        setKidsWithParents(resultArray);
        setKidsWithoutParent(finalSecondFilteredArray);
        // Update STATE (To Show)
        setKidsWithParentsToShow(resultArray);
        setKidsWithoutParentToShow(finalSecondFilteredArray);
      }
    }
    manipulateBasePlayers();
  }, [basePlayers]);

  // handke change parent name
  const handleChangeParentName = (e) => {
    setParentNameForGeneralUser(e.target.value);
  };

  // handle change parent email
  const handleChangeParentEmail = (e) => {
    setParentEmailForGeneralUser(e.target.value);
  };

  // Set custom style in react-select
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black", // Set the color for all options
    }),
  };

  // Handle Relation Dropdowm
  const handleSelectRelationChange = (selectedOptions) => {
    setParentRelationForGeneralUser(selectedOptions.value);
  };

  // Call to DATABASE to add a PARENT for this KID
  async function updateKidParentRelation() {
    if (!parentEmailForGeneralUser || parentEmailForGeneralUser == "") {
      toast.error("Parent email can't be empty!");
      return;
    }

    const checkMail = validateEmail(parentEmailForGeneralUser);
    if (!checkMail) {
      toast.error("You typed invalid mail");
      return;
    }

    if (!parentRelationForGeneralUser || parentRelationForGeneralUser == "") {
      toast.error("Relation with parent can't be empty!");
      return;
    }

    // Get the parent name (If not found then set parent name from EMAIL)
    let parentName =
      parentNameForGeneralUser && parentNameForGeneralUser != ""
        ? parentNameForGeneralUser
        : parentEmailForGeneralUser.split("@")[0];

    // Make loading state updated
    setIsLoadingAction({
      _id: selectedGeneralPlayer._id,
      updateAction: true,
      creditCardAction: false,
    });

    const { data } = await updateKidsParent({
      variables: {
        parentName,
        parentEmail: parentEmailForGeneralUser.toLowerCase(),
        kidUserId: selectedGeneralPlayer.user._id,
        relation: parentRelationForGeneralUser,
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      },
    });

    if (data.updateKidsParentRelation.errorMessage == null) {
      toast.success(
        "Parent relation for this kid has been successfully added!"
      );
      basePlayersRefetch();
      // Close Modal & reset STATEs
      closeSetupParentRelationModal();
    } else {
      toast.error(data.updateKidsParentRelation.errorMessage);
    }

    setIsLoadingAction({
      _id: null,
      updateAction: false,
      creditCardAction: false,
    });
  }

  // This function will call backend mutation to create a stripe session URL. After successfully getting the STRIPE URL we will navigate the user to that url
  async function initiateStripeCheckoutUi(userIdOfMember) {
    // Call the Mutation to get STRIPE session URL
    const { data } = await initiateStripeSessionMutation({
      variables: {
        userId: userIdOfMember,
      },
    });
    if (data.initiateStripeSetupSession.errorMessage == null) {
      window.location.href = data.initiateStripeSetupSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
    }
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  // Check CouponCode Validity
  function searchByName(searchName) {
    if (!searchName || searchName == "") {
      setKidsWithParentsToShow(kidsWithParent);
      setKidsWithoutParentToShow(kidsWithoutParent);
      return;
    }

    // Search by Front-end STATE (For Parents who have KIDS)
    const filteredParentAndKids = JSON.parse(
      JSON.stringify(kidsWithParent)
    ).filter((parent) => {
      // If the name property matches the search string. If it does, we simply return true to keep the object in the output array.
      if (parent.name.toLowerCase().includes(searchName.toLowerCase())) {
        return true;
      } else if (parent.kids && parent.kids.length > 0) {
        /* If the name property doesn’t match, we check if the object has a 'kids' property.
         * If it does, we filter the 'kids' array to find any objects whose 'name' property matches the search string.
         * If we find any matches, we update the 'kids' property of the main object to include only the matching objects,
         * and then return true to keep the object in the output array.
         */
        const matchingKids = parent.kids.filter((kid) =>
          kid.name.toLowerCase().includes(searchName.toLowerCase())
        );
        if (matchingKids.length > 0) {
          parent.kids = matchingKids;
          return true;
        }
      }
      return false;
    });

    // Search by Front-end STATE (For Users who have no KIDS/Parent)
    const filteredNormalUsers = kidsWithoutParent.filter((player) => {
      if (player.name.toLowerCase().includes(searchName.toLowerCase())) {
        return true;
      }
      return false;
    });

    // Update STATE to show
    setKidsWithParentsToShow(filteredParentAndKids);
    setKidsWithoutParentToShow(filteredNormalUsers);
  }

  // ADD MEMBER RELATED METHODS
  const addKid = () => {
    // a function to add a new coupon object to the array
    setKids([
      ...kids,
      {
        name: "",
        // startDate: new Date(),
        // endDate: null,
      },
    ]);
  };

  // a function to remove the kid object from the array by index
  const removeKid = (index) => {
    setKids(kids.filter((_, i) => i !== index));
  };

  // a function to update a coupon object by index, key and value
  const handleChangeKid = (index, key, value) => {
    setKids(
      kids.map((coupon, i) =>
        i === index ? { ...coupon, [key]: value } : coupon
      )
    );
  };

  // Caqll backend MUTATION to add member
  async function addMember() {
    if (!parentEmail || parentEmail == "") {
      toast.error("Please enter parent email!");
      return;
    }

    if (!kids || kids.length == 0) {
      toast.error("Please insert at least one kid's info!");
      return;
    }

    // Kid's name cann't be empty
    let problemKidsInfo = kids.filter(
      (kidObject) => !kidObject.name || kidObject.name == ""
    );
    if (problemKidsInfo && problemKidsInfo.length > 0) {
      toast.error("The kid's name cannot be empty!");
      return;
    }

    const { data } = await addBaseMemberByAdmin({
      variables: {
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        email: parentEmail,
        kids,
      },
    });
    if (data.addBaseMemberByAdmin.errorMessage == null) {
      toast("success", "Successfully added as base member!");
      basePlayersRefetch();
      setIsShowAddMemberForm(false);
    } else {
      toast("error", data.addBaseMemberByAdmin.errorMessage);
    }
  }

  // Close Modal
  function closeSetupParentRelationModal() {
    setIsOpenParentRelationModal(false);
    // Update STATEs
    setSelectedGeneralPlayer(null);
    setParentNameForGeneralUser(null);
    setParentEmailForGeneralUser(null);
    setParentRelationForGeneralUser(null);
  }

  // Close Verify Account Modal
  function closeVerifyModal() {
    setIsOpenVerifyModal(false);
    // Update STATEs
    setEmailToVerifyUser(null);
  }

  // Call to DATABASE to add a PARENT for this KID
  async function verifyUserEmail() {
    if (!emailToVerifyUser || emailToVerifyUser == "") {
      toast.error("Email can't be empty");
      return;
    }

    const checkMail = validateEmail(emailToVerifyUser);
    if (!checkMail) {
      toast.error("You typed invalid mail!");
      return;
    }

    const { data } = await verifyUserAccountByAdmin({
      variables: {
        userIdToVerify: selectedGeneralPlayer?.user?._id,
        userEmail: emailToVerifyUser,
      },
    });

    if (data.verifyUserAccountByAdmin.errorMessage == null) {
      toast.success(data?.verifyUserAccountByAdmin?.successMessage);
      basePlayersRefetch();
      // Close Modal & reset STATEs
      closeVerifyModal();
    } else {
      toast.error(data.verifyUserAccountByAdmin.errorMessage);
    }

    setIsLoadingAction({
      _id: null,
      updateAction: false,
      creditCardAction: false,
    });
  }

  if (basePlayersError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="bg-slate-700 flex justify-between items-center mb-3 px-5 py-3 mt-8 rounded-md">
        <h1 className="text-center font-bold text-2xl">Members</h1>
        <input
          className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
          placeholder="Search member by name"
          onChange={(e) => {
            searchByName(e.target.value);
          }}
        />

        {!isShowAddMemberForm && (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              setIsShowAddMemberForm(true);
            }}
          >
            Add Member
          </button>
        )}
      </div>

      {isShowAddMemberForm && (
        <div
          className="max-w-4xl mx-auto bg-slate-700 mt-8 py-3 px-4"
          style={{ position: "relative", borderRadius: "5px" }}
        >
          <h3 className="text-center font-bold text-[26px] mt-2 mb-6">
            Add Member
          </h3>

          <div>
            {/* PARENT EMAIL */}
            <div className="my-3">
              <label className="mr-3 pr-3 font-bold">Parent Email</label>
              <input
                className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
                onChange={(e) => setParentEmail(e.target.value)}
                placeholder="Enter parent email"
              />
            </div>

            {/* Add KIDS */}
            {/* Add KIDS */}
            <div className="my-3">
              <label className="mr-16 pr-6 pt-2 font-bold">Kids</label>
              <button
                type="button"
                onClick={addKid}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                Add Kid
              </button>
              {kids && kids.length > 0 && (
                <div
                  style={{
                    padding: "10px 10px",
                    border: "1px solid #7a787869",
                  }}
                  className="rounded-lg mt-3 mb-6 mx-10"
                >
                  {kids.map((coupon, index) => (
                    <div>
                      <div
                        key={index}
                        className="coupon my-2"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <label>Kid Name </label>
                        <input
                          className="border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display w-64"
                          onChange={(e) =>
                            handleChangeKid(index, "name", e.target.value)
                          }
                          value={coupon.name}
                          type="text"
                          // style={{ width: "150px" }}
                          placeholder="Enter kid name"
                        />

                        <button onClick={() => removeKid(index)}>
                          <FontAwesomeIcon className="" icon={faClose} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* CLOSE BUTTON */}
          <div>
            <button
              onClick={() => setIsShowAddMemberForm(false)}
              style={{ position: "absolute", top: 8, right: 15 }}
            >
              <FontAwesomeIcon className="" icon={faClose} size={"2x"} />
            </button>
          </div>

          {addBaseMemberByAdminLoading ? (
            <button className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block">
              Loading
            </button>
          ) : (
            <button
              className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
              onClick={() => {
                addMember();
              }}
            >
              Submit
            </button>
          )}
        </div>
      )}

      {/* ✅✅✅✅✅ */}
      <div className="max-w-6xl mx-auto bg-slate-700 mt-8 pb-7 px-8 mb-8 rounded-md">
        {/* ✅✅✅✅✅ (Users who have a PARENT/KID relation) */}
        {!basePlayersLoading && kidsWithParentToShow.length > 0 && (
          <h1 className="text-center font-bold text-2xl pt-8">
            Base Members (Users who have a Parent/Kid relation)
          </h1>
        )}

        {!basePlayersLoading && kidsWithParentToShow.length > 0 && (
          <h1 className="font-bold text-xl mt-3">
            <b style={{ color: "yellow" }}>{kidsWithParentToShow.length}</b>{" "}
            Parents found
          </h1>
        )}

        <div className="">
          {!basePlayersLoading &&
            kidsWithParentToShow?.map((player, index) => {
              return (
                <div
                  className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                  style={{
                    borderLeft: "5px solid green",
                    justifyContent: "space-between",
                    height: "100%",
                    backgroundColor: "#454f6680",
                    position: "relative",
                  }}
                >
                  {/* Family logo */}
                  <div
                    style={{ position: "absolute", bottom: 8, left: 8 }}
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content={"Parent"}
                    className="cursor-pointer"
                  >
                    <FontAwesomeIcon
                      className="mr-3"
                      icon={faUsers}
                      size="2x"
                      opacity={0.1}
                    />
                  </div>
                  {/* Parent AVATAR + Name */}
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                    }}
                    className="py-2"
                  >
                    {/* Parent AVATAR */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={iconFinder(player.avatar)}
                        alt="Basement Sports"
                        className="max-h-17 cursor-pointer"
                        style={{
                          color: "white",
                          minWidth: "100px",
                          maxWidth: "100px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                        onClick={() => {
                          navigate(`/admin/kid-details/${player.playerId}`);
                        }}
                      />
                    </div>

                    {/* PARENT INFO SECTION */}
                    <div
                      className="ml-5"
                      style={{
                        borderRight: "1px dotted #ffffff45",
                        paddingRight: "60px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minWidth: "250px",
                        maxWidth: "250px",
                        overflow: "hidden",
                      }}
                    >
                      {/* Parent Name */}
                      <div>{player.name}</div>
                      {/* Parent Email */}
                      <div>{player.email}</div>
                      <div>Role: {player.role}</div>

                      {/* ALL BUTTONS 💥 💥 💥 */}
                      {/* ADD PAYMENT CARD INFO */}
                      <div>
                        {isLoadingAction._id == player._id &&
                        isLoadingAction.creditCardAction ? (
                          <button className="bg-green-400 text-white font-bold py-1 px-2 rounded my-2">
                            Loading
                          </button>
                        ) : (
                          <button
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded my-2"
                            onClick={() => {
                              setIsLoadingAction({
                                _id: player?._id,
                                updateAction: false,
                                creditCardAction: true,
                              });
                              initiateStripeCheckoutUi(player?._id);
                            }}
                          >
                            Setup Payment Card
                          </button>
                        )}
                      </div>
                    </div>

                    {/* KID DETAILS + KID GAME INFOS */}
                    <div
                      style={{
                        color: "#ccd0e0",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="pl-6 text-sm  pt-1 pb-3"
                    >
                      <div className="text-xl font-bold">
                        Kid list -{" "}
                        <b style={{ color: "yellow" }}>
                          {player.kids?.length ? player.kids?.length : 0}
                        </b>{" "}
                        Kids found
                      </div>
                      {/* ALL KIDS */}
                      {/* ALL KIDS */}
                      <div
                        style={{ display: "flex", flexWrap: "wrap" }}
                        className="mt-5"
                      >
                        {player.kids.map((kid) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              minWidth: "100px",
                            }}
                            className="mx-1 mb-2"
                          >
                            {/* KID AVATAR */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={iconFinder(kid.avatar)}
                                alt="Basement Sports"
                                className="max-h-17 cursor-pointer"
                                style={{
                                  color: "white",
                                  minHeight: "50px",
                                  maxHeight: "50px",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                }}
                                onClick={() => {
                                  navigate(`/admin/kid-details/${kid._id}`);
                                }}
                              />
                            </div>

                            {/* KID INFO SECTION */}
                            <div
                              style={{ textAlign: "center" }}
                              className="mt-1"
                            >
                              {/* KID Name */}
                              <div>{kid.name}</div>

                              {/* ALL BUTTONS 💥 💥 💥 */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {/* Users who have NO PARENT/KID relation */}
        {/* Users who have NO PARENT/KID relation */}
        {!basePlayersLoading && kidsWithoutParentToShow.length > 0 && (
          <h1 className="pt-8 text-center font-bold text-2xl">
            Base Members (Users who have no Parent/Kid relation)
          </h1>
        )}

        {!basePlayersLoading && kidsWithoutParentToShow.length > 0 && (
          <h1 className="font-bold text-xl  mt-3">
            <b style={{ color: "yellow" }}>{kidsWithoutParentToShow.length}</b>{" "}
            General users found
          </h1>
        )}

        <div
          className="flex flex-wrap items-center font-display text-yellow-300 text-lg rounded-lg py-2"
          style={{
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          {!basePlayersLoading &&
            kidsWithoutParentToShow?.map((player, index) => {
              return (
                <div
                  style={{
                    height: "100%",
                    position: "relative",
                  }}
                  className="w-full md:w-1/2"
                >
                  {/* Family logo */}
                  <div
                    style={{ position: "absolute", bottom: 15, left: 20 }}
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content={
                      player.user?.verified
                        ? "Verified User"
                        : "Not verified User"
                    }
                    className="cursor-pointer"
                  >
                    <FontAwesomeIcon
                      className="mr-3"
                      icon={player.user?.verified ? faUser : faMobileScreen}
                      size="xl"
                      opacity={0.3}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      backgroundColor: "#454f6680",
                      borderRadius: "5px",
                      borderLeft: `5px solid ${
                        player.user?.verified ? "#0095ff" : "red"
                      }`,
                    }}
                    className="mx-2 my-2 px-3 py-4 shadow-md shadow-black/30"
                  >
                    {/* Parent AVATAR */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={iconFinder(player.avatar)}
                        alt="Basement Sports"
                        style={{
                          color: "white",
                          minWidth: "100px",
                          maxWidth: "100px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                        className="cursor-pointer"
                        onClick={() => {
                          navigate(`/admin/kid-details/${player._id}`);
                        }}
                      />
                    </div>

                    {/* PARENT INFO SECTION */}
                    <div
                      className="ml-5"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        overflow: "hidden",
                      }}
                    >
                      {/* Parent Name */}
                      <div>{player.name}</div>
                      {/* Parent Email */}
                      <div
                        className={`${
                          player?.user?.verified
                            ? "text-yellow-300"
                            : "text-slate-400 text-sm"
                        }`}
                      >
                        {player.user.email}
                      </div>
                      {/* ALL BUTTONS 💥 💥 💥 */}

                      {/* ADD PAYMENT CARD INFO */}
                      <div>
                        {isLoadingAction._id == player._id &&
                        isLoadingAction.creditCardAction ? (
                          <button className="bg-green-400 text-white font-bold py-1 px-2 rounded my-2">
                            Loading
                          </button>
                        ) : (
                          <button
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded my-2"
                            onClick={() => {
                              setIsLoadingAction({
                                _id: player._id,
                                updateAction: false,
                                creditCardAction: true,
                              });
                              initiateStripeCheckoutUi(player.user._id);
                            }}
                          >
                            Setup Payment Card
                          </button>
                        )}
                      </div>

                      {/* VERIFY USER */}
                      {!player?.user?.verified && (
                        <div>
                          <button
                            className="bg-cyan-700 hover:bg-cyan-900 text-white font-bold py-2 px-3 rounded mb-2"
                            onClick={() => {
                              setSelectedGeneralPlayer(player);
                              setIsOpenVerifyModal(true);
                              setEmailToVerifyUser(player?.user?.email);
                            }}
                          >
                            Verify Account
                          </button>
                        </div>
                      )}

                      {/* ADD PARENT RELATION FOR THIS KID */}
                      <div>
                        {isLoadingAction._id == player._id &&
                        isLoadingAction.updateAction ? (
                          <button className="bg-blue-400 text-white font-bold py-1 px-2 rounded mb-2">
                            ...
                          </button>
                        ) : (
                          <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mb-2"
                            onClick={() => {
                              setSelectedGeneralPlayer(player);
                              setIsOpenParentRelationModal(true);
                            }}
                          >
                            Add Parent
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {basePlayersLoading && (
          <div
            className="mt-10 mb-14"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
            }}
          >
            <DataLoading loadingType="success" />
          </div>
        )}
      </div>

      {/* PARENT RELATION MODAL MODAL */}
      {/* PARENT RELATION MODAL MODAL */}
      <Modal
        isOpen={isOpenParentRelationModal}
        onRequestClose={closeSetupParentRelationModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#3e4c5e87",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button
          onClick={closeSetupParentRelationModal}
          style={{ float: "right" }}
        >
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Add Parent{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        <div className="my-4">
          <label className="mr-4 pr-3 font-bold">Selected Kid</label>
          <input
            className="w-64 border-2 border-yellow-700 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            disabled={true}
            placeholder="Enter parent name"
            value={selectedGeneralPlayer?.name}
          />
        </div>

        <div className="my-4">
          <label className="mr-4 pr-3 font-bold">Parent Name</label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => handleChangeParentName(e)}
            placeholder="Enter parent name"
          />
        </div>

        <div className="my-4">
          <label className="mr-5 pr-3 font-bold">Parent Email</label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => handleChangeParentEmail(e)}
            placeholder="Enter parent email"
          />
        </div>

        <div className="my-4">
          <label className="mr-5 pr-3 font-bold">
            Parent relation with this Kid
          </label>
          <Select
            className="w-15 mt-3"
            value={relationOptions.filter((relation) =>
              parentRelationForGeneralUser?.includes(relation.value)
            )}
            onChange={(e) => {
              handleSelectRelationChange(e);
            }}
            options={relationOptions}
            isMulti={false}
            styles={customStyles}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {isLoadingAction._id == selectedGeneralPlayer?._id &&
          isLoadingAction.updateAction ? (
            <button className="bg-blue-400 text-white font-bold py-1 px-2 rounded mb-2">
              Loading
            </button>
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded mb-2 mt-2"
              onClick={() => {
                updateKidParentRelation();
              }}
            >
              Add Parent
            </button>
          )}
        </div>
      </Modal>

      {/* VERIFY ACCOUNT MODAL */}
      {/* VERIFY ACCOUNT MODAL */}

      <Modal
        isOpen={isOpenVerifyModal}
        onRequestClose={closeVerifyModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#3e4c5e87",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeVerifyModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Set User Email & Verify{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* Selected user name */}
        <div className="my-4 flex items-center">
          <label className="mr-4 pr-3 font-bold text-nowrap">User Name</label>
          <div className="text-white font-bold text-yellow-300">
            {selectedGeneralPlayer?.user?.name}
          </div>
        </div>

        {/* Selected user email */}
        <div className="my-4 flex items-center">
          <label className="mr-4 pr-3 font-bold text-nowrap">User Email</label>
          <input
            className="w-96 border-2 border-yellow-700 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            placeholder="Enter an email to verify"
            value={emailToVerifyUser}
            onChange={(e) => {
              setEmailToVerifyUser(e.target.value);
            }}
          />
        </div>

        <div className="flex items-center justify-center">
          {verifyUserAccountByAdminLoading ? (
            <div className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded mb-2 mt-2">
              Loading
            </div>
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded mb-2 mt-2"
              onClick={() => {
                verifyUserEmail();
              }}
            >
              Verify Account
            </button>
          )}
        </div>
      </Modal>
    </AdminPortal>
  );
}
export default KidManagement;
