import React, { useState } from "react";
import AdminPortal from "./AdminPortal";
import { useMutation } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";

// MUTATION
import CREATE_BASE from "../../gql/mutation/base/createBaseByAdmin.mutations";
import { LOCAL_STORAGE_KEYS, USER_TYPE } from "../../utils/constant";

function CreateBase() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedSportsForComponent, setSelectedSportsForComponent] = useState(
    []
  );
  const [selectedPlayerstype, setSelectedPlayerstype] = useState([]);
  const [selectedPlayerstypeForComponent, setSelectedPlayerstypeForComponent] =
    useState([]);

  // Mutations
  const [createBaseMutation, { error: createBaseError }] =
    useMutation(CREATE_BASE);

  // Handle Sports dropdown
  const handleSelectSportsChange = (selectedOptions) => {
    setSelectedSportsForComponent(selectedOptions);
    const values = selectedOptions.map((option) => option.value);
    setSelectedSports(values);
  };

  // Dropdown Options of Sports
  const sportsOption = [
    { value: "soccer", label: "Soccer" },
    { value: "hockey", label: "Hockey" },
    { value: "baseBall", label: "Baseball" },
    { value: "basketBall", label: "Basketball" },
    { value: "pickelBall", label: "Pickelball" },
    { value: "tennis", label: "Tennis" },
    { value: "racket", label: "Racket" },
    { value: "volleyBall", label: "Volleyball" },
  ];

  // Handle Gametype dropdown
  const handleSelectPlayerTypeChange = (selectedOptions) => {
    setSelectedPlayerstypeForComponent(selectedOptions);
    const values = selectedOptions.map((option) => option.value);
    setSelectedPlayerstype(values);
  };

  // Dropdown Options of Game Type
  const playerTypesOption = [
    { value: "beginner", label: "Beginner" },
    { value: "intermediate", label: "Intermediate" },
    { value: "advance", label: "Advance" },
  ];

  // Set custom style in react-select
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black", // Set the color for all options
    }),
  };

  // Submit to create Event
  async function createEvent() {
    if (name == "" || name == null) {
      toast.error("Please enter a base name!");
      return;
    } else if (slug == "" || slug == null) {
      toast.error("Please enter a unique identifier!");
      return;
    } else if (
      !selectedSports ||
      selectedSports == null ||
      selectedSports.length == 0
    ) {
      toast.error("Please select at least one Sport!");
      return;
    } else if (
      !selectedPlayerstype ||
      selectedPlayerstype == null ||
      selectedPlayerstype.length == 0
    ) {
      toast.error("Please select at least one Player type!");
      return;
    }

    const { data } = await createBaseMutation({
      variables: {
        name,
        slug,
        logo: "dome_paleturquoise",
        organizerUserId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        playersType: selectedPlayerstype,
        sport: selectedSports,
      },
    });

    if (data.createBase.errorMessage == null) {
      toast.success("Successfully created Base");
      navigate("/admin/calendar-management");

      // Add iuser type in Local Storage
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER_TYPE,
        USER_TYPE.SUPER_BASE_OWNER
      );
      // Set Base ID in Local Storage
      if (data.createBase && data.createBase._id) {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
          data.createBase._id
        );
      }
    } else {
      toast.error(data.createBase.errorMessage);
    }
  }
  return (
    <AdminPortal>
      <h3 className="text-center font-bold text-[16px] mt-2 mb-4">
        Please create your Base to manage it!
      </h3>
      <div className="max-w-lg mx-auto bg-slate-700 mt-4 py-3 px-4">
        <h3 className="text-center font-bold text-[26px] mb-4">Create Base</h3>

        {/* Name */}
        {/* Name */}
        <div className="my-3">
          <label className="mr-4 font-bold">
            Base Name <b style={{ color: "#ff0000a1" }}> *</b>
          </label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setName(e.target.value)}
            placeholder="Name of your Base"
          />
        </div>

        {/* Slug */}
        {/* Slug */}
        <div className="my-3">
          <label className="mr-24 pr-4 font-bold">
            Slug <b style={{ color: "#ff0000a1" }}> *</b>
          </label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setSlug(e.target.value)}
            placeholder="Enter a unique identifier"
          />
        </div>

        {/* Sports */}
        {/* Sports */}
        <div className="my-2 flex items-center">
          {" "}
          <label className="mr-9 font-bold">
            Sports to Host <b style={{ color: "#ff0000a1" }}> *</b>
          </label>
          <Select
            className="w-64"
            value={selectedSportsForComponent}
            onChange={handleSelectSportsChange}
            options={sportsOption}
            isMulti
            styles={customStyles}
          />
        </div>

        {/* Type of Player */}
        {/* Type of Player */}
        <div className="my-2 flex items-center ">
          <label className="mr-10 pr-3  font-bold">
            Players type <b style={{ color: "#ff0000a1" }}> *</b>
          </label>
          <Select
            className="w-64"
            value={selectedPlayerstypeForComponent}
            onChange={handleSelectPlayerTypeChange}
            options={playerTypesOption}
            isMulti
            styles={customStyles}
          />
        </div>

        {/* CONTINUE button */}
        <button
          className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
          onClick={() => {
            createEvent();
          }}
        >
          Create
        </button>
      </div>
    </AdminPortal>
  );
}
export default CreateBase;
