import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format, addYears, formatISO, addMonths, addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { utcToZonedTime, format as tzFormat } from "date-fns-tz";
import { Image } from "cloudinary-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  BASEMENT_SPORTS_OFFICAL_LOCATION,
  CLOUDINARY_INFO,
  EVENT_DURATION,
  EVENT_DURATION_HOUR,
  LOCAL_STORAGE_KEYS,
  TIME_SLOTS_DATA,
  WEEK_DAYAS,
} from "../../utils/constant";
import DataLoading from "../../component/loader/DataLoading";
import ClipLoader from "react-spinners/ClipLoader";
import CommonBackdrop from "../../component/common/CommonBackdrop";

// Rich text editor import
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Query
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";
import FIND_AVAILABLE_SLOTS from "../../gql/query/base/findAvailableSlots.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";

// Mutations
import UPDATE_EVENT from "../../gql/mutation/base/updateEvent.mutations";
import { calculateEventEndTime } from "../../utils/eventTime";

function UpdateEventByClient() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  // Query parameters
  const search = useLocation().search;
  let preSelectedDate = new URLSearchParams(search).get("preSelectedDate");
  if (preSelectedDate) {
    preSelectedDate = preSelectedDate.replace(" ", "+");
  }

  const preSelectedSlot = new URLSearchParams(search).get("preSelectedSlot");

  const [dropdownShow, setDropdownShow] = useState(false);
  const [eventType, setEventType] = useState("Special Events");
  const [caption, setCaption] = useState("");
  const [address, setAddress] = useState("");
  const [eventPrice, setEventPrice] = useState(0);

  // Coupon State
  const [promoCode, setPromoCode] = useState("");
  const [coupons, setCoupons] = useState([]);

  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedSportsForComponent, setSelectedSportsForComponent] = useState(
    []
  );
  const [selectedGametype, setSelectedGametype] = useState("");
  const [selectedGametypeForComponent, setSelectedGametypeForComponent] =
    useState("");
  const [startDate, setStartDate] = useState(
    preSelectedDate ? new Date(preSelectedDate) : new Date()
  );
  const [timeSlots, setTimeSlots] = useState(
    preSelectedSlot ? [preSelectedSlot] : []
  );

  // Rich text editor state
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Image state
  const [image, setImage] = useState(null);

  // Base opening & ending time
  const [baseOpeningTime, setBaseOpeningTime] = useState(new Date());
  const [baseEndingTime, setBaseEndingTime] = useState(new Date());

  // Event start time
  const [eventStartTime, setEventStartTime] = useState(new Date());
  const [eventStartHour, setEventStartHour] = useState(0);

  // Event end time
  const [eventEndTime, setEventEndTime] = useState(new Date());
  const [eventEndHour, setEventEndHour] = useState(0);

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_A_EVENT, {
    variables: {
      eventId,
    },
  });

  const {
    data: availableSlots,
    loading: availableSlotsLoading,
    error: availableSlotsError,
    refetch: availableSlotsRefetch,
  } = useQuery(FIND_AVAILABLE_SLOTS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      selectedDate: startDate,
      selectedEventId: eventId,
    },
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [updateEventMutation, { loading: updateEventMutationLoading }] =
    useMutation(UPDATE_EVENT);

  // DATE variables
  let oneYearFuture = addYears(new Date(), 1);

  // Initialize event data
  useEffect(() => {
    function initializeEventData() {
      // Refetch event data
      eventsRefetch();

      // Set location
      // Set Location
      setAddress(
        eventsData?.findEvent?.address
          ? eventsData.findEvent.address
          : baseData?.findBaseDetails?.address
          ? baseData.findBaseDetails.address
          : BASEMENT_SPORTS_OFFICAL_LOCATION
      );

      // If data successfully came
      if (eventsData && eventsData.findEvent) {
        let eventDetails = eventsData.findEvent;
        // Set event type
        setEventType(eventDetails.eventType);
        // set caption
        setCaption(eventDetails.caption);

        // set event price
        setEventPrice(eventDetails.eventPrice);
        // set sports
        if (eventDetails.sports && eventDetails.sports.length > 0) {
          setSelectedSports(eventDetails.sports);
          let sportsForComponent = sportsOption.filter((sport) =>
            eventDetails.sports.includes(sport.value)
          );
          setSelectedSportsForComponent(sportsForComponent);
        }
        // Set coupon
        if (eventDetails.promoCodes && eventDetails.promoCodes.length > 0) {
          let newPromoCodesArray = eventDetails.promoCodes.map((promoCode) => {
            return {
              code: promoCode.code,
            };
          });
          setCoupons(newPromoCodesArray);
        }
        // set game type
        if (eventDetails.gameType && eventDetails.gameType != null) {
          setSelectedGametype(eventDetails.gameType);
          let sportsForComponent = gameTypesOption.filter((gameType) =>
            eventDetails.gameType.includes(gameType.value)
          );
          setSelectedGametypeForComponent(sportsForComponent);
        }

        // set date
        setStartDate(new Date(eventDetails.dateTime));

        // Set banner image
        setImage(eventDetails?.bannerImage);

        // set description in Editor state
        if (eventDetails?.description) {
          const content = JSON.parse(eventDetails?.description);
          const contentState = convertFromRaw(content);
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }

        // Set Time SLOT
        setTimeSlots(eventDetails.timeSlots);

        // Refetch available Time SLOTS
        availableSlotsRefetch({
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          selectedDate: new Date(eventDetails.dateTime),
          selectedEventId: eventId,
        });

        // Update base baseOpeningHour
        if (eventDetails?.eventStartHour) {
          let currentDate = new Date();
          // set hour, minutes & seconds
          currentDate.setHours(eventDetails.eventStartHour);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
          setBaseOpeningTime(currentDate);
          // set event start time
          setEventStartTime(currentDate);
          setEventStartHour(eventDetails.eventStartHour);
        }

        // Update base baseClosingHour
        if (eventDetails?.eventEndHour) {
          let currentDate = new Date();
          // set hour, minutes & seconds
          currentDate.setHours(eventDetails?.eventEndHour);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
          setBaseEndingTime(currentDate);
          // set event end time
          setEventEndTime(currentDate);
          setEventEndHour(eventDetails?.eventEndHour);
        }
      }
    }
    initializeEventData();
  }, [eventsData, baseData]);

  // Handle Event Type Dropdown
  const handleDropdown = (e) => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-toggle")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };

  // Handle Event Type selection & clean other STATEs
  function handleEventTypeSelection(value) {
    setEventType(value);

    // Clean STATEs
    setSelectedSports([]);
    setSelectedSportsForComponent([]);
    setSelectedGametype("");
    setSelectedGametypeForComponent("");
  }

  // Dropdown Options of Event Type
  const eventTypeList = [{ type: "Special Events" }];

  // Handle Sports dropdown
  const handleSelectSportsChange = (selectedOptions) => {
    setSelectedSportsForComponent(selectedOptions);
    const values = selectedOptions.map((option) => option.value);
    setSelectedSports(values);
  };

  // Dropdown Options of Sports
  const sportsOption = [
    { value: "soccer", label: "Soccer" },
    { value: "hockey", label: "Hockey" },
    { value: "baseBall", label: "Baseball" },
    { value: "basketBall", label: "Basketball" },
    { value: "pickelBall", label: "Pickelball" },
    { value: "tennis", label: "Tennis" },
    { value: "racket", label: "Racket" },
    { value: "volleyBall", label: "Volleyball" },
  ];

  // Handle Gametype dropdown
  const handleSelectGameTypeChange = (selectedOptions) => {
    setSelectedGametypeForComponent(selectedOptions);
    setSelectedGametype(selectedOptions.value);
  };

  // Dropdown Options of Game Type
  const gameTypesOption = [
    { value: "1v", label: "1V" },
    { value: "2v", label: "2V" },
    { value: "3v", label: "3V" },
  ];

  // Set custom style in react-select
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black", // Set the color for all options
    }),
  };

  // Coupon related method
  const addCoupon = () => {
    if (coupons.length > 0) {
      return;
    }
    // a function to add a new coupon object to the array
    setCoupons([
      ...coupons,
      {
        code: "",
        // startDate: new Date(),
        // endDate: null,
      },
    ]);
  };

  // a function to remove a coupon object from the array by index
  const removeCoupon = (index) => {
    setCoupons(coupons.filter((_, i) => i !== index));
  };

  // a function to update a coupon object by index, key and value
  const handleChange = (index, key, value) => {
    setCoupons(
      coupons.map((coupon, i) =>
        i === index ? { ...coupon, [key]: value } : coupon
      )
    );
  };

  // Check if coupon is valid or not
  function isValidCoupon(promoCode) {
    return String(promoCode).match(/^[a-zA-Z][a-zA-Z0-9]*[0-9]$/);
  }

  // Submit to create Event
  async function createEvent() {
    // Check EventType was given or not
    if (eventType == "" || eventType == null) {
      toast.error("Please select Event Type!");
      return;
    }
    // Check Coupons are valid or not
    if (coupons && coupons.length > 0) {
      // Check if invalid coupon code exist or not
      for (let index = 0; index < coupons.length; index++) {
        const coupon = coupons[index];
        if (!coupon.code || coupon.code == "") {
          toast.error("Coupon code can't be empty!");
          return;
        }
        if (!String(coupon.code).match(/^[a-zA-Z][a-zA-Z0-9]*[0-9]$/)) {
          toast.error("Invalid coupon codes found!");
          return;
        }
      }
      // Check if all given coupon codes are unique
      let codes = coupons.map((obj) => obj.code); // Create an array of codes
      let set = new Set(codes); // Remove all repeated codes from the array
      if (set.size != coupons.length) {
        toast.error("All coupon codes must be unique!");
        return;
      }
    }
    // Check startedate
    if (startDate == "" || startDate == null) {
      toast.error("Please select a date & time!");
      return;
    }
    // Check if EventType is Tournament or League or Contest
    if (
      (eventType === "Tournament" ||
        eventType === "League" ||
        eventType === "Contest") &&
      (!selectedSports || selectedSports == null || selectedSports.length == 0)
    ) {
      toast.error("Please select at least one Sport!");
      return;
    }
    // Check if EventType Tournament or League
    if (
      (eventType === "Tournament" || eventType === "League") &&
      (selectedGametype == "" || selectedGametype == null)
    ) {
      toast.error("Please select Game Type!");
      return;
    }

    // Check Image if the event payment was confirmed by the ADMIN
    if (
      eventsData?.findEvent?.paidAmount &&
      eventsData?.findEvent?.paidAmount > 0
    ) {
      if (!image || image == null || image == "") {
        toast.error("Please upload a banner image!");
        return;
      }
    }

    // Now check is Rich Editor state if the event payment was confirmed by the ADMIN
    let plainText = null;
    if (
      eventsData?.findEvent?.paidAmount &&
      eventsData?.findEvent?.paidAmount > 0
    ) {
      const checkContentState = editorState.getCurrentContent();
      plainText = checkContentState.getPlainText().trim();
      const isEmpty = plainText.length === 0;
      if (isEmpty) {
        toast.error("Please insert some description!");
        return;
      }
    }

    // Set description if the event payment was confirmed by the ADMIN
    let description = null;
    if (
      eventsData?.findEvent?.paidAmount &&
      eventsData?.findEvent?.paidAmount > 0
    ) {
      description = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
    }

    let dateToSubmit = formatISO(startDate, { representation: "complete" });

    const { data } = await updateEventMutation({
      variables: {
        eventId,
        caption: caption == "" ? null : caption,
        dateTime: dateToSubmit,
        sports: selectedSports.length == 0 ? null : selectedSports,
        gameType: selectedGametype == "" ? null : selectedGametype,
        description,
        easyDescription: plainText,
        bannerImage: image,
        eventStartHour: Number(eventStartHour),
        eventEndHour: Number(eventEndHour),
        discount: Number(eventsData?.findEvent?.discount),
      },
    });

    if (data.updateEvent.errorMessage == null) {
      navigate(`/event-details/${eventId}`);
      toast.success("Successfully updated event");
    }
  }

  // Upload cloudinary image
  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    let formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "blueLinerfile");
    formData.append("cloud_name", "dva2poi3c");

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dvqldxdfv/image/upload`,
        {
          method: "post",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        setImage(data.secure_url);
      }
    } catch (error) {}
  };

  if (eventsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  return (
    <CommonBackdrop rootName='Events' pageName='Update Event'>
      <div className="max-w-5xl mx-auto py-3 px-4 mb-14">
        <h3 className="text-center font-bold text-[36px] mt-2 mb-6">
          Update Event
        </h3>

        {/* Event TYPE */}
        {/* Event TYPE */}
        <div className="dropdown cursor-pointer relative my-2 ">
          <div>
            <label className="mr-9 font-bold">
              Event Type <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <button
              className="border-2 border-slate-500 px-2 py-5 rounded dropdown-toggle  inline-flex items-center  h-6"
              type="button"
              onClick={(e) => handleDropdown(e)}
            >
              <h1 className="text-white font-display font-bold">
                {eventType ? eventType : "Event type"}
              </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-white h-6 w-6 "
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
              </svg>
            </button>
          </div>

          <div
            className={
              dropdownShow
                ? "dropdown-menu bg-black/90 z-10  min-w-[200px] whitespace-nowrap rounded-xl py-4 px-2 text-left shadow-xl show h-40  text-yellow-300 absolute  top-full mx-auto"
                : "dropdown-menu bg-black/90 z-10  min-w-[200px] whitespace-nowrap rounded-xl py-4 px-2 text-left shadow-xl hidden text-yellow-300 absolute top-full"
            }
          >
            {eventTypeList.map((event) => (
              <button
                key={event.type}
                onClick={() => handleEventTypeSelection(event.type)}
                className="block dropdown-text mx-auto"
              >
                {event.type}
              </button>
            ))}
          </div>
        </div>

        {/* Caption */}
        {/* Caption */}
        <div className="my-3">
          <label className="mr-16 pr-3 font-bold">Caption</label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setCaption(e.target.value)}
            value={caption}
          />
        </div>

        {/* Date Picker for DATE */}
        {/* Date Picker for DATE */}
        <div className="my-2 flex items-center">
          <label className="mr-20 pr-2 font-bold">
            Date <b style={{ color: "#ff0000a1" }}> * </b>
          </label>

          <DatePicker
            className="rounded w-64 text-xl font-bold text-black"
            style={{ width: "100px" }}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              // Unset Time Slot
              setTimeSlots([]);
              // Refetch available Time Slots
              availableSlotsRefetch({
                baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                selectedDate: new Date(date),
                selectedEventId: eventId,
              });
            }}
            maxDate={oneYearFuture}
            minDate={new Date()}
            dateFormat="MMMM d, yyyy"
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Time"
            placeholderText="Select Date and Time"
            disabled={true}
          />
        </div>

        {/* Event Start Time */}
        {/* Event Start Time */}
        <div className="my-3 flex items-center">
          <label className="min-w-[135px] font-bold ">
            Start Time <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <div>
            <DatePicker
              className="rounded w-32 text-xl font-bold text-black"
              style={{ width: "100px" }}
              selected={eventStartTime}
              onChange={(date) => {
                // Set event start time
                setEventStartTime(date);
                setEventStartHour(date.getHours());
              }}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              timeFormat="h:mm aa"
              timeIntervals={60}
              timeCaption="Time"
              placeholderText="Start Time"
              minTime={baseOpeningTime}
              maxTime={baseEndingTime}
              disabled={true}
            />
          </div>
        </div>

        {/* Event End Time */}
        {/* Event End Time */}
        <div className="my-2 flex items-center">
          <label className="min-w-[135px] font-bold ">
            End Time <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <div>
            <DatePicker
              className="rounded w-32 text-xl font-bold text-black"
              style={{ width: "100px" }}
              selected={eventEndTime}
              onChange={(date) => {
                // Set event start time
                setEventEndTime(date);
                setEventEndHour(date.getHours());
              }}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              timeFormat="h:mm aa"
              timeIntervals={60}
              timeCaption="Time"
              placeholderText="Start Time"
              minTime={eventStartTime}
              maxTime={baseEndingTime}
              disabled={true}
            />
          </div>
        </div>

        {/* Sports */}
        {/* Sports */}
        {(eventType === "Tournament" ||
          eventType === "League" ||
          eventType === "Contest") && (
          <div className="my-2 flex items-center">
            {" "}
            <label className="mr-16 pr-2 font-bold">
              Sports <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <Select
              className="w-64"
              value={selectedSportsForComponent}
              onChange={handleSelectSportsChange}
              options={sportsOption}
              isMulti
              styles={customStyles}
            />
          </div>
        )}

        {/* Game Type */}
        {/* Game Type */}
        {(eventType === "Tournament" || eventType === "League") && (
          <div className="my-2 flex items-center ">
            <label className="mr-10 font-bold">
              Game type <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <Select
              className="w-64"
              value={selectedGametypeForComponent}
              onChange={handleSelectGameTypeChange}
              options={gameTypesOption}
              isMulti={false}
              styles={customStyles}
            />
          </div>
        )}

        {/* Capacity */}
        {/* Capacity */}
        <div className="flex items-center">
          <label className="mr-11 pr-5 font-bold">Capacity</label>
          <div className="font-bold">
            {baseData?.findBaseDetails?.capacity
              ? baseData?.findBaseDetails?.capacity
              : 0}{" "}
            Person
          </div>
        </div>

        {/* BANNER IMAGE */}
        {/* CLIENT can update/ upload a banner image if ADMIN confirm the payment or CLIENT made some payment */}
        {eventsData?.findEvent?.paidAmount &&
        eventsData?.findEvent?.paidAmount > 0 ? (
          <div style={{ display: "flex" }} className="mt-5">
            <label className="mr-2 pr-2 font-bold">
              Banner Image <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <div>
              <input type="file" onChange={handleFileChange} />
              {image && (
                <img
                  src={image}
                  alt="Uploaded"
                  style={{ borderRadius: "10px", maxHeight: "300px" }}
                  className="mt-5"
                />
              )}
            </div>
          </div>
        ) : null}

        {/* Event Location */}
        {/* Event Location */}
        <div className="my-3 flex items-center">
          <label className="mr-16 pr-1 font-bold">Location</label>
          <div className="w-[500px] py-2 bg-transparent rounded text-white font-bold font-display">
            {address}
          </div>
        </div>

        {/* Description */}
        {/* CLIENT can update/ upload description if ADMIN confirm the payment or CLIENT made some payment */}
        {eventsData?.findEvent?.paidAmount &&
        eventsData?.findEvent?.paidAmount > 0 ? (
          <div>
            <div className="mt-5">
              <label className="mr-10 pr-2 font-bold">
                Description <b style={{ color: "#ff0000a1" }}> * </b>
              </label>
            </div>

            {/* DEWCRIPTION body */}
            <div className="mt-2 text-black">
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                placeholder="Enter event description."
              />
            </div>
          </div>
        ) : null}

        {/* SUBMIT button */}
        {updateEventMutationLoading ? (
          <button
            className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold pt-3 pb-2 px-8 rounded mx-auto block"
            disabled={true}
          >
            <ClipLoader
              color={"#ffffff"}
              loading={true}
              css={override}
              size={20}
            />
          </button>
        ) : (
          <button
            className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
            onClick={() => {
              createEvent();
            }}
          >
            Submit
          </button>
        )}
      </div>
    </CommonBackdrop>
  );
}
export default UpdateEventByClient;
