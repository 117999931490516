import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import AdminPortal from "./AdminPortal";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CONTACT_FORM_URL, LOCAL_STORAGE_KEYS } from "../../utils/constant";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import { setHours, setMinutes } from "date-fns";
// Query
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";

// MUTATION
import UPDATE_BASE from "../../gql/mutation/base/updateBaseByAdmin.mutations";

function EventManagement() {
  let baseId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);

  const [halfDayPrice, setHalfDayPrice] = useState(0);
  const [fullDayPrice, setFullDayPrice] = useState(0);

  // Payment STATE
  const [payByCash, setPayByCash] = useState(false);
  const [isEnabledDeposit, setIsEnabledDeposit] = useState(false);
  const [depositInPercent, setDepositInPercent] = useState(1);
  const [perHourPrice, setPerHourPrice] = useState(0);
  const [eventMinimumHour, setEventMinimumHour] = useState(0);
  const [zelleNumber, setZelleNumber] = useState("");
  const [venmoNumber, setVenmoNumber] = useState("");

  // Query
  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [updateBaseMutation, { error: updateBaseError }] =
    useMutation(UPDATE_BASE);

  // Update Image State from Database data
  useEffect(() => {
    function setDate() {
      if (baseData?.findBaseDetails?.payByCash) {
        setPayByCash(baseData.findBaseDetails.payByCash);
      }

      if (baseData?.findBaseDetails?.isEnabledDeposit) {
        setIsEnabledDeposit(baseData.findBaseDetails.isEnabledDeposit);
      }

      if (baseData?.findBaseDetails?.depositInPercent) {
        setDepositInPercent(baseData.findBaseDetails.depositInPercent);
      }

      // perHourPrice
      if (baseData?.findBaseDetails?.perHourPrice) {
        setPerHourPrice(baseData.findBaseDetails.perHourPrice);
      }

      // set event minimum hour
      if (baseData?.findBaseDetails?.eventMinimumHour) {
        setEventMinimumHour(baseData.findBaseDetails.eventMinimumHour);
      }

      // set event zelle number
      if (baseData?.findBaseDetails?.zelleNumber) {
        setZelleNumber(baseData.findBaseDetails.zelleNumber);
      }

      // set event venmo number
      if (baseData?.findBaseDetails?.venmoNumber) {
        setVenmoNumber(baseData.findBaseDetails.venmoNumber);
      }
    }
    setDate();
    baseRefetch();
  }, [baseData]);

  // Submit to create Event
  async function updateBase() {
    const { data } = await updateBaseMutation({
      variables: {
        baseId: baseData.findBaseDetails._id,
        payByCash,
        isEnabledDeposit,
        depositInPercent,
        perHourPrice: Number(perHourPrice),
        eventMinimumHour: Number(eventMinimumHour),
        zelleNumber,
        venmoNumber,
      },
    });

    if (data.updateBaseByAdmin.errorMessage == null) {
      toast.success("Successfully updated Base");
    } else {
      toast.error(data.updateBaseByAdmin.errorMessage);
    }
  }

  if (baseLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (baseError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="max-w-6xl mx-auto bg-slate-700 mt-10 py-3 px-8 mb-8 rounded-md">
        <h1 className="text-center font-bold text-3xl mt-2 mb-4">
          Event Management
        </h1>

        <div className="mt-10">
          {/* CASH PAY */}
          <div className="my-3 flex flex-row">
            <h1 className="min-w-[155px] font-bold">
              Pay by Cash<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            {/* STATUS of recurring */}
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                setPayByCash(!payByCash);
              }}
            >
              <input
                className="border-none rounded-lg"
                type="checkbox"
                checked={payByCash}
              />
              <div className="ml-2 font-bold">Enable payment by Cash</div>
            </div>
          </div>

          {/* DEPOSIT */}
          <div className="my-3 flex items-center flex-row">
            <h1 className="min-w-[155px] font-bold">
              Deposit<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            {/* STATUS of deposit */}
            <div
              className="flex items-center mr-10 cursor-pointer"
              onClick={() => {
                setIsEnabledDeposit(!isEnabledDeposit);
              }}
            >
              <input
                className="border-none rounded-lg"
                type="checkbox"
                checked={isEnabledDeposit}
              />
              <div className="ml-2 font-bold">
                Enable deposit to Book a Place
              </div>
            </div>
          </div>

          {/* DEPOSIT AMOUNT */}
          {isEnabledDeposit && (
            <div className="my-3 flex items-center flex-row">
              <h1 className="min-w-[155px] font-bold">Deposit Amount</h1>
              <input
                className="border-2 w-20 border-slate-500 px-2 bg-transparent rounded  text-white font-bold font-display"
                onChange={(e) => setDepositInPercent(Number(e.target.value))}
                type="number"
                placeholder="Enter price in $"
                value={depositInPercent}
                min={1}
                max={100}
              />
              <h1 className="ml-2 font-bold">%</h1>
            </div>
          )}

          {/* Per hour Price */}
          <div className="my-3 flex flex-row items-center">
            <h1 className="min-w-[155px] font-bold">
              Per hour price<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <input
              className="border-2 w-20 border-slate-500 px-2 bg-transparent rounded  text-white font-bold font-display"
              onChange={(e) => setPerHourPrice(e.target.value)}
              type="number"
              placeholder="Enter price in $"
              value={perHourPrice}
              min={0}
            />
            <h1 className="ml-2 font-bold">$</h1>
          </div>

          {/* Minimum hour to select */}
          <div className="my-3 flex flex-row items-center">
            <h1 className="min-w-[155px] font-bold">
              Minimum Hour<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <input
              className="border-2 w-20 border-slate-500 px-2 bg-transparent rounded  text-white font-bold font-display"
              onChange={(e) => setEventMinimumHour(e.target.value)}
              type="number"
              value={eventMinimumHour}
              min={0}
              max={24}
            />
            <h1 className="ml-2 font-bold">Hours</h1>
          </div>

          {/* Zelle number */}
          <div className="my-3 flex flex-row items-center">
            <h1 className="min-w-[155px] font-bold">Zelle Number</h1>
            <input
              className="border-2 w-64 border-slate-500 px-2 py-2 bg-transparent rounded  text-white font-bold font-display"
              onChange={(e) => setZelleNumber(e.target.value)}
              value={zelleNumber}
              placeholder="Enter Zelle Number"
            />
            <h1 className="ml-2 font-bold text-sm">
              <i className="font-bold text-yellow-200">NOTE:</i> Client will see
              it while creating an event
            </h1>
          </div>

          {/* Zelle number */}
          <div className="my-3 flex flex-row items-center">
            <h1 className="min-w-[155px] font-bold">Venmo Number</h1>
            <input
              className="border-2 w-64 border-slate-500 px-2 py-2 bg-transparent rounded  text-white font-bold font-display"
              onChange={(e) => setVenmoNumber(e.target.value)}
              value={venmoNumber}
              placeholder="Enter Venmo Number"
            />
            <h1 className="ml-2 font-bold text-sm">
              <i className="font-bold text-yellow-200">NOTE:</i> Client will see
              it while creating an event
            </h1>
          </div>
        </div>

        {/* SUBMIT button */}
        <button
          className="mt-14 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
          onClick={() => {
            updateBase();
          }}
        >
          Update
        </button>
      </div>
    </AdminPortal>
  );
}
export default EventManagement;
