import { gql } from "@apollo/client";

export default gql`
  query findUsedHours(
    $baseSlug: String!
    $selectedDate: String
    $selectedEventId: String
  ) {
    findUsedHours(
      baseSlug: $baseSlug
      selectedDate: $selectedDate
      selectedEventId: $selectedEventId
    ) {
      additionalData
      errorMessage
      successMessage
    }
  }
`;
