import { gql } from "@apollo/client";

export const FIND_ADMIN_PASSCODE = gql`
  query findAdminPasscodeForBase($baseId: String!) {
    findAdminPasscodeForBase(baseId: $baseId) {
      isSet
      passcode
    }
  }
`;
