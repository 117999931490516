import React, { useState, useContext, useEffect } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faCircleArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
import differenceInYears from "date-fns/differenceInYears";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import AdminPortal from "./AdminPortal";
// import "react-calendar/dist/Calendar.css";
import iconFinder from "../../utils/iconFinder";
import ClipLoader from "react-spinners/ClipLoader";

// Query
import FIND_KID_DETAILS from "../../gql/query/base/findSinglePlayerStats.query";
import FIND_MY_BOOKED_EVENT from "../../gql/query/base/findMyBookedEvents.query";

// MUTATION
import INITIATE_STRIPE_BILLING_PORTAL_SESSION from "../../gql/mutation/base/initiateStripeBillingPortalSession.mutations";
import INITIATE_STRIPE_SESSION from "../../gql/mutation/base/initiateStripeSession.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  RANDOM_AVATAR_URLS,
  OTHER_VIDEO_TYPES_TO_FETCH,
  PROJECT_BSPORTS_SUPER_BASE_EVENT_SLOT_PURCHASE,
  USER_ROLES,
  EVENTS_TYPE,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";
import AdminSIdeEventCard from "../../component/events/adminSIdeEventCard";

const AdminKidDetails = () => {
  // Common data
  const navigate = useNavigate();
  const { kidPlayerId } = useParams();

  // Declare STATE
  const [player, setPlayer] = useState(null);

  // Slot related STATE
  const [slotAmount, setSlotAmount] = useState(1);

  // Action Button states
  const [isLoadingAction, setIsLoadingAction] = useState({
    managePaymentAction: false,
    purchaseSlotAction: false,
  });

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // Query
  const {
    data: findMyKidPlayer,
    loading: findMyKidPlayerLoading,
    error: findMyKidPlayerError,
    refetch: findMyKidPlayerRefetch,
  } = useQuery(FIND_KID_DETAILS, {
    variables: {
      _id: kidPlayerId,
    },
  });

  const {
    data: bookedEventData,
    loading: bookedEventLoading,
    error: bookedEventError,
    refetch: bookedEventRefetch,
  } = useQuery(FIND_MY_BOOKED_EVENT, {
    variables: {
      userPlayerId: kidPlayerId,
    },
  });

  // Mutation
  const [
    initiateStripeBillingPortalSessionMutation,
    {
      loading: initiateStripeBillingPortalSessionMutationLoading,
      error: initiateStripeBillingPortalSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_BILLING_PORTAL_SESSION);

  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SESSION);

  // Set data
  useEffect(() => {
    if (findMyKidPlayer?.findSinglePlayerStats) {
      setPlayer(findMyKidPlayer?.findSinglePlayerStats);
    }
  }, [findMyKidPlayer]);

  // This function will generate a Billing Portal for management of Subscriptions
  async function initiateStripeBillingPortalForSubscriptionManagment(userId) {
    setIsLoadingAction({
      managePaymentAction: true,
      purchaseSlotAction: false,
    });
    // Call the Mutation to get STRIPE session URL
    const { data } = await initiateStripeBillingPortalSessionMutation({
      variables: {
        userId,
      },
    });
    if (data.initiateStripeBillingPortalSession.errorMessage == null) {
      window.location.href =
        data.initiateStripeBillingPortalSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
    }
    setIsLoadingAction({
      managePaymentAction: false,
      purchaseSlotAction: false,
    });
  }

  // Slot purchase
  async function initiateStripeCheckoutUiForSlotPurchase(userId) {
    setIsLoadingAction({
      managePaymentAction: false,
      purchaseSlotAction: true,
    });
    // Call the Mutation to get STRIPE session URL
    const { data } = await initiateStripeSessionMutation({
      variables: {
        project: PROJECT_BSPORTS_SUPER_BASE_EVENT_SLOT_PURCHASE,
        userId,
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        slotAmountToPurchase: Number(slotAmount),
      },
    });
    if (data.initiateStripeSession.errorMessage == null) {
      window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
    } else {
      toast.error(data.initiateStripeSession.errorMessage);
    }
    setIsLoadingAction({
      managePaymentAction: false,
      purchaseSlotAction: false,
    });
  }

  if (findMyKidPlayerError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="bg-slate-700 flex justify-between mb-3 px-5 py-3 mt-8 rounded">
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            className="mr-3 cursor-pointer"
            icon={faCircleArrowLeft}
            onClick={() => {
              navigate(-1);
            }}
          />
          <h1 className="font-bold text-lg">
            {" "}
            {player?.user?.role == USER_ROLES.KID
              ? "Kid"
              : player?.user?.role == USER_ROLES.PARENT
              ? "Guardian"
              : "User"}{" "}
            Details
          </h1>
        </div>
      </div>

      {!findMyKidPlayerLoading && player ? (
        <div className="overflow-x-auto">
          {/* ✅✅✅ */}
          <div
            className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
            style={{
              borderLeft: "5px solid yellow",
              justifyContent: "space-between",
              height: "100%",
              backgroundColor: "#454f6680",
            }}
          >
            {/* KID + PARENT relation */}
            <div
              style={{
                display: "flex",
                height: "100%",
                justifyContent: "space-between",
              }}
              className="py-2"
            >
              {/* KID DETAILS or PARENT details */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px dotted #ffffff45",
                  paddingRight: "60px",
                }}
              >
                {/* kid or parent avatar */}
                <div
                  style={{
                    height: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={iconFinder(player.user.avatar)}
                    alt="Basement Sports"
                    className="max-h-17"
                    style={{
                      color: "white",
                      minWidth: "100px",
                      maxWidth: "100px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                  />
                </div>

                {/* KID/PARENT INFO SECTION */}
                <div className="ml-5" style={{}}>
                  {/* kid/parent Name */}
                  <div>{player.user.name}</div>

                  {/* kid/parent age */}
                  <div style={{ color: "#ccd0e0" }}>
                    {player.user.age ? (
                      <div>{player.user.age} y/o</div>
                    ) : player.user.dateOfBirth ? (
                      <div>
                        {differenceInYears(
                          new Date(),
                          new Date(player.user.dateOfBirth)
                        )}{" "}
                        y/o
                      </div>
                    ) : null}
                  </div>

                  {/* kid gender */}
                  {player?.user?.role == USER_ROLES.KID && (
                    <div>
                      <b style={{ color: "#ccd0e0" }}>
                        {player.user.gender == "female" ? "Girl" : "Boy"}
                      </b>
                    </div>
                  )}

                  {/* Follower */}
                  <div className="text-xs mt-1" style={{ color: "#ccd0e0" }}>
                    Followers:{" "}
                    {player.user?.followFollowers?.followers?.length
                      ? player.user.followFollowers.followers.length
                      : 0}
                  </div>
                  {/* Followings */}
                  <div className="text-xs" style={{ color: "#ccd0e0" }}>
                    Followings:{" "}
                    {player.user?.followFollowers?.followings?.length
                      ? player.user.followFollowers.followings.length
                      : 0}
                  </div>
                </div>
              </div>

              {/* PARENT DETAILS or All KIDS*/}
              {player?.user?.role == USER_ROLES.KID &&
              player.user?.parentDetails ? (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="ml-2"
                >
                  {/* PARENT AVATAR */}
                  <img
                    src={iconFinder(player.user?.parentDetails?.avatar)}
                    alt="Basement Sports"
                    className="max-h-17 cursor-pointer"
                    style={{
                      color: "white",
                      width: "90px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                    onClick={() => {
                      navigate(
                        `/admin/kid-details/${player.user?.parentDetails?.player?._id}`
                      );
                    }}
                  />

                  {/* parent info */}

                  <div className="ml-2">
                    {/* Relation with kid (Show only if Kid) */}
                    <div>
                      Guardian Name:{" "}
                      <b style={{ color: "#ccd0e0" }}>
                        {player.user.parentDetails.name}
                      </b>
                    </div>

                    <div>
                      Relation:{" "}
                      <b style={{ color: "#ccd0e0" }}>
                        {player.parentRelation}
                      </b>
                    </div>

                    <div>
                      Email:{" "}
                      <b style={{ color: "#ccd0e0" }}>
                        {player.user.parentDetails.email}
                      </b>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pl-6">
                  <div
                    className="text-xl font-bold"
                    style={{ color: "#ccd0e0" }}
                  >
                    Kid list -{" "}
                    <b style={{ color: "yellow" }}>
                      {player?.user?.kidsDetails?.length
                        ? player?.user?.kidsDetails?.length
                        : 0}
                    </b>{" "}
                    Kids found
                  </div>
                  {/* ALL KIDS */}
                  <div
                    style={{ display: "flex", flexWrap: "wrap" }}
                    className="mt-5"
                  >
                    {player?.user?.kidsDetails?.map((kid) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          minWidth: "100px",
                        }}
                        className="mx-1 mb-2"
                      >
                        {/* KID AVATAR */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={iconFinder(kid.avatar)}
                            alt="Basement Sports"
                            className="max-h-17 cursor-pointer"
                            style={{
                              color: "white",
                              minHeight: "50px",
                              maxHeight: "50px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                            }}
                            onClick={() => {
                              navigate(
                                `/admin/kid-details/${kid?.player?._id}`
                              );
                            }}
                          />
                        </div>

                        {/* KID INFO SECTION */}
                        <div style={{ textAlign: "center" }} className="mt-1">
                          {/* KID Name */}
                          <div>{kid.name}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* ✅✅✅ */}

          {/* Event & Slot management */}
          {player.user.role != USER_ROLES.KID && (
            <div className="font-semibold my-auto mt-5">
              <h1 className="text-xl" style={{ color: "white" }}>
                Event/ Slot Management
              </h1>

              {/* Game wise STATs */}
              <div className="flex flex-wrap">
                {/* CHECK PAYMENT PROFILE */}
                {/* <div className="w-full md:w-1/3 md:pr-2">
                <div
                  className="rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30 my-3"
                  style={{
                    backgroundColor: "#454f6680",
                    borderLeft: "5px solid #00fff2",
                  }}
                >
                  <div style={{}}>
                    <h1 className="text-lg text-center mb-3">
                      Check user Payment Profile
                    </h1>
                    <h1 className="text-sm text-center mb-3">
                      You can check & manage all payment methods and
                      subscriptions for this user from here.
                    </h1>
                  </div>
                  {isLoadingAction.managePaymentAction ? (
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mb-2 mx-auto block">
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mb-2 mx-auto block"
                      onClick={() => {
                        initiateStripeBillingPortalForSubscriptionManagment(
                          findMyKidPlayer?.findSinglePlayerStats?.userId
                        );
                      }}
                    >
                      Manage Payment Profile
                    </button>
                  )}
                </div>
              </div> */}

                {/* ADD NEW PAYMENT METHOD */}
                <div className="w-full md:w-1/3 md:px-2">
                  <div
                    className="rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30 my-3"
                    style={{
                      backgroundColor: "#454f6680",
                      borderLeft: "5px solid #00fff2",
                    }}
                  >
                    {/* Description */}
                    <div style={{}}>
                      <h1 className="text-lg text-center mb-3">
                        Purchase slot for this Parent
                      </h1>
                      <h1 className="text-sm text-center mb-3">
                        Purchase slots for this parent to help them buy an event
                        for their kids.
                      </h1>
                    </div>
                    {/* Button to purchase */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        onChange={(e) => setSlotAmount(e.target.value)}
                        className="bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-lg"
                        type="number"
                        min={1}
                        value={slotAmount}
                        style={{ width: "65px", height: "30px" }}
                      />
                      {isLoadingAction.purchaseSlotAction ? (
                        <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mb-2">
                          Loading...
                        </button>
                      ) : (
                        <button
                          className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mb-2"
                          onClick={() => {
                            initiateStripeCheckoutUiForSlotPurchase(
                              findMyKidPlayer?.findSinglePlayerStats?.userId
                            );
                          }}
                        >
                          Make Payment
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* BOOKED EVENTS HISTORY */}
          {player.user.role != USER_ROLES.KID && (
            <div>
              <h1 className="mt-4 text-xl font-bold" style={{ color: "white" }}>
                Booked Events ( Found{" "}
                <b style={{ color: "gold" }}>
                  {bookedEventData?.findMyBookedEvents?.length}
                </b>{" "}
                )
              </h1>
              {bookedEventLoading ? (
                <div className="flex items-center justify-center">
                  <ClipLoader
                    color={"#ffffff"}
                    loading={true}
                    css={override}
                    size={40}
                  />
                </div>
              ) : (
                <div className="flex flex-wrap">
                  {bookedEventData?.findMyBookedEvents?.map((event, index) => {
                    return (
                      <AdminSIdeEventCard
                        event={event}
                        key={index}
                        navigate={navigate}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}

          {/* STATISTICS */}
          {player.user.role != USER_ROLES.PARENT && (
            <div className="font-semibold my-auto mt-5">
              <h1 className="text-xl" style={{ color: "white" }}>
                Statistics
              </h1>

              {/* Game wise STATs */}
              <div className="flex justify-between flex-wrap">
                {/* SOCCER */}
                <div className="w-full md:w-1/3 md:pr-2">
                  <div
                    className="rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30 my-3"
                    style={{
                      backgroundColor: "#454f6680",
                      borderLeft: "5px solid #00BA2E",
                    }}
                  >
                    {/* title + soccer icon */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h1 className="text-lg text-center mb-3">Soccer</h1>
                      <img
                        src={"/images/game/soccerLine.png"}
                        alt="Basement Sports"
                        className="max-h-8 mr-3"
                      />
                    </div>
                    {/* statistics */}
                    <div>
                      Total Goals:{" "}
                      {player.soccerStats?.score
                        ? player.soccerStats?.score
                        : 0}
                    </div>
                    <div>
                      Total Played games:{" "}
                      {player.soccerStats?.totalNumberOfGames
                        ? player.soccerStats.totalNumberOfGames
                        : 0}
                    </div>
                  </div>
                </div>

                {/* HOCKEY */}
                <div className="w-full md:w-1/3 md:px-2">
                  <div
                    className="rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30 my-3"
                    style={{
                      backgroundColor: "#454f6680",
                      borderLeft: "5px solid #007DCF",
                    }}
                  >
                    {/* title + soccer icon */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h1 className="text-lg text-center mb-3">Hockey</h1>
                      <img
                        src={"/images/game/hockeyLine.png"}
                        alt="Basement Sports"
                        className="max-h-8 mr-3"
                      />
                    </div>
                    {/* statistics */}
                    <div>
                      Total Goals:{" "}
                      {player.hockeyStats?.score
                        ? player.hockeyStats?.score
                        : 0}
                    </div>
                    <div>
                      Total Played games:{" "}
                      {player.hockeyStats?.totalNumberOfGames
                        ? player.hockeyStats.totalNumberOfGames
                        : 0}
                    </div>
                  </div>
                </div>

                {/* BASEBALLA */}
                <div className="w-full md:w-1/3 md:pl-2">
                  <div
                    className="rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30 my-3"
                    style={{
                      backgroundColor: "#454f6680",
                      borderLeft: "5px solid #E43247",
                    }}
                  >
                    {/* title + soccer icon */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h1 className="text-lg text-center mb-3">Baseball</h1>
                      <img
                        src={"/images/game/baseballLine.png"}
                        alt="Basement Sports"
                        className="max-h-8 mr-3"
                      />
                    </div>
                    <div>
                      Total Goals:{" "}
                      {player.baseBallStats?.runs
                        ? player.baseBallStats.runs
                        : 0}
                    </div>
                    <div>
                      Total Played games:{" "}
                      {player.baseBallStats?.totalNumberOfGames
                        ? player.baseBallStats.totalNumberOfGames
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* VIDEOS */}
          {player.streamingRecords.length > 0 && (
            <div className="font-semibold my-auto mt-5">
              <h1 className="text-xl" style={{ color: "white" }}>
                Videos
              </h1>
              {/* videos */}
              <div
                className="flex flex-wrap"
                style={{
                  alignItems: "center",
                }}
              >
                {player.streamingRecords.map((video, index) => {
                  return (
                    <div
                      className="w-full md:w-1/3"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="mr-3 my-3 cursor-pointer"
                        style={{
                          position: "relative",
                          borderRadius: "10px",
                          overflow: "hidden",
                        }}
                        onClick={() =>
                          navigate(
                            `/admin/video/${video._id}?otherVideoTypes=${OTHER_VIDEO_TYPES_TO_FETCH.fetchSamePlayerVideos}&playerUserId=${player.userId}`
                          )
                        }
                      >
                        {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                        <img
                          src={
                            video?.videoThumbnailUrl
                              ? video.videoThumbnailUrl
                              : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                          }
                          alt="Basement Sports"
                          className={`md:block w-[90px] object-cover `}
                          style={{ width: "100%", height: "200px" }}
                        />

                        {/* PLAY ICON */}
                        <div
                          style={{
                            color: "yellow",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon icon={faCirclePlay} size="3x" />
                        </div>

                        {/* video details */}
                        <div
                          style={{
                            backgroundColor: "#454f6680",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          className="px-4 py-4"
                        >
                          {/* left panel */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* avatar */}
                            <img
                              src={
                                RANDOM_AVATAR_URLS[player.user.avatar]
                                  ? RANDOM_AVATAR_URLS[player.user.avatar]
                                  : RANDOM_AVATAR_URLS["user4_asset4"]
                              }
                              alt="Basement Sports"
                              className={`md:block w-[90px] mr-2`}
                              style={{ width: "40px", height: "40px" }}
                            />
                            {/* video caption & base name */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {/* video caption */}
                              <div
                                className="text-lg"
                                style={{
                                  fontWeight: "700",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  minWidth: "50px",
                                  maxWidth: "140px",
                                }}
                              >
                                {video.caption}
                              </div>
                              {/* base name */}
                              <div
                                className="text-sm"
                                style={{ opacity: "0.5" }}
                              >
                                @{"base"}
                              </div>
                            </div>
                          </div>

                          {/* right panel */}
                          <div style={{ display: "flex" }}>
                            <div className="text-xs" style={{ opacity: "0.5" }}>
                              {formatDistanceToNow(Number(video.createdAt))} ago
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {player.streamingRecords.length == 0 && (
                  <div>No videos yet</div>
                )}
              </div>
            </div>
          )}

          {/* HIGHLIGHTS */}
          {/* {player.photos.length > 0 && (
            <div className="font-semibold my-auto mt-5">
              <h1 className="text-xl" style={{ color: "white" }}>
                Highlights
              </h1>
              <div
                className="flex flex-wrap"
                style={{
                  alignItems: "center",
                }}
              >
                {player.photos.map((photo, index) => {
                  return (
                    <div
                      className="w-full md:w-1/6"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="mr-3 my-3 cursor-pointer"
                        style={{
                          position: "relative",
                          borderRadius: "10px",
                          overflow: "hidden",
                        }}
                        onClick={() =>
                          navigate(
                            `admin/highlight/${photo._id}/${player.userId}`
                          )
                        }
                      >
                        <img
                          src={photo.url}
                          alt="Basement Sports"
                          className={`md:block w-[90px] object-cover `}
                          style={{ width: "100%", height: "300px" }}
                        />

                        <div
                          style={{
                            color: "yellow",
                            position: "absolute",
                            top: 5,
                            right: 5,
                          }}
                        >
                          <img
                            src={
                              RANDOM_AVATAR_URLS[player.user.avatar]
                                ? RANDOM_AVATAR_URLS[player.user.avatar]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className={`md:block w-[90px] mr-2`}
                            style={{ width: "40px", height: "40px" }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                {player.streamingRecords.length == 0 && (
                  <div>No videos yet</div>
                )}
              </div>
            </div>
          )} */}
        </div>
      ) : (
        <div className="mt-20">
          <DataLoading loadingType="success" />
        </div>
      )}
    </AdminPortal>
  );
};

export default AdminKidDetails;
