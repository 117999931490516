export default function TransparentCard({
  children,
  border = false,
  className,
}) {
  return (
    <div
      className={`bg-transparent-card rounded-lg ${
        border ? "border-l-yellow-300 border-l-[5px]" : ""
      } ${className || "px-2 py-3 md:px-3 md:py-4 lg:px-6"} `}
    >
      {children}
    </div>
  );
}
