import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import AdminPortal from "./AdminPortal";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CONTACT_FORM_URL, LOCAL_STORAGE_KEYS } from "../../utils/constant";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import { setHours, setMinutes } from "date-fns";
// Query
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";

// MUTATION
import UPDATE_BASE from "../../gql/mutation/base/updateBaseByAdmin.mutations";

function SessionManagement() {
  let baseId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);

  const [slotDetails, setSlotDetails] = useState({
    price: 0,
    discount: 0,
    subTtile: "",
    description: "",
  });

  // Query
  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [updateBaseMutation, { error: updateBaseError }] =
    useMutation(UPDATE_BASE);

  // Update Image State from Database data
  useEffect(() => {
    function setDate() {
      if (baseData?.findBaseDetails?.slotDetails) {
        setSlotDetails(
          JSON.parse(JSON.stringify(baseData.findBaseDetails?.slotDetails))
        );
      }
    }
    setDate();
    baseRefetch();
  }, [baseData]);

  // Submit to create Event
  async function updateBase() {
    const { data } = await updateBaseMutation({
      variables: {
        baseId: baseData.findBaseDetails._id,
        slotDetails,
      },
    });

    if (data.updateBaseByAdmin.errorMessage == null) {
      toast.success("Successfully updated Base");
    } else {
      toast.error(data.updateBaseByAdmin.errorMessage);
    }
  }

  if (baseLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (baseError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="max-w-6xl mx-auto bg-slate-700 mt-10 py-3 px-8 mb-8 rounded-md">
        <h1 className="text-center font-bold text-3xl mt-2 mb-4">
          Session Management
        </h1>

        {/* Session details */}
        <div className="mt-10">
          <div className="my-3 flex flex-row items-center w-[700px]">
            <h1 className="w-1/4 font-bold">
              Session price<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <input
              className="border-2 w-20 border-slate-500 px-2 bg-transparent rounded  text-white font-bold font-display"
              onChange={(e) =>
                setSlotDetails({
                  ...slotDetails,
                  price: Number(e.target.value),
                })
              }
              type="number"
              placeholder="Enter price in $"
              value={slotDetails.price ? slotDetails.price : null}
              min={0}
            />
            <h1 className="ml-2 font-bold">$</h1>
          </div>

          <div className="my-3 flex  flex-row w-[700px] items-center">
            <h1 className=" w-1/4  font-bold">
              Session Sub Title<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <input
              className="border-2 w-3/4 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
              onChange={(e) =>
                setSlotDetails({ ...slotDetails, subTtile: e.target.value })
              }
              value={slotDetails.subTtile ? slotDetails.subTtile : null}
            />
          </div>

          <div className="my-3 flex flex-row w-[700px] items-center">
            <h1 className=" w-1/4  font-bold">
              Session Description<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <textarea
              className=" border-2 w-3/4  h-[100px] border-slate-500 px-2  bg-transparent rounded text-white font-bold font-display"
              onChange={(e) =>
                setSlotDetails({ ...slotDetails, description: e.target.value })
              }
              value={slotDetails.description ? slotDetails.description : null}
            />
          </div>
        </div>

        {/* SUBMIT button */}
        <button
          className="mt-14 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
          onClick={() => {
            updateBase();
          }}
        >
          Update
        </button>
      </div>
    </AdminPortal>
  );
}
export default SessionManagement;
