import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CategoryItem = ({ item, minted }) => {
  return (
    <div className="grid sx:grid-cols-1 ssx:grid-cols-2 sm:grid-cols-2 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-3 relative">
      {item.map((item) => {
        return (
          <article key={item._id}>
            <div className="rounded-lg  h-[340px] xxl:h-[430px] block  px-[.5rem] py-[.8rem]  hover:border-yellow-300 border border-transparent ease-in-out duration-500">
              <figure className="relative ">
                <div className="items-center flex ">
                  <div className="absolute m-auto left-0 right-0 top-1/2  z-10 transform transition duration-500 hover:rotate-6 hover:scale-105">
                    <Link to={`/nft-details/${item._id}`} state={{ item }}>
                      <img
                        src={
                          item.collectionType === "Female"
                            ? item.easyImageUrl
                            : item.url
                        }
                        alt="https://res.cloudinary.com/dva2poi3c/image/upload/v1688704127/g2uA_rbi04o.gif"
                        className="w-full h-[240px] xxl:h-[300px]  object-contain mx-auto"
                      />
                    </Link>
                  </div>

                  <div
                    className="absolute m-auto left-0 right-0 top-[100px] animate-spin inline-block w-10 h-10 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                    role="status"
                    aria-label="loading"
                  ></div>
                </div>
              </figure>
              <div className="mt-[270px] xxl:mt-[330px]  ">
                <h1 className="text-center font-display hover:text-yellow-300 text-base text-white">
                  {item.nftName}
                </h1>
              </div>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default CategoryItem;
