import { gql } from "@apollo/client";

export default gql`
  query findCheckedInUsers($baseId: String!) {
    findCheckedInUsers(baseId: $baseId) {
      _id
      name
      email
    }
  }
`;