import React, { useState, useEffect } from "react";
import AdminPortal from "./AdminPortal";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  format,
  addYears,
  formatISO,
  addMonths,
  addDays,
  differenceInHours,
  differenceInDays,
} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { utcToZonedTime, format as tzFormat } from "date-fns-tz";
import { Image } from "cloudinary-react";
import DataLoading from "../../component/loader/DataLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCircleArrowLeft,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import iconFinder from "../../utils/iconFinder";

// Rich text editor import
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Query
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";
import FIND_USED_HOURS from "../../gql/query/base/findUsedHours.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";
import FIND_ADMIN_UNSEEN_MESSAGE_COUNT from "../../gql/query/base/findAdminUnseenMessageCounts.query";

// Mutations
import UPDATE_EVENT from "../../gql/mutation/base/updateEvent.mutations";

import {
  CLIENT_CREATED_EVENT_PAYMENT_STATUSES,
  CLOUDINARY_INFO,
  EVENT_DURATION,
  EVENT_DURATION_HOUR,
  LOCAL_STORAGE_KEYS,
  PAYMENT_METHOD,
  WEEK_DAYAS,
} from "../../utils/constant";
import { calculateEventEndTime } from "../../utils/eventTime";

function UpdateEvent() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  const [dropdownShow, setDropdownShow] = useState(false);
  const [eventType, setEventType] = useState("");
  const [caption, setCaption] = useState("");
  const [address, setAddress] = useState("");
  const [eventPrice, setEventPrice] = useState(0);
  const [amountToPay, setAmountToPay] = useState(0); // In "Dollar"

  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedSportsForComponent, setSelectedSportsForComponent] = useState(
    []
  );
  const [selectedGametype, setSelectedGametype] = useState("");
  const [selectedGametypeForComponent, setSelectedGametypeForComponent] =
    useState("");
  const [capacity, setCapacity] = useState(0);
  const [startDate, setStartDate] = useState(new Date());

  // Recurring event
  const [hasRecurring, setHasRecurring] = useState(false);
  const [recurringStartDate, setRecurringStartDate] = useState(new Date());
  const [recurringEndDate, setRecurringEndDate] = useState(
    addMonths(new Date(), 1)
  );
  const [recurringDays, setRecurringDays] = useState([]);
  const [maxDateOfEndRecurring, setMaxDateOfEndRecurring] = useState(
    addMonths(new Date(), 1)
  );
  // We set initial value of "totalRecurringEvents" = 1 so that "totalRecurringEvents" multiply with "TOTAL PRICE" won't give 0
  const [totalRecurringEvents, setTotalRecurringEvents] = useState(1); // Total recurring days within selected recurring duration

  // Rich text editor state
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Image state
  const [image, setImage] = useState(null);

  // Base opening & ending time
  const [baseOpeningTime, setBaseOpeningTime] = useState(new Date());
  const [baseEndingTime, setBaseEndingTime] = useState(new Date());

  // Event start time
  const [eventStartTime, setEventStartTime] = useState(new Date());
  const [eventStartHour, setEventStartHour] = useState(0);

  // Event end time
  const [eventEndTime, setEventEndTime] = useState(new Date());
  const [eventEndHour, setEventEndHour] = useState(0);

  // All used dates
  const [usedHoursOfSelectedDate, setUsedHoursOfSelectedDate] = useState([]);

  // All available hours
  const [availableHours, setAvailableHours] = useState([]);

  // Coupon state
  const [coupons, setCoupons] = useState([]);

  // Single discount code for CLIENT events
  const [discount, setDiscount] = useState(0); // Admin can set this discount only for CLIENT events

  const eventHour = [1, 2, 3, 4, 6, 12];

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_A_EVENT, {
    variables: {
      eventId,
    },
  });

  const {
    data: allUsedHours,
    loading: allUsedHoursLoading,
    error: allUsedHoursError,
    refetch: allUsedHoursRefetch,
  } = useQuery(FIND_USED_HOURS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      selectedDate: startDate,
      selectedEventId: eventId,
    },
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  const {
    data: findAdminUnseenMessagesCount,
    loading: findAdminUnseenMessagesCountLoading,
    error: findAdminUnseenMessagesCountError,
    refetch: findAdminUnseenMessagesCountRefetch,
  } = useQuery(FIND_ADMIN_UNSEEN_MESSAGE_COUNT, {
    variables: {
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      eventId,
    },
    pollInterval: 1000,
  });

  // Mutations
  const [
    updateEventMutation,
    { error: updateEventMutationError, loading: updateEventMutationLoading },
  ] = useMutation(UPDATE_EVENT);

  // DATE variables
  let oneYearFuture = addYears(new Date(), 1);

  // Initialize event data
  // Initialize event data
  useEffect(() => {
    function initializeEventData() {
      // Refetch event data
      eventsRefetch();

      // If data successfully came
      if (eventsData && eventsData.findEvent) {
        let eventDetails = eventsData.findEvent;
        // Set event type
        setEventType(eventDetails.eventType);
        // set caption
        setCaption(eventDetails.caption);
        // set address
        setAddress(eventDetails.address ? eventDetails.address : "");

        // set event price
        setEventPrice(eventDetails.eventPrice);
        // set sports
        if (eventDetails.sports && eventDetails.sports.length > 0) {
          setSelectedSports(eventDetails.sports);
          let sportsForComponent = sportsOption.filter((sport) =>
            eventDetails.sports.includes(sport.value)
          );
          setSelectedSportsForComponent(sportsForComponent);
        }
        // Set coupon
        if (eventDetails.promoCodes && eventDetails.promoCodes.length > 0) {
          let newPromoCodesArray = eventDetails.promoCodes.map((promoCode) => {
            return {
              code: promoCode.code,
            };
          });
          setCoupons(newPromoCodesArray);
        }
        // set game type
        if (eventDetails.gameType && eventDetails.gameType != null) {
          setSelectedGametype(eventDetails.gameType);
          let sportsForComponent = gameTypesOption.filter((gameType) =>
            eventDetails.gameType.includes(gameType.value)
          );
          setSelectedGametypeForComponent(sportsForComponent);
        }
        // set slot
        if (
          eventDetails.capacity &&
          eventDetails.capacity != 0 &&
          eventDetails.capacity != null
        ) {
          setCapacity(eventDetails.capacity);
        }
        // set date
        setStartDate(new Date(eventDetails.dateTime));

        // Set banner image
        if (eventDetails?.bannerImage) {
          setImage(eventDetails?.bannerImage);
        }

        // set description in Editor state
        if (eventDetails?.description) {
          const content = JSON.parse(eventDetails.description);
          const contentState = convertFromRaw(content);
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }

        // Refetch available Time SLOTS
        allUsedHoursRefetch({
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          selectedDate: new Date(eventDetails.dateTime),
          selectedEventId: eventId,
        });

        // Update base baseOpeningHour
        if (eventDetails?.eventStartHour) {
          let currentDate = new Date();
          // set hour, minutes & seconds
          currentDate.setHours(eventDetails.eventStartHour);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
          // set event start time
          setEventStartTime(currentDate);
          setEventStartHour(eventDetails.eventStartHour);
        }

        // Update base baseClosingHour
        if (eventDetails?.eventEndHour) {
          let currentDate = new Date();
          // set hour, minutes & seconds
          currentDate.setHours(eventDetails?.eventEndHour);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);
          // set event end time
          setEventEndTime(currentDate);
          setEventEndHour(eventDetails?.eventEndHour);
        }

        // Set recurring data
        if (eventDetails.hasRecurring) {
          setHasRecurring(eventDetails.hasRecurring);
          setRecurringStartDate(
            eventDetails?.recurringStartDate
              ? new Date(Number(eventDetails.recurringStartDate))
              : new Date()
          );
          setRecurringEndDate(
            eventDetails?.recurringEndDate
              ? new Date(Number(eventDetails.recurringEndDate))
              : addMonths(new Date(), 1)
          );
          setRecurringDays(eventDetails.recurringDays);
        }

        // Set discount for client events
        setDiscount(eventDetails?.discount ? eventDetails?.discount : 0);
      }
    }
    initializeEventData();
  }, [eventsData]);

  // Set used HOURS
  // Set used HOURS
  useEffect(() => {
    function setUsedHours() {
      if (allUsedHours?.findUsedHours && baseData?.findBaseDetails) {
        // The used hours
        let usedHours = JSON.parse(allUsedHours?.findUsedHours?.additionalData);
        let finalDateObjects = [];

        // Convert all used hours to DATE Object
        for (let index = 0; index < usedHours.length; index++) {
          let currentTime = new Date();
          currentTime.setHours(usedHours[index]);
          currentTime.setMinutes(0);
          currentTime.setSeconds(0);
          finalDateObjects.push(currentTime);
        }

        // Set used DATE objects into a STATE
        setUsedHoursOfSelectedDate(finalDateObjects);

        // Find all the available hours of the BASE
        let baseHours = [];
        let baseOpeningHour = baseData?.findBaseDetails?.baseOpeningHour;
        let baseClosingHour = baseData?.findBaseDetails?.baseClosingHour;
        for (let index = baseOpeningHour; index <= baseClosingHour; index++) {
          baseHours.push(index);
        }

        // Find all the available HOURS to use
        let allAvailableHours = baseHours.filter(
          (baseHour) => !usedHours.includes(baseHour)
        );
        setAvailableHours(allAvailableHours); // Set the available hours

        // set base OPENING hour to set the minimum hour in <DatePicker />
        let currentDate = new Date();
        currentDate.setHours(baseOpeningHour);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        setBaseOpeningTime(currentDate);

        // set base CLOSING hour to set the maximum hour in <DatePicker />
        let newCurrentDate = new Date();
        newCurrentDate.setHours(baseClosingHour);
        newCurrentDate.setMinutes(0);
        newCurrentDate.setSeconds(0);
        setBaseEndingTime(newCurrentDate);
      }
    }
    setUsedHours();
  }, [allUsedHours, baseData]);

  // Refetch admin unseen messages count for this event
  useEffect(() => {
    function refetchCount() {
      findAdminUnseenMessagesCountRefetch();
    }
    refetchCount();
  }, [findAdminUnseenMessagesCount]);

  // Update amount To PAY
  useEffect(() => {
    function updateAmountToPay() {
      if (baseData?.findBaseDetails) {
        setAmountToPay(
          (differenceInHours(eventEndTime, eventStartTime) < 0
            ? 0
            : differenceInHours(eventEndTime, eventStartTime)) *
            baseData?.findBaseDetails?.perHourPrice
        );
      }
    }
    updateAmountToPay();
  }, [baseData, eventStartTime, eventEndTime]);

  // Update total recurring events count
  useEffect(() => {
    function updateRecrruingEventsCount() {
      let eventsCount = 0;

      // Calculate the number of days between the start and end dates
      const daysCount =
        differenceInDays(recurringEndDate, recurringStartDate) + 1;

      // Loop over the daysCount
      for (let i = 0; i <= daysCount; i++) {
        // Add i daysCount to the start date
        let currentDate = addDays(recurringStartDate, i);

        // Format the day name as Monday, Tuesday, etc.
        let dayName = format(currentDate, "EEE");
        dayName = dayName.toUpperCase();

        if (recurringDays.includes(dayName)) {
          ++eventsCount; // Increase the recurring events count
        }
      }

      // If total recurring events count is 0 then set it to 1 so that total price multiplication won't give 0
      if (eventsCount <= 0) {
        eventsCount = 1;
      }

      setTotalRecurringEvents(eventsCount);
    }
    updateRecrruingEventsCount();
  }, [
    hasRecurring,
    recurringStartDate,
    recurringEndDate,
    recurringDays,
    startDate,
  ]);

  // Handle Event Type Dropdown
  const handleDropdown = (e) => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-toggle")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };

  // Dropdown Options of Event Type
  const eventTypeList = [
    { type: "Tournament" },
    { type: "Special Events" },
    { type: "League" },
    { type: "Contest" },
  ];

  // Handle Sports dropdown
  const handleSelectSportsChange = (selectedOptions) => {
    console.log("Came: ", selectedOptions);
    setSelectedSportsForComponent(selectedOptions);
    const values = selectedOptions.map((option) => option.value);
    setSelectedSports(values);
  };

  // Dropdown Options of Sports
  const sportsOption = [
    { value: "soccer", label: "Soccer" },
    { value: "hockey", label: "Hockey" },
    { value: "baseBall", label: "Baseball" },
    { value: "basketBall", label: "Basketball" },
    { value: "pickelBall", label: "Pickelball" },
    { value: "tennis", label: "Tennis" },
    { value: "racket", label: "Racket" },
    { value: "volleyBall", label: "Volleyball" },
  ];

  // Handle Gametype dropdown
  const handleSelectGameTypeChange = (selectedOptions) => {
    setSelectedGametypeForComponent(selectedOptions);
    setSelectedGametype(selectedOptions.value);
  };

  // Dropdown Options of Game Type
  const gameTypesOption = [
    { value: "1v", label: "1V" },
    { value: "2v", label: "2V" },
    { value: "3v", label: "3V" },
  ];

  // Set custom style in react-select
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black", // Set the color for all options
    }),
  };

  // Coupon related method
  const addCoupon = () => {
    // a function to add a new coupon object to the array
    setCoupons([
      ...coupons,
      {
        code: "",
        // startDate: new Date(),
        // endDate: null,
      },
    ]);
  };

  // a function to remove a coupon object from the array by index
  const removeCoupon = (index) => {
    setCoupons(coupons.filter((_, i) => i !== index));
  };

  // a function to update a coupon object by index, key and value
  const handleChange = (index, key, value) => {
    setCoupons(
      coupons.map((coupon, i) =>
        i === index ? { ...coupon, [key]: value } : coupon
      )
    );
  };

  // Submit to update Event
  async function updateEvent() {
    // Check EventType was given or not
    if (eventType == "" || eventType == null) {
      toast.error("Please select Event Type!");
      return;
    }
    // Check eventprice
    if (
      eventsData?.findEvent?.isCreatedByAdmin == true &&
      (!eventPrice || eventPrice == null || eventPrice == 0)
    ) {
      toast.error("Please choose a valid price!");
      return;
    }

    // Check the time
    if (
      baseData?.findBaseDetails?.eventMinimumHour &&
      differenceInHours(eventEndTime, eventStartTime) <
        baseData?.findBaseDetails?.eventMinimumHour
    ) {
      toast.error(
        `You can't submit a request that is less than ${baseData?.findBaseDetails?.eventMinimumHour} hour`
      );
      return;
    }

    if (differenceInHours(eventEndTime, eventStartTime) <= 0) {
      toast.error("You can't submit a request that is less than 1 hour");
      return;
    }

    // Check if the selected start time & end time sequential or not
    let selectedHours = [];
    for (let hour = eventStartHour; hour <= eventEndHour; hour++) {
      selectedHours.push(hour);
    }
    let unavailableButSelectedHours = selectedHours.filter(
      (hour) => !availableHours.includes(hour)
    );
    if (unavailableButSelectedHours.length > 0) {
      toast.error(
        "Start and End time should be sequential within available hours!"
      );
      return;
    }

    // Check Coupons are valid or not
    if (coupons && coupons.length > 0) {
      // Check if invalid coupon code exist or not
      for (let index = 0; index < coupons.length; index++) {
        const coupon = coupons[index];
        if (!coupon.code || coupon.code == "") {
          toast.error("Coupon code can't be empty!");
          return;
        }
        if (!String(coupon.code).match(/^[a-zA-Z][a-zA-Z0-9]*[0-9]$/)) {
          toast.error("Invalid coupon codes found!");
          return;
        }
      }
      // Check if all given coupon codes are unique
      let codes = coupons.map((obj) => obj.code); // Create an array of codes
      let set = new Set(codes); // Remove all repeated codes from the array
      if (set.size != coupons.length) {
        toast.error("All coupon codes must be unique!");
        return;
      }
    }
    // Check startedate
    if (startDate == "" || startDate == null) {
      toast.error("Please select a date & time!");
      return;
    }
    // Check recurring data
    if (hasRecurring) {
      if (recurringDays.length == 0) {
        toast.error("Please choose at least one recurring day!");
        return;
      }
    }
    // Check if EventType is Tournament or League or Contest
    if (
      (eventType === "Tournament" ||
        eventType === "League" ||
        eventType === "Contest") &&
      (!selectedSports || selectedSports == null || selectedSports.length == 0)
    ) {
      toast.error("Please select at least one Sport!");
      return;
    }
    // Check if EventType Tournament or League
    if (
      (eventType === "Tournament" || eventType === "League") &&
      (selectedGametype == "" || selectedGametype == null)
    ) {
      toast.error("Please select Game Type!");
      return;
    }

    // Now check is Rich Editor state empty or not
    const checkContentState = editorState.getCurrentContent();
    const plainText = checkContentState.getPlainText().trim();
    const isEmpty = plainText.length === 0;

    let dateToSubmit = formatISO(startDate, { representation: "complete" });

    const { data } = await updateEventMutation({
      variables: {
        eventId,
        caption: caption == "" ? null : caption,
        dateTime: dateToSubmit,
        eventPrice:
          recurringDays.length > 0
            ? Number(amountToPay) * totalRecurringEvents
            : Number(amountToPay),
        sports: selectedSports.length == 0 ? null : selectedSports,
        gameType: selectedGametype == "" ? null : selectedGametype,
        description: isEmpty
          ? null
          : JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        easyDescription: isEmpty ? null : plainText,
        bannerImage: image,
        promoCodes: coupons,
        address,
        hasRecurring,
        recurringStartDate,
        recurringEndDate,
        recurringDays,
        eventStartHour: Number(eventStartHour),
        eventEndHour: Number(eventEndHour),
        discount: Number(discount),
        adminUserId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      },
    });

    if (data.updateEvent.errorMessage == null) {
      toast.success("Successfully updated event");
      eventsRefetch();
    }
  }

  // Upload cloudinary image
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("JJJJ: ", file);

    let formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "blueLinerfile");
    formData.append("cloud_name", "dva2poi3c");

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dvqldxdfv/image/upload`,
        {
          method: "post",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      );

      console.log("DONE: ", response);

      if (response.ok) {
        const data = await response.json();
        console.log("DONE 222: ", data);

        setImage(data.secure_url);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  if (eventsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (eventsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  console.log("Called 3: ", eventsData.findEvent);

  return (
    <AdminPortal>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        // className="ml-10 mr-10 mt-6"
        className="md:w-12/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mt-8 mb-3 rounded-lg"
      >
        <div className="">
          <Link
            to={"/admin/calendar-management"}
            className="font-bold text-lg px-4 m-1 py-1 rounded"
          >
            <FontAwesomeIcon className="mr-3" icon={faCircleArrowLeft} />
            Back
          </Link>
        </div>
        <div style={{ display: "flex" }}>
          {!eventsLoading &&
            eventsData?.findEvent?.isCreatedByAdmin == false && (
              <Link
                to={`/admin/chat/${eventId}`}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block mr-4"
                style={{ position: "relative" }}
              >
                <button>Message Client</button>

                {!findAdminUnseenMessagesCountLoading &&
                  findAdminUnseenMessagesCount?.findAdminUnseenMessageCounts
                    ?.additionalData &&
                  Number(
                    findAdminUnseenMessagesCount?.findAdminUnseenMessageCounts
                      ?.additionalData
                  ) > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        top: -16,
                        right: -10,
                        backgroundColor: "red",
                        borderRadius: "50px",
                        color: "white",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      className="text-lg font-bold w-[32px] h-[32px] rounded-lg flex items-center"
                    >
                      {
                        findAdminUnseenMessagesCount
                          ?.findAdminUnseenMessageCounts?.additionalData
                      }
                    </div>
                  )}
              </Link>
            )}

          {!eventsLoading &&
            eventsData?.findEvent?.isCreatedByAdmin == false && (
              <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mx-auto block mr-4">
                Cancel Event
              </button>
            )}

          {!eventsLoading &&
            eventsData?.findEvent?.recurringEventIds?.length > 1 && (
              <Link to={`/admin/recurring-events/${eventId}`} className="mr-4">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block">
                  Recurring Events (
                  {eventsData?.findEvent?.recurringEventIds?.length
                    ? eventsData?.findEvent?.recurringEventIds?.length
                    : 0}
                  )
                </button>
              </Link>
            )}

          <Link to={`/admin/add-event/${eventId}`} className="mr-4">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block">
              Use as Template
            </button>
          </Link>

          <Link to={`/admin/participants/${eventId}`} className="mr-4">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block h-[100%]">
              Participants
            </button>
          </Link>

          <Link to={`/admin/add-participants/${eventId}`} className="mr-4">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block">
              Add Participants
            </button>
          </Link>

          <Link to={`/admin/event-send-mail/${eventId}`}>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block">
              Mail Participants
            </button>
          </Link>
        </div>
      </div>

      <div className="max-w-6xl mx-auto bg-slate-700 mt-5 py-3 px-4 mb-14 rounded-lg">
        <h3 className="text-center font-bold text-[30px] mt-2 mb-6">
          Update Event
        </h3>

        {!eventsLoading && eventsData?.findEvent?.isCreatedByAdmin == false && (
          <div>
            <div
              className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
              style={{
                borderLeft: "5px solid green",
                justifyContent: "space-between",
                height: "100%",
                backgroundColor: "#454f6680",
                position: "relative",
              }}
            >
              {/* Family logo */}
              <div
                style={{ position: "absolute", bottom: 8, left: 8 }}
                data-tooltip-id="tooltip-content"
                data-tooltip-content={"Parent"}
                className="cursor-pointer"
              >
                <FontAwesomeIcon
                  className="mr-3"
                  icon={faUsers}
                  size="2x"
                  opacity={0.1}
                />
              </div>
              {/* Parent AVATAR + Name */}
              <div
                style={{
                  display: "flex",
                  height: "100%",
                }}
                className="py-2"
              >
                {/* Parent AVATAR */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={iconFinder(
                      eventsData?.findEvent?.createdByDetails?.avatar
                    )}
                    alt="Basement Sports"
                    className="max-h-17 cursor-pointer"
                    style={{
                      color: "white",
                      minWidth: "100px",
                      maxWidth: "100px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                    onClick={() => {
                      navigate(
                        `/admin/kid-details/${eventsData?.findEvent?.createdByDetails?.player?._id}`
                      );
                    }}
                  />
                </div>

                {/* PARENT INFO SECTION */}
                <div
                  className="ml-5"
                  style={{
                    borderRight: "1px dotted #ffffff45",
                    paddingRight: "60px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minWidth: "250px",
                    maxWidth: "250px",
                    overflow: "hidden",
                  }}
                >
                  {/* Parent Name */}
                  <div>{eventsData?.findEvent?.createdByDetails?.name}</div>
                  {/* Parent Email */}
                  <div>{eventsData?.findEvent?.createdByDetails?.email}</div>
                  <div>
                    Role: {eventsData?.findEvent?.createdByDetails?.role}
                  </div>
                </div>

                {/* PAYMENT INFO */}
                {/* PAYMENT INFO */}
                <div
                  className="text-md mt-2 px-5 py-2 rounded-lg"
                  style={{ color: "gold" }}
                >
                  {/* Payment Method */}
                  <div className="mr-5">
                    <b style={{ color: "cyan" }}>Payment Method:</b>{" "}
                    {eventsData?.findEvent?.paymentMethod ==
                    PAYMENT_METHOD.DEPOSIT_BY_STRIPE
                      ? "Stripe"
                      : eventsData?.findEvent?.paymentMethod}
                  </div>
                  {/* Payment STATUS */}
                  <div
                    className="mr-5 font-normal"
                    style={{ color: "#ff9393" }}
                  >
                    <b style={{ color: "cyan" }}>Payment Status: </b>{" "}
                    {eventsData?.findEvent?.paymentStatus}
                  </div>
                  {/* Paid Amount */}
                  <div className="mr-5 font-normal" style={{ color: "gold" }}>
                    <b style={{ color: "cyan" }}>Paid amount: </b> $
                    {eventsData?.findEvent?.paidAmount / 100}
                  </div>
                  {/* DUE amount */}
                  {(eventsData?.findEvent?.paymentStatus ==
                    CLIENT_CREATED_EVENT_PAYMENT_STATUSES.DEPOSITED ||
                    eventsData?.findEvent?.paymentStatus ==
                      CLIENT_CREATED_EVENT_PAYMENT_STATUSES.INCOMPLETE) && (
                    <div
                      className="mr-5 font-normal"
                      style={{ color: "#ff9393" }}
                    >
                      <b style={{ color: "cyan" }}>Due: </b> $
                      {(eventsData?.findEvent?.amountToPay -
                        eventsData?.findEvent?.paidAmount) /
                        100}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* ✅✅✅ */}
          </div>
        )}

        {/* EVENT TYPE */}
        {/* EVENT TYPE */}
        <div className="mt-8 mb-3">
          <label className="mr-9 pr-3 font-bold">Event Type</label>
          <input
            className="w-64 border-2 border-slate-600 px-2 py-2 bg-transparent rounded text-gray font-bold font-display"
            value={eventsData.findEvent?.eventType}
            disabled={true}
          />
        </div>

        {/* Caption */}
        {/* Caption */}
        <div className="my-3">
          <label className="mr-16 pr-3 font-bold">Caption</label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setCaption(e.target.value)}
            value={caption}
          />
        </div>

        {/* Date Picker for DATE */}
        {/* Date Picker for DATE */}
        <div className="my-2">
          <label className="mr-20 pr-1 font-bold">
            Date <b style={{ color: "#ff0000a1" }}> * </b>
          </label>

          <DatePicker
            className="rounded w-64 text-xl font-bold text-black"
            style={{ width: "100px" }}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              // Refetch available Time Slots
              allUsedHoursRefetch({
                baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                selectedDate: new Date(date),
                selectedEventId: eventId,
              });
            }}
            maxDate={oneYearFuture}
            minDate={new Date()}
            dateFormat="MMMM d, yyyy, EEE"
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Time"
            placeholderText="Select Date and Time"
          />
        </div>

        {/* Event Start Time */}
        {/* Event Start Time */}
        <div className="my-3 flex items-center">
          <label className="min-w-[135px] font-bold ">
            Start Time <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <div>
            {allUsedHoursLoading ? (
              <ClipLoader
                color={"#ffffff"}
                loading={true}
                css={override}
                size={20}
              />
            ) : (
              <DatePicker
                className="rounded w-32 text-xl font-bold text-black"
                style={{ width: "100px" }}
                selected={eventStartTime}
                onChange={(date) => {
                  // Set event start time
                  setEventStartTime(date);
                  setEventStartHour(date.getHours());
                  // Set event end time & hour
                  setEventEndTime(date);
                  setEventEndHour(date.getHours());
                }}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                timeIntervals={60}
                timeCaption="Time"
                placeholderText="Start Time"
                minTime={baseOpeningTime}
                maxTime={baseEndingTime}
                excludeTimes={usedHoursOfSelectedDate}
              />
            )}
          </div>
        </div>

        {/* Event End Time */}
        {/* Event End Time */}
        <div className="my-2 flex items-center">
          <label className="min-w-[135px] font-bold ">
            End Time <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <div>
            {allUsedHoursLoading ? (
              <ClipLoader
                color={"#ffffff"}
                loading={true}
                css={override}
                size={20}
              />
            ) : (
              <DatePicker
                className="rounded w-32 text-xl font-bold text-black"
                style={{ width: "100px" }}
                selected={eventEndTime}
                onChange={(date) => {
                  // Set event start time
                  setEventEndTime(date);
                  setEventEndHour(date.getHours());
                }}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                timeFormat="h:mm aa"
                timeIntervals={60}
                timeCaption="Time"
                placeholderText="Start Time"
                minTime={eventStartTime}
                maxTime={baseEndingTime}
                excludeTimes={usedHoursOfSelectedDate}
              />
            )}
          </div>
        </div>

        {/* DURATION */}
        {/* DURATION */}
        <div className="mt-0 mb-3 flex items-center">
          <label className="mr-16 pr-1 font-bold">Duration</label>
          <div className="flex items-center text-xl font-bold">
            {differenceInHours(eventEndTime, eventStartTime) < 0
              ? 0
              : differenceInHours(eventEndTime, eventStartTime)}{" "}
            hours
          </div>
        </div>

        {/* Event Price */}
        {/* Event Price */}
        <div className="my-3 flex items-center">
          <label className="mr-10 pr-14 font-bold">Price</label>
          <div
            className={`text-2xl font-bold   ${
              discount > 0 ? "line-through text-slate-400" : "text-yellow-300"
            }`}
          >
            ${amountToPay * totalRecurringEvents}
          </div>
          {discount > 0 && (
            <div className="text-2xl font-bold text-yellow-300 ml-3">
              {amountToPay * totalRecurringEvents - discount < 0 ? (
                <div>$0</div>
              ) : (
                <div>${amountToPay * totalRecurringEvents - discount}</div>
              )}
            </div>
          )}
          {recurringDays.length == 0 ? (
            <div className="ml-3">
              ( ${baseData?.findBaseDetails?.perHourPrice}/hour x{" "}
              {differenceInHours(eventEndTime, eventStartTime) < 0
                ? 0
                : differenceInHours(eventEndTime, eventStartTime)}{" "}
              hours ){" "}
            </div>
          ) : (
            <div className="ml-3">
              ( ${baseData?.findBaseDetails?.perHourPrice}/hour x{" "}
              {differenceInHours(eventEndTime, eventStartTime) < 0
                ? 0
                : differenceInHours(eventEndTime, eventStartTime)}{" "}
              hours x {recurringDays.length > 0 ? totalRecurringEvents : 0}{" "}
              Recurring events ){" "}
            </div>
          )}
        </div>

        {/* Event DISCOUNT */}
        {/* Event DISCOUNT */}
        {eventsData?.findEvent?.isCreatedByAdmin == true && (
          <div className="my-3 flex items-center">
            <label className="mr-10 pr-7 font-bold">Discount</label>
            <input
              className="border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display w-32"
              onChange={(e) => {
                setDiscount(e.target.value);
              }}
              value={discount}
              type="text"
              placeholder="Ex:  asdf10"
            />
            <div className="ml-2">$</div>

            {/* Discount info */}
            {amountToPay * totalRecurringEvents > 0 && discount > 0 && (
              <div className="flex items-center ml-6">
                <div className="text-lg">
                  <b className="text-yellow-100 font-bold">
                    {(
                      (discount / (amountToPay * totalRecurringEvents)) *
                      100
                    ).toFixed(2)}
                    %
                  </b>{" "}
                  Discount
                </div>

                <div className="flex items-center ml-4 font-bold text-2xl">
                  ({" "}
                  <div className="text-2xl font-bold line-through text-slate-400">
                    ${amountToPay * totalRecurringEvents}
                  </div>
                  <div className="text-2xl font-bold text-yellow-300 ml-3">
                    {amountToPay * totalRecurringEvents - discount < 0 ? (
                      <div>$0</div>
                    ) : (
                      <div>
                        ${amountToPay * totalRecurringEvents - discount}
                      </div>
                    )}
                  </div>{" "}
                  )
                </div>
              </div>
            )}
          </div>
        )}

        {/* RECURRING EVENT */}
        {/* RECURRING EVENT */}
        {!eventsLoading && eventsData?.findEvent?.isCreatedByAdmin == true && (
          <div className="my-5" style={{ display: "flex" }}>
            <label className="min-w-[135px] font-bold">Recurring</label>

            <div>
              {/* STATUS of recurring */}
              <div className="flex items-center">
                <input
                  className="border-none rounded-lg"
                  onClick={() => {
                    setHasRecurring(!hasRecurring);
                    setRecurringStartDate(new Date());
                    setRecurringEndDate(addMonths(new Date(), 1));
                    setRecurringDays([]);
                  }}
                  type="checkbox"
                  checked={hasRecurring}
                />
                <label className="ml-2 font-bold">
                  Enable recurring this event
                </label>
              </div>

              {hasRecurring && (
                <div>
                  {/* FROM & TO */}
                  <div className="flex items-center mt-2">
                    {/* FROM */}
                    <div className="flex items-center mr-5">
                      <div className="font-bold mr-2">From</div>
                      <DatePicker
                        className="rounded w-64 text-black"
                        style={{ width: "100px" }}
                        selected={recurringStartDate}
                        onChange={(date) => {
                          setRecurringStartDate(date);
                          // Set recurring end date
                          setRecurringEndDate(addMonths(new Date(date), 1));
                          // Set 1 month as MAX for Recurring End Date
                          setMaxDateOfEndRecurring(
                            addMonths(new Date(date), 1)
                          );
                        }}
                        maxDate={oneYearFuture}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select recurring start date"
                      />
                    </div>

                    {/* TO */}
                    <div className="flex items-center mr-5">
                      <div className="font-bold mr-2">To</div>
                      <DatePicker
                        className="rounded w-64 text-black"
                        style={{ width: "100px" }}
                        selected={recurringEndDate}
                        onChange={(date) => {
                          setRecurringEndDate(date);
                        }}
                        maxDate={maxDateOfEndRecurring}
                        minDate={recurringStartDate}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select recurring end date"
                      />
                    </div>
                  </div>
                  {/* SET RECURRING FOR DAY */}
                  <div className="flex items-center font-bold">
                    <div className="mr-2 my-2">Days</div>
                    <div className="flex flex-wrap items-center">
                      {/* ALL DAYS */}
                      <div
                        className="px-2 py-2 mr-2 my-2 rounded-lg cursor-pointer"
                        style={{
                          backgroundColor:
                            recurringDays.length == WEEK_DAYAS.length
                              ? "#0054ffc9"
                              : "#44546f",
                        }}
                        onClick={() => {
                          if (recurringDays.length == WEEK_DAYAS.length) {
                            setRecurringDays([]);
                          } else {
                            setRecurringDays(WEEK_DAYAS);
                          }
                        }}
                      >
                        ALL DAYS
                      </div>
                      {/* WEEK DAYS */}
                      {WEEK_DAYAS.map((day) => (
                        <div
                          className="px-2 py-2 mr-2 my-2 rounded-lg cursor-pointer"
                          style={{
                            backgroundColor: recurringDays.includes(day)
                              ? "#0054ffc9"
                              : "#44546f",
                          }}
                          onClick={() => {
                            if (recurringDays.includes(day)) {
                              setRecurringDays(
                                recurringDays.filter(
                                  (weekDay) => weekDay != day
                                )
                              );
                            } else {
                              setRecurringDays([...recurringDays, day]);
                            }
                          }}
                        >
                          {day}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Hour Price */}
        {/* Hour Price */}
        {eventsData?.findEvent &&
          eventsData?.findEvent?.isCreatedByAdmin == false && (
            <div className="mt-2 mb-3 flex items-center">
              <label className="mr-12 pr-1 font-bold">Hour Price</label>
              {baseLoading ? (
                <div className="ml-3 my-2 pt-1">
                  <ClipLoader
                    color={"#ffffff"}
                    loading={true}
                    css={override}
                    size={20}
                  />
                </div>
              ) : (
                <div className="flex items-center text-xl font-bold">
                  ${baseData?.findBaseDetails?.perHourPrice}
                  <div
                    className="text-xs ml-6"
                    style={{ color: "#c1e9ef99", alignSelf: "center" }}
                  >
                    <i>NOTE:</i> You can update per hour price from Event
                    Management
                  </div>
                </div>
              )}
            </div>
          )}

        {/* Event PROMO CODE */}
        {/* Event PROMO CODE */}
        <div className="my-3">
          <div style={{ display: "flex" }}>
            <label className="mr-4 pr-6 pt-2 font-bold">Promo Code</label>
            <div>
              <button
                type="button"
                onClick={addCoupon}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              >
                Add Coupon
              </button>
              <div
                className="text-xs mt-3 w-[600px]"
                style={{ color: "#c1e9ef99", alignSelf: "center" }}
              >
                <div>
                  <i>NOTE:</i> Example coupon{" "}
                  <b style={{ color: "yellow" }}>Morning200</b> (where 200
                  represents a $200 discount).
                </div>

                {coupons && coupons.length > 0 && (
                  <div
                    style={{
                      padding: "10px 10px",
                      border: "1px solid #7a787869",
                    }}
                    className="rounded-lg mt-3 mb-6"
                  >
                    {coupons.map((coupon, index) => (
                      <div>
                        <div
                          key={index}
                          className="coupon my-2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <label className="text-lg">Code </label>
                          <input
                            className="border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display w-32 mx-5"
                            onChange={(e) =>
                              handleChange(index, "code", e.target.value)
                            }
                            value={coupon.code}
                            type="text"
                            // style={{ width: "150px" }}
                            placeholder="Ex:  asdf10"
                          />

                          {/* Discount */}
                          {String(coupon.code).match(
                            /^[a-zA-Z][a-zA-Z0-9]*[0-9]$/
                          ) && (
                            <div>
                              {recurringDays.length > 0 ? (
                                <div className="text-lg">
                                  <b className="text-yellow-100 font-bold">
                                    {(
                                      (Number(coupon.code.match(/[0-9]+/)) /
                                        (amountToPay * totalRecurringEvents)) *
                                      100
                                    ).toFixed(2)}{" "}
                                    %
                                  </b>{" "}
                                  Discount
                                </div>
                              ) : (
                                <div className="text-lg">
                                  <b className="text-yellow-100 font-bold">
                                    {(
                                      (Number(coupon.code.match(/[0-9]+/)) /
                                        amountToPay) *
                                      100
                                    ).toFixed(2)}{" "}
                                    %
                                  </b>{" "}
                                  discount
                                </div>
                              )}
                            </div>
                          )}

                          {/* New price */}
                          {String(coupon.code).match(
                            /^[a-zA-Z][a-zA-Z0-9]*[0-9]$/
                          ) && (
                            <div>
                              {recurringDays.length > 0 ? (
                                <div className="flex items-center text-2xl font-bold">
                                  {amountToPay * totalRecurringEvents >
                                    Number(coupon.code.match(/[0-9]+/)) && (
                                    <div className="flex items-center">
                                      (
                                      <div className="line-through">
                                        ${amountToPay * totalRecurringEvents}
                                      </div>
                                      <div
                                        className="ml-3"
                                        style={{ color: "gold" }}
                                      >
                                        $
                                        {amountToPay * totalRecurringEvents -
                                          Number(coupon.code.match(/[0-9]+/))}
                                      </div>
                                      )
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="flex items-center text-2xl font-bold">
                                  {amountToPay >
                                    Number(coupon.code.match(/[0-9]+/)) && (
                                    <div className="flex items-center">
                                      (
                                      <div className="line-through">
                                        ${amountToPay}
                                      </div>
                                      <div
                                        className="ml-3"
                                        style={{ color: "gold" }}
                                      >
                                        $
                                        {amountToPay -
                                          Number(coupon.code.match(/[0-9]+/))}
                                      </div>
                                      )
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          <button onClick={() => removeCoupon(index)}>
                            <FontAwesomeIcon className="" icon={faClose} />
                          </button>
                        </div>
                        {coupon.code != "" && (
                          <div className="mb-4">
                            {String(coupon.code).match(
                              /^[a-zA-Z][a-zA-Z0-9]*[0-9]$/
                            ) ? (
                              <div style={{ color: "#06ff06" }}>
                                Valid coupon code!
                              </div>
                            ) : (
                              <div style={{ color: "red" }}>
                                Invalid coupon code!
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Already paid */}
        {/* Already paid */}
        {eventsData?.findEvent &&
          eventsData?.findEvent?.isCreatedByAdmin == false && (
            <div className="mt-0 flex items-center">
              <label className="mr-6 font-bold">Already Paid</label>
              {baseLoading ? (
                <div className="ml-3 my-2 pt-1">
                  <ClipLoader
                    color={"#ffffff"}
                    loading={true}
                    css={override}
                    size={20}
                  />
                </div>
              ) : (
                <div className="flex items-center">
                  <div className="px-3 py-2 bg-transparent rounded text-2xl text-yellow-200 font-bold">
                    ${eventsData?.findEvent?.paidAmount / 100}
                  </div>
                </div>
              )}
            </div>
          )}

        {/* Due Amount */}
        {/* Due Amount */}
        {eventsData?.findEvent &&
          eventsData?.findEvent?.isCreatedByAdmin == false && (
            <div className="mt-0 mb-3 flex items-center">
              <label className="mr-7 font-bold">Due Amount</label>
              {baseLoading ? (
                <div className="ml-3 my-2 pt-1">
                  <ClipLoader
                    color={"#ffffff"}
                    loading={true}
                    css={override}
                    size={20}
                  />
                </div>
              ) : (
                <div className="flex items-center">
                  <div className="px-3 py-2 bg-transparent rounded text-2xl text-yellow-200 font-bold">
                    {/* ${amountToPay} */}$
                    {amountToPay -
                      eventsData?.findEvent?.paidAmount / 100 -
                      discount >
                    0
                      ? amountToPay -
                        eventsData?.findEvent?.paidAmount / 100 -
                        discount
                      : 0}
                  </div>

                  {/* With discount */}
                  <div>( Discounted ${discount} )</div>
                </div>
              )}
            </div>
          )}

        {/* DISCOUNT FOR CLIENT EVENT */}
        {/* DISCOUNT FOR CLIENT EVENT */}
        {eventsData?.findEvent &&
          eventsData?.findEvent?.isCreatedByAdmin == false && (
            <div className="mt-1 mb-3">
              <div className="flex items-center">
                <label className="mr-2 pr-6 font-bold">Add Discount</label>
                <div
                  className="flex items-center"
                  style={{ color: "#c1e9ef99", alignSelf: "center" }}
                >
                  <input
                    className="w-32 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
                    onChange={(e) => setDiscount(e.target.value)}
                    value={discount}
                    type="number"
                  />
                  <div
                    className="ml-3 flex items-center"
                    style={{ color: "white" }}
                  >
                    ${" "}
                    <div
                      className="font-bold text-xs ml-6"
                      style={{ color: "#c1e9ef99", alignSelf: "center" }}
                    >
                      <i className="font-bold text-yellow-300">NOTE:</i> Actual
                      dollar amount
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Sports */}
        {/* Sports */}
        {(eventType === "Tournament" ||
          eventType === "League" ||
          eventType === "Contest") && (
          <div className="my-2 flex items-center">
            {" "}
            <label className="mr-16 pr-2 font-bold">
              Sports <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <Select
              className="w-64"
              value={selectedSportsForComponent}
              onChange={handleSelectSportsChange}
              options={sportsOption}
              isMulti
              styles={customStyles}
            />
          </div>
        )}

        {/* Game Type */}
        {/* Game Type */}
        {(eventType === "Tournament" || eventType === "League") && (
          <div className="my-2 flex items-center ">
            <label className="mr-10 font-bold">
              Game type <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <Select
              className="w-64"
              value={selectedGametypeForComponent}
              onChange={handleSelectGameTypeChange}
              options={gameTypesOption}
              isMulti={false}
              styles={customStyles}
            />
          </div>
        )}

        {/* Capacity */}
        {/* Capacity */}
        {eventType === "Special Events" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <label className="mr-12 pr-2 font-bold">
              Capacity <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <div style={{ display: "flex" }}>
              <div>{capacity}</div>
              <div
                className="ml-2 font-bold flex items-center"
                style={{ color: "white" }}
              >
                Person
                <div
                  className="text-xs ml-6"
                  style={{ color: "#c1e9ef99", alignSelf: "center" }}
                >
                  <i className="text-yellow-300">NOTE:</i> You can update
                  capacity from Base Management
                </div>
              </div>
            </div>
          </div>
        )}

        {/* BANNER IMAGE */}
        <div style={{ display: "flex" }} className="mt-5">
          <label className="mr-2 pr-2 font-bold">
            Banner Image <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <div>
            <input type="file" onChange={handleFileChange} />
            {image && (
              <img
                src={image}
                alt="Uploaded"
                style={{ borderRadius: "10px", maxHeight: "300px" }}
                className="mt-5"
              />
            )}
          </div>
        </div>

        {/* Event Location */}
        {/* Event Location */}
        <div className="my-3">
          <label className="mr-16 pr-1 font-bold">Location</label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
        </div>

        {/* Description */}
        <div className="mt-5">
          <label className="mr-10 pr-2 font-bold">
            Description <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
        </div>

        {/* DEWCRIPTION body */}
        <div className="mt-2 text-black">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            placeholder="Enter event description."
          />
        </div>

        {/* SUBMIT button */}
        {/* SUBMIT button */}
        {updateEventMutationLoading ? (
          <button
            className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold pt-3 pb-2 px-8 rounded mx-auto block"
            disabled={true}
          >
            <ClipLoader
              color={"#ffffff"}
              loading={true}
              css={override}
              size={20}
            />
          </button>
        ) : (
          <button
            className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
            onClick={() => {
              updateEvent();
            }}
          >
            Update
          </button>
        )}
      </div>
    </AdminPortal>
  );
}
export default UpdateEvent;
