import { gql } from "@apollo/client";

export default gql`
  query findAdminUnseenMessageCounts(
    $userId: String
    $baseSlug: String
    $eventId: String
  ) {
    findAdminUnseenMessageCounts(
      userId: $userId
      baseSlug: $baseSlug
      eventId: $eventId
    ) {
      additionalData
    }
  }
`;
