import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { formatDistance, formatDistanceToNow } from "date-fns";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import copy from "copy-to-clipboard";
import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  Constants,
} from "@videosdk.live/react-sdk";
import Hls from "hls.js";

import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import FIND_STREAM_DETAILS from "../../gql/query/base/findMatchStreamById.query.js";
import FIND_ALL_LIVE_STREAMS from "../../gql/query/base/findAllStreamsByFilter.query.js";
import { LOCAL_STORAGE_KEYS, RANDOM_AVATAR_URLS } from "../../utils/constant";

const ViewerView = () => {
  const { join, hlsState, hlsUrls } = useMeeting();
  const [hasJoined, setHasJoined] = useState(false);
  const [joinAttempts, setJoinAttempts] = useState(0);
  const [playbackError, setPlaybackError] = useState(null);
  const [playAttempts, setPlayAttempts] = useState(0);
  const playerRef = useRef(null);
  const maxJoinAttempts = 3;
  const maxPlayAttempts = 3;
  const joinRetryDelay = 2000;
  const playRetryDelay = 2000;

  const joinMeeting = useCallback(async () => {
    try {
      console.log(`Attempting to join meeting (Attempt ${joinAttempts + 1})`);
      await join();
      console.log("Successfully joined the meeting");
      setHasJoined(true);
    } catch (error) {
      console.error(
        `Error joining meeting (Attempt ${joinAttempts + 1}):`,
        error
      );
      if (joinAttempts < maxJoinAttempts - 1) {
        setJoinAttempts(joinAttempts + 1);
        setTimeout(joinMeeting, joinRetryDelay);
      } else {
        setPlaybackError(
          "Failed to join the meeting after multiple attempts. Please try refreshing the page."
        );
      }
    }
  }, [join, joinAttempts]);

  useEffect(() => {
    if (!hasJoined) {
      joinMeeting();
    }
  }, [hasJoined, joinMeeting]);

  const playVideo = useCallback(() => {
    if (playerRef.current) {
      playerRef.current
        .play()
        .then(() => {
          console.log("Video playback started successfully");
          setPlayAttempts(0);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
          if (playAttempts < maxPlayAttempts - 1) {
            console.log(
              `Retrying video playback (Attempt ${playAttempts + 2})`
            );
            setPlayAttempts(playAttempts + 1);
            setTimeout(playVideo, playRetryDelay);
          } else {
            setPlaybackError(
              "Failed to play the video after multiple attempts. Please try refreshing the page."
            );
          }
        });
    }
  }, [playAttempts]);

  useEffect(() => {
    console.log("HLS State:", hlsState);
    console.log("HLS URLs:", hlsUrls);

    if (hlsUrls?.playbackHlsUrl && hlsState === "HLS_PLAYABLE") {
      console.log("Attempting to play HLS stream");
      if (Hls.isSupported()) {
        const hls = new Hls({
          debug: true,
          enableWorker: true,
          lowLatencyMode: true,
          backBufferLength: 90,
        });

        hls.loadSource(hlsUrls.playbackHlsUrl);
        hls.attachMedia(playerRef.current);

        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          console.log("HLS: Media attached");
          playVideo();
        });

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log("HLS: Manifest parsed");
        });

        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error("HLS.js error:", event, data);
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                console.error("HLS: Fatal network error encountered");
                setPlaybackError(
                  "Network error occurred. Please check your connection."
                );
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.error("HLS: Fatal media error encountered");
                setPlaybackError(
                  "Media error occurred. Attempting to recover."
                );
                hls.recoverMediaError();
                break;
              default:
                console.error("HLS: Fatal error encountered");
                setPlaybackError(
                  "An error occurred with the video stream. Attempting to recover."
                );
                hls.destroy();
                setTimeout(() => {
                  setPlayAttempts(0);
                  playVideo();
                }, 2000);
                break;
            }
          }
        });

        return () => {
          hls.destroy();
        };
      } else if (
        playerRef.current.canPlayType("application/vnd.apple.mpegurl")
      ) {
        playerRef.current.src = hlsUrls.playbackHlsUrl;
        playerRef.current.addEventListener("loadedmetadata", () => {
          playVideo();
        });
      } else {
        console.error("HLS is not supported in this browser.");
        setPlaybackError(
          "Your browser doesn't support the required video format."
        );
      }
    } else {
      console.log("HLS is not playable yet or URLs are not available");
    }
  }, [hlsUrls, hlsState, playVideo]);

  function getHLSStateMessage(hlsState) {
    switch (hlsState) {
      case "HLS_STOPPED":
        return {
          message: "Stream is currently offline",
          subMessage:
            "The broadcaster hasn't started the stream yet. Please check back later.",
        };
      case "HLS_STARTING":
        return {
          message: "Stream is starting",
          subMessage:
            "We're getting things ready. This should only take a moment.",
        };
      case "HLS_STARTED":
        return {
          message: "Stream is processing",
          subMessage:
            "The stream is being prepared for viewing. It should be available shortly.",
        };
      case "HLS_PLAYABLE":
        return {
          message: "🟢 Stream is live",
          subMessage: "Enjoy the broadcast!",
        };
      case "HLS_STOPPING":
        return {
          message: "Stream is ending",
          subMessage:
            "The broadcaster is wrapping up. The stream will end soon.",
        };
      default:
        return {
          message: "Stream status unknown",
          subMessage:
            "We're having trouble determining the stream status. Please try refreshing the page.",
        };
    }
  }

  return (
    <div>
      <div className="relative flex flex-col bg-black p-2 rounded-lg mb-2">
        <span className="font-bold text-white">
          {getHLSStateMessage(hlsState).message}
        </span>
        <span className="text-white font-light">
          {getHLSStateMessage(hlsState).subMessage}
        </span>
      </div>
      {hlsState === "HLS_PLAYABLE" ? (
        <div>
          <video
            ref={playerRef}
            id="hlsPlayer"
            controls
            style={{ width: "100%", height: "100%" }}
            playsInline
            muted={false}
            onError={(e) => {
              console.error("Video error:", e.target.error);
              setPlaybackError(
                `Video error: ${e.target.error?.message || "Unknown error"}`
              );
              setTimeout(() => {
                setPlayAttempts(0);
                playVideo();
              }, 2000);
            }}
            onPlay={() => console.log("Video started playing")}
            onPause={() => console.log("Video paused")}
            onWaiting={() => console.log("Video is waiting for data")}
            onStalled={() => console.log("Video playback has stalled")}
          >
            Your browser does not support the video tag.
          </video>
          {playbackError && (
            <div className="text-red-500 mt-2">Error: {playbackError}</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

const StreamPlay = () => {
  const navigate = useNavigate();
  const { baseSlug, streamId } = useParams();
  const location = useLocation();
  const autoIsFullScreen = new URLSearchParams(location.search).get(
    "isFullScreen"
  );

  const [meetingId, setMeetingId] = useState(null);
  const [key, setKey] = useState(0);

  const authToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJkMmYyYTBkZS1iNzZjLTQzNDMtYTU0Ny01OGVhMzQ1YzAxMDUiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyNDMxNTAzMCwiZXhwIjoxNzU1ODUxMDMwfQ.Oizfp_tNiWM8r7qbgF1wRP96taOsXGCUwLwCJfIjKA8";

  const {
    data: findStreamDetails,
    loading: findStreamDetailsLoading,
    error: findStreamDetailsError,
  } = useQuery(FIND_STREAM_DETAILS, {
    variables: {
      matchStreamId: streamId,
    },
  });

  const {
    data: findAllStreams,
    loading: findAllStreamsLoading,
    error: findAllStreamsError,
  } = useQuery(FIND_ALL_LIVE_STREAMS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
    },
  });

  useEffect(() => {
    if (findStreamDetails?.findMatchStreamById?.channelName) {
      setMeetingId(findStreamDetails.findMatchStreamById.channelName);
      setKey((prevKey) => prevKey + 1);
    }
  }, [findStreamDetails, streamId]);

  const handleStreamChange = (newStreamId) => {
    navigate(
      `/${baseSlug}/stream/${newStreamId}?isFullScreen=false`
    );
  };

  const copyToClipboard = (url) => {
    copy(url);
    toast.success("Successfully copied link to share!");
  };

  if (findStreamDetailsLoading || findAllStreamsLoading) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );
  }

  if (findStreamDetailsError || findAllStreamsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    authToken &&
    meetingId && (
      <MeetingProvider
        key={key}
        config={{
          meetingId,
          micEnabled: false,
          webcamEnabled: false,
          name: "Viewer",
          mode: Constants.modes.VIEWER,
        }}
        token={authToken}
      >
        <MeetingConsumer>
          {() => (
            <CommonBackdrop rootName="Bases" pageName="Live Stream">
              <div>
                <div className="font-semibold my-auto mt-5">
                  <div className="flex justify-between flex-wrap">
                    <div className="w-full md:w-3/4">
                      <div className="md:mr-5">
                        <ViewerView />

                        <div className="flex justify-between mt-4">
                          <div className="flex items-center">
                            <img
                              src={
                                RANDOM_AVATAR_URLS[
                                  findStreamDetails.findMatchStreamById
                                    ?.userDetails?.avatar
                                ] || RANDOM_AVATAR_URLS["user4_asset4"]
                              }
                              alt="User Avatar"
                              className="w-10 h-10 rounded-full mr-3"
                            />
                            <div>
                              <div className="text-xl font-bold text-white">
                                {findStreamDetails.findMatchStreamById.caption}
                              </div>
                              {findStreamDetails.findMatchStreamById
                                ?.createdAt && (
                                <div className="text-sm text-gray-400">
                                  {formatDistance(
                                    new Date(),
                                    new Date(
                                      Number(
                                        findStreamDetails.findMatchStreamById
                                          .createdAt
                                      )
                                    )
                                  )}{" "}
                                  ago
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="flex items-center">
                            <EmailShareButton
                              url={window.location.href}
                              className="mr-2"
                            >
                              <EmailIcon size={32} round />
                            </EmailShareButton>
                            <WhatsappShareButton
                              url={window.location.href}
                              className="mr-2"
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <TelegramShareButton
                              url={window.location.href}
                              className="mr-2"
                            >
                              <TelegramIcon size={32} round />
                            </TelegramShareButton>
                            <FacebookShareButton
                              url={window.location.href}
                              className="mr-2"
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                              url={window.location.href}
                              className="mr-2"
                            >
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <button
                              onClick={() =>
                                copyToClipboard(window.location.href)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCopy}
                                className="text-white bg-gray-700 rounded-full p-2"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/4">
                      <h2 className="text-xl font-bold mb-4">More Streams</h2>
                      {findAllStreams.findAllStreamsByFilter.map((stream) => (
                        <div
                          key={stream._id}
                          className={`mb-4 rounded-lg overflow-hidden cursor-pointer ${
                            streamId === stream._id
                              ? "border-2 border-yellow-500"
                              : ""
                          }`}
                          onClick={() => handleStreamChange(stream._id)}
                        >
                          <div className="relative">
                            <img
                              src="https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                              alt="Stream Thumbnail"
                              className="w-full h-32 object-cover"
                            />
                            <FontAwesomeIcon
                              icon={faCirclePlay}
                              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-yellow-500 text-4xl"
                            />
                          </div>
                          <div className="bg-gray-800 p-2">
                            <div className="flex items-center">
                              <img
                                src={
                                  RANDOM_AVATAR_URLS[
                                    stream.userDetails?.avatar
                                  ] || RANDOM_AVATAR_URLS["user4_asset4"]
                                }
                                alt="User Avatar"
                                className="w-8 h-8 rounded-full mr-2"
                              />
                              <div>
                                <div className="text-sm font-semibold text-white truncate">
                                  {stream.caption}
                                </div>
                                <div className="text-xs text-gray-400">
                                  {formatDistanceToNow(
                                    Number(stream.createdAt)
                                  )}{" "}
                                  ago
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </CommonBackdrop>
          )}
        </MeetingConsumer>
      </MeetingProvider>
    )
  );
};

export default StreamPlay;
