import React, { useState, useContext, useEffect } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format } from "date-fns";
import differenceInYears from "date-fns/differenceInYears";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
import { debounce } from "lodash";
import iconFinder from "../../utils/iconFinder";

// import "react-calendar/dist/Calendar.css";

// Query
import FIND_SUBSCRIBE_KIDS from "../../gql/query/base/findMyAllKidsToSubscribeBase.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetailsForBaseDashboard.query";

// MUTATION
import CHECK_COUPON_CODE_VALIDITY from "../../gql/mutation/nft/checkCouponCodeValidity.mutations";
import INITIATE_STRIPE_SESSION from "../../gql/mutation/base/initiateStripeSession.mutations";
import INITIATE_STRIPE_BILLING_PORTAL_SESSION from "../../gql/mutation/base/initiateStripeBillingPortalSession.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  RANDOM_AVATAR_URLS,
  SUPER_BASE_COUPON_CODE_INFO,
  PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
  USER_ROLES,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";

const ManageKids = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);

  // Modal STATE
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventDetails, setSelectedScheDetail] = useState(null);
  const [selectedKidIds, setSelectedKidIds] = useState([]);

  // Coupon Code State
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeCheckResponse, setCouponCodeCheckResponse] = useState("");
  const [totalDiscountInDollar, setTotalDiscountInDollar] = useState(0);

  // Query
  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_SUBSCRIBE_KIDS, {
    variables: {
      parentId: userId,
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [
    checkCouponCodeValidity,
    { loading: loadingCheckCouponValidity, error: errorCheckCouponValidity },
  ] = useMutation(CHECK_COUPON_CODE_VALIDITY);

  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SESSION);

  const [
    initiateStripeBillingPortalSessionMutation,
    {
      loading: initiateStripeBillingPortalSessionMutationLoading,
      error: initiateStripeBillingPortalSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_BILLING_PORTAL_SESSION);

  // Modal functions
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);

    // Make coupon code info as empty for safety
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
    setSelectedKidIds([]);
  }

  // For Monthy subscription
  async function initiateStripeUiForMonthlyKidSubscription() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          couponCode,
          kidIds: selectedKidIds, // Only one kid will be sent into backend by this array "selectedKidIds"
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  // Check CouponCode Validity
  const checkCouponValidity = debounce(async (couponCode, paymentFor) => {
    // Set coupon code
    setCouponCode(couponCode);
    // Call to backend for checking this coupon code
    const { data } = await checkCouponCodeValidity({
      variables: {
        couponCode,
      },
    });
    // If coupon code is correct then set discount price
    if (data.checkCouponCodeValidity.errorMessage == null) {
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.successMessage);
      // Set Month Subscription discount
      setTotalDiscountInDollar(
        SUPER_BASE_COUPON_CODE_INFO.MONTHLY_SUBSCRIPTION_DISCOUNT_IN_DOLLAR
      );
    } else if (data.checkCouponCodeValidity.errorMessage) {
      setTotalDiscountInDollar(0);
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.errorMessage);
    }
  }, 600);

  useEffect(() => {
    function setEventIdFromSlug() {
      findMyKidsRefetch();
    }
    setEventIdFromSlug();
  }, []);

  // This function will generate a Billing Portal for management of Subscriptions
  async function initiateStripeBillingPortalForSubscriptionManagment() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeBillingPortalSessionMutation({
        variables: {
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        },
      });
      if (data.initiateStripeBillingPortalSession.errorMessage == null) {
        window.location.href =
          data.initiateStripeBillingPortalSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  if (findMyKidsLoading || baseLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (findMyKidsError || baseError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  let baseDetails = baseData?.findBaseDetails;

  return (
    <CommonBackdrop rootName='Bases' pageName='Manage Membership'>
      <div className="overflow-x-auto">

        {/* Title */}
        <div
          className="w-full flex items-center flex-row mt-5"
          style={{ justifyContent: "space-between" }}
        >
          <h1 style={{ fontWeight: "700" }}>
            {" "}
            My Kids -{" "}
            <b style={{ color: "yellow" }}>
              {findMyKids.findMyAllKidsToSubscribeBase.length}
            </b>{" "}
            found{" "}
          </h1>

          {/* Add Member button */}
          <button
            className="text-sm py-1 px-5 rounded-full max-h-7"
            style={{
              backgroundColor: "#353F55",
              color: "#FFFFFF",
            }}
            onClick={() => {
              navigate("/add-kid");
            }}
          >
            <FontAwesomeIcon icon={faUser} />{" "}
            <b className="ml-2"> Add Members</b>
          </button>
        </div>

        {findMyKids &&
        findMyKids.findMyAllKidsToSubscribeBase &&
        findMyKids.findMyAllKidsToSubscribeBase.length > 0 ? (
          <div>
            {findMyKids.findMyAllKidsToSubscribeBase.map((kid, index) => {
              return (
                <div style={{ height: "100%" }}>
                  {/* KIDS */}
                  <div
                    className=" mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                    key={index}
                    style={{
                      borderLeft: "5px solid yellow",
                      justifyContent: "space-between",
                      height: "100%",
                      backgroundColor: "#454f6680",
                    }}
                  >
                    {/* KID AVATAR + Name + Check button */}
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                      }}
                      className="py-2"
                    >
                      {/* KID AVATAR */}
                      <div
                        style={{
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={iconFinder(kid.avatar)}
                          onClick={() => {
                            navigate(
                              `${
                                localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
                                  ? `/kid-details/${kid.player._id}`
                                  : "/login"
                              }`
                            );
                          }}
                          alt="Basement Sports"
                          className="max-h-17 cursor-pointer"
                          style={{
                            color: "white",
                            width: "100px",

                            paddingLeft: "15px",
                            paddingRight: "15px",
                          }}
                        />
                      </div>

                      {/* KID INFO SECTION */}
                      <div
                        className="ml-5"
                        style={{
                          paddingRight: "60px",
                        }}
                      >
                        {/* kid Name */}
                        <div>{kid.name}</div>
                        {/* kid age */}
                        <div style={{ color: "#ccd0e0" }}>
                          {kid.age ? (
                            <div>{kid.age} y/o</div>
                          ) : kid.dateOfBirth ? (
                            <div>
                              {differenceInYears(
                                new Date(),
                                new Date(kid.dateOfBirth)
                              )}{" "}
                              y/o
                            </div>
                          ) : null}
                        </div>
                        {/* kid gender */}
                        <div>
                          <b style={{ color: "#ccd0e0" }}>
                            {kid.gender == "female" ? "Girl" : "Boy"}
                          </b>
                        </div>
                      </div>

                      {/* {console.log("++++: ", kid.createdAt)} */}

                      {/* KID DETAILS + KID GAME INFOS */}
                      <div
                        style={{
                          color: "#ccd0e0",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                        className="pl-6 text-sm pt-1 pb-3"
                      >
                        {/* <div>
                          Joined{" "}
                          {formatDistanceToNow(new Date(kid.createdAt), {
                            addSuffix: true,
                          })}{" "}
                          ago.
                        </div> */}
                      </div>
                    </div>

                    {/* KID premium status + Base symbol */}
                    <div className="px-5">
                      <div
                        onClick={() => {
                          navigate(
                            `${
                              localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
                                ? `/kid-details/${kid.player._id}`
                                : "/login"
                            }`
                          );
                        }}
                        className="w-[210px] text-center h-[40px] my-2 cursor-pointer text-sm py-2.5 px-5 rounded-full"
                        style={{
                          backgroundColor: "#00000033",
                          color: "#FFFFFF",
                        }}
                      >
                        DETAILS
                      </div>

                      {kid.paymentDetails?.paymentFor ===
                      PAYMENT_FOR.PURCHASE_FULL_MONTH_EVENT_OF_BASE ? (
                        <>
                          <div
                            className="w-[210px] text-center  h-[40px] my-2 text-sm py-2.5 px-5 rounded-full font-bold"
                            style={{
                              backgroundColor: "#00000033",
                              color: "yellow",
                            }}
                          >
                            PREMIUM MEMBER
                          </div>

                          <div
                            onClick={() => {
                              initiateStripeBillingPortalForSubscriptionManagment();
                            }}
                            className="w-[210px] text-center  h-[40px] my-2 cursor-pointer text-sm py-2.5 px-5 rounded-full"
                            style={{
                              backgroundColor: "#00000033",
                              color: "#05d7ff",
                            }}
                          >
                            MANAGE SUBSCRIPTION
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={() => {
                            setSelectedKidIds([kid._id]);
                            // Open Modal
                            openModal();
                          }}
                          className="w-[210px] text-center  h-[40px] my-2 cursor-pointer text-sm py-2.5 px-5 rounded-full"
                          style={{
                            backgroundColor: "#00000033",
                            color: "#FFFFFF",
                          }}
                        >
                          BUY SUBSCRIPTION
                        </div>
                      )}

                      {/* <div
                        className="text-sm py-2 px-5 rounded-full "
                        style={{
                          backgroundColor: "#00000033",
                          color: "#FFFFFF",
                        }}
                      >
                        BASE MEMBER
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>No Kids added yet! Please add kid first!</div>
        )}
      </div>

      {/* MODAL MODAL */}
      {/* MODAL MODAL */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Manage Kid Membership{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* COUPON CODE ENTRY */}
        <div>
          <div className="mt-2">
            <div className="mb-4">
              <label className="block font-bold mb-2" for="cvv">
                Coupon Code (Get discount)
              </label>
              <input
                onChange={(e) =>
                  checkCouponValidity(
                    e.target.value,
                    PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE
                  )
                }
                className="form-control w-full bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-full"
                type="text"
                placeholder="Coupon Code"
              />

              {couponCode && (
                <p
                  className={`text-xl font-bold text-center my-3 ${
                    couponCodeCheckResponse === "Coupon found"
                      ? "text-green-400"
                      : "text-red-400"
                  }`}
                >
                  {couponCodeCheckResponse}
                </p>
              )}
            </div>

            {/* Amount to pay */}
            <div
              className="text-center text-yellow-300 text-xl mb-4 mt-1"
              style={{ fontWeight: "700" }}
            >
              Total amount to pay: $
              {(baseDetails?.membershipDetails?.price
                ? baseDetails.membershipDetails.price // if monthly membership price was set from admin panel. Otherwise use default price.
                : EVENT_PAY.PER_MONTH_PAY) - totalDiscountInDollar}{" "}
              {/* Discount */}
              {totalDiscountInDollar > 0 && (
                <b
                  style={{ color: "white" }}
                  className="text-center text-sm mb-4"
                >
                  (${totalDiscountInDollar} discount)
                </b>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
              onClick={() => {
                // initiateStripeCheckoutUi(selectedEventId);
                initiateStripeUiForMonthlyKidSubscription();
              }}
            >
              {!initiateStripeSessionMutationLoading
                ? "ENROLL KID"
                : "LOADING..."}
            </button>
          </div>
        </div>

        {/* FINAL SUBMIT BUTTON for payment/ Free for Join */}
      </Modal>
    </CommonBackdrop>
  );
};

export default ManageKids;
