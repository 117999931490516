import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import AdminPortal from "./AdminPortal";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  CLIENT_CREATED_EVENT_PAYMENT_STATUSES,
  CONTACT_FORM_URL,
  EVENTS_TYPE,
  LOCAL_STORAGE_KEYS,
  PAYMENT_METHOD,
} from "../../utils/constant";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import { format, formatISO, setHours, setMinutes } from "date-fns";
import { Link, useNavigate } from "react-router-dom";

// Query
import FIND_EVENTS_WITH_UNSEEN_MESSAGES from "../../gql/query/base/findAdminEventsWithUnseenMessages.query";

// MUTATION
import UPDATE_BASE from "../../gql/mutation/base/updateBaseByAdmin.mutations";

function NewMessages() {
  const navigate = useNavigate();
  let baseId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // Query
  const {
    data: findEvents,
    loading: findEventsLoading,
    error: findEventsError,
    refetch: findEventsRefetch,
  } = useQuery(FIND_EVENTS_WITH_UNSEEN_MESSAGES, {
    variables: {
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    pollInterval: 1000,
  });

  // Mutations
  const [updateBaseMutation, { error: updateBaseError }] =
    useMutation(UPDATE_BASE);

  // Update Image State from Database data
  useEffect(() => {
    function refetchEvents() {
      findEventsRefetch();
    }
    refetchEvents();
  }, [findEvents]);

  return (
    <AdminPortal>
      <div className="max-w-6xl mx-auto bg-slate-700 mt-10 py-3 px-8 mb-8 rounded-md">
        <h1 className="text-center font-bold text-3xl mt-2 mb-4">
          Events (New Messages)
        </h1>

        {findEventsLoading ? (
          <div className="flex items-center justify-center mt-10 mb-16">
            <ClipLoader
              color={"#ffffff"}
              loading={true}
              css={override}
              size={50}
            />
          </div>
        ) : (
          <div>
            {findEvents?.findAdminEventsWithUnseenMessages?.map((event) => {
              return (
                <div>
                  {/* DATE + EVENT info + BOOK session button */}
                  <div
                    className="mb-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:ml-5 py-2"
                    style={{
                      backgroundColor: "#86939140",
                      borderLeft: "5px solid yellow",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* DATE & Event INFO */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="py-2"
                    >
                      {/* DATE section */}
                      <div
                        style={{
                          color: "white",
                          width: "80px",
                          borderRight: "1px dotted #ffffff45",
                          paddingLeft: "15px",
                        }}
                        className="text-2xl"
                      >
                        {format(new Date(event.dateTime), "EEE MMM dd")}
                        <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                          ( {format(new Date(event.dateTime), "yyyy")} )
                        </div>
                      </div>

                      {/* Schdule INFO section */}
                      <div className="font-semibold ml-5">
                        {/* TIME */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                            <div className="w-1 h-1 rounded-full bg-black"></div>
                          </div>
                          {/* Time value */}
                          <div
                            style={{ color: "#8F9BB3" }}
                            className="text-sm ml-2"
                          >
                            {event.eventType}
                          </div>
                        </div>

                        {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
                          <>
                            {event.caption ? (
                              <div className="text-xl mt-1 mb-2">
                                {event.caption}
                              </div>
                            ) : (
                              <div className="text-xl mt-1 mb-2">
                                Special Event
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {/* CAPTION */}
                            <div className="text-sm">
                              Event Type:{" "}
                              <b style={{ color: "#ccd0e0" }}>
                                {event.eventType}
                              </b>
                            </div>

                            {/* CAPTION */}
                            {event.caption && (
                              <div className="text-sm">
                                Title:{" "}
                                <b style={{ color: "#ccd0e0" }}>
                                  {event.caption}
                                </b>
                              </div>
                            )}
                          </>
                        )}

                        {/* CAPTION */}
                        {event.unseenMessageCountsForAdmin > 0 && (
                          <div
                            className="text-lg bg-blue-900 px-5 py-3 rounded-lg flex items-center"
                            style={{ color: "white" }}
                          >
                            Messages from Client{" "}
                            <div
                              className="ml-3 text-lg w-[35px] h-[35px] rounded-3xl flex items-center"
                              style={{
                                color: "white",
                                backgroundColor: "red",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              {event.unseenMessageCountsForAdmin}
                            </div>
                          </div>
                        )}

                        {/* SPORTS */}
                        {event.sports && event.sports?.length > 0 && (
                          <div className="text-sm">
                            Sports:{" "}
                            <b style={{ color: "#ccd0e0" }}>
                              {event.sports.join(", ")}
                            </b>
                          </div>
                        )}

                        {/* GAME TYPE */}
                        {event.gameType && (
                          <div className="text-sm">
                            Game Type:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.gameType}</b>
                          </div>
                        )}

                        {/* PRICE */}
                        {event.eventPrice && (
                          <div
                            className="text-md mt-2"
                            style={{ color: "#00fff5" }}
                          >
                            Price: ${event.eventPrice}
                          </div>
                        )}

                        {!event.isCreatedByAdmin && (
                          <div className="text-sm mt-2">
                            <div>
                              <b style={{ color: "cyan" }}>Created by:</b>{" "}
                              Client
                            </div>
                            {/* Payment Method */}
                            <div className="mt-1">
                              <b style={{ color: "cyan" }}>Payment Method:</b>{" "}
                              {event.paymentMethod ==
                              PAYMENT_METHOD.DEPOSIT_BY_STRIPE
                                ? "Stripe"
                                : event.paymentMethod}
                            </div>
                            {/* Payment STATUS */}
                            <div
                              className="mt-1 font-normal"
                              style={{ color: "#ff9393" }}
                            >
                              <b style={{ color: "cyan" }}>Payment Status: </b>{" "}
                              {event.paymentStatus}
                            </div>
                            {/* Paid Amount */}
                            <div
                              className="mt-1 font-normal"
                              style={{ color: "gold" }}
                            >
                              <b style={{ color: "cyan" }}>Paid amount: </b> $
                              {event.paidAmount / 100}
                            </div>
                            {/* DUE amount */}
                            {(event.paymentStatus ==
                              CLIENT_CREATED_EVENT_PAYMENT_STATUSES.DEPOSITED ||
                              event.paymentStatus ==
                                CLIENT_CREATED_EVENT_PAYMENT_STATUSES.INCOMPLETE) && (
                              <div
                                className="mr-5 font-normal"
                                style={{ color: "#ff9393" }}
                              >
                                <b style={{ color: "cyan" }}>Due: </b> $
                                {(event.amountToPay - event.paidAmount) / 100}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="mr-5"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {/* Event update button */}
                      <button
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded my-1 mr-2"
                        onClick={() => {
                          navigate(`${`/admin/chat/${event._id}`}`);
                        }}
                      >
                        Details
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* NO events messages */}
        {!findEventsLoading &&
          findEvents?.findAdminEventsWithUnseenMessages &&
          findEvents?.findAdminEventsWithUnseenMessages?.length <= 0 && (
            <div className="flex justify-center text-3xl text-cyan-400 mt-16 mb-16">
              No new messages found!
            </div>
          )}
      </div>
    </AdminPortal>
  );
}
export default NewMessages;
