import { useMutation, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { LOCAL_STORAGE_KEYS } from "../utils/constant";
import { AvatarIcon } from "../component/common/AvatarIcons";
import iconFinder from "../utils/iconFinder";
import FIND_CHECKED_IN_USERS from "../gql/query/findCheckedInUsers.query";
import CHECK_USER_IN from "../gql/mutation/checkUserIn.mutation";
import CHECK_USER_OUT from "../gql/mutation/checkUserOut.mutation";
import FIND_BASE_DETAILS from "../gql/query/base/findBaseDetails.query";
import FIND_USER_DETAILS from "../gql/query/profile/me.query";
import FIND_KIDS_OF_THE_BASE from "../gql/query/base/findAllKidMembersOfTheBase.query";
import { SAVE_ADMIN_PASSCODE } from "../gql/mutation/base/saveAdminPasscodeForFrontDeskMode.mutation";
import { FIND_ADMIN_PASSCODE } from "../gql/query/base/findAdminPasscodeForBase.query";

// Constants for passcode modes
const PASSCODE_MODES = {
  CHECK_IN: "check-in",
  SETTING_PASSCODE: "setting-passcode",
  RESETTING_PASSCODE: "resetting-passcode",
  ADMIN_EXIT: "admin-exit",
};

// Constants for passcode actions
const PASSCODE_ACTIONS = {
  EXIT: "exit",
  CAN_RESET: "can-reset",
  PASSWORD_SET: "password-set",
  INVALID_PASSWORD: "invalid-password",
};

// Custom hook to manage passcode logic
const usePasscodeManager = () => {
  const INITIAL_STATE = PASSCODE_MODES.CHECK_IN;
  const [passcode, setPasscode] = useState(""); // Stores the admin passcode
  const [isPasscodeSet, setIsPasscodeSet] = useState(false); // Indicates if a passcode has been set
  const [passcodeMode, setPasscodeMode] = useState(INITIAL_STATE); // Determines the current passcode mode
  const [passcodeInput, setPasscodeInput] = useState(""); // Tracks user input for passcodes

  const { data, refetch } = useQuery(FIND_ADMIN_PASSCODE, {
    variables: { baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID },
  });

  const [saveAdminPasscodeInDb] = useMutation(SAVE_ADMIN_PASSCODE);

  // Initialize passcode from db or set the mode to "setting-passcode"
  useEffect(() => {    
    const isPasscodeSet = data?.findAdminPasscodeForBase?.isSet;
    const storedPasscode = data?.findAdminPasscodeForBase?.passcode;

    if (isPasscodeSet && storedPasscode) {
      setPasscode(storedPasscode);
      setIsPasscodeSet(true);
    } else {
      setPasscodeMode(PASSCODE_MODES.SETTING_PASSCODE);
    }
    refetch();
  }, [data]);

  async function setNewPasscode(newPasscode) {
    // ensuring it's at least 4 characters long
    if (newPasscode.length >= 4) {
      await saveAdminPasscodeInDb({
        variables: {
          baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
          passcode: newPasscode,
        },
      });
      setPasscode(newPasscode);
      setIsPasscodeSet(true);
      setPasscodeMode(PASSCODE_MODES.CHECK_IN);
      setPasscodeInput("");
      return true;
    }
    return false;
  }

  const clearPasscodeInput = () => setPasscodeInput(""); // Clear the passcode input field

  const handlePasscodeInput = (input) => setPasscodeInput(input); // Update the passcode input field

  const verifyPasscode = () => passcodeInput === passcode; // Verify the entered passcode matches the stored passcode

  const startPasscodeReset = () => {
    setPasscodeMode(PASSCODE_MODES.RESETTING_PASSCODE);
    setPasscodeInput("");
  };

  const startAdminPasscodeEntry = () => {
    setPasscodeMode(PASSCODE_MODES.ADMIN_EXIT);
    setPasscodeInput("");
  };

  // Cancel any ongoing passcode action
  const resetPasscodeInput = () => {
    setPasscodeInput("");
  };

  const resetPasscodeState = () => {
    setPasscodeMode(INITIAL_STATE);
    resetPasscodeInput();
  };

  // Determine the display text based on the current passcode mode
  const getPasscodeModeDisplayText = () => {
    switch (passcodeMode) {
      case PASSCODE_MODES.CHECK_IN:
        return "Enter Check-In Code";
      case PASSCODE_MODES.SETTING_PASSCODE:
        return "Set Admin Passcode";
      case PASSCODE_MODES.RESETTING_PASSCODE:
        return "Enter Current Passcode";
      case PASSCODE_MODES.ADMIN_EXIT:
        return "Enter Admin Passcode";
      default:
        return "Check-In Mode";
    }
  };

  // Handle passcode submission based on the current mode
  const handlePasscodeSubmission = () => {
    let success = false;
    let action = PASSCODE_ACTIONS.INVALID_PASSWORD;

    switch (passcodeMode) {
      case PASSCODE_MODES.ADMIN_EXIT:
        if (verifyPasscode()) {
          success = true;
          action = PASSCODE_ACTIONS.EXIT; // Exit to admin mode
        }
        break;
      case PASSCODE_MODES.SETTING_PASSCODE:
        if (setNewPasscode(passcodeInput)) {
          success = true;
          action = PASSCODE_ACTIONS.PASSWORD_SET; // Successfully set a new passcode
        }
        break;
      case PASSCODE_MODES.RESETTING_PASSCODE:
        if (verifyPasscode()) {
          setPasscodeMode(PASSCODE_MODES.SETTING_PASSCODE);
          setPasscodeInput("");
          success = true;
          action = PASSCODE_ACTIONS.CAN_RESET; // Allow passcode reset
        }
        break;
      default:
        break;
    }

    return { success, action };
  };

  return {
    passcodeMode,
    passcodeInput,
    isPasscodeSet,
    getPasscodeModeDisplayText,
    clearPasscodeInput,
    handlePasscodeInput,
    handlePasscodeSubmission,
    startPasscodeReset,
    startAdminPasscodeEntry,
    resetPasscodeInput,
    resetPasscodeState,
  };
};

const Button = ({
  onClick,
  disabled,
  label,
  className,
  bgColor = "bg-blue-500",
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`min-w-fit whitespace-nowrap px-3 py-2 lg:py-5 lg:px-8 text-lg lg:text-2xl ${
      disabled && "cursor-not-allowed"
    } text-white rounded hover:opacity-90 transition-colors ${bgColor} ${className}`}
  >
    {label}
  </button>
);

const Display = ({ input }) => (
  <div className="w-72 h-12 bg-white text-gray-800 rounded flex items-center justify-end px-4 text-2xl mb-4">
    {input}
  </div>
);

const AdminControls = ({
  isAdminOpen,
  preventExit,
  onExit,
  onCancel,
  onForceExit,
  onResetPasscode,
}) => (
  <div className="absolute top-5 left-5 flex gap-2">
    {!isAdminOpen ? (
      <Button label="Admin" bgColor="bg-surface-secondary" onClick={onCancel} />
    ) : (
      <div className="w-full overflow-x-auto">
        {preventExit ? (
          <Button
            onClick={onForceExit}
            label="Exit without setting admin passcode"
            bgColor={"bg-red-500"}
          />
        ) : (
          <>
            <Button
              onClick={onExit}
              disabled={preventExit}
              label="Exit front desk"
              bgColor={preventExit ? "" : "bg-red-500"}
            />
            <Button
              onClick={onResetPasscode}
              disabled={preventExit}
              label="Reset passcode"
              bgColor="bg-orange-500"
            />
          </>
        )}

        <Button onClick={onCancel} label="Cancel" bgColor="bg-yellow-500" />
      </div>
    )}
  </div>
);

const NumberPad = ({ onNumberClick, onClear, onOk }) => (
  <div className="grid grid-cols-3 gap-4">
    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
      <Button
        key={number}
        onClick={() => onNumberClick(number)}
        label={number}
      />
    ))}
    <Button onClick={onClear} label="Clear" className="text-xl" />
    <Button onClick={() => onNumberClick(0)} label="0" />
    <Button onClick={onOk} label="OK" className="text-xl" />
  </div>
);

// Main component handling front desk mode functionality
export default function FrontDeskMode({ setIsFrontDesk }) {
  const [activeTab, setActiveTab] = useState("all");
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [checkInInput, setCheckInInput] = useState("");
  const pm = usePasscodeManager(); // Keep the object returned from usePasscodeManager as "pm"

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // #region logic
  // Handle number pad click
  const handleNumberClick = (number) => {
    if (pm.passcodeMode !== PASSCODE_MODES.CHECK_IN) {
      pm.handlePasscodeInput(pm.passcodeInput + number);
    } else {
      setCheckInInput((prev) => prev + number);
    }
  };

  const handleClear = () => {
    if (pm.passcodeMode !== PASSCODE_MODES.CHECK_IN) {
      pm.handlePasscodeInput(pm.passcodeInput.slice(0, -1));
    } else {
      setCheckInInput((prev) => prev.slice(0, -1));
    }
  };

  const handleCheckIn = () => {
    alert(`Code entered: ${checkInInput}`);
    setCheckInInput("");
  };

  const handleOk = () => {
    if (pm.passcodeMode === PASSCODE_MODES.CHECK_IN) {
      handleCheckIn();
      return;
    }

    const result = pm.handlePasscodeSubmission();
    if (result.success) {
      switch (result.action) {
        case PASSCODE_ACTIONS.EXIT:
          setIsFrontDesk(false); // Exit the front desk mode
          break;
        case PASSCODE_ACTIONS.PASSWORD_SET:
          alert("Admin passcode has been set");
          setIsAdminOpen(false);
          break;
        case PASSCODE_ACTIONS.CAN_RESET:
          alert("Enter new admin passcode");
          break;

        default:
          return;
      }
    } else {
      if (pm.passcodeMode === PASSCODE_MODES.SETTING_PASSCODE) {
        alert("Passcode must be at least 4 digits");
      } else {
        alert("Incorrect passcode");
      }
    }

    pm.clearPasscodeInput();
    setCheckInInput("");
  };

  const handleExitAttempt = () => pm.startAdminPasscodeEntry();

  const handleForceExit = () => setIsFrontDesk(false);

  const toggleAdminControls = () => {
    setIsAdminOpen(!isAdminOpen);
    if (pm.isPasscodeSet) {
      pm.resetPasscodeState();
    }
    pm.resetPasscodeInput();
  };

  const currentInput =
    pm.passcodeMode === PASSCODE_MODES.CHECK_IN
      ? checkInInput
      : pm.passcodeInput;

  // #endregion

  const { data: allBaseMembers, refetch: refetchBaseDetails } = useQuery(
    FIND_BASE_DETAILS,
    {
      variables: { baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID },
    }
  );

  const { data: allKids } = useQuery(FIND_KIDS_OF_THE_BASE, {
    variables: {
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
    },
  });

  const { data: checkedInUsers, refetch: refetchCheckInUsers } = useQuery(
    FIND_CHECKED_IN_USERS,
    {
      variables: {
        baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
      },
    }
  );

  const checkedInUserIds = checkedInUsers?.findCheckedInUsers?.map(
    (user) => user?._id
  );

  const [checkUserInMutation] = useMutation(CHECK_USER_IN);
  const [checkUserOutMutation] = useMutation(CHECK_USER_OUT);

  async function handleCheckInOrCheckOut(userId) {
    const shouldCheckUserOut = checkedInUserIds?.includes(userId) || false;
    const mutationMethod = shouldCheckUserOut
      ? checkUserOutMutation
      : checkUserInMutation;

    await mutationMethod({
      variables: {
        baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
        userId,
      },
    });

    refetchBaseDetails();
    refetchCheckInUsers();
  }

  function separateKidsByCheckInStatus(allKidIds, checkedInUserIds) {
    let checkedInKids = [];
    let notCheckedInKids = [];

    if (Array.isArray(allKidIds) && Array.isArray(checkedInUserIds)) {
      allKidIds.forEach((kidId) => {
        if (checkedInUserIds.includes(kidId)) checkedInKids.push(kidId);
        else notCheckedInKids.push(kidId);
      });
    }

    return [checkedInKids, notCheckedInKids];
  }

  const allKidIds = allKids?.findAllKidMembersOfTheBase?.map((kid) => kid?._id);
  const allCheckedInUserIds = checkedInUsers?.findCheckedInUsers?.map(
    (user) => user?._id
  );

  const [checkedInKids, notCheckedInKids] = separateKidsByCheckInStatus(
    allKidIds,
    allCheckedInUserIds
  );

  const bgImage =
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1694410382/bSportsbackground_oezmdo.png";
  const style = {
    backgroundImage: `url(${bgImage})`,
  };

  return (
    <div style={style} className="h-screen bg-cover">
      <div className="min-w-[300px] h-full overflow-y-auto flex flex-col items-center justify-center text-white">
        <AdminControls
          onForceExit={handleForceExit}
          isAdminOpen={isAdminOpen}
          preventExit={!pm.isPasscodeSet}
          onExit={handleExitAttempt}
          onCancel={toggleAdminControls}
          onResetPasscode={pm.startPasscodeReset}
        />

        {isAdminOpen && pm.passcodeMode !== PASSCODE_MODES.CHECK_IN ? (
          <>
            {" "}
            {/* only display this when we want press admin and then exit */}
            <div className="text-2xl mb-4">
              {pm.getPasscodeModeDisplayText()}
            </div>
            <Display input={currentInput} />
            <NumberPad
              onNumberClick={handleNumberClick}
              onClear={handleClear}
              onOk={handleOk}
            />{" "}
          </>
        ) : (
          <div className="mt-[270px] px-10 md:px-0 md:mt-20 w-full md:w-[500px] mx-auto">
            <div className="flex mb-4">
              <button
                className={`px-4 py-2 ${
                  activeTab === "all" ? "bg-blue" : "bg-gray-600"
                }`}
                onClick={() => handleTabClick("all")}
              >
                All Members
              </button>
              <button
                className={`px-4 py-2 ${
                  activeTab === "checkedIn" ? "bg-blue" : "bg-gray-600"
                }`}
                onClick={() => handleTabClick("checkedIn")}
              >
                Checked-In Members
              </button>
            </div>

            {/* Tab Content */}
            <div className="flex-1 w-full h-screen md:h-[600px] overflow-y-auto">
              {activeTab === "all" && (
                <UserList
                  users={notCheckedInKids}
                  actionName="Check in"
                  onClick={handleCheckInOrCheckOut}
                />
              )}
              {activeTab === "checkedIn" && (
                <UserList
                  users={checkedInKids}
                  actionName="Check out"
                  onClick={handleCheckInOrCheckOut}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function UserList({ users, actionName, onClick }) {
  return (
    <div className="flex flex-col gap-2">
      {users?.length > 0 ? (
        users?.map((user) => {
          return (
            <div
              key={user}
              className="flex justify-between px-4 py-4 rounded-lg bg-surface-secondary"
            >
              <UserCard userId={user} />
              <button onClick={() => onClick(user)}>{actionName}</button>
            </div>
          );
        })
      ) : (
        <p>No members found</p>
      )}
    </div>
  );
}

function UserCard({ userId }) {
  const { data } = useQuery(FIND_USER_DETAILS, {
    variables: {
      _id: userId,
    },
  });
  const userDetail = data?.me;

  return (
    <div className="flex items-center gap-3">
      <AvatarIcon avatar={iconFinder(userDetail?.avatar)} />
      <div>
        <span className="text-lg font-bold">{userDetail?.name}</span>
        <span>
          <span>{userDetail?.age}</span>
        </span>
      </div>
    </div>
  );
}
