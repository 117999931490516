import React, { useState, useEffect } from "react";
import {
  CLIENT_CREATED_EVENT_PAYMENT_STATUSES,
  EVENTS_TYPE,
  EVENT_APPROVAL_STATUSES,
  EVENT_DURATION,
  LOCAL_STORAGE_KEYS,
  PAYMENT_METHOD,
  RANDOM_AVATAR_URLS,
  TIME_SLOTS_DATA,
} from "../../utils/constant";
import { format, formatISO } from "date-fns";

function AdminSIdeEventCard({
  event,
  navigate,
  goToEventDetails,
  goToEventEditPage,
  goToKidDetails,
  bookSessionFromEventCard,
  findMyKids,
}) {
  // Event start time
  const [eventStartTime, setEventStartTime] = useState(new Date());

  // Event end time
  const [eventEndTime, setEventEndTime] = useState(new Date());

  // Initialize event data
  useEffect(() => {
    function initializeEventData() {
      // Update base baseOpeningHour
      if (event?.eventStartHour) {
        let currentDate = new Date();
        // set hour, minutes & seconds
        currentDate.setHours(event.eventStartHour);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        // set event start time
        setEventStartTime(currentDate);
      }

      // Update base baseClosingHour
      if (event?.eventEndHour) {
        let currentDate = new Date();
        // set hour, minutes & seconds
        currentDate.setHours(event?.eventEndHour);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        // set event end time
        setEventEndTime(currentDate);
      }
    }
    initializeEventData();
  }, []);

  return (
    <div className="w-full md:w-1/2">
      <div
        className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 mr-5 py-2"
        style={{
          borderLeft: "5px solid yellow",
          justifyContent: "space-between",
          position: "relative",
          backgroundColor: "#454f6680",
        }}
      >
        {/* DATE & Event INFO */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="py-2"
        >
          {/* DATE section */}
          <div
            style={{
              color: "white",
              width: "80px",
              borderRight: "1px dotted #ffffff45",
              paddingLeft: "15px",
            }}
            className="text-2xl"
          >
            {format(new Date(event.dateTime), "EEE MMM dd")}
            <div
              style={{
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              ( {format(new Date(event.dateTime), "yyyy")} )
            </div>
          </div>

          {/* Schdule INFO section */}
          <div className="font-semibold ml-5">
            {/* TIME */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                <div className="w-1 h-1 rounded-full bg-black"></div>
              </div>
              {/* Time value */}
              <div style={{ color: "#8F9BB3" }} className="text-sm ml-2">
                {event.eventType}
              </div>
            </div>

            {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
              <>
                {event.caption ? (
                  <div className="text-xl mt-1 mb-2">{event.caption}</div>
                ) : (
                  <div className="text-xl mt-1 mb-2">Special Event</div>
                )}
              </>
            ) : (
              <>
                {/* CAPTION */}
                <div className="text-sm">
                  Event Type:{" "}
                  <b style={{ color: "#ccd0e0" }}>{event.eventType}</b>
                </div>

                {/* CAPTION */}
                {event.caption && (
                  <div className="text-sm">
                    Title: <b style={{ color: "#ccd0e0" }}>{event.caption}</b>
                  </div>
                )}
              </>
            )}

            {/* DURATIOON */}
            {event.duration && (
              <div className="text-sm">
                Duration: <b style={{ color: "#ccd0e0" }}>{event.duration}</b>
              </div>
            )}

            {/* SPORTS */}
            {event.sports && event.sports.length > 0 && (
              <div className="text-sm">
                Sports:{" "}
                <b style={{ color: "#ccd0e0" }}>{event.sports.join(", ")}</b>
              </div>
            )}

            {/* GAME TYPE */}
            {event.gameType && (
              <div className="text-sm">
                Game Type: <b style={{ color: "#ccd0e0" }}>{event.gameType}</b>
              </div>
            )}

            {/* START & END Time */}
            <div
              className="flex items-center text-xl font-bold"
              style={{ color: "white" }}
            >
              <div>{format(eventStartTime, "h:mm aa")}</div>
              <div className="text-md mx-3 text-yellow-200">to</div>
              <div>{format(eventEndTime, "h:mm aa")}</div>
            </div>

            {/* PRICE */}
            {event.eventPrice && (
              <div className="text-md mt-2" style={{ color: "#00fff5" }}>
                Price: ${event.eventPrice}
              </div>
            )}
          </div>
        </div>

        <button
          className="bg-blue-500 text-lg mx-2 font-bold py-1 px-5 rounded-lg"
          style={{
            color: "#FFFFFF",
          }}
          onClick={() => {
            navigate(`${`/admin/update-event/${event._id}`}`);
          }}
        >
          DETAILS
        </button>
      </div>
    </div>
  );
}

export default AdminSIdeEventCard;
