import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import AdminPortal from "./AdminPortal";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CONTACT_FORM_URL, LOCAL_STORAGE_KEYS } from "../../utils/constant";
import { toast } from "react-toastify";

// Query
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";

// MUTATION
import ADD_BASE_SUB_ADMIN from "../../gql/mutation/base/addBaseSubAdmin.mutations";

function SubAdmins() {
  let baseId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);

  // Image state
  const [email, setEmail] = useState(null);

  // Query
  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [
    addBaseSubAdminMutation,
    { error: updateBaseError, loading: addBaseSubAdminLoading },
  ] = useMutation(ADD_BASE_SUB_ADMIN);

  // Update Image State from Database data
  useEffect(() => {
    function setDate() {
      baseRefetch();
    }
    setDate();
  }, [baseData]);

  // Submit to create Event
  async function addBaseSubAdmin() {
    if (!email || email == "") {
      toast.error("Please insert an email to add!");
      return;
    }

    const checkMail = validateEmail(email);
    if (!checkMail) {
      toast.error("You typed invalid mail");
      return;
    }

    const { data } = await addBaseSubAdminMutation({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        email: email.toLowerCase(),
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      },
    });

    if (data.addBaseSubAdmin.errorMessage == null) {
      baseRefetch();
      toast.success("Successfully added base admin!");
    } else {
      toast.error(data.addBaseSubAdmin.errorMessage);
    }
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  if (baseLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (baseError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="max-w-6xl mx-auto bg-slate-700 mt-10 py-3 px-8 mb-8 rounded-md">
        <h1 className="text-center font-bold text-3xl mt-2 mb-4">All admins</h1>

        {/* Sub Admins */}
        <table className="bg-slate-700/80 border-yellow-100 border border-b-transparent text-sm mx-auto">
          <thead className="border-b border-yellow-100">
            <tr>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Serial</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span className="">Name</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Email</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {baseData.findBaseDetails?.adminsDetails?.map((admin, index) => {
              return (
                <tr
                  className=" border-b border-yellow-100"
                  role="row"
                  key={index}
                  style={{
                    backgroundColor:
                      admin.email ==
                      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL)
                        ? "#3864a8ab"
                        : "",
                    fontWeight:
                      admin.email ==
                      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL)
                        ? "bold"
                        : "",
                    color:
                      admin.email ==
                      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL)
                        ? "yellow"
                        : "white",
                  }}
                >
                  {/* Serial */}
                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="mr-2 lg:mr-4">{index + 1}</span>
                  </td>

                  {/* Name */}
                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="mr-2 lg:mr-4">{admin.name}</span>
                  </td>

                  {/* Email */}
                  <td className=" lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className={"text-red cursor-pointer"}>
                      {admin.email}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* CREATE NEW SUB ADMIN */}
        <div className="text-2xl mt-10 mb-2 font-bold text-yellow-100">
          Add new admin
        </div>

        <div className="my-3 flex  flex-row w-[670px] items-center">
          <h1 className=" w-1/4  font-bold">
            Email<b style={{ color: "#ff0000a1" }}> </b>
          </h1>
          <input
            className="border-2 w-3/4 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Enter sub admin email"
          />
        </div>

        {/* SUBMIT button */}
        {addBaseSubAdminLoading ? (
          <button className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block">
            Loading
          </button>
        ) : (
          <button
            className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
            onClick={() => {
              addBaseSubAdmin();
            }}
          >
            Create
          </button>
        )}
      </div>
    </AdminPortal>
  );
}
export default SubAdmins;
