import Header from "./component/Header";
import { useMemo, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ThemeProvider } from "next-themes";

import Login from "./pages/bSports/auth/Login";

import VisaPayment from "./pages/bSports/nftPayment/VisaPayment";
import KidcoinPayment from "./pages/bSports/nftPayment/KidcoinPayment";
import { ShoppingCardContext } from "./context/cardContext";

import MyNfts from "./pages/bSports/nft/MyNfts";

import ShopingCard from "./pages/shop/ShopingCard";
import MintNfts from "./pages/bSports/nft/MintNfts";
import FirebaseAuth from "./pages/kidcoin/firebase/firebaseAuth";
import Signup from "./pages/bSports/auth/Signup";
import ForgetPass from "./pages/bSports/auth/ForgetPass";
import Profile from "./pages/bSports/Profile";

import NftRanking from "./pages/bSports/nft/NftRanking";
import Dashboard from "./pages/adminPortal/Dashboard";
import Users from "./pages/adminPortal/Users";
import Event from "./pages/adminPortal/Event";
import Groups from "./pages/adminPortal/Groups";
import AddUser from "./pages/adminPortal/AddUser";
import AddEvent from "./pages/adminPortal/AddEvent";
import BaseAdminRoute from "./routes/baseAdminRoute";
import CreateBaseByAdmin from "./pages/adminPortal/CreateBaseByAdmin";
import "./Custom.css";

// CSS
import "react-toastify/dist/ReactToastify.css";
import BaseManagement from "./pages/adminPortal/BaseManagement";
import Participants from "./pages/adminPortal/Participants";
import Registration from "./pages/base/registration";
import HomePage from "./pages/home/HomePage";
import Payments from "./pages/adminPortal/Payments";
import AddKid from "./pages/base/addKid";
import EventsCalender from "./pages/base/eventsCalender";
import PaymentHistory from "./pages/base/paymentHistory";
import ManageKids from "./pages/base/ManageKids";
import KidDetails from "./pages/base/KidDetails";
import Affiliates from "./pages/adminPortal/Affiliates";
import AddAffiliate from "./pages/adminPortal/AddAffiliate";
import BaseDashboard from "./pages/base/dashboard";
import VideoPlay from "./pages/base/VideoPlay";
import Highlights from "./pages/base/Highlights";
import BaseHome from "./pages/base/baseHome";
import CheckBaseLoggedInUser from "./routes/checkBaseLoggedInUser";
import EventDetails from "./pages/base/eventDetails";
import UpdateEvent from "./pages/adminPortal/UpdateEvent";
import NftAccount from "./pages/bSports/nft/NftAccount";
import MyEvents from "./pages/base/myEvents";
import SubAdmins from "./pages/adminPortal/SubAdmins";
import VideoHighlights from "./pages/base/VideoHighlights";
import KidManagement from "./pages/adminPortal/KidManagement";
import BaseHighlights from "./pages/adminPortal/BaseHighlights";
import BaseVideoPlay from "./pages/adminPortal/BaseVideoPlay";
import EventSendMail from "./pages/adminPortal/EventSendMail";
import LiveStreams from "./pages/base/LiveStreams";
import StreamPlay from "./pages/base/StreamPlay";
import AdminKidDetails from "./pages/adminPortal/AdminKidDetails";
import AddEventParticipants from "./pages/adminPortal/AddEventParticipants";
import RecurringEvents from "./pages/adminPortal/RecurringEvents";
import AddEventByClient from "./pages/base/addEventByClient";
import UpdateEventByClient from "./pages/base/updateEventByClient";
import SessionManagement from "./pages/adminPortal/SessionManagement";
import EventManagement from "./pages/adminPortal/EventManagement";
import ChatWithClient from "./pages/adminPortal/chatWithClient";
import ChatWithAdmin from "./pages/base/chatWithAdmin";
import NewMessages from "./pages/adminPortal/NewMessages";

function App() {
  const [card, setCard] = useState({
    totalNft: 0,
    allSelectedNft: [],
    collectionType: "Female",
  });
  const shopingCard = useMemo(() => ({ card, setCard }), [card, setCard]);

  return (
    <ShoppingCardContext.Provider value={shopingCard}>
      <ThemeProvider defaultTheme="dark" attribute="class">
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/forget-password" element={<ForgetPass />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="/my-nfts" element={<MyNfts />} />
            <Route path="/nft-account" element={<NftAccount />} />
            <Route path="/world-cup-nft-rankings" element={<NftRanking />} />

            <Route path="/mint-nfts" element={<MintNfts />} />
            <Route path="/payment-history" element={<PaymentHistory />} />

            <Route path="/cart" element={<ShopingCard />} />

            <Route path="/mintNftWithVisa" element={<VisaPayment />} />

            <Route path="/eventPay" element={<VisaPayment />} />

            <Route path="/mintNftWithKidCoin" element={<KidcoinPayment />} />
            <Route path="/kidcoinLogin" element={<FirebaseAuth />} />

            <Route path="/add-event/:eventId?" element={<AddEventByClient />} />
            <Route
              path="/update-event/:eventId?"
              element={<UpdateEventByClient />}
            />

            {/* ADMIN ROUTES. */}
            {/* ADMIN ROUTES. */}
            <Route path="/admin/createBase" element={<CreateBaseByAdmin />} />
            {/* ADMIN ROUTES. Check accessibility. */}
            <Route
              path="/admin"
              element={<BaseAdminRoute element={<Event />} />}
            />
            <Route
              path="/admin/users"
              element={<BaseAdminRoute element={<Users />} />}
            />
            <Route
              path="/admin/add-user"
              element={<BaseAdminRoute element={<AddUser />} />}
            />
            <Route
              path="/admin/calendar-management"
              element={<BaseAdminRoute element={<Event />} />}
            />
            <Route
              path="/admin/recurring-events/:eventId"
              element={<BaseAdminRoute element={<RecurringEvents />} />}
            />
            <Route
              path="/admin/add-event/:eventId?"
              element={<BaseAdminRoute element={<AddEvent />} />}
            />
            <Route
              path="/admin/update-event/:eventId"
              element={<BaseAdminRoute element={<UpdateEvent />} />}
            />
            <Route
              path="/admin/event-send-mail/:eventId"
              element={<BaseAdminRoute element={<EventSendMail />} />}
            />
            <Route
              path="/admin/groups"
              element={<BaseAdminRoute element={<Groups />} />}
            />
            <Route
              path="/admin/base-management"
              element={<BaseAdminRoute element={<BaseManagement />} />}
            />
            <Route
              path="/admin/session-management"
              element={<BaseAdminRoute element={<SessionManagement />} />}
            />
            <Route
              path="/admin/new-messages"
              element={<BaseAdminRoute element={<NewMessages />} />}
            />
            <Route
              path="/admin/event-management"
              element={<BaseAdminRoute element={<EventManagement />} />}
            />
            <Route
              path="/admin/sub-admins"
              element={<BaseAdminRoute element={<SubAdmins />} />}
            />
            <Route
              path="/admin/kid-management"
              element={<BaseAdminRoute element={<KidManagement />} />}
            />
            <Route
              path="/admin/kid-details/:kidPlayerId"
              element={<BaseAdminRoute element={<AdminKidDetails />} />}
            />

            <Route
              path="/admin/video/:videoId"
              element={<BaseAdminRoute element={<BaseVideoPlay />} />}
            />

            <Route
              path="/admin/base-highlights"
              element={<BaseAdminRoute element={<BaseHighlights />} />}
            />
            <Route
              path="/admin/chat/:eventId"
              element={<BaseAdminRoute element={<ChatWithClient />} />}
            />
            <Route
              path="/admin/participants/:eventId"
              element={<BaseAdminRoute element={<Participants />} />}
            />
            <Route
              path="/admin/add-participants/:eventId"
              element={<BaseAdminRoute element={<AddEventParticipants />} />}
            />
            <Route
              path="/admin/payments"
              element={<BaseAdminRoute element={<Payments />} />}
            />
            <Route
              path="/admin/affiliates"
              element={<BaseAdminRoute element={<Affiliates />} />}
            />
            <Route
              path="/admin/add-affiliate"
              element={<BaseAdminRoute element={<AddAffiliate />} />}
            />

            {/* BASE FRONTEND for customer */}
            <Route path="/:baseSlug/registration" element={<Registration />} />

            <Route
              path="/add-kid"
              element={<CheckBaseLoggedInUser element={<AddKid />} />}
            />
            <Route
              path="/manage-kids"
              element={<CheckBaseLoggedInUser element={<ManageKids />} />}
            />
            <Route path="/highlights" element={<VideoHighlights />} />
            <Route path="/streams" element={<LiveStreams />} />
            <Route path="/kid-details/:kidPlayerId" element={<KidDetails />} />
            <Route path="/event-details/:eventId" element={<EventDetails />} />
            <Route path="/chat/:eventId" element={<ChatWithAdmin />} />
            <Route path="/video/:videoId" element={<VideoPlay />} />
            <Route
              path="/:baseSlug/stream/:streamId"
              element={<StreamPlay />}
            />
            <Route
              path="/highlight/:highlightId/:userId"
              element={<Highlights />}
            />
            <Route
              path="/:baseSlug/eventscalender"
              element={<EventsCalender />}
            />

            <Route path="/:baseSlug/my-events" element={<MyEvents />} />

            {/*  Not using any more 👇 */}
            <Route path="/dashboard" element={<BaseDashboard />} />

            <Route path="/:suuperbaseSlug/dashboard" element={<BaseHome />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ShoppingCardContext.Provider>
  );
}

export default App;
