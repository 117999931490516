import gql from "graphql-tag";

export default gql`
  mutation createMessage(
    $createdBy: String
    $userType: String
    $baseSlug: String
    $message: String
    $eventId: String
  ) {
    createMessage(
      createdBy: $createdBy
      userType: $userType
      baseSlug: $baseSlug
      message: $message
      eventId: $eventId
    ) {
      _id
    }
  }
`;
