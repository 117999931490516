import gql from "graphql-tag";

export default gql`
  mutation updateEvent(
    $eventId: String
    $caption: String
    $dateTime: String
    $eventPrice: Int
    $sports: [String]
    $gameType: String
    $description: String
    $easyDescription: String
    $bannerImage: String
    $promoCodes: [PromoCodes]
    $address: String
    $hasRecurring: Boolean
    $recurringStartDate: String
    $recurringEndDate: String
    $recurringDays: [String]
    $eventStartHour: Int
    $eventEndHour: Int
    $discount: Int
    $adminUserId: String
  ) {
    updateEvent(
      eventId: $eventId
      caption: $caption
      dateTime: $dateTime
      eventPrice: $eventPrice
      sports: $sports
      gameType: $gameType
      description: $description
      easyDescription: $easyDescription
      bannerImage: $bannerImage
      promoCodes: $promoCodes
      address: $address
      hasRecurring: $hasRecurring
      recurringStartDate: $recurringStartDate
      recurringEndDate: $recurringEndDate
      recurringDays: $recurringDays
      eventStartHour: $eventStartHour
      eventEndHour: $eventEndHour
      discount: $discount
      adminUserId: $adminUserId
    ) {
      _id
    }
  }
`;
