import gql from "graphql-tag";

export default gql`
  mutation updateBaseByAdmin(
    $baseId: String
    $bannerImage: String
    $slotDetails: baseExtraDetails
    $membershipDetails: baseExtraDetails
    $sliderImages: [String]
    $baseAddress: String
    $capacity: Int
    $payByCash: Boolean
    $isEnabledDeposit: Boolean
    $depositInPercent: Int
    $baseOpeningHour: Int
    $baseClosingHour: Int
    $perHourPrice: Int
    $eventMinimumHour: Int
    $zelleNumber: String
    $venmoNumber: String
  ) {
    updateBaseByAdmin(
      baseId: $baseId
      bannerImage: $bannerImage
      slotDetails: $slotDetails
      membershipDetails: $membershipDetails
      sliderImages: $sliderImages
      baseAddress: $baseAddress
      capacity: $capacity
      payByCash: $payByCash
      isEnabledDeposit: $isEnabledDeposit
      depositInPercent: $depositInPercent
      baseOpeningHour: $baseOpeningHour
      baseClosingHour: $baseClosingHour
      perHourPrice: $perHourPrice
      eventMinimumHour: $eventMinimumHour
      zelleNumber: $zelleNumber
      venmoNumber: $venmoNumber
    ) {
      _id
      name
      logo
      bannerImage
      lattitude
      longitude
      city
      country
      difficulty
      sport
      errorMessage
    }
  }
`;
