import { useEffect, useState } from "react";
import { AUTH_TOKEN, LOCAL_STORAGE_KEYS } from "../../utils/constant";
import { useQuery } from "@apollo/client";
import FIND_ALL_LIVE_STREAMS from "../query/base/findAllStreamsByFilter.query";
import FIND_BASE_DETAILS from "../query/base/findBaseDetailsForBaseDashboard.query";

// TODO get by base Id
export default function useGetRecordings() {
  const [recordingsLoaded, setRecordingsLoaded] = useState(false);
  const [recordings, setRecordings] = useState(null);

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
      //baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  const {
    data: videoStreams,
    loading: loadingVideoStreams,
    error: errorVideoStreams,
    refetch: refetchVideoStreams,
  } = useQuery(FIND_ALL_LIVE_STREAMS, {
    variables: {
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
    },
  });

  const getAllRecordings = async (meetingId) => {
    const res = await fetch(
      `https://api.videosdk.live/v2/recordings/?roomId=${meetingId}`,
      {
        method: "GET",
        headers: {
          authorization: `${AUTH_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const fetchRecordings = async () => {
    const recordingsData = await Promise.all(
      videoStreams?.findAllStreamsByFilter?.map(async (stream) => {
        const recordings = await getAllRecordings(stream.channelName);
        //console.log(recordings);

        if (recordings.pageInfo.total > 0) {          
          return recordings.data?.map((recording) => {
            //console.log("Recording created at", recording.createdAt);
            return {
              ...stream,
              baseDetails: baseData?.findBaseDetails,
              url: recording?.file?.fileUrl,
              streamStartedAt: recording?.createdAt,
            };
          });
        }
        return [];
      })
    );

    let flattenedRecordings = recordingsData.flat();
    flattenedRecordings.sort(
      (a, b) => new Date(b.streamStartedAt) - new Date(a.streamStartedAt)
    );

    setRecordings(flattenedRecordings);
    setRecordingsLoaded(true);
  };

  useEffect(() => {
    if (videoStreams?.findAllStreamsByFilter && baseData?.findBaseDetails) {
      if (recordingsLoaded) return;
      fetchRecordings();
    } else {
      refetchVideoStreams();
      baseRefetch();
    }
  }, [videoStreams, baseData]);

  //console.log(recordings);

  return {
    data: recordings ?? [],
    loading: loadingVideoStreams,
    error: errorVideoStreams, // TODO: include errors encountered fetching recordings
  };
}
