import { gql } from "@apollo/client";

export default gql`
  query findAdminEventsWithUnseenMessages($userId: String, $baseSlug: String) {
    findAdminEventsWithUnseenMessages(userId: $userId, baseSlug: $baseSlug) {
      _id
      eventType
      caption
      dateTime
      eventPrice
      duration
      sports
      gameType
      bookedBy
      slot
      participantKidIds
      timeSlots
      paymentStatus
      createdBy
      paymentMethod
      isCreatedByAdmin
      amountToPay
      paidAmount
      approvalStatus
      eventDuration
      dateTimeAsDate
      eventStartHour
      eventEndHour
      venmoOrZelleTrnxId
      unseenMessageCountsForAdmin
    }
  }
`;
