import { gql } from "@apollo/client";

export default gql`
  query findBasePlayers(
    $baseId: String
    $baseSlug: String
    $searchName: String
  ) {
    findBasePlayers(
      baseId: $baseId
      baseSlug: $baseSlug
      searchName: $searchName
    ) {
      _id
      name
      userId
      createdBy
      avatar
      parentRelation

      user {
        _id
        name
        parentId
        role
        email
        verified

        parentDetails {
          _id
          name
          email
          avatar
          role

          player {
            _id
          }
        }
      }
    }
  }
`;
