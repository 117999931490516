import React, { useState, useEffect } from "react";
import AdminPortal from "./AdminPortal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useQuery } from "@apollo/client";
import DataLoading from "../../component/loader/DataLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faUsers,
  faUser,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import __ from "lodash";
import iconFinder from "../../utils/iconFinder";

// Query
import FIND_EVENT_PARTICIPANTS from "../../gql/query/base/findEventParticipantUsers.query";
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";
import { BASEMENT_SPORTS_OFFICAL_LOCATION } from "../../utils/constant";

function Participants() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParent, setKidsWithParents] = useState([]);
  const [kidsWithoutParent, setKidsWithoutParent] = useState([]);

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParentToShow, setKidsWithParentsToShow] = useState([]);
  const [kidsWithoutParentToShow, setKidsWithoutParentToShow] = useState([]);

  // Query
  const {
    data: eventParticipants,
    loading: eventParticipantsLoading,
    error: baseError,
    refetch: eventParticipantsRefetch,
  } = useQuery(FIND_EVENT_PARTICIPANTS, {
    variables: {
      eventId,
    },
  });

  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_A_EVENT, {
    variables: {
      eventId,
    },
  });

  useEffect(() => {
    function manipulateBasePlayers() {
      if (eventParticipants?.findEventParticipantUsers) {
        const allUsers = eventParticipants?.findEventParticipantUsers;

        // ✅ First array (If the "parentId" exists)
        // ✅ First array (If the "parentId" exists)
        const filteredArray = __.filter(
          allUsers,
          (user) =>
            user?.parentId && user.parentId != "" && user.parentId != undefined
        );
        // Group by similar "parentId"
        const groupedArray = __.groupBy(filteredArray, (user) => user.parentId);
        // Now create an object like this format { "parentId1": { name: "parentId1_name", email: "parentId1_email", kids: all_kids } }
        const resultArray = __.map(groupedArray, (value, key) => {
          const parentDetails = value[0].parentDetails;
          const kids = value;
          return {
            _id: parentDetails?._id,
            playerId: parentDetails?.player?._id,
            name: parentDetails?.name,
            email: parentDetails?.email,
            avatar: parentDetails?.avatar,
            role: parentDetails?.role,
            kids: kids,
          };
        });

        // ✅ Second array (If the "parentId" not exists)
        // ✅ Second array (If the "parentId" not exists)
        const secondArray = __.filter(
          allUsers,
          (user) =>
            !user.parentId || user.parentId == "" || user.parentId == undefined
        );
        // all parent User IDS those have KIDS
        let parentIdsThoseHaveKids = Object.keys(groupedArray);
        // Now remove those already added parents from this second array
        const finalSecondFilteredArray = secondArray.filter(
          (user) => !parentIdsThoseHaveKids.includes(user._id)
        );

        // Update STATE
        setKidsWithParents(resultArray);
        setKidsWithoutParent(finalSecondFilteredArray);
        // Update STATE (To Show)
        setKidsWithParentsToShow(resultArray);
        setKidsWithoutParentToShow(finalSecondFilteredArray);
      }
    }
    manipulateBasePlayers();
  }, [eventParticipants]);

  // Refetch data on focus
  useEffect(() => {
    function refetchData() {
      eventParticipantsRefetch();
      eventsRefetch();
    }
    refetchData();
  }, []);

  if (eventParticipantsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  return (
    <AdminPortal>
      <div
        className="md:w-11/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mt-8 mb-3 "
        style={{ justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="cursor-pointer"
          >
            <FontAwesomeIcon className="mr-3" icon={faCircleArrowLeft} />
          </div>
          <h1 className="text-center font-bold text-2xl ml-7">
            Event Participants
          </h1>
        </div>
        <div>
          <Link to={`/admin/add-participants/${eventId}`} className="mr-4">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Add Participants
            </button>
          </Link>
        </div>
      </div>

      {/* EVENT DETAILS */}
      {!eventsLoading && (
        <div
          className={`overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto px-4 flex items-center font-display text-yellow-300 text-lg rounded-lg py-2 mt-5`}
          style={{
            justifyContent: "space-between",
            backgroundColor: "#454f6680",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
            }}
            className="py-2"
          >
            <div>
              <h1>{eventsData.findEvent?.caption}</h1>
              <h1>
                Date :{" "}
                {format(
                  new Date(eventsData.findEvent?.dateTime),
                  "do MMM yyyy"
                )}
              </h1>

              <div>
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="text-sm"
                >
                  {eventsData?.findEvent?.timeSlots?.map((slot) => (
                    <div
                      className={`bg-green-500 hover:bg-green-700 px-2 py-2 rounded-lg mt-2 mr-2 font-bold cursor-pointer`}
                      style={{ color: "white" }}
                    >
                      {slot}
                    </div>
                  ))}
                </div>
              </div>

              {/* Location */}
              <h1>
                Location :{" "}
                {eventsData.findEvent?.address
                  ? eventsData.findEvent?.address
                  : BASEMENT_SPORTS_OFFICAL_LOCATION}
              </h1>
            </div>
          </div>
        </div>
      )}

      <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto">
        <div className="max-w-6xl mx-auto bg-slate-700 mt-8 pb-7 px-8 mb-8 rounded-md">
          {/* ✅✅✅✅✅ (Users who have a PARENT/KID relation) */}
          {!eventParticipantsLoading && kidsWithParentToShow.length > 0 && (
            <h1 className="text-center font-bold text-2xl pt-8">
              Event Participants (Users who have a Parent/Kid relation)
            </h1>
          )}

          {!eventParticipantsLoading && kidsWithParentToShow.length > 0 && (
            <h1 className="font-bold text-xl mt-3">
              <b style={{ color: "yellow" }}>{kidsWithParentToShow.length}</b>{" "}
              Parents found
            </h1>
          )}

          <div className="">
            {!eventParticipantsLoading &&
              kidsWithParentToShow?.map((player, index) => {
                return (
                  <div
                    className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                    style={{
                      borderLeft: "5px solid green",
                      justifyContent: "space-between",
                      height: "100%",
                      backgroundColor: "#454f6680",
                      position: "relative",
                    }}
                  >
                    {/* Family logo */}
                    <div
                      style={{ position: "absolute", bottom: 8, left: 8 }}
                      data-tooltip-id="tooltip-content"
                      data-tooltip-content={"Parent"}
                      className="cursor-pointer"
                    >
                      <FontAwesomeIcon
                        className="mr-3"
                        icon={faUsers}
                        size="2x"
                        opacity={0.1}
                      />
                    </div>
                    {/* Parent AVATAR + Name */}
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                      }}
                      className="py-2"
                    >
                      {/* Parent AVATAR */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={iconFinder(player.avatar)}
                          alt="Basement Sports"
                          className="max-h-17 cursor-pointer"
                          style={{
                            color: "white",
                            minWidth: "100px",
                            maxWidth: "100px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                          }}
                          onClick={() => {
                            navigate(`/admin/kid-details/${player.playerId}`);
                          }}
                        />
                      </div>

                      {/* PARENT INFO SECTION */}
                      <div
                        className="ml-5"
                        style={{
                          borderRight: "1px dotted #ffffff45",
                          paddingRight: "60px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          minWidth: "250px",
                          maxWidth: "250px",
                          overflow: "hidden",
                        }}
                      >
                        {/* Parent Name */}
                        <div>{player.name}</div>
                        {/* Parent Email */}
                        <div>{player?.email}</div>
                        <div>Role: {player.role}</div>
                      </div>

                      {/* KID DETAILS + KID GAME INFOS */}
                      <div
                        style={{
                          color: "#ccd0e0",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                        className="pl-6 text-sm  pt-1 pb-3"
                      >
                        <div className="text-xl font-bold">
                          Kid list -{" "}
                          <b style={{ color: "yellow" }}>
                            {player.kids?.length ? player.kids?.length : 0}
                          </b>{" "}
                          Kids found
                        </div>
                        {/* ALL KIDS */}
                        {/* ALL KIDS */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexWrap: "wrap",
                          }}
                          className="mt-5"
                        >
                          {player.kids.map((kid) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                minWidth: "100px",
                              }}
                              className="mx-1 mb-2"
                            >
                              {/* KID AVATAR */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={iconFinder(kid.avatar)}
                                  alt="Basement Sports"
                                  className="max-h-17 cursor-pointer"
                                  style={{
                                    color: "white",
                                    minHeight: "50px",
                                    maxHeight: "50px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                  }}
                                  onClick={() => {
                                    navigate(
                                      `/admin/kid-details/${kid.player._id}`
                                    );
                                  }}
                                />
                              </div>

                              {/* KID INFO SECTION */}
                              <div
                                style={{ textAlign: "center" }}
                                className="mt-1"
                              >
                                {/* KID Name */}
                                <div>{kid.name}</div>
                                {/* CHECKED-In or not */}
                                {eventsData?.findEvent?.checkedInUserIds?.includes(
                                  kid._id
                                ) && (
                                  <div
                                    className="text-white font-bold py-1 px-3 mt-1 rounded"
                                    style={{ backgroundColor: "#189b32" }}
                                  >
                                    Checked In
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* ✅✅✅✅✅ (Users who have no PARENT/KID relation) */}
          {!eventParticipantsLoading && kidsWithoutParentToShow.length > 0 && (
            <h1 className="pt-8 text-center font-bold text-2xl">
              Event Participants (Users who have no Parent/Kid relation)
            </h1>
          )}

          {!eventParticipantsLoading && kidsWithoutParentToShow.length > 0 && (
            <h1 className="font-bold text-xl  mt-3">
              <b style={{ color: "yellow" }}>
                {kidsWithoutParentToShow.length}
              </b>{" "}
              General users found
            </h1>
          )}

          <div
            className="flex flex-wrap items-center font-display text-yellow-300 text-lg rounded-lg py-2"
            style={{
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            {!eventParticipantsLoading &&
              kidsWithoutParentToShow?.map((player, index) => {
                return (
                  <div
                    style={{
                      height: "100%",
                      position: "relative",
                    }}
                    className="w-full md:w-1/2"
                  >
                    {/* Family logo */}
                    <div
                      style={{ position: "absolute", bottom: 15, left: 20 }}
                      data-tooltip-id="tooltip-content"
                      data-tooltip-content={
                        player.user?.verified
                          ? "Verified User"
                          : "Not verified User"
                      }
                      className="cursor-pointer"
                    >
                      <FontAwesomeIcon
                        className="mr-3"
                        icon={player.user?.verified ? faUser : faMobileScreen}
                        size="xl"
                        opacity={0.3}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        backgroundColor: "#454f6680",
                        borderRadius: "5px",
                        borderLeft: `5px solid ${
                          player.user?.verified ? "#0095ff" : "red"
                        }`,
                      }}
                      className="mx-2 my-2 px-3 py-4 shadow-md shadow-black/30"
                    >
                      {/* Parent AVATAR */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={iconFinder(player.avatar)}
                          alt="Basement Sports"
                          style={{
                            color: "white",
                            minWidth: "100px",
                            maxWidth: "100px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                          }}
                          className="cursor-pointer"
                          onClick={() => {
                            navigate(`/admin/kid-details/${player.player._id}`);
                          }}
                        />
                      </div>

                      {/* PARENT INFO SECTION */}
                      <div
                        className="ml-5"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        {/* Parent Name */}
                        <div>{player.name}</div>
                        {/* Parent Email */}
                        <div className="text-sm">{player.email}</div>
                        {/* CHECKED-In or not */}
                        {eventsData?.findEvent?.checkedInUserIds?.includes(
                          player._id
                        ) && (
                          <div
                            className="text-white font-bold py-1 px-3 mt-1 rounded w-24 text-center text-sm"
                            style={{ backgroundColor: "#189b32" }}
                          >
                            Checked In
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {eventParticipantsLoading && (
            <div
              className="mt-10 mb-14"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
              }}
            >
              <DataLoading loadingType="success" />
            </div>
          )}
        </div>
      </div>
    </AdminPortal>
  );
}
export default Participants;
