import { useState, useEffect } from "react";

function useFrontDeskMode(defaultState = false) {
  const [isFrontDeskMode, setIsFrontDeskMode] = useState(defaultState);

  const toggleFrontDeskMode = () => {
    setIsFrontDeskMode((prevState) => {
      const newState = !prevState;
      localStorage.setItem("isFrontDeskMode", JSON.stringify(newState)); 
      return newState;
    });
  };

  // useEffect to load the state from local storage when the component mounts
  useEffect(() => {
    const storedState = localStorage.getItem("isFrontDeskMode");
    if (storedState !== null) {
      setIsFrontDeskMode(JSON.parse(storedState)); 
    } else {
      setIsFrontDeskMode(defaultState);
    }
  }, []); // only once when the component mounts

  return [isFrontDeskMode, toggleFrontDeskMode];
}

export default useFrontDeskMode;
