import { gql } from "@apollo/client";

export default gql`
  query findBaseDetails($baseSlug: String, $baseId: String) {
    findBaseDetails(baseSlug: $baseSlug, baseId: $baseId) {
      _id
      name
      slug
      logo
      bannerImage
      lattitude
      longitude
      city
      country
      organizerUserId
      playersType
      members
      difficulty
      sport
      createdBy
      type
      description
      sliderImages
      address
      timeSlots
      capacity
      payByCash
      isEnabledDeposit
      depositInPercent
      halfDayPrice
      fullDayPrice
      baseOpeningHour
      baseClosingHour
      perHourPrice
      eventMinimumHour

      zelleNumber
      venmoNumber

      events {
        _id
        eventType
        caption
        dateTime
        eventPrice
        duration
        sports
        gameType
        slot
        participantKidIds
      }

      slotDetails {
        price
        discount
        subTtile
        description
      }
      membershipDetails {
        price
        discount
        subTtile
        description
      }

      membersDetail {
        name
        email
      }

      adminIds
      adminsDetails {
        name
        email
      }
    }
  }
`;
