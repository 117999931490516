import React, { useState, useEffect } from "react";
import AdminPortal from "./AdminPortal";
import Calendar from "react-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useQuery } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format, formatISO } from "date-fns";
import { faRotateBack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import ClipLoader from "react-spinners/ClipLoader";

// Query
import FIND_RECURRING_EVENTS from "../../gql/query/base/findRecurringEvents.query";

import {
  EVENTS_FILTER_BY,
  EVENTS_TYPE,
  LOCAL_STORAGE_KEYS,
  MONTHS_BY_NUMBER,
} from "../../utils/constant";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";

function RecurringEvents() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  let baseId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);

  // Calender State
  const [dateValue, setDateValue] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedFilterType, setFilterType] = useState(EVENTS_FILTER_BY.DATE);

  // Search filter STATE
  const [searchEventName, setSearchEventName] = useState("");

  // Query
  const {
    data: recurringEvents,
    loading: recurringEventsLoading,
    error: recurringEventsError,
    refetch: recurringEventsRefetch,
  } = useQuery(FIND_RECURRING_EVENTS, {
    variables: {
      eventId,
    },
  });

  // Refetch event data
  useEffect(() => {
    function refetchRecurringEvents() {
      recurringEventsRefetch();
    }
    refetchRecurringEvents();
  }, []);

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  if (recurringEventsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="bg-slate-700 flex justify-between mb-3 px-5 py-3 mt-8">
        <h3 className="font-display font-bold text-lg">Recurring Events</h3>
      </div>

      {/* SCHDULES */}
      {recurringEventsLoading ? (
        <div className="mt-20 w-full md:w-1/2">
          <DataLoading loadingType="success" />
        </div>
      ) : (
        <div className="w-full md:w-1/2 ">
          <div>
            {recurringEvents.findRecurringEvents?.map((event, index) => {
              return (
                <div>
                  {/* DATE + EVENT info + BOOK session button */}
                  <div
                    className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:ml-5 py-2"
                    key={index}
                    style={{
                      backgroundColor: "#86939140",
                      borderLeft: "5px solid yellow",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* DATE & Event INFO */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="py-2"
                    >
                      {/* DATE section */}
                      <div
                        style={{
                          color: "white",
                          width: "80px",
                          borderRight: "1px dotted #ffffff45",
                          paddingLeft: "15px",
                        }}
                        className="text-2xl"
                      >
                        {format(new Date(event.dateTime), "EEE MMM dd")}
                        <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                          ( {format(new Date(event.dateTime), "yyyy")} )
                        </div>
                      </div>

                      {/* Schdule INFO section */}
                      <div className="font-semibold ml-5">
                        {/* TIME */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                            <div className="w-1 h-1 rounded-full bg-black"></div>
                          </div>
                          {/* Time value */}
                          <div
                            style={{ color: "#8F9BB3" }}
                            className="text-sm ml-2"
                          >
                            {format(new Date(event.dateTime), "h:mm aa")}
                          </div>
                        </div>

                        {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
                          <>
                            {event.caption ? (
                              <div className="text-xl mt-1 mb-2">
                                {event.caption}
                              </div>
                            ) : (
                              <div className="text-xl mt-1 mb-2">
                                Special Event
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {/* CAPTION */}
                            <div className="text-sm">
                              Event Type:{" "}
                              <b style={{ color: "#ccd0e0" }}>
                                {event.eventType}
                              </b>
                            </div>

                            {/* CAPTION */}
                            {event.caption && (
                              <div className="text-sm">
                                Title:{" "}
                                <b style={{ color: "#ccd0e0" }}>
                                  {event.caption}
                                </b>
                              </div>
                            )}
                          </>
                        )}

                        {/* DURATIOON */}
                        {event.duration && (
                          <div className="text-sm">
                            Duration:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.duration}</b>
                          </div>
                        )}

                        {/* SPORTS */}
                        {event.sports && event.sports.length > 0 && (
                          <div className="text-sm">
                            Sports:{" "}
                            <b style={{ color: "#ccd0e0" }}>
                              {event.sports.join(", ")}
                            </b>
                          </div>
                        )}

                        {/* GAME TYPE */}
                        {event.gameType && (
                          <div className="text-sm">
                            Game Type:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.gameType}</b>
                          </div>
                        )}

                        {/* SLOT */}
                        {event.slot && (
                          <div className="text-sm">
                            #SLOT:{" "}
                            <b style={{ color: "#ccd0e0" }}>{event.slot}</b>
                          </div>
                        )}

                        {/* PRICE */}
                        {event.eventPrice && (
                          <div
                            className="text-md mt-2"
                            style={{ color: "#00fff5" }}
                          >
                            Price: ${event.eventPrice}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mr-5">
                      {/* Event update button */}
                      <button
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mr-2"
                        onClick={() => {
                          navigate(`${`/admin/update-event/${event._id}`}`);
                        }}
                      >
                        Details
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </AdminPortal>
  );
}
export default RecurringEvents;
