import gql from "graphql-tag";

export default gql`
  mutation verifyCashOfClientEvent(
    $adminUserId: String
    $eventId: String
    $paidAmountByClient: Int
  ) {
    verifyCashOfClientEvent(
      adminUserId: $adminUserId
      eventId: $eventId
      paidAmountByClient: $paidAmountByClient
    ) {
      successMessage
      errorMessage
    }
  }
`;
