import { gql } from "@apollo/client";

export default gql`
  query findEvent($eventId: String!) {
    findEvent(eventId: $eventId) {
      _id
      eventType
      caption
      dateTime
      duration
      sports
      gameType
      slot
      participantKidIds
      checkedInUserIds
      paymentStatus
      createdBy
      isCreatedByAdmin
      paymentMethod
      amountToPay
      paidAmount
      approvalStatus
      eventDuration
      eventStartHour
      eventEndHour
      unseenMessageCounts
      discount

      hasRecurring
      recurringStartDate
      recurringEndDate
      recurringDays
      recurringEventIds

      participantKidDetails {
        _id
        name
        avatar
        gender
      }

      createdByDetails {
        _id
        name
        avatar
        email
        role
        player {
          _id
        }
      }

      participantParentIds

      eventPrice
      description
      bannerImage

      address
      capacity
      timeSlots

      promoCodes {
        code
      }

      bookedBy
      invitedTo {
        email
        acceptStatus
        userId
        avatar
        name
        playerId
      }

      streamingRecords {
        _id
        userDetails {
          _id
          avatar
        }
        url
        videoThumbnailUrl
        caption
        createdAt
      }
    }
  }
`;
